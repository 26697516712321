<div [ngClass]="entityClassName">
  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [toolbarInstanceId]="instanceName"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [properties]="listOptions"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
    <div *entityListItemActions="let item">
      <div ngbDropdown container="body" class="col-main-table-actions">
        <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
        <div ngbDropdownMenu>
          <button type="button" ngbDropdownItem (click)="edit(item.data)">
            <i class="fa fa-pencil-square-o fa-fw"></i>&nbsp;<span [translate]="'transferenciasarmazens.list.actions.editar'"></span>
          </button>

          <button type="button" ngbDropdownItem (click)="showRastreabilidade(item.data)">
            <i class="fa fa-binoculars fa-fw me-1"></i><span [translate]="'docscomerciais.actions.rastreabilidade'"></span>
          </button>
        </div>
      </div>
    </div>
    <div *entityListItemDetail="let item">
      <tranferencias-armazens-detail [doc]="item.data._doc"></tranferencias-armazens-detail>
    </div>
  </entity-list>
</div>
