<div class="clifos-gdocs" *ngIf="!modeComments">
  <gdocs-viewer
    [folderId]="folderID"
    [showTreeViewer]="false"
    [treeCollapsed]="false"
    [params]="params"
    [tipoDocEntity]="tipoDocEntity"
    (evtAddingFile)="onGDocsViewerAddingFile($event)"
    [callback]="callbackGDocs">
  </gdocs-viewer>
</div>
<div class="clifos-gdocsComments" *ngIf="modeComments">
  <gdoccomentarios-viewer [folderId]="folderID" [params]="params" [callback]="callbackGDocs" [tipoDocEntity]="tipoDocEntity"> </gdoccomentarios-viewer>
</div>
