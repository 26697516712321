import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ApiService} from '../../../services/api/api.service';
import {AuthService} from '../../../services/auth/auth.service';
import {EFimAnoState, IJsonFimAnoStatus} from './jsonFimAno.module.interface';
import {ENTITY_NAME_EMPRESAS_ERP, IEmpresaExtended} from '../../../entities/empresas/empresas.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IFimAnoExecute, IFimAnoUIData} from './fimAno.module.interface';
import {IStatusService} from '../../../services/generic/status.service.interface';
import moment, {Moment} from 'moment';
import {TDate} from '../../../../common/dates';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class FimAnoService implements IStatusService<EFimAnoState, IJsonFimAnoStatus> {
  private readonly _path: string;
  private readonly _serviceEmpresas: IEntityService<IEmpresaExtended>;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _authService: AuthService
  ) {
    this._path = `${this._apiService.path.restapi}/fimano`;
    this._serviceEmpresas = this._entityServiceBuilder.build<IEmpresaExtended>(ENTITY_NAME_EMPRESAS_ERP);
  }

  public status(): TServiceResponse<IJsonFimAnoStatus> {
    return this._apiService.get<IJsonFimAnoStatus>({url: `${this._path}/status/execucao`});
  }

  public scheduled(status: IJsonFimAnoStatus): boolean {
    return status.state === EFimAnoState.Scheduled;
  }

  public running(status: IJsonFimAnoStatus): boolean {
    return status.state === EFimAnoState.Started;
  }

  public finished(status: IJsonFimAnoStatus): boolean {
    return status.state === EFimAnoState.Ended;
  }

  public cancelable(): boolean {
    return true;
  }

  public async fetchUIData(): Promise<IFimAnoUIData> {
    const session: TUserSession = await this._authService.identity();
    const responseEmpresa: HttpResponse<IEmpresaExtended> = await this._serviceEmpresas.get({id: session.erp.nEmpresa});
    const empresa: IEmpresaExtended = responseEmpresa.body;
    const fimAnoUIData: IFimAnoUIData = {
      companyName: `${empresa.nempresa} - ${empresa.nome}`,
      companyCodPais: empresa.codPais,
      acountingPeriod: empresa.periodoContabilistico,
      ircYear: empresa.anoCursoIRC,
      yearToClose: empresa.anoCursoIRC
    };
    return fimAnoUIData;
  }

  public horario(): TServiceResponse<Array<Moment>> {
    return this._apiService.get<Array<TDate>>({url: `${this._path}/horario`}).then((response: HttpResponse<Array<TDate>>) =>
      response.clone<Array<Moment>>({
        body: response.body.map((date: TDate) => moment(date))
      })
    );
  }

  public valida(validaClasse0: boolean = true): TServiceResponse<Array<string>> {
    return this._apiService.get<Array<string>>({url: `${this._path}/valida`, params: {validaClasse0: validaClasse0}});
  }

  public executar(action: IFimAnoExecute): TServiceResponse<void> {
    return this._apiService.post<void, IFimAnoExecute>({
      url: `${this._path}/executar`,
      body: {
        nDiario: action.nDiario,
        nDescritivo: action.nDescritivo,
        saldaContasClasse0: action.saldaContasClasse0,
        saldaContasClasse9: action.saldaContasClasse9,
        fazTratamentoME: action.fazTratamentoME,
        comJob: action.comJob,
        agendadoPara: action.agendadoPara
      }
    });
  }

  public cancel(): TServiceResponse<void> {
    return this._apiService.post<void>({url: `${this._path}/cancelar`});
  }
}
