<div class="rh-dgemps-ficha">
  <div class="rh-dgemps-ficha-top mb-2">
    <div class="rh-dgemps-ficha-top-card">
      <span style="font-weight: bold" [translate]="'dgpempsFicha.papel'"></span>:&nbsp;<span>{{ papel }}</span>
    </div>
    <div class="rh-dgemps-ficha-top-card">
      <span style="font-weight: bold" class="bold" [translate]="'dgpempsFicha.servico'"></span>:&nbsp;<span>{{ servico }}</span>
    </div>
  </div>

  <div class="entity-detail-form">
    <h5 class="rh-dgemps-ficha-title"><i class="fa fa-id-card fa-fw me-2"></i><span [translate]="'dgpempsFicha.ficha'"></span></h5>
    <div class="rh-dgemps-ficha-body d-flex">
      <div class="rh-dgemps-ficha-body-fields">
        <pl-form (evtSubmitted)="alterarCampos($event)">
          <pl-group>
            <label>Colaborador</label>
            <edit>
              <span class="form-control-align">{{ model.codEmp }}</span>
            </edit>
          </pl-group>
          <pl-group>
            <pl-group>
              <label>Nome</label>
              <edit>
                <pl-edit type="text" attrName="inputNome" [(model)]="model.nome"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label>Apelido</label>
              <edit>
                <pl-edit type="text" attrName="inputApelido" [(model)]="model.apelido"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <label>Morada</label>
            <edit>
              <pl-edit type="text" attrName="inputRua" [(model)]="model.rua"></pl-edit>
              <pl-edit type="text" attrName="inputMorada" [(model)]="model.morada" style="padding: 0"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group>
              <label>Cód. Postal</label>
              <edit>
                <pl-edit type="text" attrName="inputCodPostal" [(model)]="model.codPostal"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label>Localidade</label>
              <edit>
                <pl-edit type="text" attrName="inputLocalidade" [(model)]="model.nomeLocalida"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <label>Email</label>
            <edit>
              <pl-edit type="text" attrName="inputEmail" [(model)]="model.email"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group>
              <label>Nº Telefone</label>
              <edit>
                <pl-edit type="text" attrName="inputTelefone" [(model)]="model.nTelefone"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label>Telemóvel</label>
              <edit>
                <pl-edit type="text" attrName="inputTelemovel" [(model)]="model.telemovelPart1"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label>IBAN</label>
              <edit>
                <pl-edit type="text" attrName="inputIban" [model]="model.iban" (modelChange)="ibanChanged($event)" [properties]="{modelOptions: {updateOn: 'blur'}}">
                  <div *inputGroup="{klass: 'hollow-form'}">
                    <pl-tooltip *ngIf="!ibanValid" [config]="{text: 'prhentalters.tooltips.ibanInvalido', container: 'body'}">
                      <i *plTooltipContent class="fa fa-fw fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                    </pl-tooltip>
                  </div>
                </pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label>SWIFT</label>
              <edit>
                <pl-edit type="text" attrName="inputSwift" [(model)]="model.swift"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'dgempsfull.fields.situacaoCivilTitular'"></label>
              <edit>
                <pl-edit-select
                  attrName="situacaoCivilTitular"
                  [(model)]="model.situacaoCivilTitular"
                  [source]="situacaoCivilTitularDataSource"
                  [properties]="{select: {labelProp: 'label'}, disallowClear: true}">
                </pl-edit-select>
              </edit>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'dgempsfull.fields.numDepend'"></label>
                <edit>
                  <pl-edit
                    type="smallint"
                    attrName="numDepend"
                    [(model)]="model.numDepend"
                    [properties]="{modelOptions: {updateOn: 'blur'}, placeholder: 'dgempsfull.fields.numDependPlaceholder', validators: {min: {value: 0}}}">
                  </pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label>
                  <span [translate]="'dgempsfull.fields.numDependDef'"></span>
                  <pl-tooltip class="num-depend-def-label" [config]="tooltipNumDependDef">
                    <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                  </pl-tooltip>
                </label>
                <edit>
                  <pl-edit
                    type="smallint"
                    attrName="numDependDef"
                    [(model)]="model.numDependDef"
                    [properties]="{modelOptions: {updateOn: 'blur'}, placeholder: 'dgempsfull.fields.numDependDefPlaceholder', validators: {min: {value: 0}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>
          </pl-group>

          <pl-group [attrName]="'btnAlterar'">
            <label></label>
            <edit>
              <button type="submit" class="btn btn-primary" [plPromise]="globalPromise"><i class="fa fa-fw fa-floppy-o"></i> Alterar</button>
            </edit>
          </pl-group>
        </pl-form>
      </div>
      <div class="rh-dgemps-ficha-body-capture-image d-flex flex-column" [plPromise]="promisePicture">
        <pl-media-devices-camera-capture-image [hideActions]="true" [model]="colaboradorPicture" (modelChange)="changedPicture($event)" [properties]="propertiesCaptureImage">
        </pl-media-devices-camera-capture-image>

        <pl-button klass="btn btn-sm btn-danger mt-1" [onClick]="fnDeleteFoto" *ngIf="this.colaboradorPicture !== undefined">
          <i class="fa fa-fw fa-trash-o"></i>&nbsp;<span [translate]="'impdadosemp.deleteFoto'"></span>
        </pl-button>
      </div>
    </div>
  </div>
</div>
