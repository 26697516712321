import {getDocument, PageViewport, PDFDataRangeTransport, PDFDocumentProxy, PDFPageProxy} from 'pdfjs-dist';
import type {DocumentInitParameters, RenderParameters} from 'pdfjs-dist/types/src/display/api';
import {isArrayBuffer, isBlob, isFile, isString, isTypedArray} from '../common/utilities/utilities';
import {mergeCanvasVertically} from '../common/utilities/dom.utilities';
import {PlFileReader} from '../common/filereader/filereader';
import {TPlPdfSource} from './pdf.component.interface';

/**
 * Converts a PDF document into a single image blob by rendering each page onto a canvas.
 * If the PDF contains multiple pages, the resulting canvases are merged vertically.
 *
 * @param src - The source of the PDF to be converted, of type TPlPdfSource.
 * @param document - The Document object for creating canvases.
 * @returns A Promise that resolves to a Blob representing the rendered PDF image.
 *
 * @throws {Error} If the conversion from canvas to blob fails.
 */
export async function convertPdfToImage(src: TPlPdfSource, document: Document): Promise<Blob> {
  if (!src) {
    throw new Error('Invalid PDF source');
  }
  if (!document) {
    throw new Error('Invalid document object provided. This method is designed to be used in a browser environment, is your environment supported?');
  }

  const pdfSource: DocumentInitParameters = await parsePdfSrc(src);
  const pdf: PDFDocumentProxy = await getDocument(pdfSource).promise;

  const canvases: Array<HTMLCanvasElement> = [];
  canvases.length = pdf.numPages;

  await Promise.all(
    Array.from({length: pdf.numPages}, async (unknown: unknown, index: number) => {
      const page: PDFPageProxy = await pdf.getPage(index + 1);
      const viewport: PageViewport = page.getViewport({scale: 2});
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext: RenderParameters = {
        canvasContext: canvas.getContext('2d'),
        viewport
      };
      await page.render(renderContext).promise;
      canvases[index] = canvas;
    })
  );

  const canvas: HTMLCanvasElement = mergeCanvasVertically(canvases);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob: Blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Failed to convert canvas to blob'));
        }
      },
      'image/png',
      1
    );
  });
}

export async function parsePdfSrc(src: TPlPdfSource): Promise<DocumentInitParameters> {
  if (src) {
    const pdfSource: DocumentInitParameters = {};
    if (isString(src)) {
      if (src.length) {
        pdfSource.url = src;
        return pdfSource;
      }
    } else if (isTypedArray(src)) {
      pdfSource.data = src;
      return pdfSource;
    } else if (isArrayBuffer(src)) {
      pdfSource.data = new Uint8Array(src);
      return pdfSource;
    } else if (isFile(src) || isBlob(src)) {
      const fileReader: PlFileReader = new PlFileReader(src);
      const valueAsArrayBuffer: ArrayBuffer = await fileReader.readAsArrayBuffer();
      pdfSource.data = new Uint8Array(valueAsArrayBuffer);
      return pdfSource;
    } else if (src instanceof PDFDataRangeTransport) {
      pdfSource.range = src;
      return pdfSource;
    }
  }
  return undefined;
}
