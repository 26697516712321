import {ROLE} from '../../services/role.const';
import {IJsonConfigTransfArmazem, IJsonDocTransfArmazem} from './jsonTransferenciasArmazens.entity.interface';
import {TransferenciasArmazensListComponent} from './components/list/transferenciasArmazens.entity.list.component';
import {
  ENTITY_NAME_TRANSFERENCIAS_ARMAZENS,
  ITransfArmazemDetailStateParams,
  ITransfArmazemEntity,
  ITransfArmazemEntityService,
  TTransfArmazemRequestData
} from './transferenciasArmazens.entity.interface';
import {TransferenciasArmazensModuleComponent} from './components/transferenciasArmazens.entity.component';
import {TransferenciasArmazensService} from './transferenciasArmazens.entity.service';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {Transition} from '@uirouter/core';
import {HttpResponse} from '@angular/common/http';

export const ENTITY_TRANSFERENCIAS_ARMAZENS: ITransfArmazemEntity = {
  name: ENTITY_NAME_TRANSFERENCIAS_ARMAZENS,
  roles: [ROLE.ERP],
  searchPlaceholder: 'transferenciasarmazens.pesquisa',
  sidebarTitle: 'portals.sidebar.modules.transferenciasarmazens',
  metadata: {
    keyName: 'faccbIdOrigem',
    fields: [
      {name: 'faccbIdOrigem', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.faccbIdOrigem', visible: false, properties: {devExpress: {dataGrid: {showInColumnChooser: false}}}},
      {name: 'nDocfaOrigem', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.nDocfaOrigem', visible: false},
      {name: 'nDocOrigem', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.nDocOrigem', visible: false},
      {name: 'nNumerOrigem', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.nNumerOrigem', visible: false},
      {name: 'nDocfaDestino', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.nDocfaDestino', visible: false},
      {name: 'nDocDestino', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.nDocDestino', visible: false},
      {name: 'nNumerDestino', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.nNumerDestino', visible: false},
      {name: 'numeroOrigem', type: 'string', caption: 'transferenciasarmazens.list.fields.numeroOrigem'},
      {name: 'numeroDestino', type: 'string', caption: 'transferenciasarmazens.list.fields.numeroDestino'},
      {name: 'dataDoc', type: 'date', caption: 'transferenciasarmazens.list.fields.dataDoc'}
    ],
    searchFields: 'numeroOrigem,numeroDestino,dataDoc',
    order: 'dataDoc'
  },
  actions: {
    new: true,
    detail: false,
    edit: false,
    delete: false,
    search: true,
    filter: true
  },
  list: {
    state: {
      component: TransferenciasArmazensListComponent,
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'transferenciasarmazens.title_list'
      }
    }
  },
  new: {
    state: {
      component: TransferenciasArmazensModuleComponent,
      params: {
        ndocfa: {value: 0},
        nnumer: {value: 0},
        ndocumento: {value: 0}
      },
      resolve: [
        {
          provide: 'configTransfArmazem',
          deps: [TransferenciasArmazensService],
          useFactory: (configTransfArmazem: TransferenciasArmazensService): Promise<IJsonConfigTransfArmazem> => {
            return configTransfArmazem.getConfiguracoesTransfArmazens().then((response) => response.body);
          }
        }
      ]
    }
  },
  detail: {
    state: {
      urlSuffix: '?ndocfa&nnumer&ndocumento',
      component: TransferenciasArmazensModuleComponent,
      params: {
        doc: {type: 'any'},
        ndocfa: {type: 'int'},
        nnumer: {type: 'int'},
        ndocumento: {type: 'int'}
      },
      resolve: [
        {
          provide: 'model',
          deps: [EntityServiceBuilder, Transition],
          useFactory: (entityServiceBuilder: EntityServiceBuilder, transition: Transition): Promise<IJsonDocTransfArmazem> => {
            const params: ITransfArmazemDetailStateParams = <ITransfArmazemDetailStateParams>transition.params();
            if (params.id && params.ndocfa && params.nnumer && params.ndocumento) {
              const service: ITransfArmazemEntityService = entityServiceBuilder.build<IJsonDocTransfArmazem, ITransfArmazemEntityService>(ENTITY_NAME_TRANSFERENCIAS_ARMAZENS);
              return service.get({id: params.id, ndocfa: params.ndocfa, nnumer: params.nnumer, ndocumento: params.ndocumento}).then((response: HttpResponse<IJsonDocTransfArmazem>) => response.body);
            }
            return Promise.resolve(undefined);
          }
        },
        {
          provide: 'configTransfArmazem',
          deps: [TransferenciasArmazensService],
          useFactory: (configTransfArmazem: TransferenciasArmazensService): Promise<IJsonConfigTransfArmazem> => {
            return configTransfArmazem.getConfiguracoesTransfArmazens().then((response) => response.body);
          }
        }
      ]
    }
  },
  service: function () {
    const superGet = this.get.bind(this);

    this.get = (config: TTransfArmazemRequestData) => {
      const {id, ndocfa, nnumer, ndocumento} = config;
      return superGet({id: id, params: {ndocfa: ndocfa, nnumer: nnumer, ndocumento: ndocumento}, ...config});
    };
  }
};
