<dx-data-grid
  [cgDxDataGrid]="definition"
  cgDxDataGridInstanceName="dadosAAlterar"
  [dataSource]="definition.dataSource"
  (onInitialized)="onDataGridInitializedDadosAAlterar($event)"
  (onCellClick)="onDataGridCellClickDadosAAlterar($event)">
  <div *dxTemplate="let item of 'detail'; let itemIndex = index">
    <dx-data-grid [cgDxDataGrid]="definitionMasterDetail" [dataSource]="item.data.lista">
      <div *dxTemplate="let itemAction of 'actions'" class="text-center">
        <dx-button
          class="dx-button-icon-fontawesome"
          [hint]="'alteracoesentidades.tooltip.aprovar' | translate"
          (click)="aprovar(item.data, itemAction.data)"
          icon="fa fa-fw fa-check"
          [stylingMode]="'text'"></dx-button>

        <dx-button
          class="dx-button-icon-fontawesome"
          [hint]="'alteracoesentidades.tooltip.rejeitar' | translate"
          (click)="rejeitar(item.data, itemAction.data)"
          icon="fa fa-fw fa-ban"
          [stylingMode]="'text'"></dx-button>
      </div>
    </dx-data-grid>
  </div>
</dx-data-grid>
