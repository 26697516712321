import {firstValueFrom, of} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';
import {EDocTipoEntity, IJsonGDFolder, IJsonGDFolderContent} from './JsonGDoc.entity.interface';
import {ENTITY_NAME_GDOC, IGDocEntity} from './gdoc.entity.interface';
import {ROLE} from '../../services/role.const';
import {ICommentItem} from '../../components/gdocscomentarios/gdocscomentarios.component.interface';

export const ENTITY_GDOC: IGDocEntity = {
  name: ENTITY_NAME_GDOC,
  roles: [ROLE.ERP, ROLE.COLABORADOR],
  metadata: {
    keyName: 'folderId',
    descriptionName: 'gdocs',
    fields: [
      {name: 'folderId', width: '10px', caption: 'gdocs.fields.cab.folderId', visible: false},
      {name: 'name', type: 'date', width: '10px', caption: 'gdocs.fields.cab.name'}
    ],
    order: 'name',
    searchFields: 'name'
  },
  autocomplete: {
    rowTemplate: '{{nome}}',
    output: 'nome',
    searchFields: 'nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true
  },
  service: function () {
    this.getFolderUrl = (docid: string, folderid: string) => {
      return of(`${this.entityUrl()}/${folderid}/${docid}/downloadfile`);
    };

    this.uploadFile = async (file: File, folderid: string, filename: string, params: string, tipodocentity: number) => {
      const url = await this.uploadFileUrl(folderid);
      const formData = new FormData();
      formData.append('file', file, file.name);
      return this.upload({url: url, body: formData, params: {filename: filename, params: params, tipodocentity: tipodocentity}});
    };

    this.uploadFileUrl = (folderid: string, filename?: string, params?: string, tipodocentity?: EDocTipoEntity) => {
      return firstValueFrom(
        buildSessionUrlWithParams(`${this.entityUrl()}/${folderid}/uploadfile`, {
          filename: filename,
          params: params,
          tipodocentity: tipodocentity
        })
      );
    };

    this.deleteFile = (folderid: string, docid: string) => {
      return this.delete({url: `${folderid}/${docid}`});
    };

    this.getFolderId = (parentFolderId: string, folderName: string) => {
      return this.get<IJsonGDFolder>({url: 'folderid', params: {parentfolderid: parentFolderId, foldername: folderName}});
    };

    this.getFolderIdAnexos = (parentFolderId: string, folderName: string) => {
      return this.get<IJsonGDFolderContent>({url: 'folderidanexos', params: {parentfolderid: parentFolderId, foldername: folderName}});
    };

    this.deleteComment = (docid: string, comentarioid: string) => {
      return this.delete({url: `comment/${docid}/${comentarioid}`});
    };

    this.addComment = (folderid: string, comment: ICommentItem) => {
      return this.post({url: `comment/${folderid}`, body: comment});
    };

    this.updateComment = (comentarioid: string, comment: ICommentItem) => {
      return this.put({url: `comment/${comentarioid}`, body: comment});
    };

    this.checkFolder = (tipodocentity: EDocTipoEntity, parameters: string) => {
      return this.get<IJsonGDFolder>({url: `checkfolder/${tipodocentity}/${parameters}`});
    };
  }
};
