import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isDefinedNotNull, isObject} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ConciliacaoBancariaService} from '../../conciliacaoBancaria.module.service';
import {DATA_SOURCE_DEBITO_CREDITO} from '../../../../../datasources/debitocredito/debitoCredito.datasource';
import {
  EConciliacaoBancariaAddLinhaExtratoBancoModalMode,
  EConciliacaoBancariaExtratoBancoTipo,
  IConciliacaoBancaria,
  IConciliacaoBancariaLinItem,
  IConciliacaoBancariaNovaLinhaExtratoBancoModal,
  IRestCmdConciliacaoBancariaNovaLinhaExtratoBanco
} from '../../conciliacaoBancaria.module.interface';
import {EDebitoCredito} from '../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {ENTITY_NAME_POCS} from '../../../../../entities/pocs/pocs.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IDataSourceItem} from '../../../../../components/datasource/datasources.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonPOC} from '../../../../../entities/pocs/jsonPOC.entity.interface';
import moment from 'moment';

@Component({
  selector: 'conciliacaoBancaria-add-linha-extrato-banco-modal',
  templateUrl: './conciliacaoBancaria.addLinhaExtratoBanco.modal.component.html'
})
export class ConciliacaoBancariaAddLinhaExtratoBancoModalComponent extends CGModalComponent<IConciliacaoBancariaLinItem | IConciliacaoBancariaNovaLinhaExtratoBancoModal> implements OnInit {
  @Input() public nContaBanco: string;
  @Input() public periodo: string;
  @Input() public conciliacaoBancaria: IConciliacaoBancaria;
  @Input() public modalTitle: string;
  @Input() public model: IConciliacaoBancariaNovaLinhaExtratoBancoModal;
  @Input() public modalMode: EConciliacaoBancariaAddLinhaExtratoBancoModalMode;
  @Input() public tipo: EConciliacaoBancariaExtratoBancoTipo;

  public readonly pocsFilter: string;
  public readonly modalModes: typeof EConciliacaoBancariaAddLinhaExtratoBancoModalMode;
  public readonly tipoMovimentoItems: ReadonlyArray<IDataSourceItem<EDebitoCredito>>;
  public notaCaption: string;
  public notaMsg: string;
  public saveBtnTitle: string;

  private readonly _pocsService: IEntityService<IJsonPOC>;
  private _isEditMode: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _conciliacaoBancariaService: ConciliacaoBancariaService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.pocsFilter = `tipo=${EDebitoCredito.Debito}`;
    this.modalModes = EConciliacaoBancariaAddLinhaExtratoBancoModalMode;
    this.tipoMovimentoItems = DATA_SOURCE_DEBITO_CREDITO.data;
    this.notaCaption = '';
    this.notaMsg = '';
    this._pocsService = this._entityServiceBuilder.build<IJsonPOC>(ENTITY_NAME_POCS);
  }

  public ngOnInit(): void {
    this._isEditMode = isObject(this.model);
    this.saveBtnTitle = this._isEditMode ? 'global.btn.save' : 'global.btn.create';
    if (!this._isEditMode) {
      this.model = {
        contaBanco: this.nContaBanco,
        radical: `${this.conciliacaoBancaria.radicalContaContrapartida}${this.nContaBanco}`,
        data:
          this.tipo === EConciliacaoBancariaExtratoBancoTipo.PrimeiraConciliacao
            ? moment(this.conciliacaoBancaria.extratoBanco.dataInicio).subtract(1, 'months').endOf('month')
            : this.conciliacaoBancaria.extratoBanco.dataFim,
        dc: EDebitoCredito.Debito,
        descricao: '',
        descritivo: undefined,
        diario: undefined,
        nContaLanc: `${this.conciliacaoBancaria.radicalContaContrapartida}${this.nContaBanco}`,
        valor: 0,
        valorSemDC: 0
      };
    }
    if (this.modalMode !== EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyBanco) {
      this._checkNContaLanc(this.model.nContaLanc);
    }
  }

  public close(): Promise<void> {
    switch (this.modalMode) {
      case EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyContabilidade:
        super.close(this.model);
        return Promise.resolve();
      case EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyBanco:
        if (this._isEditMode) {
          return this._conciliacaoBancariaService.editarLinhaExtratoBanco(this.model, this.nContaBanco, this.periodo).then(() => {
            this.model.valorSemDC = this.model.valor;
            if (this.model.dc === EDebitoCredito.Debito) {
              this.model.valorSemDC *= -1;
            }
            super.close(this.model);
          });
        }
        return this._conciliacaoBancariaService.novaLinhaExtratoBanco(this.model, this.tipo, this.nContaBanco, this.periodo).then((response: IRestCmdConciliacaoBancariaNovaLinhaExtratoBanco) => {
          super.close(response.concilLinItem);
        });
      case EConciliacaoBancariaAddLinhaExtratoBancoModalMode.Both:
        return this._conciliacaoBancariaService.novaLinhaExtratoBanco(this.model, this.tipo, this.nContaBanco, this.periodo).then(() => {
          super.close();
        });
      default:
        return Promise.resolve();
    }
  }

  public dismiss(): void {
    super.close(EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyContabilidade ? this.model : undefined);
  }

  public onPocChange(value: string): void {
    this.model.radical = value;
    if (isDefinedNotNull(value)) {
      this._pocsService
        .get({
          id: value,
          reportExceptions: false
        })
        .then((response) => {
          this.model.nContaLanc = response.body.nConta;
          this.notaMsg = `${response.body.nConta} - ${response.body.nome}`;
        })
        .catch(() => {
          this.model.nContaLanc = this.model.radical.includes(this.model.contaBanco) ? this.model.radical : `${this.model.radical}${this.model.contaBanco}`;
          this._checkNContaLanc(this.model.nContaLanc);
        });
    }
  }

  public onDiarioChange(value: number): void {
    this.model.diario = value;
    if (this.modalMode === EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyContabilidade) {
      this._conciliacaoBancariaService.updateRegDiario(value, this.nContaBanco, this.periodo);
    }
  }

  public onDescritivoChange(value: number): void {
    this.model.descritivo = value;
    if (this.modalMode === EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyContabilidade) {
      this._conciliacaoBancariaService.updateRegDescritivo(value, this.nContaBanco, this.periodo);
    }
  }

  private _checkNContaLanc(nContaLanc: string): void {
    this._pocsService
      .get({
        id: nContaLanc,
        reportExceptions: false
      })
      .then((response) => {
        this.notaCaption = 'conciliacaoBancaria.seraLancadaNaConta';
        this.notaMsg = `${response.body.nConta} - ${response.body.nome}`;
      })
      .catch(() => {
        this.notaCaption = 'conciliacaoBancaria.seraCriadaNovaConta';
        this.notaMsg = this._translateService.instant('conciliacaoBancaria.s01Contrapartida2', {
          radical: this.model.radical,
          nconta: this.model.radical.includes(this.model.contaBanco) ? '' : this.model.contaBanco,
          nomeConta: this.conciliacaoBancaria.extratoBanco.nomeConta
        });
      });
  }
}
