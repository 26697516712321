import {Component, Injector, Input, OnInit} from '@angular/core';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../cg/modal/cgmodal.component';
import {ICommentItem} from '../gdocscomentarios.component.interface';
import moment from 'moment';
import {ENTITY_NAME_GDOC, IGDocEntityService} from '../../../entities/gdoc/gdoc.entity.interface';
import {IJsonGDFolder} from '../../../entities/gdoc/JsonGDoc.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';

@Component({
  selector: 'gdoccomentarios-edit-modal',
  templateUrl: './gdocscomentarios.edit.modalcomponent.html'
})
export class GDocsComentariosEditModalComponent extends CGModalComponent<ICommentItem> implements OnInit {
  @Input() public comment: ICommentItem;
  @Input() public folderId: string;
  public isEditing: boolean;

  private readonly _serviceGDoc: IGDocEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceGDoc = this._entityServiceBuilder.build<IJsonGDFolder, IGDocEntityService>(ENTITY_NAME_GDOC);
  }

  public ngOnInit(): void {
    this.isEditing = !isEmpty(this.comment?.comentarioID);
    if (!this.comment) {
      this.comment = {
        comentarioID: '',
        comentarioParentID: '',
        docID: '',
        descricao: '',
        dados: '',
        data: moment(),
        utilizador: ''
      };
    }
  }

  public async close(): Promise<void> {
    if (isEmpty(this.comment.descricao) && isEmpty(this.comment.dados)) {
      this._plAlertService.error('gdocscomentarios.erros.tituloTextoVazio');
      return;
    }

    if (this.folderId === undefined) {
      this._plAlertService.error('gdocscomentarios.erros.folderIdUndefined');
      return;
    }

    if (!this.isEditing) {
      await this._serviceGDoc.addComment(String(this.folderId), this.comment);
    } else {
      await this._serviceGDoc.updateComment(String(this.comment.comentarioID), this.comment);
    }

    super.close(this.comment);
  }
}
