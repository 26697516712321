import type {Column, default as dxDataGrid} from 'devextreme/ui/data_grid';
import type {DxDataGridComponent, DxLookupComponent} from 'devextreme-angular';
import type {
  TDevExpressDataGridAlignment,
  TDevExpressDataGridColumnButtonName,
  TDevExpressDataGridColumnCalculateFilterExpressionTarget,
  TDevExpressDataGridColumnChooserMode,
  TDevExpressDataGridColumnCommand,
  TDevExpressDataGridColumnCustomizeTextTarget,
  TDevExpressDataGridColumnDataType,
  TDevExpressDataGridColumnFilterOperation,
  TDevExpressDataGridColumnFilterType,
  TDevExpressDataGridColumnFixedPosition,
  TDevExpressDataGridColumnGoupInterval,
  TDevExpressDataGridColumnRenderingMode,
  TDevExpressDataGridColumnResizingMode,
  TDevExpressDataGridColumnSearchMode,
  TDevExpressDataGridColumnSelectedFilterOperation,
  TDevExpressDataGridColumnSortOrder,
  TDevExpressDataGridColumnType,
  TDevExpressDataGridEditingMode,
  TDevExpressDataGridEditingNewRowPosition,
  TDevExpressDataGridEditingRefreshMode,
  TDevExpressDataGridEnterKeyAction,
  TDevExpressDataGridEnterKeyDirection,
  TDevExpressDataGridFilterSyncEnabled,
  TDevExpressDataGridGroupingExpandMode,
  TDevExpressDataGridGroupPanelVisible,
  TDevExpressDataGridPagerDisplayMode,
  TDevExpressDataGridRowRenderingMode,
  TDevExpressDataGridRowType,
  TDevExpressDataGridScrollingMode,
  TDevExpressDataGridSelectedFilterOperation,
  TDevExpressDataGridSelectionMode,
  TDevExpressDataGridSelectionSelectAllMode,
  TDevExpressDataGridSelectionShowCheckBoxesMode,
  TDevExpressDataGridSortingMode,
  TDevExpressDataGridStartEditAction,
  TDevExpressDataGridStateStoringType,
  TDevExpressDataGridSummaryProcess,
  TDevExpressDataGridSummaryType
} from './devexpress.datagrid.types.interface';
import type {TDevExpressDataGridExport} from './export/devexpress.datagrid.export.interface';
import {TDevExpressDataGridStateBeforeStoreFn, TDevExpressDataGridStateGenerateStateInstanceIdFn} from './state/devexpress.datagrid.state.interface';
import type {TDevExpressDataGridStoreChangeType} from './store/devexpress.datagrid.store.interface';
import type {TDevExpressFilterExpression} from '../datalayer/filtering/devexpress.datalayer.filtering.interface';
import type {TDevExpressFormat} from '../widget/devexpress.widget.interface';
import type {TDevExpressValidatorRule} from '../validator/devexpress.validator.rules.interface';
import {IDevExpressDataGridEventOnExporting} from './events/devexpress.datagrid.events.interface';

export type TDevExpressDataGridColumnButtonLogicalFn<TRowData = unknown, TKey = unknown> = (options: IDevExpressDataGridColumnButtonLogicalFnOptions<TRowData, TKey>) => boolean;

export type TDevExpressDataGridColumnButtonOnClickEventFn<TRowData = unknown, TKey = unknown> = (event: IDevExpressDataGridColumnButtonOnClickEvent<TRowData, TKey>) => void;

export type TDevExpressDataGridColumnCalculateCellValueFn<TRowData> = (rowData: TRowData) => string | number;

export type TDevExpressDataGridColumnCalculateDisplayValueFn<TRowData> = (rowData: TRowData) => string | number;

export type TDevExpressDataGridColumnSetCellValueFn<TRowData> = (newData: TRowData, value: unknown, currentRowData: TRowData) => Promise<unknown>;

export type TDevExpressDataGridColumnCalculateFilterExpressionFn<TRowData = unknown, TKey = unknown> = (
  this: IDevExpressDataGridColumn<TRowData, TKey>,
  filterValue: unknown | Array<unknown>,
  selectedFilterOperation: TDevExpressDataGridSelectedFilterOperation,
  target: TDevExpressDataGridColumnCalculateFilterExpressionTarget
) => TDevExpressFilterExpression;

export type TDevExpressDataGridColumnCalculateSortValueFn<TRowData = unknown, TKey = unknown> = (this: IDevExpressDataGridColumn<TRowData, TKey>, rowData: TRowData) => string | number;

export type TDevExpressDataGridColumnLookupDisplayExprFn<T> = (data: T) => string;

export type TDevExpressDataGridColumnChooser = DxDataGridComponent['columnChooser'] & {
  mode?: TDevExpressDataGridColumnChooserMode;
};

export type TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => string;

export type TDevExpressDataGridOnExportingFn<T = unknown> = (event: IDevExpressDataGridEventOnExporting<T>) => Promise<void> | void;

export type TDevExpressDataGridColumnSortingMethod<T = unknown> = (a: T, b: T) => number;

export type TDevExpressDataGridDataSource = DxDataGridComponent['dataSource'];

export type TDevExpressDataGridGrouping = DxDataGridComponent['grouping'] & {
  expandMode?: TDevExpressDataGridGroupingExpandMode;
};

export type TDevExpressDataGridGroupPanel = DxDataGridComponent['groupPanel'] & {
  visible?: TDevExpressDataGridGroupPanelVisible;
};

export type TDevExpressDataGridHeightFn = () => number;

export type TDevExpressDataGridPager = DxDataGridComponent['pager'] & {
  allowedPageSizes?: Array<number>;
  displayMode?: TDevExpressDataGridPagerDisplayMode;
};

export type TDevExpressDataGridScrolling = DxDataGridComponent['scrolling'] & {
  columnRenderingMode?: TDevExpressDataGridColumnRenderingMode;
  mode?: TDevExpressDataGridScrollingMode;
  rowRenderingMode?: TDevExpressDataGridRowRenderingMode;
};

export type TDevExpressDataGridSearchPanel = DxDataGridComponent['searchPanel'] & {
  focusOnInit?: boolean;
};

export type TDevExpressDataGridSelection = DxDataGridComponent['selection'] & {
  mode?: TDevExpressDataGridSelectionMode;
  selectAllMode?: TDevExpressDataGridSelectionSelectAllMode;
  showCheckBoxesMode?: TDevExpressDataGridSelectionShowCheckBoxesMode;
};

export type TDevExpressDataGridSorting = DxDataGridComponent['sorting'] & {
  mode?: TDevExpressDataGridSortingMode;
};

export type TDevExpressDataGridStateStoring = DxDataGridComponent['stateStoring'] & {
  type?: TDevExpressDataGridStateStoringType;
  version?: number;
  beforeStore?: TDevExpressDataGridStateBeforeStoreFn;
  generateStateInstanceIdFn?: TDevExpressDataGridStateGenerateStateInstanceIdFn;
};

export type TDevExpressDataGridSummaryCalculateCustomSummaryFn<T = unknown, S = T> = (options: IDevExpressDataGridSummaryCalculateCustomSummaryOptions<T, S>) => void;

export type TDevExpressDataGridSummaryCustomizeTextFn = (itemInfo: IDevExpressDataGridSummaryCustomizeTextItemInfo) => string | number;

export type TDevExpressDataGridEditing = DxDataGridComponent['editing'] & {
  mode?: TDevExpressDataGridEditingMode;
  newRowPosition?: TDevExpressDataGridEditingNewRowPosition;
  refreshMode?: TDevExpressDataGridEditingRefreshMode;
  startEditAction?: TDevExpressDataGridStartEditAction;
};

export type TDevExpressDataGridKeyboardNavigation = DxDataGridComponent['keyboardNavigation'] & {
  enterKeyAction?: TDevExpressDataGridEnterKeyAction;
  enterKeyDirection?: TDevExpressDataGridEnterKeyDirection;
};

export type TDevExpressDataGridEditCellTemplateDataSetValueFn<T> = (newValue: T, newText: string) => void;

export type TDevExpressDataGridToolbar = DxDataGridComponent['toolbar'];

export interface IDevExpressDataGrid<TRowData = unknown, TKey = unknown> {
  allowColumnReordering?: boolean;
  allowColumnResizing?: boolean;
  columnAutoWidth?: boolean;
  columnChooser?: TDevExpressDataGridColumnChooser;
  columnFixing?: DxDataGridComponent['columnFixing'];
  columnHidingEnabled?: boolean;
  columnMinWidth?: number;
  columnResizingMode?: TDevExpressDataGridColumnResizingMode;
  columns?: Array<IDevExpressDataGridColumn<TRowData, TKey>>;
  dataSource?: TDevExpressDataGridDataSource;
  editing?: TDevExpressDataGridEditing;
  errorRowEnabled?: boolean;
  export?: TDevExpressDataGridExport;
  filterBuilder?: DxDataGridComponent['filterBuilder'];
  filterPanel?: DxDataGridComponent['filterPanel'];
  filterRow?: DxDataGridComponent['filterRow'];
  filterSyncEnabled?: TDevExpressDataGridFilterSyncEnabled;
  filterValue?: TDevExpressFilterExpression;
  focusedColumnIndex?: number;
  focusedRowEnabled?: boolean;
  focusedRowIndex?: number;
  focusedRowKey?: TKey;
  grouping?: TDevExpressDataGridGrouping;
  groupPanel?: TDevExpressDataGridGroupPanel;
  headerFilter?: DxDataGridComponent['headerFilter'];
  height?: string | number | TDevExpressDataGridHeightFn;
  hoverStateEnabled?: boolean;
  keyboardNavigation?: TDevExpressDataGridKeyboardNavigation;
  keyExpr?: string;
  masterDetail?: DxDataGridComponent['masterDetail'];
  noDataText?: string;
  pager?: TDevExpressDataGridPager;
  paging?: DxDataGridComponent['paging'];
  remoteOperations?: DxDataGridComponent['remoteOperations'];
  repaintChangesOnly?: boolean;
  rowAlternationEnabled?: boolean;
  rowDragging?: DxDataGridComponent['rowDragging'];
  scrolling?: TDevExpressDataGridScrolling;
  searchPanel?: TDevExpressDataGridSearchPanel;
  selectedRowKeys?: Array<TKey>;
  selection?: TDevExpressDataGridSelection;
  showBorders?: boolean;
  showColumnLines?: boolean;
  showRowLines?: boolean;
  sorting?: TDevExpressDataGridSorting;
  stateStoring?: TDevExpressDataGridStateStoring;
  summary?: IDevExpressDataGridSummary;
  toolbar?: TDevExpressDataGridToolbar;
  defaults?: Omit<IDevExpressDataGrid<TRowData, TKey>, 'columns' | 'stateStoring' | 'defaults'>;
  width?: number | string;

  onExporting?: (event: IDevExpressDataGridEventOnExporting<TRowData, TKey>) => void;
}

export interface IDevExpressDataGridRow<TRowData = unknown, TKey = unknown> {
  data: TRowData;
  key: TKey;
  rowIndex: number;
  rowType: TDevExpressDataGridRowType;
  values: Array<unknown>;
  groupIndex?: number;
  isEditing: boolean;
  isExpanded?: boolean;
  isNewRow?: boolean;
  isSelected?: boolean;
}

export interface IDevExpressDataGridColumn<TRowData = unknown, TKey = unknown> {
  alignment?: TDevExpressDataGridAlignment;
  allowEditing?: boolean;
  allowExporting?: boolean;
  allowFiltering?: boolean;
  allowFixing?: boolean;
  allowGrouping?: boolean;
  allowHeaderFiltering?: boolean;
  allowHiding?: boolean;
  allowReordering?: boolean;
  allowResizing?: boolean;
  allowSearch?: boolean;
  allowSorting?: boolean;
  calculateCellValue?: TDevExpressDataGridColumnCalculateCellValueFn<TRowData>;
  calculateDisplayValue?: string | TDevExpressDataGridColumnCalculateDisplayValueFn<TRowData>;
  calculateFilterExpression?: TDevExpressDataGridColumnCalculateFilterExpressionFn<TRowData, TKey>;
  calculateSortValue?: TDevExpressDataGridColumnCalculateSortValueFn<TRowData, TKey>;
  caption?: string;
  cellTemplate?: string;
  editCellTemplate?: string;
  editorOptions?: unknown;
  buttons?: Array<TDevExpressDataGridColumnButtonName | IDevExpressDataGridColumnButton<TRowData, TKey>>;
  columns?: Array<IDevExpressDataGridColumn<TRowData, TKey>>;
  cssClass?: string;
  customizeText?: TDevExpressDataGridColumnCustomizeTextFn;
  dataField?: string;
  dataType?: TDevExpressDataGridColumnDataType;
  filterOperations?: Array<TDevExpressDataGridColumnFilterOperation>;
  filterType?: TDevExpressDataGridColumnFilterType;
  // eslint-disable-next-line @typescript-eslint/no-restricted-types
  filterValue?: string | number | boolean | Date;
  // eslint-disable-next-line @typescript-eslint/no-restricted-types
  filterValues?: Array<string | number | boolean | Date>;
  fixed?: boolean;
  fixedPosition?: TDevExpressDataGridColumnFixedPosition;
  format?: TDevExpressFormat;
  groupCellTemplate?: string;
  groupIndex?: number;
  headerAlignment?: TDevExpressDataGridAlignment;
  headerCellTemplate?: string;
  headerFilter?: IDevExpressDataGridColumnHeaderFilter;
  hidingPriority?: number;
  lookup?: IDevExpressDataGridColumnLookup;
  minWidth?: string | number;
  name?: string;
  selectedFilterOperation?: TDevExpressDataGridColumnSelectedFilterOperation;
  showInColumnChooser?: boolean;
  showEditorAlways?: boolean;
  sortIndex?: number;
  sortingMethod?: TDevExpressDataGridColumnSortingMethod;
  sortOrder?: TDevExpressDataGridColumnSortOrder;
  type?: TDevExpressDataGridColumnType;
  validationRules?: Array<TDevExpressValidatorRule>;
  visible?: boolean;
  visibleIndex?: number;
  width?: string | number;
  defaults?: Omit<IDevExpressDataGridColumn<TRowData, TKey>, 'defaults'>;
  setCellValue?: TDevExpressDataGridColumnSetCellValueFn<TRowData>;
}

export interface IDevExpressDataGridColumnButton<TRowData = unknown, TKey = unknown> {
  cssClass?: string;
  disabled?: boolean | TDevExpressDataGridColumnButtonLogicalFn<TRowData, TKey>;
  hint?: string;
  icon?: string;
  name?: TDevExpressDataGridColumnButtonName;
  onClick?: TDevExpressDataGridColumnButtonOnClickEventFn<TRowData, TKey>;
  template?: string;
  text?: string;
  visible?: boolean | TDevExpressDataGridColumnButtonLogicalFn<TRowData, TKey>;
}

export interface IDevExpressDataGridColumnButtonLogicalFnOptions<TRowData = unknown, TKey = unknown> {
  column: IDevExpressDataGridColumn<TRowData, TKey>;
  component: dxDataGrid;
  row: IDevExpressDataGridRow<TRowData, TKey>;
}

export interface IDevExpressDataGridColumnButtonOnClickEvent<TRowData = unknown, TKey = unknown> {
  column: IDevExpressDataGridColumn<TRowData, TKey>;
  component: dxDataGrid;
  element: HTMLElement;
  event: MouseEvent;
  row: IDevExpressDataGridRow<TRowData, TKey>;
}

export interface IDevExpressDataGridColumnCustomizeTextCellInfo {
  groupInterval: string | number;
  target: TDevExpressDataGridColumnCustomizeTextTarget;
  // eslint-disable-next-line @typescript-eslint/no-restricted-types
  value: string | number | Date;
  valueText: string;
}

export interface IDevExpressDataGridColumnHeaderFilter {
  allowSearch?: boolean;
  dataSource?: Array<unknown> | TDevExpressDataGridDataSource;
  groupInterval?: string | number | TDevExpressDataGridColumnGoupInterval;
  height?: number;
  searchMode?: string | TDevExpressDataGridColumnSearchMode;
  width?: number;
}

export interface IDevExpressDataGridColumnLookup<T = unknown> {
  allowClearing?: boolean;
  dataSource?: DxLookupComponent['dataSource'];
  cgDataSource?: string;
  displayExpr?: string | TDevExpressDataGridColumnLookupDisplayExprFn<T>;
  valueExpr?: string;
}

export interface IDevExpressDataGridSummary {
  calculateCustomSummary?: TDevExpressDataGridSummaryCalculateCustomSummaryFn;
  groupItems?: Array<IDevExpressDataGridSummaryGroupItems>;
  recalculateWhileEditing?: boolean;
  skipEmptyValues?: boolean;
  texts?: DxDataGridComponent['summary']['texts'];
  totalItems?: Array<IDevExpressDataGridSummaryTotalItems>;
}

export interface IDevExpressDataGridSummaryGroupItems {
  column?: string;
  alignByColumn?: boolean;
  customizeText?: TDevExpressDataGridSummaryCustomizeTextFn;
  displayFormat?: string;
  name?: string;
  showInColumn?: string;
  showInGroupFooter?: boolean;
  skipEmptyValues?: boolean;
  summaryType?: TDevExpressDataGridSummaryType;
  valueFormat?: TDevExpressFormat;
}

export interface IDevExpressDataGridSummaryTotalItems {
  column?: string;
  alignment?: TDevExpressDataGridAlignment;
  cssClass?: string;
  customizeText?: TDevExpressDataGridSummaryCustomizeTextFn;
  displayFormat?: string;
  name?: string;
  showInColumn?: string;
  skipEmptyValues?: boolean;
  summaryType?: TDevExpressDataGridSummaryType;
  valueFormat?: TDevExpressFormat;
}

export interface IDevExpressDataGridSummaryCalculateCustomSummaryOptions<T = unknown, S = T> {
  component: dxDataGrid;
  groupIndex: number;
  name: string;
  summaryProcess: TDevExpressDataGridSummaryProcess;
  totalValue: S;
  value: T;
}

export interface IDevExpressDataGridSummaryCustomizeTextItemInfo {
  // eslint-disable-next-line @typescript-eslint/no-restricted-types
  value: string | number | Date;
  valueText: string;
}

export interface IDevExpressDataGridLoadResult<TRowData = unknown> {
  data: Array<TRowData>;
  totalCount?: number;
}

export interface IDevExpressDataGridCellTemplateData<TRowData = unknown, TKey = unknown> {
  component: dxDataGrid;
  column: IDevExpressDataGridEventColumn<TRowData, TKey>;
  columnIndex: number;
  data: TRowData;
  displayValue: string;
  oldValue: unknown;
  row: IDevExpressDataGridRow<TRowData, TKey>;
  rowIndex: number;
  rowType: TDevExpressDataGridRowType;
  text: string;
  value: unknown;
}

export interface IDevExpressDataGriHeaderCellTemplateData<TRowData = unknown, TKey = unknown> {
  column: IDevExpressDataGridEventColumn<TRowData, TKey>;
  columnIndex: number;
  component: dxDataGrid;
}

export interface IDevExpressDataGridEditCellTemplateData<TRowData = unknown, TKey = unknown> {
  column: IDevExpressDataGridColumn<TRowData, TKey>;
  columnIndex: number;
  component: dxDataGrid;
  data: TRowData;
  displayValue: unknown;
  row: IDevExpressDataGridRow<TRowData, TKey>;
  rowIndex: number;
  rowType: TDevExpressDataGridRowType;
  setValue: TDevExpressDataGridEditCellTemplateDataSetValueFn<unknown>;
  text: string;
  value: unknown;
}

export interface IDevExpressDataGridDataChange<TRowData = unknown, TKey = unknown> {
  data: TRowData;
  index: number;
  key: TKey;
  pageIndex: number;
  type: TDevExpressDataGridStoreChangeType;
}

export interface IDevExpressDataGridEventColumn<TRowData = unknown, TKey = unknown> extends Column<TRowData, TKey> {
  bestFitWidth?: number;
  command?: string | TDevExpressDataGridColumnCommand;
  headerAlignment?: TDevExpressDataGridAlignment;
  index?: number;
  visibleWidth?: number;
}

export const DEVEXPRESS_DATAGRID_ACTIONS_DATAFIELD_NAME = 'cgactions';
