import {Observable} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {EDocTipoEntity, IJsonGDFolder, IJsonGDFolderContent} from './JsonGDoc.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {ICommentItem} from '../../components/gdocscomentarios/gdocscomentarios.component.interface';

export enum EFolderDoc {
  None = '{F0000000-0000-0000-0000-00000000000F}',
  DefaultFolderEmpresa = '{FE000000-0000-0000-0000-0000000000FE}',
  DefaultFolderApolicesSC = '{F0000000-0001-0001-0001-00000000000F}',
  DefaultFolderCRM = '{F0000000-0002-0002-0002-00000000000F}',
  DefaultFolderProce = '{F0000000-0003-0003-0003-00000000000F}',
  DefaultFolderObras = '{F0000000-0004-0004-0004-00000000000F}',
  DefaultFolderEmpregado = '{F0000000-0005-0005-0005-00000000000F}',
  DefaultFolderCT = '{F0000000-0006-0006-0006-00000000000F}',
  DefaultFolderContTrab = '{F0000000-0007-0007-0007-00000000000F}',
  DefaultFolderRequisicao = '{F0000000-0008-0008-0008-00000000000F}',
  DefaultFolderWebContab = '{F0000000-0009-0009-0009-00000000000F}',
  DefaultFolderCliFo = '{F0000000-0010-0010-0010-00000000000F}',
  DefaultFolderCliPo = '{F0000000-0011-0011-0011-00000000000F}',
  DefaultFolderLogistica = '{F0000000-0012-0012-0012-00000000000F}',
  DefaultFolderGestCom = '{F0000000-0013-0013-0013-00000000000F}',
  DefaultFolderMBRefPagams = '{F0000000-0014-0014-0014-00000000000F}',
  DefaultFolderCotacoes = '{F0000000-0015-0015-0015-00000000000F}',
  DefaultFolderQuadroPessoal = '{F0000000-0016-0016-0016-00000000000F}',
  DefaultFolderSegurancaSocial = '{F0000000-0017-0017-0017-00000000000F}',
  DefaultFolderTransfBancarias = '{F0000000-0018-0018-0018-00000000000F}',
  DefaultFolderCentroCusto = '{F0000000-0019-0019-0019-00000000000F}',
  DefaultFolderEvEmp = '{F0000000-0020-0020-0020-00000000000F}',
  DefaultFolderLotes = '{F0000000-0021-0021-0021-00000000000F}',
  DefaultFolderEstrArt = '{F0000000-0022-0022-0022-00000000000F}',
  DefaultFolderExtCtNotas = '{F0000000-0023-0023-0023-00000000000F}',
  DefaultFolderPnLin = '{F0000000-0024-0024-0024-00000000000F}',
  DefaultFolderOpLin = '{F0000000-0025-0025-0025-00000000000F}',
  DefaultFolderWFInstance = '{F0000000-0026-0026-0026-00000000000F}',
  DefaultFolderObrasAutos = '{F0000000-0027-0027-0027-00000000000F}',
  DefaultFolderCompras = '{F0000000-0028-0028-0028-00000000000F}',
  DefaultFolderGarantiasBancarias = '{F0000000-0029-0029-0029-00000000000F}',
  DefaultFolderArtigos = '{F0000000-0030-0030-0030-00000000000F}',
  DefaultFolderContabilidade = '{F0000000-0031-0031-0031-00000000000F}',
  DefaultFolderEmailOutlook = '{F0000000-0032-0032-0032-00000000000F}',
  DefaultFolderContabNaoClassificados = '{F0000000-0033-0033-0033-00000000000F}',
  DefaultFolderContabClassificados = '{F0000000-0034-0034-0034-00000000000F}',
  DefaultFolderContabNaoClassificadosVendas = '{F0000000-0035-0035-0035-00000000000F}',
  DefaultFolderContabNaoClassificadosCompras = '{F0000000-0036-0036-0036-00000000000F}',
  DefaultFolderContabNaoClassificadosOperacoesDiversas = '{F0000000-0037-0037-0037-00000000000F}',
  DefaultFolderContabClassificadosVendas = '{F0000000-0038-0038-0038-00000000000F}',
  DefaultFolderContabClassificadosCompras = '{F0000000-0039-0039-0039-00000000000F}',
  DefaultFolderContabClassificadosOperacoesDiversas = '{F0000000-0040-0040-0040-00000000000F}',
  DefaultFolderContabDocsPartilhados = '{F0000000-0041-0041-0041-00000000000F}',
  DefaultFolderContabDocsPartilhadosRecebidos = '{F0000000-0042-0042-0042-00000000000F}',
  DefaultFolderContabDocsPartilhadosEnviados = '{F0000000-0043-0043-0043-00000000000F}',
  DefaultFolderContabDocsPartilhadosPorEnviar = '{F0000000-0044-0044-0044-00000000000F}',
  DefaultFolderManutPlanoExecucao = '{F0000000-0045-0045-0045-00000000000F}',
  DefaultFolderObrasAdicionais = '{F0000000-0046-0046-0046-00000000000F}',
  DefaultFolderSeguradoraFolhaFerias = '{F0000000-0047-0047-0047-00000000000F}',
  DefaultFolderImagens = '{F0000000-0048-0048-0048-00000000000F}',
  DefaultFolderPortalReg = '{F0000000-0049-0049-0040-00000000000F}',
  DefaultFolderEFatura = '{F0000000-0050-0050-0050-00000000000F}',
  DefaultFolderDeclaracaoMensalREmuneracoesAT = '{F0000000-0051-0051-0051-00000000000F}',
  DefaultFolderCOPE = '{F0000000-0050-0050-0052-00000000000F}',
  DefaultFolderCOTFO = '{F0000000-0053-0053-0053-00000000000F}',
  DefaultFolderRelatorioUnico = '{F0000000-0054-0054-0054-00000000000F}',
  DefaultFolderSubDe = '{F0000000-0055-0055-0055-00000000000F}',
  DefaultFolderRHDHEMPCompetencia = '{F0000000-0056-0056-0056-00000000000F}',
  DefaultFolderModelo3 = '{F0000000-0057-0057-0057-00000000000F}',
  DefaultFolderARCLI = '{F0000000-0058-0058-0058-00000000000F}',
  DefaultFolderTRServicos = '{F0000000-0059-0059-0059-00000000000F}',
  DefaultFolderPCAccao = '{F0000000-0060-0060-0060-00000000000F}',
  DefaultFolderDebDiretos = '{F0000000-0058-0058-0061-00000000000F}',
  DefaultFolderLinksUteis = '{F0000000-0062-0062-0062-00000000000F}',
  DefaultFolderTributAutonoTaxas = '{F0000000-0063-0063-0063-00000000000F}',
  DefaultFolderDPIVA = '{F0000000-0064-0064-0064-00000000000F}',
  DefaultFolderEdiImpEncomendas = '{F0000000-0065-0065-0065-00000000000F}',
  DefaultFolderByGUIDDeprecated = '{F0000000-0066-0066-0066-00000000000F}',
  DefaultFolderIMBEM = '{F0000000-0067-0067-0067-00000000000F}',
  DefaultFolderIMBEMMapas = '{F0000000-0068-0068-0068-00000000000F}',
  DefaultFolderPRHComInt = '{F0000000-0069-0069-0069-00000000000F}',
  DefaultFolderPRHDocsPlata = '{F0000000-0070-0070-0070-00000000000F}',
  DefaultFolderDocsPartilhadosCliente = '{F0000000-0071-0071-0071-00000000000F}',
  DefaultFolderEmpresaLogotipos = '{F0000000-0072-0072-0072-00000000000F}',
  DefaultFolderDeclaracaoPeriodicaIVA = '{F0000000-0073-0073-0073-00000000000F}',
  DefaultFolderSOAPActionsServer = '{F0000000-0074-0074-0074-00000000000F}',
  DefaultFolderDeclaracaoRecapitulativaIVA = '{F0000000-0075-0075-0075-00000000000F}',
  DefaultFolderIRCPagamentosAntecipados = '{F0000000-0076-0076-0076-00000000000F}',
  DefaultFolderModelo22 = '{F0000000-0077-0077-0077-00000000000F}',
  DefaultFolderIES = '{F0000000-0078-0078-0078-00000000000F}',
  DefaultFolderModelo10 = '{F0000000-0079-0079-0079-00000000000F}',
  DefaultFolderRetencoesIRSIRCIS = '{F0000000-0080-0080-0080-00000000000F}',
  DefaultFolderRV = '{F0000000-0081-0081-0081-00000000000F}',
  DefaultFolderRendas = '{F0000000-0082-0082-0082-00000000000F}',
  DefaultFolderCaixaGeralAposentacoes = '{F0000000-0083-0083-0083-00000000000F}',
  DefaultFolderDossierFiscal = '{F0000000-0084-0084-0084-00000000000F}',
  DefaultFolderGTAtividadeProc = '{F0000000-0085-0085-0085-00000000000F}',
  DefaultFolderGTTarefaProc = '{F0000000-0086-0086-0086-00000000000F}',
  DefaultFolderFotoEmpregado = '{F0000000-0087-0087-0087-00000000000F}',
  DefaultFolderArCla = '{F0000000-0088-0088-0088-00000000000F}',
  DefaultFolderPRHTarefaCab = '{F0000000-0089-0089-0089-00000000000F}',
  DefaultFolderGestaoReservas = '{F0000000-0090-0090-0090-00000000000F}',
  DefaultFolderTesCheque = '{F0000000-0091-0091-0091-00000000000F}',
  DefaultFolderTesLetra = '{F0000000-0092-0092-0092-00000000000F}',
  DefaultFolderOutros = '{F0000000-9999-9999-9999-00000000000F}',
  DefaultFolderConciliacaoBancaria = '{F0000000-0099-0099-0099-00000000000F}'
}

export interface IGDocEntityService extends IEntityService<IJsonGDFolder> {
  getFolderUrl(docid: string, folderid: string): Observable<string>;

  uploadFile(file: File, folderid: string, filename: string, params: string, tipodocentity: EDocTipoEntity): Promise<HttpResponse<unknown>>;

  uploadFileUrl(folderid: string, filename?: string, params?: string, tipodocentity?: EDocTipoEntity): Promise<string>;

  deleteFile(folderid: string, docid: string): Promise<HttpResponse<void>>;

  getFolderId(parentFolderId: string, folderName: string): Promise<HttpResponse<IJsonGDFolder>>;

  getFolderIdAnexos(parentFolderId: string, folderName: string): Promise<HttpResponse<IJsonGDFolderContent>>;

  deleteComment(docid: string, comentarioid: string): Promise<HttpResponse<void>>;

  addComment(folderid: string, comment: ICommentItem): Promise<HttpResponse<void>>;

  updateComment(comentarioid: string, comment: ICommentItem): Promise<HttpResponse<void>>;

  checkFolder(tipodocentity: EDocTipoEntity, parameters: string): Promise<HttpResponse<IJsonGDFolder>>;
}

export interface IGDocEntity extends IEntityDefinition<IJsonGDFolder, IGDocEntityService> {}

export const ENTITY_NAME_GDOC = 'gdoc';
