import {HttpResponse} from '@angular/common/http';
import {Component, Injector, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {copy, IPlToolbarItem, IPlToolbarMenuItem, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGridEventOnCellClick} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IJsonConfigTransfArmazem, IJsonDocTransfArmazem} from '../../jsonTransferenciasArmazens.entity.interface';
import {TransferenciasArmazensConfigModalComponent} from '../../modals/config/transferenciasArmazens.config.modal.component';
import {IDocTransfArmazemCabWithData, ITransfArmazemRequest} from '../../transferenciasArmazens.entity.interface';
import {TransferenciasArmazensService} from '../../transferenciasArmazens.entity.service';
import {DocumentoPesqDocsModalComponent} from '../../../docscomerciais/modals/pesqdocs/documento.pesqDocs.modal.component';
import {IJsonDocComercial, IJsonDocComercialLinha} from '../../../docscomerciais/jsonDocComercial.entity.interface';
import {DEFAULT_TOOLBAR_ID} from '../../../../../config/constants';
import {DocumentoRastreabilidadeService} from '../../../docscomerciais/modals/rastreabilidade/documento.rastreabilidade.service';
import {IJsonRastreabilidadeFaturacao} from '../../../docscomerciais/modals/rastreabilidade/jsonRastreabilidade.interface';
import {DocumentoRastreabilidadeModalComponent} from '../../../docscomerciais/modals/rastreabilidade/documento.rastreabilidade.modal.component';
import {ENTITY_NAME_ARTIGOS, IArtigo, IArtigosEntityService} from '../../../artigos/artigos.entity.interface';

@Component({
  selector: 'transferencias-armazens-list',
  templateUrl: './transferenciasArmazens.entity.list.component.html'
})
export class TransferenciasArmazensListComponent extends ModuloEntityListComponent<IDocTransfArmazemCabWithData> implements OnInit {
  private _configTransfArmazem: IJsonConfigTransfArmazem;
  private readonly _dropdownNovo: IPlToolbarItem;
  private readonly _btnDocSuporte: IPlToolbarMenuItem;
  private readonly _serviceArtigos: IArtigosEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _transferenciasArmazensService: TransferenciasArmazensService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _documentoRastreabilidadeService: DocumentoRastreabilidadeService
  ) {
    super(_injector);
    this._transferenciasArmazensService.getConfiguracoesTransfArmazens().then((response: HttpResponse<IJsonConfigTransfArmazem>) => {
      this._configTransfArmazem = response.body;
    });
    this._serviceArtigos = this._entityServiceBuilder.build<IArtigo, IArtigosEntityService>(ENTITY_NAME_ARTIGOS);

    this._dropdownNovo = {
      order: 100,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'novo',
      type: 'dropdown-split',
      iconLeft: '<i class="fa fa-fw fa-plus-circle"></i>',
      class: 'btn-primary',
      caption: 'global.btn.new',
      click: () => this.novo(),
      menu: []
    };

    this._btnDocSuporte = {
      caption: 'transferenciasarmazens.list.btn.carregarDocSuporte',
      iconLeft: '<i class="fa fa-fw fa-stack-overflow"></i>',
      click: () => this.copiarDocumento()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'config',
      order: 101,
      type: 'button',
      iconLeft: '<i class="fa fa-cog fa-fw"></i>',
      class: 'btn-light',
      caption: 'global.btn.config',
      click: () => this._configModal()
    });
    this._dropdownNovo.menu.push(this._btnDocSuporte);
    this.toolbar.addButton(this._dropdownNovo);
  }

  public onDetail({data}: IDevExpressDataGridEventOnCellClick<IDocTransfArmazemCabWithData>): Promise<void> {
    return this._transferenciasArmazensService.getDocDetail(data.nDocfaOrigem, data.nNumerOrigem, data.nDocOrigem).then((response) => {
      data._doc = response.body;
    });
  }

  public edit(data: IDocTransfArmazemCabWithData): void {
    const params: ITransfArmazemRequest = {id: 'doc', ndocfa: data.nDocfaOrigem, nnumer: data.nNumerOrigem, ndocumento: data.nDocOrigem};
    this._stateService.go(this.states.detail.name, params).then(() => undefined);
  }

  public showRastreabilidade(item: IDocTransfArmazemCabWithData): Promise<void> {
    return this._documentoRastreabilidadeService.getRastreabilidade(item.nDocfaOrigem, item.nNumerOrigem, item.nDocOrigem).then((response: HttpResponse<Array<IJsonRastreabilidadeFaturacao>>) => {
      const modalRef = this._cgModalService.showVanilla(DocumentoRastreabilidadeModalComponent, {size: 'fullscreen'});
      const componentInstance: DocumentoRastreabilidadeModalComponent = modalRef.componentInstance;
      componentInstance.rastreabilidade = response.body;
      componentInstance.faccbId = item.faccbIdOrigem;
    });
  }

  public novo(): Promise<void> {
    return this._stateService.go(this.states.new.name).then(() => undefined);
  }

  public copiarDocumento(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocumentoPesqDocsModalComponent);
    const componentInstance: DocumentoPesqDocsModalComponent = modalInstance.componentInstance;
    componentInstance.doc = {cab: {faccbId: 0, nConta: ''}, linhas: []};
    componentInstance.origemTransfArmazens = true;

    const doc: IJsonDocTransfArmazem = {
      cab: {
        faccbIdOrigem: 0,
        nDocfaOrigem: 0,
        nDocOrigem: 0,
        nNumerOrigem: 0,
        nDocfaDestino: 0,
        nDocDestino: 0,
        nNumerDestino: 0,
        dataDoc: undefined
      },
      linhas: []
    };

    return modalInstance.result.then((result: IJsonDocComercial) => {
      doc.cab.numeroSuporte = result.cab.nDoc;
      doc.cab.faccbIdSuporte = result.cab.faccbId;

      this._appService.setGlobalLoading(true);

      const promises = [];
      result.linhas.map((linha: IJsonDocComercialLinha) => {
        return promises.push(
          this._serviceArtigos.get({id: linha.nArtigo}).then((response) => {
            doc.linhas.push({
              origemNArtigo: linha.nArtigo,
              nomeArtigoOrigem: linha.nomeArtigo,
              origemNArmazem: linha.nArmazem,
              nomeArmazemOrigem: this._configTransfArmazem.nomeArmazemOrigem,
              origemQtd: linha.qtd1,
              destinoNArtigo: linha.nArtigo,
              nomeArtigoDestino: linha.nomeArtigo,
              destinoNArmazem: this._configTransfArmazem.nArmazemDestino,
              nomeArmazemDestino: this._configTransfArmazem.nomeArmazemDestino,
              destinoQtd: linha.qtd1,
              facliIdOrigem: linha.facliId,
              isDocSuporte: true,
              temLote: response.body.temLote
            });
          })
        );
      });

      Promise.allSettled(promises).then(() => {
        this._stateService.go(this.states.new.name, {doc: doc}).then(() => undefined);
      });
    });
  }

  private _configModal(): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(TransferenciasArmazensConfigModalComponent);
    const componentInstance: TransferenciasArmazensConfigModalComponent = modalInstance.componentInstance;
    componentInstance.configTransfArmazem = copy(this._configTransfArmazem);
    return modalInstance.result.then(() => {
      this._plAlertService.success('transferenciasarmazens.messages.configGuardadasSucesso');
    });
  }
}
