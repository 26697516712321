import {AfterContentChecked, Component, ContentChildren, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges} from '@angular/core';
import {NgbDropdownConfig, Placement} from '@ng-bootstrap/ng-bootstrap';
import {CgCustomActionsAutocompleteContentDirective} from './customActions.autocomplete.content.directive';
import {IAutocompleteCustomAction} from './customActions.autocomplete.component.interface';
import {isFunction, TValueOrPromise} from 'pl-comps-angular';

const PLACEMENT_DEFAULT: ReadonlyArray<Placement> = Object.freeze(['bottom-right', 'bottom-left', 'top-right', 'top-left']);

@Component({
  selector: 'cg-autocomplete-custom-actions',
  templateUrl: './customActions.autocomplete.component.html'
})
export class CgAutoCompleteCustomActionsComponent implements OnInit, OnChanges, AfterContentChecked {
  @Input() public customActions: Array<IAutocompleteCustomAction>;
  @Input() public dropdownContainer: NgbDropdownConfig['container'];
  @Input() public placement: Placement | Array<Placement> | string;

  @Output() public readonly evtOpenChanged: EventEmitter<boolean>;

  public templateContent: CgCustomActionsAutocompleteContentDirective;

  @ContentChildren(CgCustomActionsAutocompleteContentDirective, {descendants: false}) private readonly _templateContent: QueryList<CgCustomActionsAutocompleteContentDirective>;

  constructor() {
    this.evtOpenChanged = new EventEmitter<boolean>();
    this.customActions = [];
    this.placement = PLACEMENT_DEFAULT.slice();
    this.dropdownContainer = 'body';
  }

  public ngOnInit(): void {
    this._handleChanges();
  }

  public ngOnChanges({dropdownContainer, customEntityActions, placement}: SimpleChanges): void {
    if (dropdownContainer && !dropdownContainer.isFirstChange()) {
      this._changedDropdownContainer(dropdownContainer.currentValue);
    }
    if (customEntityActions && !customEntityActions.isFirstChange()) {
      this._changedCustomEntityActions(customEntityActions.currentValue);
    }
    if (placement && !placement.isFirstChange()) {
      this._changedPlacement(placement.currentValue);
    }
  }

  public ngAfterContentChecked(): void {
    this.templateContent = this._templateContent.first;
  }

  public openChanged(value: boolean): void {
    this.evtOpenChanged.emit(value);
  }

  public executeAction = (customAction: IAutocompleteCustomAction) => (): TValueOrPromise<void> => {
    if (isFunction(customAction.action)) {
      return customAction.action(customAction.data);
    }
    return undefined;
  };

  private _handleChanges(): void {
    this._changedDropdownContainer();
    this._changedCustomEntityActions();
    this._changedPlacement();
  }

  private _changedDropdownContainer(value: NgbDropdownConfig['container'] = this.dropdownContainer): void {
    this.dropdownContainer = value || 'body';
  }

  private _changedCustomEntityActions(value: Array<IAutocompleteCustomAction> = this.customActions): void {
    this.customActions = value || [];
  }

  private _changedPlacement(value: Placement | Array<Placement> | string = this.placement): void {
    this.placement = value || PLACEMENT_DEFAULT.slice();
  }
}
