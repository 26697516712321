import {merge} from 'lodash-es';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {DxDataGridComponent, ICollectionNestedOption} from 'devextreme-angular';
import {Summary, ToolbarItem} from 'devextreme/ui/data_grid';
import dxTextBox from 'devextreme/ui/text_box';
import {ClickEvent, Properties as ButtonProperties} from 'devextreme/ui/button';
import {AfterContentInit, Directive, EventEmitter, Host, Inject, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, Renderer2, SimpleChanges} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {
  copy,
  elementIndex,
  IPlDynamicVisualsSecondaryClickAction,
  IPlFormatConfig,
  isArray,
  isBoolean,
  isDefinedNotNull,
  isEmpty,
  isFunction,
  isNumber,
  isObject,
  isString,
  isUndefinedOrNull,
  Logger,
  TPlEditType
} from 'pl-comps-angular';
import {AppService} from '../../../../services/app/app.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {DataSourcesRegistryService} from '../../../datasource/datasources.registry.service';
import {DEV_EXPRESS_DATA_GRID_CONFIGURATION} from '../configuration/devexpress.datagrid.configuration';
import {DEV_EXPRESS_DATAGRID_RESET_STATE_BUTTON_NAME} from '../state/devexpress.datagrid.statestoring.interface';
import {DEV_EXPRESS_FORMAT_INTEGER, devExpressGenerateFormatDouble} from '../../widget/devexpress.widget.utilities';
import {DEV_EXPRESS_PERCENT_FORMATS, DEV_EXPRESS_SUPPRESS_ZEROS_FORMATS} from '../utilities/devexpress.datagrid.utilities.interface';
import {
  devExpressDataGridCellPreparedHighlight,
  devExpressDataGridColumnPercent,
  devExpressDataGridColumnSuppressZeros,
  devExpressDataGridNormalizeFilterExpressionAccents,
  devExpressDataGridTranslateLookupDataSource
} from '../utilities/devexpress.datagrid.utilities';
import {devExpressDataGridExtendState} from '../utilities/devexpress.datagrid.statestoring.utilities';
import {devExpressDataGridGenerateExportMenuActionExcel, devExpressDataGridGenerateExportMenuActionPdf} from '../export/devexpress.datagrid.export';
import {DevExpressDataGridStateStoringService} from '../state/devexpress.datagrid.statestoring.service';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {extractBindingProperty} from '../../../../../common/utils/utils';
import {IDataSource} from '../../../datasource/input/datasource.input.component.interface';
import {DEVEXPRESS_DATAGRID_ACTIONS_DATAFIELD_NAME, IDevExpressDataGrid, IDevExpressDataGridColumn} from '../devexpress.datagrid.interface';
import {IDevExpressDataGridConfiguration} from '../configuration/devexpress.datagrid.configuration.interface';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContentReady,
  IDevExpressDataGridEventOnExporting,
  IDevExpressDataGridEventOnKeyDown,
  IDevExpressDataGridEventOnOptionChanged,
  IDevExpressDataGridEventOnRowExpanded
} from '../events/devexpress.datagrid.events.interface';
import {IDevExpressDataGridExportExcelProps, IDevExpressDataGridExportPdfProps} from '../export/devexpress.datagrid.export.interface';
import {IDevExpressDataGridState} from '../state/devexpress.datagrid.state.interface';
import {IDevExpressFormatObject, TDevExpressFormat} from '../../widget/devexpress.widget.interface';
import {SELECTOR_DEV_EXPRESS_DATAGRID_EXPORT_BUTTON, SELECTOR_DEV_EXPRESS_DATAGRID_MASTER_DETAIL_ROW, SELECTOR_DEV_EXPRESS_DATAGRID_SEARCH_PANEL} from '../../widget/devexpress.selectors.interface';
import {TDevExpressDataGridColumnDataType} from '../devexpress.datagrid.types.interface';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';

const SELECTOR_DATA_ROW = '.dx-data-row';
const UPDATE_DIMENSIONS_DEBOUNCE = 10;

@Directive({
  selector: '[cgDxDataGrid]'
})
export class DevExpressDataGridCentralGestDirective implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  @Input() public cgDxDataGrid: void | '' | IDevExpressDataGrid;
  @Input() public cgDxDataGridColumns: Array<IDevExpressDataGridColumn>;
  @Input() public cgDxDataGridInstanceName: string;
  @Input() public cgDxDataGridState: IDevExpressDataGridState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public selectedRowKeys: Array<any>;
  @Output() public readonly cgDxDataGridStateLoad: EventEmitter<IDevExpressDataGridState>;
  @Output() public readonly cgDxDataGridStateChange: EventEmitter<IDevExpressDataGridState>;
  @Output() public readonly cgDxDataGridStateCleared: EventEmitter<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly cgDxDataGridKeydownLastRow: EventEmitter<IDevExpressDataGridEventOnKeyDown<any, any>>;

  private readonly _document: Document;
  private readonly _defaultsMap: Map<string, unknown>;
  private readonly _subjectUpdateDimensions: Subject<void>;
  private readonly _subscriptionFormat: Subscription;
  private readonly _subscriptionUpdateDimensions: Subscription;
  private readonly _subscriptionOnContentReady: Subscription;
  private readonly _subscriptionOnCellPrepared: Subscription;
  private readonly _subscriptionOnRowExpanded: Subscription;
  private readonly _subscriptionOnOptionChanged: Subscription;
  private readonly _subscriptionOnKeydown: Subscription;
  private _dataGrid: IDevExpressDataGrid;
  private _formatDouble: string;
  private _firstContentReady: boolean;
  private _observerInstanceElement: ResizeObserver;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(DOCUMENT) document: any,
    private readonly _renderer: Renderer2,
    private readonly _logger: Logger,
    private readonly _translateService: TranslateService,
    private readonly _appService: AppService,
    private readonly _authService: AuthService,
    private readonly _dataSourcesRegistryService: DataSourcesRegistryService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _devExpressDataGridStateStoringService: DevExpressDataGridStateStoringService,
    @Inject(DEV_EXPRESS_DATA_GRID_CONFIGURATION) private readonly _defaultConfiguration: IDevExpressDataGridConfiguration,
    @Host() private readonly _dataGridComponent: DxDataGridComponent
  ) {
    this.cgDxDataGridStateLoad = new EventEmitter<IDevExpressDataGridState>();
    this.cgDxDataGridStateChange = new EventEmitter<IDevExpressDataGridState>();
    this.cgDxDataGridStateCleared = new EventEmitter<void>();
    this.cgDxDataGridKeydownLastRow = new EventEmitter<IDevExpressDataGridEventOnKeyDown>();
    this._document = document;
    this._defaultsMap = new Map<string, unknown>();
    this._subjectUpdateDimensions = new Subject<void>();
    this._firstContentReady = true;
    this._updateTableDimensions = this._updateTableDimensions.bind(this);

    this._subscriptionFormat = this._appService.format().subscribe((format: IPlFormatConfig) => {
      const decimalDigits = isNumber(format.decimalsLimit) ? format.decimalsLimit : 2;
      this._formatDouble = devExpressGenerateFormatDouble(decimalDigits);
    });

    this._subscriptionUpdateDimensions = this._subjectUpdateDimensions.asObservable().pipe(debounceTime(UPDATE_DIMENSIONS_DEBOUNCE)).subscribe(this._updateTableDimensions);

    this._subscriptionOnContentReady = this._dataGridComponent.onContentReady.subscribe((event: IDevExpressDataGridEventOnContentReady) => {
      this._onContentReady(event);
    });

    this._subscriptionOnCellPrepared = this._dataGridComponent.onCellPrepared.subscribe((event: IDevExpressDataGridEventOnCellPrepared) => {
      this._onCellPrepared(event);
    });

    this._subscriptionOnRowExpanded = this._dataGridComponent.onRowExpanded.subscribe((event: IDevExpressDataGridEventOnRowExpanded) => {
      // Ignore if expanded row is not a master row
      if (isArray(event.key)) {
        return;
      }

      if (!this._observerInstanceElement) {
        this._observerInstanceElement = new ResizeObserver(() => {
          this._subjectUpdateDimensions.next();
        });
      } else {
        this._observerInstanceElement.disconnect();
      }

      for (const element of Array.from(event.element.querySelectorAll(SELECTOR_DEV_EXPRESS_DATAGRID_MASTER_DETAIL_ROW))) {
        this._observerInstanceElement.observe(element);
      }
    });

    this._subscriptionOnOptionChanged = this._dataGridComponent.onOptionChanged.subscribe((event: IDevExpressDataGridEventOnOptionChanged) => {
      this._onOptionChanged(event);
    });

    this._subscriptionOnKeydown = this._dataGridComponent.onKeyDown.subscribe((event: IDevExpressDataGridEventOnKeyDown) => {
      this._onKeydown(event);
    });
  }

  public ngOnInit(): void {
    this._changedDataGrid(isObject(this.cgDxDataGrid) ? <IDevExpressDataGrid>this.cgDxDataGrid : undefined);
    this._applyConfiguration();
  }

  public ngOnChanges({cgDxDataGrid, cgDxDataGridColumns}: SimpleChanges): void {
    const changedDataGrid: boolean = cgDxDataGrid && !cgDxDataGrid.isFirstChange();
    if (changedDataGrid) {
      this._cleanupPreviousDefaults();
      this._changedDataGrid(cgDxDataGrid.currentValue);
      if (!isArray(this.cgDxDataGridColumns) && isArray(this._dataGrid.columns)) {
        this._cleanupPreviousColumnsDefaults();
        this._changedDataGridColumns(this._dataGrid.columns);
      }
    }
    if (cgDxDataGridColumns && !cgDxDataGridColumns.isFirstChange()) {
      this._cleanupPreviousColumnsDefaults();
      this._changedDataGridColumns(cgDxDataGridColumns.currentValue);
    }
    if (changedDataGrid) {
      this._applyConfiguration();
    }
  }

  public ngAfterContentInit(): void {
    if (isArray(this.cgDxDataGridColumns)) {
      if (this.cgDxDataGridColumns.length) {
        this._changedDataGridColumns(this.cgDxDataGridColumns);
      }
    } else if (isArray(this._dataGrid.columns)) {
      if (this._dataGrid.columns.length) {
        this._changedDataGridColumns(this._dataGrid.columns);
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionFormat.unsubscribe();
    this._subscriptionUpdateDimensions.unsubscribe();
    this._subscriptionOnContentReady.unsubscribe();
    this._subscriptionOnCellPrepared.unsubscribe();
    this._subscriptionOnRowExpanded.unsubscribe();
    this._subscriptionOnOptionChanged.unsubscribe();
    this._subscriptionOnKeydown.unsubscribe();
    if (this._observerInstanceElement) {
      this._observerInstanceElement.disconnect();
    }
  }

  private _changedDataGrid(dataGrid: IDevExpressDataGrid): void {
    // Apply default configuration to data grid
    this._dataGrid = this._setDataGridDefaultConfiguration(dataGrid);

    if (this._dataGrid.export.enabled && !isFunction(this._dataGrid.onExporting)) {
      this._dataGrid.onExporting = this._onExporting.bind(this);
    }

    if (this._dataGrid.searchPanel.placeholder) {
      this._dataGrid.searchPanel.placeholder = this._translateService.instant(this._dataGrid.searchPanel.placeholder);
    }

    if (this._dataGrid.stateStoring.enabled && this._dataGrid.stateStoring.type === 'custom') {
      if (!isFunction(this._dataGrid.stateStoring.customLoad)) {
        this._dataGrid.stateStoring.customLoad = async () => {
          const instanceId: string = await this._generateStateInstanceId();
          if (instanceId) {
            try {
              let dataGridState: IDevExpressDataGridState = await this._devExpressDataGridStateStoringService.customLoad(instanceId, this._dataGrid);
              if (isObject(this.cgDxDataGridState)) {
                dataGridState = merge({}, dataGridState, this.cgDxDataGridState);
                this.cgDxDataGridState = undefined;
              }
              dataGridState = merge<IDevExpressDataGridState, IDevExpressDataGridState, IDevExpressDataGridState>(
                {
                  filterPanel: isObject(this._dataGrid.filterPanel)
                    ? {
                        filterEnabled: isBoolean(this._dataGrid.filterPanel.filterEnabled) ? this._dataGrid.filterPanel.filterEnabled : undefined
                      }
                    : undefined
                },
                dataGridState,
                {
                  allowedPageSizes: this._dataGrid.pager?.allowedPageSizes,
                  filterValue: this._dataGrid.filterValue,
                  focusedRowKey: this._dataGrid.focusedRowKey,
                  pageIndex: this._dataGrid.paging?.pageIndex,
                  pageSize: this._dataGrid.paging?.pageSize,
                  searchText: this._dataGrid.searchPanel?.text,
                  selectedRowKeys: this.selectedRowKeys || this._dataGrid.selectedRowKeys || this._dataGridComponent.instance.option('selectedRowKeys') || []
                }
              );
              this.cgDxDataGridStateLoad.emit(dataGridState);
              return dataGridState;
            } catch (reason) {
              this._logger.error(reason);
            }
          }
          return undefined;
        };
      }
      if (!isFunction(this._dataGrid.stateStoring.customSave)) {
        this._dataGrid.stateStoring.customSave = async (state: IDevExpressDataGridState) => {
          devExpressDataGridExtendState(state);
          this.cgDxDataGridStateChange.emit(state);
          const instanceId: string = await this._generateStateInstanceId();
          if (!instanceId) {
            return;
          }
          try {
            await this._devExpressDataGridStateStoringService.customSave(instanceId, this._dataGrid, state);
          } catch (reason) {
            this._logger.error(reason);
          }
        };
      }

      if (!isEmpty(this.cgDxDataGridInstanceName)) {
        if (!isObject(this._dataGrid.toolbar)) {
          this._dataGrid.toolbar = {};
        }
        if (!isArray(this._dataGrid.toolbar.items)) {
          this._dataGrid.toolbar.items = ['groupPanel', 'applyFilterButton', 'addRowButton', 'saveButton', 'revertButton', 'exportButton', 'columnChooserButton', 'searchPanel'];
        }
        let btnClearState: ToolbarItem = <ToolbarItem>this._dataGrid.toolbar.items.find((toolbarItem) => {
          return isObject(toolbarItem) && (<ToolbarItem>toolbarItem).name === DEV_EXPRESS_DATAGRID_RESET_STATE_BUTTON_NAME;
        });
        if (!btnClearState) {
          btnClearState = {
            name: DEV_EXPRESS_DATAGRID_RESET_STATE_BUTTON_NAME,
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'preferences',
              hint: this._translateService.instant('components.devextreme.datagrid.preferences'),
              onClick: (event: ClickEvent) => {
                this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, [
                  {
                    icon: 'fa-trash',
                    caption: 'components.devextreme.datagrid.statestoring.clear',
                    click: () =>
                      this._clearState().catch((reason: unknown) => {
                        this._logger.error(reason);
                      })
                  }
                ]);
              }
            } satisfies ButtonProperties
          };
        }
        const toolbarSearchPanelIndex: number = this._dataGrid.toolbar.items.findIndex((toolbarItem) => {
          return toolbarItem === 'searchPanel' || (<ToolbarItem>toolbarItem)?.name === 'searchPanel';
        });
        if (toolbarSearchPanelIndex === -1) {
          this._dataGrid.toolbar.items.push(btnClearState);
        } else {
          this._dataGrid.toolbar.items.splice(toolbarSearchPanelIndex, 0, btnClearState);
        }
      }
    }

    // Evaluate defaults
    if (isObject(this._dataGrid.defaults)) {
      for (const optionName of Object.keys(this._dataGrid.defaults)) {
        const optionValue: unknown = this._dataGrid.defaults[optionName];
        if (isUndefinedOrNull(optionValue)) {
          continue;
        }
        this._defaultsMap.set(optionName, optionValue);
        if (isUndefinedOrNull(this._dataGrid[optionName])) {
          this._dataGrid[optionName] = optionValue;
        }
      }
      delete this._dataGrid.defaults;
    }
  }

  private _changedDataGridColumns(columns: Array<IDevExpressDataGridColumn>): void {
    const queryList: QueryList<ICollectionNestedOption> = new QueryList<ICollectionNestedOption>();
    if (isArray(columns)) {
      // Apply default configuration to columns
      for (let i = 0; i < columns.length; i++) {
        columns[i] = this._setDataGridColumnDefaultConfiguration(columns[i]);
        const column: IDevExpressDataGridColumn = columns[i];

        // Evaluate column defaults
        if (isObject(column.defaults)) {
          for (const optionName of Object.keys(column.defaults)) {
            const optionValue: unknown = column.defaults[optionName];
            if (isUndefinedOrNull(optionValue)) {
              continue;
            }
            this._defaultsMap.set(`columns[${i}].${optionName}`, optionValue);
            if (isUndefinedOrNull(column[optionName])) {
              column[optionName] = optionValue;
            }
          }
          delete column.defaults;
        }
      }

      queryList.reset(
        columns.map<ICollectionNestedOption>((column: IDevExpressDataGridColumn, index: number) => {
          return {_index: index, _value: column};
        })
      );
    }
    this._dataGridComponent.setChildren('columns', queryList);
  }

  private _setDataGridDefaultConfiguration(dataGrid: IDevExpressDataGrid): IDevExpressDataGrid {
    return merge<object, IDevExpressDataGrid, IDevExpressDataGrid>({}, this._defaultConfiguration.dataGrid, dataGrid);
  }

  private _setDataGridColumnDefaultConfiguration(column: IDevExpressDataGridColumn): IDevExpressDataGridColumn {
    const handledColumn = !column.type ? merge<object, IDevExpressDataGridColumn, IDevExpressDataGridColumn>({}, this._defaultConfiguration.columns, column) : copy(column);
    if (handledColumn.caption) {
      handledColumn.caption = this._translateService.instant(handledColumn.caption);
    }
    let format: string;
    let dataType: TDevExpressDataGridColumnDataType;
    switch (<TDevExpressDataGridColumnDataType | TPlEditType>handledColumn.dataType) {
      case 'currency':
        dataType = 'number';
        format = 'currency';
        break;
      case 'double':
        dataType = 'number';
        format = this._formatDouble;
        break;
      case 'percent':
        dataType = 'number';
        format = 'percent';
        break;
      case 'integer':
      case 'smallint':
        dataType = 'number';
        format = DEV_EXPRESS_FORMAT_INTEGER;
        break;
      case 'cgnumber':
      case 'cginteger':
      case 'cgsmallint':
        dataType = 'number';
        break;
    }
    if (!handledColumn.format && format) {
      handledColumn.format = format;
    }
    if (isObject(handledColumn.format) && !(<IDevExpressFormatObject>handledColumn.format).type && format) {
      (<IDevExpressFormatObject>handledColumn.format).type = format;
    }
    handledColumn.format = this._handleFormat(handledColumn.format, handledColumn);
    if (isObject(handledColumn.format)) {
      if ((<IDevExpressFormatObject>handledColumn.format).suppressZeros && DEV_EXPRESS_SUPPRESS_ZEROS_FORMATS.includes(handledColumn.dataType)) {
        devExpressDataGridColumnSuppressZeros(handledColumn);
      }
      if ((<IDevExpressFormatObject>handledColumn.format).percent && DEV_EXPRESS_PERCENT_FORMATS.includes(handledColumn.dataType)) {
        devExpressDataGridColumnPercent(handledColumn);
      }
    }
    if (handledColumn.dataType === 'boolean' && isBoolean(handledColumn.filterValue) && isEmpty(handledColumn.selectedFilterOperation)) {
      handledColumn.selectedFilterOperation = '=';
    }
    if (handledColumn.lookup) {
      if (handledColumn.lookup?.cgDataSource && !handledColumn.lookup.dataSource) {
        const dataSource: IDataSource<unknown> = this._dataSourcesRegistryService.get(handledColumn?.lookup.cgDataSource);
        if (dataSource?.autocomplete && dataSource?.data) {
          if (!handledColumn.lookup.valueExpr) {
            handledColumn.lookup.valueExpr = extractBindingProperty(dataSource.autocomplete.valueExpr, false);
          }
          if (!handledColumn.lookup.displayExpr && isString(dataSource.autocomplete.rowTemplate)) {
            handledColumn.lookup.displayExpr = extractBindingProperty(dataSource.autocomplete.output, true);
          }
          handledColumn.lookup.dataSource = dataSource.data.slice();
        }
      }
      handledColumn.lookup = devExpressDataGridTranslateLookupDataSource(this._translateService, handledColumn.lookup);
    }
    if (dataType) {
      handledColumn.dataType = dataType;
    }
    if (
      (!handledColumn.dataType || handledColumn.dataType === 'string') &&
      !handledColumn.calculateFilterExpression &&
      (!this._dataGrid.remoteOperations || (isObject(this._dataGrid.remoteOperations) && !(<{filtering: boolean}>this._dataGrid.remoteOperations).filtering))
    ) {
      handledColumn.calculateFilterExpression = devExpressDataGridNormalizeFilterExpressionAccents;
    }
    if (handledColumn.type === 'actions') {
      handledColumn.type = 'buttons';
      if (isEmpty(handledColumn.dataField)) {
        handledColumn.dataField = DEVEXPRESS_DATAGRID_ACTIONS_DATAFIELD_NAME;
      }
      if (isEmpty(handledColumn.caption)) {
        handledColumn.caption = ' ';
      }
      if (!isBoolean(handledColumn.allowEditing)) {
        handledColumn.allowEditing = false;
      }
      if (!isBoolean(handledColumn.allowExporting)) {
        handledColumn.allowExporting = false;
      }
      if (!isBoolean(handledColumn.allowFiltering)) {
        handledColumn.allowFiltering = false;
      }
      if (!isBoolean(handledColumn.allowFixing)) {
        handledColumn.allowFixing = false;
      }
      if (!isBoolean(handledColumn.allowGrouping)) {
        handledColumn.allowGrouping = false;
      }
      if (!isBoolean(handledColumn.allowHeaderFiltering)) {
        handledColumn.allowHeaderFiltering = false;
      }
      if (!isBoolean(handledColumn.allowHiding)) {
        handledColumn.allowHiding = false;
      }
      if (!isBoolean(handledColumn.allowReordering)) {
        handledColumn.allowReordering = false;
      }
      if (!isBoolean(handledColumn.allowResizing)) {
        handledColumn.allowResizing = false;
      }
      if (!isBoolean(handledColumn.allowSearch)) {
        handledColumn.allowSearch = false;
      }
      if (!isBoolean(handledColumn.allowSorting)) {
        handledColumn.allowSorting = false;
      }
      if (!isBoolean(handledColumn.showInColumnChooser)) {
        handledColumn.showInColumnChooser = false;
      }
      if (!isBoolean(handledColumn.fixed)) {
        handledColumn.fixed = true;
      }
      if (isEmpty(handledColumn.fixedPosition)) {
        handledColumn.fixedPosition = 'right';
      }
    }
    if (isArray(handledColumn.columns)) {
      for (let i = 0; i < handledColumn.columns.length; i++) {
        handledColumn.columns[i] = this._setDataGridColumnDefaultConfiguration(handledColumn.columns[i]);
      }
    }
    return handledColumn;
  }

  private _setDataGridSummaryConfiguration(summary: Summary): void {
    if (isArray(summary.groupItems)) {
      for (const groupItem of summary.groupItems) {
        groupItem.valueFormat = this._handleFormat(groupItem.valueFormat);
      }
    }
    if (isArray(summary.totalItems)) {
      for (const totalItem of summary.totalItems) {
        totalItem.valueFormat = this._handleFormat(totalItem.valueFormat);
      }
    }
  }

  private _applyConfiguration(): void {
    for (const key of Object.keys(this._dataGrid)) {
      let value: unknown = this._dataGrid[key];
      if ((isFunction(value) && key !== 'onExporting') || key === 'columns' || key === 'dataSource' || key === 'defaults') {
        continue;
      }
      if (isObject(value) && !isArray(value)) {
        const optionValue: unknown = this._dataGridComponent.instance.option(key);
        if (isObject(optionValue) && !isArray(optionValue)) {
          if (key === 'summary') {
            let summary = <Summary>value;

            const groupItems = summary.groupItems;
            const totalItems = summary.totalItems;

            const currentDataGridSummary: Summary = this._dataGridComponent.instance.option(key);

            value = merge({}, summary, currentDataGridSummary);

            summary = <Summary>value;

            if (groupItems !== currentDataGridSummary.groupItems) {
              summary.groupItems = groupItems;
            }

            if (totalItems !== currentDataGridSummary.totalItems) {
              summary.totalItems = totalItems;
            }

            this._setDataGridSummaryConfiguration(summary);
          } else {
            value = merge({}, this._dataGridComponent.instance.option(key), <object>value);
          }
        }
      }
      this._dataGridComponent.instance.option(key, value);
    }
  }

  private _updateTableDimensions(): void {
    if (this._dataGridComponent.instance) {
      this._dataGridComponent.instance.updateDimensions();
    }
  }

  private _onContentReady(event: IDevExpressDataGridEventOnContentReady): void {
    if (this._firstContentReady) {
      this._firstContentReady = false;
      if (this._dataGrid.searchPanel?.focusOnInit) {
        const inputSearch: HTMLInputElement = event.component.element().querySelector(SELECTOR_DEV_EXPRESS_DATAGRID_SEARCH_PANEL);
        if (inputSearch) {
          const textbox: dxTextBox = <dxTextBox>dxTextBox.getInstance(inputSearch);
          if (textbox) {
            textbox.focus();
          }
        }
      }
    }
  }

  private _onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared): void {
    devExpressDataGridCellPreparedHighlight(event);

    switch (event.rowType) {
      case 'header':
        if (event.column.headerAlignment) {
          let cssClass: string;
          switch (event.column.headerAlignment) {
            case 'left':
              cssClass = 'text-left';
              break;
            case 'center':
              cssClass = 'text-center';
              break;
            case 'right':
              cssClass = 'text-right';
              break;
          }
          if (cssClass) {
            this._renderer.addClass(event.cellElement, cssClass);
          }
        }
        break;
    }
  }

  private _onExporting(event: IDevExpressDataGridEventOnExporting): void {
    // Disable deprecated built-in export functionality
    event.cancel = true;

    let hostElement: HTMLElement = event.element.querySelector<HTMLElement>(SELECTOR_DEV_EXPRESS_DATAGRID_EXPORT_BUTTON);
    if (!hostElement) {
      hostElement = this._document.querySelector<HTMLElement>(`body > .dx-popup-wrapper ${SELECTOR_DEV_EXPRESS_DATAGRID_EXPORT_BUTTON}`);
    }
    const actions: Array<IPlDynamicVisualsSecondaryClickAction> = [];

    if (this._dataGrid.export.enabledExcel) {
      let properties: IDevExpressDataGridExportExcelProps = this._dataGrid.export.propertiesExcel;
      if (!isObject(properties)) {
        properties = {};
      }
      if (!properties.filename && this._dataGrid.export.filename) {
        properties.filename = this._dataGrid.export.filename;
      }
      actions.push(devExpressDataGridGenerateExportMenuActionExcel(event, this._translateService, properties));
    }

    if (this._dataGrid.export.enabledPdf) {
      let properties: IDevExpressDataGridExportPdfProps = this._dataGrid.export.propertiesPdf;
      if (!isObject(properties)) {
        properties = {};
      }
      if (!properties.filename && this._dataGrid.export.filename) {
        properties.filename = this._dataGrid.export.filename;
      }
      actions.push(devExpressDataGridGenerateExportMenuActionPdf(event, this._translateService, properties));
    }

    if (actions.length) {
      this._devExpressDataGridUIService.openContextMenu(hostElement, actions, {cssClass: 'datagrid-export-dropdown-menu-left'});
    }
  }

  private _onOptionChanged({fullName, value}: IDevExpressDataGridEventOnOptionChanged): void {
    if (fullName.endsWith('sortOrder') || fullName.endsWith('groupIndex')) {
      this._dataGridComponent.instance.collapseAll(-1);
    }
    if (!this._defaultsMap.size || isDefinedNotNull(value)) {
      return;
    }
    const defaultOptionValue: unknown = this._defaultsMap.get(fullName);
    if (isDefinedNotNull(defaultOptionValue)) {
      this._dataGridComponent.instance.option(fullName, defaultOptionValue);
    }
  }

  private _onKeydown(event: IDevExpressDataGridEventOnKeyDown): void {
    const row = (<Element>event.event.target).closest(SELECTOR_DATA_ROW);
    if (!row) {
      return;
    }
    const container = row.closest('tbody');
    if (!container) {
      return;
    }
    const rows = container.querySelectorAll(SELECTOR_DATA_ROW).length;
    const rowIndex = elementIndex(row);
    if (rowIndex === rows - 1) {
      this.cgDxDataGridKeydownLastRow.emit(event);
    }
  }

  private _cleanupPreviousDefaults(): void {
    for (const optionName of this._defaultsMap.keys()) {
      if (!optionName.startsWith('columns')) {
        this._defaultsMap.delete(optionName);
      }
    }
  }

  private _cleanupPreviousColumnsDefaults(): void {
    for (const optionName of this._defaultsMap.keys()) {
      if (optionName.startsWith('columns')) {
        this._defaultsMap.delete(optionName);
      }
    }
  }

  private _handleFormat(format: TDevExpressFormat, column?: IDevExpressDataGridColumn): TDevExpressFormat {
    if (!format) {
      return undefined;
    } else if (isObject(format)) {
      if ((<IDevExpressFormatObject>format).type === 'double' || column?.dataType === 'double') {
        (<IDevExpressFormatObject>format).type = isNumber((<IDevExpressFormatObject>format).decimalsLimit)
          ? devExpressGenerateFormatDouble((<IDevExpressFormatObject>format).decimalsLimit)
          : this._formatDouble;
      }
    } else if (format === 'double' || column?.dataType === 'double') {
      return this._formatDouble;
    }
    return format;
  }

  private _generateStateInstanceId(): Promise<string> {
    if (isEmpty(this.cgDxDataGridInstanceName)) {
      return Promise.resolve('');
    }
    return this._authService.identity().then((session: TUserSession) => {
      if (!session) {
        return Promise.resolve('');
      }
      if (isDefinedNotNull(this.cgDxDataGrid)) {
        const gridInstance = <IDevExpressDataGrid>this.cgDxDataGrid;
        if (isFunction(gridInstance.stateStoring?.generateStateInstanceIdFn)) {
          return gridInstance.stateStoring.generateStateInstanceIdFn(session.userId, session.erp.nEmpresa, this.cgDxDataGridInstanceName);
        }
      }
      return `${session.userId}.${session.erp.nEmpresa}.${this.cgDxDataGridInstanceName}`;
    });
  }

  private _clearState(): Promise<void> {
    return this._generateStateInstanceId().then((instanceId: string) => {
      if (!instanceId) {
        return Promise.resolve();
      }
      return this._devExpressDataGridStateStoringService.clear(instanceId).then(() => {
        this._dataGridComponent.instance.option('state', null);
        this.cgDxDataGridStateCleared.emit();
      });
    });
  }
}
