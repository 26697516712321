<pl-autocomplete
  [attrName]="attrName"
  [model]="outputValue"
  (modelChange)="changedOutputValue($event)"
  [inputClass]="inputClass"
  [allowInvalid]="options?.allowInvalid"
  [allowEmpty]="options?.allowEmpty"
  [filterFields]="filterFields"
  [output]="entityOutput"
  [placeholder]="placeholder"
  [rowTemplate]="rowTemplate"
  [showFilter]="showFilter"
  [source]="fnGetData"
  [validateFn]="fnValidate"
  [searchOnShow]="searchOnShow"
  [cacheValues]="cacheValues"
  [cacheValuesInstanceId]="cacheValuesInstanceId"
  [cacheValuesAutoReload]="cacheValuesAutoReload"
  [loadingDelayDuration]="loadingDelayDuration"
  [properties]="{
    disabled: options?.disabled,
    disallowInput: options?.disallowInput,
    disallowClear: options?.disallowClear,
    events: options?.events,
    onSelect: fnSelect,
    raw: options?.raw,
    readonly: options?.readonly,
    validate: options?.validate,
    validators: options?.validators,
    showToggle: options?.showToggle,
    cssClassDropdownWrapper: options?.cssClassDropdownWrapper
  }"
  (evtInputValueChanged)="changedInputValue($event)">
  <ng-container *ngIf="showActions && (!options?.disabled || options?.hideActionsOnDisabled === false)">
    <ng-container *ngIf="quickCreateEnabled">
      <div *inputGroup="{klass: 'hollow-form quick-create-btn'}">
        <span (click)="fnMaintenanceNew()" plDelegateEventDownwardsClick>+</span>
      </div>
    </ng-container>

    <cg-autocomplete-custom-actions
      *inputGroup="{klass: 'hollow-form'}"
      class="d-block"
      [dropdownContainer]="options?.maintenanceProperties?.dropdownContainer || 'body'"
      [customActions]="autocompleteCustomActions"
      (evtOpenChanged)="openChanged($event)">
      <div *cgCustomActionsContent>
        <button type="button" *ngIf="maintenanceAllowList" ngbDropdownItem [click]="fnMaintenanceList" plPromise>
          <i class="fa fa-fw fa-search" aria-hidden="true"></i>&nbsp;<span>{{ maintenanceHeaderList }}</span>
        </button>

        <button type="button" *ngIf="maintenanceAllowEdit" ngbDropdownItem [click]="fnMaintenanceEdit" plPromise>
          <i class="fa fa-fw fa-pencil-square" aria-hidden="true"></i>&nbsp;<span>{{ maintenanceHeaderEdit }}</span>
        </button>
      </div>
    </cg-autocomplete-custom-actions>
  </ng-container>

  <ng-container *ngFor="let itemGroup of itemGroups">
    <ng-container *inputGroup="itemGroup.config">
      <ng-container *ngTemplateOutlet="itemGroup.templateRef"></ng-container>
    </ng-container>
  </ng-container>
</pl-autocomplete>
