import {assign} from 'lodash-es';
import {Remote, wrap} from 'comlink';
import type {ReaderOptions, ReadResult} from 'zxing-wasm/reader';
import {Inject, Injectable} from '@angular/core';
import {CGC_BARCODE_CONFIG} from './config/barcode.config';
import {DI_BARCODE_CONFIG} from './config/barcode.di';
import type {ICGCBarcodeConfig} from './config/barcode.config.interface';
import type {ICGCBarcodeWorker} from './worker/barcode.worker.interface';
import {Logger} from '../logger/logger';

@Injectable({
  providedIn: 'root'
})
export class CGCBarcodeService {
  protected readonly _config: ICGCBarcodeConfig;
  private _internalWorker: Remote<ICGCBarcodeWorker>;

  constructor(
    @Inject(DI_BARCODE_CONFIG) config: ICGCBarcodeConfig,
    private readonly _logger: Logger
  ) {
    this._config = assign({}, CGC_BARCODE_CONFIG, config);
  }

  public async readBarcodes(imageFile: Blob, readerOptions?: ReaderOptions): Promise<Array<ReadResult>> {
    const worker = this._worker;
    return worker.readBarcodes(imageFile, readerOptions);
  }

  private get _worker(): Remote<ICGCBarcodeWorker> {
    if (!this._internalWorker) {
      this._internalWorker = wrap<ICGCBarcodeWorker>(new Worker(new URL('./worker/barcode.worker', import.meta.url), {type: 'module'}));
      this._internalWorker.init(this._config).catch((reason: unknown) => {
        this._logger.error('Failed to initialize barcode worker', reason);
      });
    }
    return this._internalWorker;
  }
}
