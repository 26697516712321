<div class="bancos-extrato-module">
  <div class="bancos-extrato-module-no-license">
    <pl-alert [type]="!licencaStoreModePublic && acessoBankingAutomation ? 'info' : 'error'" [closeable]="false" *ngIf="!empresaTemCGBanking || cgOnCGBankingExpired || !acessoBankingAutomation">
      <ng-container *ngIf="!empresaTemCGBanking" [ngSwitch]="licencaStoreModePublic">
        <ng-container *ngSwitchCase="false">
          <div [translate]="'bancosextrato.text.noLicense'"></div>
          <a *ngIf="!promiseActivateCGBanking" [translate]="'bancosextrato.text.activate'" (click)="activateCGBanking()"></a>
        </ng-container>

        <div *ngSwitchCase="true" [innerHTML]="'bancosextrato.errors.licenseNotActivatedCGOn' | translate: {href: this.cgStoreUrlBackOffice}"></div>
      </ng-container>

      <ng-container *ngIf="empresaTemCGBanking">
        <div *ngIf="!acessoBankingAutomation" [translate]="!licencaStoreModePublic ? 'bancosextrato.text.accessDenied' : 'bancosextrato.text.accessDeniedStore'"></div>

        <div *ngIf="acessoBankingAutomation && cgOnCGBankingExpired" [innerHTML]="'bancosextrato.errors.licenseCGOnExpired' | translate: {href: this.cgStoreUrlBackOffice}"></div>
      </ng-container>
    </pl-alert>
  </div>

  <ng-container *ngIf="(empresaTemCGBanking || !licencaStoreModePublic) && acessoBankingAutomation">
    <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>

    <div class="bancos-extrato-module-heading">
      <pl-form>
        <pl-group>
          <pl-group class="bancos-extrato-module-heading-bank-account">
            <label [translate]="'bancosextrato.fields.bankAccount'"></label>
            <edit>
              <pl-autocomplete
                attrName="bankaccount"
                output="descricao"
                rowTemplate="descricao"
                [model]="bankAccount"
                (modelChange)="changedBankAccount($event)"
                [properties]="propertiesAutocompleteBankAccount"
                plAutoFocus>
                <ng-template plAutocompleteDropdownContent let-autocomplete="component">
                  <div class="pl-autocomplete-dropdown bancos-extrato-module-bank-account-dropdown">
                    <dx-data-grid
                      [cgDxDataGrid]="dataGridBankAccounts"
                      [cgDxDataGridInstanceName]="dataGridBankAccountInstanceName"
                      [dataSource]="dataGridSourceBankAccounts"
                      (onFocusedRowChanged)="onDataGridBankAccountFocusedRowChanged($event, autocomplete)">
                    </dx-data-grid>
                  </div>
                </ng-template>

                <div
                  *inputGroup="{klass: 'btn-light', disabled: !bankAccount, title: 'bancosextrato.actions.refreshAccount' | translate, promise: promiseRefreshAccount}"
                  (click)="refreshAccount()"
                  data-capture-click>
                  <i class="fa fa-fw fa-refresh" aria-hidden="true"></i>
                </div>

                <div
                  *inputGroup="{klass: 'btn-light', disabled: !bankAccount, title: 'bancosextrato.actions.editBankAccount' | translate, promise: promiseEditBankAccount}"
                  (click)="editBankAccount()"
                  data-capture-click>
                  <i class="fa fa-fw fa-pencil" aria-hidden="true"></i>
                </div>

                <div *inputGroup="{klass: 'btn-light', title: 'bancosextrato.actions.addBankAccount' | translate, promise: promiseAddBankAccount}" (click)="addBankAccount()" data-capture-click>
                  <i class="fa fa-fw fa-plus" aria-hidden="true"></i>
                </div>

                <ng-container *ngIf="!simpleMode">
                  <div
                    *inputGroup="{klass: 'btn-light', title: 'bancosextrato.actions.importByReconciliation' | translate, promise: promiseGetTransactionsByConciliacaoBancaria}"
                    (click)="getTransactionsByConciliacaoBancaria()"
                    data-capture-click>
                    <i class="fa fa-fw fa-download" aria-hidden="true"></i>
                  </div>
                </ng-container>
              </pl-autocomplete>
            </edit>
          </pl-group>

          <pl-group class="bancos-extrato-module-heading-dates">
            <pl-group class="bancos-extrato-module-heading-date">
              <label [translate]="'global.text.fromDate'"></label>
              <edit>
                <pl-edit-datepicker [model]="startDate" (modelChange)="changedStartDate($event)" attrName="startdate" [properties]="propertiesDate"></pl-edit-datepicker>
              </edit>
            </pl-group>

            <pl-group class="bancos-extrato-module-heading-date">
              <label [translate]="'global.text.toDate'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="endDate" attrName="enddate" [properties]="propertiesDate"></pl-edit-datepicker>
              </edit>
            </pl-group>

            <pl-group class="bancos-extrato-module-heading-search">
              <edit>
                <pl-button klass="btn-sm btn-primary" [onClick]="fnSearch">
                  <i class="fa fa-fw fa-search" aria-hidden="true"></i>&nbsp;<span [translate]="'bancosextrato.actions.search'"></span>
                </pl-button>
              </edit>
            </pl-group>
          </pl-group>
        </pl-group>
      </pl-form>
    </div>

    <div class="bancos-extrato-module-warnings" *ngIf="warnings.length > 0">
      <pl-alert type="warning" [closeable]="true" (evtClosed)="clearWarnings()">
        <span *ngFor="let warning of warnings" [innerHTML]="warning"></span>
      </pl-alert>
    </div>

    <hr />

    <div class="bancos-extrato-module-add-in-series" *ngIf="!simpleMode && !toolbarItemLancarDocsEmSerie">
      <pl-edit
        type="boolean"
        attrName="lancardocsemserie"
        [model]="lancarDocsEmSerie"
        (modelChange)="changedLancarDocsEmSerie($event)"
        [properties]="{label: 'bancosextrato.actions.lancarDocsEmSerie'}">
      </pl-edit>
    </div>
  </ng-container>

  <div class="bancos-extrato-module-grid">
    <pl-split-view [initialSizeLeft]="60" [hideRightSide]="!acessoBankingAutomation || !contabilidadeDigital" (evtSizeValuesChanged)="onResizerValuesChanged(callbackDocViewer)">
      <div *plSplitViewLeftSide>
        <dx-data-grid
          [cgDxDataGrid]="dataGrid"
          [cgDxDataGridInstanceName]="dataGridInstanceName"
          [dataSource]="dataGridSource"
          (onInitialized)="onDataGridInitialized($event)"
          (onContentReady)="onDataGridContentReady($event)"
          (onFocusedRowChanged)="onDataGridFocusedRowChanged($event)"
          (onRowPrepared)="onDataGridRowPrepared($event)"
          (onCellPrepared)="onDataGridCellPrepared($event)"
          (onCellClick)="onDataGridCellClick($event)"
          (onSelectionChanged)="onDataGridSelectionChanged($event)"
          (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
          (wheel)="onDataGridWheel($event)">
          <div *dxTemplate="let toolbarItem of 'toolbarTemplateActions'">
            <div class="bancos-extrato-module-actions">
              <div class="btn-toolbar" role="toolbar">
                <div class="btn-group btn-group-sm" role="group" *ngIf="!simpleMode">
                  <button type="button" class="btn btn-info" [attr.title]="'bancosextrato.actions.fullExtract' | translate" [class.active]="fullExtract" (click)="toggleFullExtract()">
                    <i class="fa fa-fw fa-bars" aria-hidden="true"></i>
                  </button>
                </div>

                <div class="btn-group btn-group-sm" role="group" *ngIf="!simpleMode && fullExtract">
                  <button
                    type="button"
                    class="btn btn-primary"
                    [attr.title]="'bancosextrato.actions.viewDocument' | translate"
                    [disabled]="disableViewFocusedDocument"
                    [click]="fnViewFocusedDocument"
                    plPromise>
                    <i class="fa fa-fw fa-file" aria-hidden="true"></i>
                  </button>
                </div>

                <div class="btn-group btn-group-sm" role="group" *ngIf="!simpleMode">
                  <button
                    type="button"
                    class="btn btn-primary"
                    [attr.title]="'bancosextrato.actions.ignoreDocuments' | translate"
                    [disabled]="disableIgnoreDocuments"
                    [click]="fnIgnoreDocuments"
                    plPromise>
                    <i class="fa fa-fw fa-ban" aria-hidden="true"></i>
                  </button>
                </div>

                <div class="btn-group btn-group-sm" role="group">
                  <button type="button" class="btn btn-primary" [attr.title]="labelAddPredefDesc" [disabled]="disableAddPredefDesc" [click]="fnAddPredefDescFocusedTransaction" plPromise>
                    <i class="fa fa-fw fa-plus" aria-hidden="true"></i>
                  </button>

                  <button type="button" class="btn btn-warning" [attr.title]="labelEditPredefDesc" [disabled]="disableEditPredefDesc" [click]="fnEditPredefDescFocusedTransaction" plPromise>
                    <i class="fa fa-fw fa-pencil" aria-hidden="true"></i>
                  </button>

                  <button type="button" class="btn btn-danger" [attr.title]="labelRemovePredefDesc" [disabled]="disableRemovePredefDesc" [click]="fnRemovePredefDescFocusedTransaction" plPromise>
                    <i class="fa fa-fw fa-trash" aria-hidden="true"></i>
                  </button>
                </div>

                <div class="btn-group btn-group-sm" role="group" *ngIf="!simpleMode">
                  <button type="button" class="btn btn-info" [attr.title]="'bancosextrato.actions.mergeMode' | translate" [class.active]="mergeMode" (click)="mergeMode = !mergeMode">
                    <i class="fa fa-fw pl-icon-unir-docs" aria-hidden="true"></i>
                  </button>
                </div>

                <div class="btn-group btn-group-sm" role="group" *ngIf="simpleMode">
                  <button
                    type="button"
                    class="btn btn-primary"
                    [attr.title]="'bancosextrato.actions.doReceipt' | translate"
                    [disabled]="disableDoReceipt"
                    [click]="fnDoReceiptFocusedTransaction"
                    plPromise>
                    <i class="fa fa-fw fa-user-plus" aria-hidden="true"></i>
                  </button>

                  <button
                    type="button"
                    class="btn btn-primary"
                    [attr.title]="'bancosextrato.actions.doPayment' | translate"
                    [disabled]="disableDoPayment"
                    [click]="fnDoPaymentFocusedTransaction"
                    plPromise>
                    <i class="fa fa-fw fa-credit-card-alt" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div *dxTemplate="let toolbarItem of 'toolbarTemplateLancarDocsEmSerie'">
            <div class="bancos-extrato-module-add-in-series">
              <pl-edit
                type="boolean"
                attrName="lancardocsemserie"
                [model]="lancarDocsEmSerie"
                (modelChange)="changedLancarDocsEmSerie($event)"
                [properties]="{label: 'bancosextrato.actions.lancarDocsEmSerie'}">
              </pl-edit>
            </div>
          </div>

          <div *dxTemplate="let cellInfo of 'editCellTemplatePredefinido'">
            <entity-autocomplete
              entity="predefinidoscontabilidade"
              [model]="cellInfo.data"
              (modelChange)="changedCellPreDefinido(cellInfo, $event)"
              [filter]="filterPredefinido"
              [customActions]="customActionPreDefinido"
              [fieldsMap]="{preDefinidosID: 'predefinidoID', descricao: 'predefinidoNome'}">
            </entity-autocomplete>
          </div>

          <div *dxTemplate="let row of 'dataGridTemplateDocDigital'" [ngSwitch]="row.data.docDigital">
            <i *ngSwitchCase="entidadeBancariaDocDigital.Tem" class="fa fa-fw fa-file text-success" [attr.title]="'bancosextrato.docDigital.tem' | translate"></i>
            <i *ngSwitchCase="entidadeBancariaDocDigital.Varios" class="fa fa-fw fa-files-o text-warning" [attr.title]="'bancosextrato.docDigital.varios' | translate"></i>
          </div>

          <div *dxTemplate="let row of 'dataGridTemplateActions'">
            <button type="button" class="btn btn-xs btn-light" (click)="openRowActions(row.data, $event)">
              <i class="fa fa-fw fa-cogs" aria-hidden="true"></i>
            </button>
          </div>

          <div *dxTemplate="let row of 'dataGridTemplateDetail'">
            <div class="bancos-extrato-module-grid-row-detail" *ngIf="row.data.jsonDocContabilidade">
              <pl-toolbar [instanceId]="row.data.toolbarInstanceId"></pl-toolbar>
              <hr />
              <doccontabilidade
                [(model)]="row.data.jsonDocContabilidade"
                [predefinido]="row.data.jsonPreDefinido"
                [callback]="row.data.callbackDocContabilidade"
                [toolbarInstanceName]="row.data.toolbarInstanceId"
                [onLoadPreDefinido]="fnOnLoadPreDefinido(row.data)"
                [onLoadFocusField]="'linhas'"
                [onAfterInitDocument]="fnDocContabilidadeAfterInitDocument(row.data)"
                plLifeCycle
                (evtInit)="docContabilidadeInit($event, row.data, contabDigitalAttachment)"
                (evtDestroy)="docContabilidadeDestroy(row.data)">
              </doccontabilidade>
            </div>
          </div>
        </dx-data-grid>
      </div>

      <div *plSplitViewRightSide class="bancos-extrato-module-docviewer" [class.docviewer-footer-collapsed]="docViewerFooterCollapsed">
        <contabilidadedigital-docviewer-recolha
          [state]="docViewerState"
          [searchResult]="contabilidadeDigitalSearchResult"
          [searchParams]="contabilidadeDigitalSearchParams"
          [addDocsMode]="docViewerAddDocsMode"
          [hideToolbarOnEmptyDoc]="false"
          [showFooter]="true"
          [showFooterCollapse]="!isMobile"
          [footerCollapsed]="docViewerFooterCollapsed"
          [callback]="callbackDocViewer"
          (evtChangedAttachment)="changedContabDigitalAttachment(contabDigitalAttachment, $event)"
          (footerCollapsedChange)="changedDocViewerFooterCollapsed($event)">
        </contabilidadedigital-docviewer-recolha>
      </div>
    </pl-split-view>
  </div>

  <div class="bancos-extrato-module-legend">
    <cg-table-legend [legend]="tableLegend"></cg-table-legend>
    <div *ngIf="simpleMode" class="simple-mode-filter">
      <span><span [translate]="'bancosextrato.simplemodefilter.filter'"></span>:</span>
      <pl-autocomplete
        [source]="simpleModeFilterSource"
        [model]="simpleModeFilter"
        (modelChange)="simpleModeFilterChanged($event)"
        [rowTemplate]="'caption'"
        [output]="'caption'"
        [allowInvalid]="false"
        [allowEmpty]="false">
      </pl-autocomplete>
    </div>
  </div>

  <div class="bancos-extrato-module-panel-slider">
    <cg-panel-slider [collapsed]="!panelSliderVisible" (collapsedChange)="panelSliderVisible = !$event">
      <div class="bancos-extrato-module-panel-slider-content" *cgPanelSliderContent>
        <div class="card">
          <div class="card-header" (click)="panelSliderTransactionsCollapsed = !panelSliderTransactionsCollapsed">
            <a class="btn-link" [translate]="'bancosextrato.text.selectedTransactions'"></a>&nbsp;
            <i class="fa fa-caret-right" aria-hidden="true" [plAnimationRotate]="!panelSliderTransactionsCollapsed"></i>
          </div>

          <pl-animation-collapse [collapsed]="panelSliderTransactionsCollapsed">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-sm">
                  <thead>
                    <th [translate]="'global.text.date'"></th>
                    <th [translate]="'bancosextrato.fields.description'"></th>
                    <th [translate]="'bancosextrato.fields.amount'"></th>
                    <th [translate]="'global.text.saldo'"></th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let transaction of panelSliderTransactions">
                      <td [attr.title]="transaction.dateShort | cgcDate">{{ transaction.dateShort | cgcDate }}</td>
                      <td [attr.title]="transaction.description">{{ transaction.description }}</td>
                      <td [attr.title]="transaction.amount | cgcDecimal: precision">{{ transaction.amount | cgcDecimal: precision }}</td>
                      <td [attr.title]="transaction.accountBalance | cgcDecimal: precision">{{ transaction.amount | cgcDecimal: precision }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </pl-animation-collapse>
        </div>

        <hr />

        <pl-toolbar [instanceId]="panelSliderTransaction?.toolbarInstanceId"></pl-toolbar>

        <hr />

        <pl-split-view [initialSizeLeft]="60" [hideRightSide]="!contabilidadeDigital" (evtSizeValuesChanged)="onResizerValuesChanged(callbackPanelSliderDocViewer)">
          <div *plSplitViewLeftSide>
            <doccontabilidade
              [(model)]="panelSliderTransaction.jsonDocContabilidade"
              [predefinido]="panelSliderTransaction.jsonPreDefinido"
              [callback]="panelSliderTransaction.callbackDocContabilidade"
              [toolbarInstanceName]="panelSliderTransaction.toolbarInstanceId"
              [onLoadPreDefinido]="fnOnLoadPreDefinido(panelSliderTransaction)"
              [onAfterInitDocument]="fnDocContabilidadeAfterInitDocument(panelSliderTransaction)"
              plLifeCycle
              (evtInit)="docContabilidadeInit($event, panelSliderTransaction, contabDigitalPanelSliderAttachment)"
              (evtDestroy)="docContabilidadeDestroy(panelSliderTransaction)">
            </doccontabilidade>
          </div>

          <div *plSplitViewRightSide class="bancos-extrato-module-panel-slider-docviewer" [class.docviewer-footer-collapsed]="docViewerFooterCollapsed">
            <contabilidadedigital-docviewer-recolha
              [state]="docViewerState"
              [searchResult]="panelSliderContabilidadeDigitalSearchResult"
              [searchParams]="contabilidadeDigitalSearchParams"
              [showFooter]="true"
              [showFooterCollapse]="!isMobile"
              [footerCollapsed]="docViewerFooterCollapsed"
              [callback]="callbackPanelSliderDocViewer"
              (evtChangedAttachment)="changedContabDigitalAttachment(contabDigitalPanelSliderAttachment, $event)"
              (footerCollapsedChange)="changedDocViewerFooterCollapsed($event)">
            </contabilidadedigital-docviewer-recolha>
          </div>
        </pl-split-view>
      </div>
    </cg-panel-slider>
  </div>

  <cg-panel-multiple-selection-execute
    *ngIf="(empresaTemCGBanking || !licencaStoreModePublic) && !simpleMode && selectedTransactions.length > 0 && !panelSliderVisible && (!autoSuggestDoc || !allowAutoSuggestDoc || lancarDocsEmSerie)"
    [labelTitle]="'components.panelmultipleselectionexecute.labels.selectedDocs'"
    [labelAction]="!lancarDocsEmSerie ? 'bancosextrato.actions.suggestDoc' : 'bancosextrato.actions.lancarDocs'"
    [count]="selectedTransactions.length"
    [executeFn]="fnDoLancarDocs">
  </cg-panel-multiple-selection-execute>
</div>
