<div class="file-viewer">
  <div class="file-viewer-container">
    <div>
      <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="comments" [dataSource]="comments" (onCellClick)="onCellClick($event)">
        <div *dxTemplate="let item of 'templateToolbar'">
          <div *ngIf="!readOnly" class="file-viewer-header">
            <div class="btn-actions-top ps-0">
              <div class="btn-group btn-group-sm">
                <button type="button" class="btn btn-sm btn-outline-primary" (click)="addComment()">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  <span [translate]="'gdocscomentarios.tooltip.novo'"></span>
                </button>
                <button type="button" class="btn btn-sm btn-outline-primary" (click)="editComment()" [disabled]="!selectedComment">
                  <i class="fa fa-edit" aria-hidden="true"></i>
                  <span [translate]="'gdocscomentarios.tooltip.editar'"></span>
                </button>
                <button type="button" class="btn btn-sm btn-outline-primary" (click)="removeComment()" [disabled]="!selectedComment">
                  <i class="fa fa-minus" aria-hidden="true"></i>
                  <span [translate]="'gdocscomentarios.tooltip.delete'"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </dx-data-grid>
    </div>
  </div>
</div>
