import {Component, Injector, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {fromJson, isArray, isEmpty, isNumber, isObject, isString, isUndefined, isUndefinedOrNull, KEYCODES, PlAlertService, PlCompsService, PlI18nService, toJson} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGLocalStorageGroupService} from '../../../../services/storage/localstoragegroup.service';
import {EGroupName, FORM_INVALID_CANNOT_SUBMIT} from '../../../../../config/constants';
import {ICGConfigurations} from '../../../../services/config/config.service.interface';
import {EReciboTipoDistribuicao, IRecibo, IReciboDistribuirValor, IReciboLinha, IRecibosParams, NDECIMAIS_CAMBIO_DEFAULT, reciboDefault} from '../../recibos.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import moment, {Moment} from 'moment';
import {RecibosDistribuivalorModalComponent} from '../../modals/distribuirvalormodal/recibos.distribuiValor.modal.component';
import {RecibosSaveModalComponent} from '../../modals/savemodal/recibos.save.modal.component';
import {RecibosService} from '../../service/recibos.entity.service';
import {IRecibosSaveModalResult} from '../../modals/savemodal/recibos.save.modal.component.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContentReady,
  IDevExpressDataGridEventOnEditorPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowExpanding,
  IDevExpressDataGridEventOnSaved
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {IDocsComerciaisEntityService} from '../../../docscomerciais/docsComerciais.entity.interface';
import {IJsonDocComercial} from '../../../docscomerciais/jsonDocComercial.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {DocumentosService} from '../../../docscomerciais/service/documentos.entity.service';
import {Properties as NumberBoxOptions} from 'devextreme/ui/number_box';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {SCHEMA_STRING} from '../../../../../common/schemas';
import {IJsonClifo} from '../../../clifos/jsonClifo.entity.interface';
import {round} from '../../../../../common/utils/utils';

const STORAGE_KEY = 'recibo';
const FIVE_DECIMAL_FORMATOR = '#,##0.00000';
const TWO_DECIMAL_FORMATOR = '#,##0.00';
const DOCUMENTO_CONTABILIDADE = 'Documento contabilidade';

@Component({
  selector: 'recibos-edit',
  templateUrl: './recibos.entity.edit.component.html'
})
export class RecibosEditComponent extends ModuloEntityDetailComponent<IRecibo> implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid<IReciboLinha>;
  public readonly config: ICGConfigurations;
  public readonly tipoDistribuicao: typeof EReciboTipoDistribuicao;

  public edittable: boolean;
  public form: UntypedFormGroup;
  public isloading: boolean;
  public extPocCabID: string;
  public codMoedaEmpresa: number;
  public abreviaturaMoedaEmpresa: string;
  public abreviaturaMoedaLinha: string;
  public isME: boolean;
  public invalidHeaderDate: boolean;
  public clienteOutput: string;
  public modelClifo: Partial<IJsonClifo>;
  public totalNaoVencido: number;
  public totalVencido: number;
  public modelReciboDistribuir: IReciboDistribuirValor;
  public valorDistribuir: number;

  private readonly _recibosParams: IRecibosParams;
  private readonly _docsComerciaisService: IDocsComerciaisEntityService;
  private _dataGridInstance: dxDataGrid;

  private _changedField: string;
  private _savedmodel: IRecibo;
  private _previousDateValue: Moment;
  private _firstLoad: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _recibosService: RecibosService,
    private readonly _cgLocalStorageGroupService: CGLocalStorageGroupService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plCompsService: PlCompsService,
    private readonly _plI18nService: PlI18nService,
    private readonly _plAlertService: PlAlertService,
    private readonly _documentosService: DocumentosService
  ) {
    super(_injector);
    this.config = this._configService.configurations;
    this._docsComerciaisService = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');
    this.isME = false;
    this.clienteOutput = '{{nConta}} - {{nome}}';
    this._firstLoad = true;
    this._changedField = '';
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columnAutoWidth: true,
      columns: [
        {dataField: 'tipoDocumento', dataType: 'string', caption: 'recibos.recibo.linhas.tipoDocumento', allowEditing: false},
        {dataField: 'numeroDocumento', dataType: 'string', caption: 'recibos.recibo.linhas.numeroDocumento', visible: true, allowEditing: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'recibos.recibo.linhas.dataDoc', allowEditing: false},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'recibos.recibo.linhas.nDocExterno', visible: false, allowEditing: false},
        {dataField: 'dataDocExterno', dataType: 'date', caption: 'recibos.recibo.linhas.dataDocExterno', visible: false, allowEditing: false},
        {dataField: 'dataVencimento', dataType: 'date', caption: 'recibos.recibo.linhas.dataVencimento', allowEditing: false},
        {dataField: 'valor', dataType: 'double', caption: 'recibos.recibo.linhas.valorDoc', cellTemplate: 'cellTemplateValorPor', allowEditing: false, visible: false},
        {dataField: 'valorPorReceber', dataType: 'double', caption: 'recibos.recibo.linhas.valorPorReceber', cellTemplate: 'cellTemplateValorPorReceber', allowEditing: false},
        {dataField: 'descricao', dataType: 'string', caption: 'recibos.recibo.linhas.descricao', allowEditing: false, visible: false},
        {
          dataField: 'montanteRetido',
          dataType: 'double',
          caption: 'recibos.recibo.linhas.montanteRetido',
          format: {decimalsLimit: this.config.contabilidade.decimais.valor},
          allowEditing: false
        },
        {
          dataField: 'cambioOrigem',
          dataType: 'double',
          caption: 'recibos.recibo.linhas.cambioOrigem',
          visible: this.isME,
          showInColumnChooser: this.isME,
          format: {decimalsLimit: NDECIMAIS_CAMBIO_DEFAULT},
          allowEditing: false
        },
        {
          dataField: 'cambioPagamento',
          dataType: 'double',
          caption: 'recibos.recibo.linhas.cambioPagam',
          visible: this.isME,
          showInColumnChooser: this.isME,
          format: {decimalsLimit: NDECIMAIS_CAMBIO_DEFAULT},
          editorOptions: {format: FIVE_DECIMAL_FORMATOR} satisfies NumberBoxOptions,
          allowSorting: false
        },
        {
          dataField: 'percDesconto',
          dataType: 'double',
          caption: 'recibos.recibo.linhas.percDesconto',
          allowSorting: false,
          editorOptions: {format: TWO_DECIMAL_FORMATOR} satisfies NumberBoxOptions
        },
        {dataField: 'valorDesconto', dataType: 'double', caption: 'recibos.recibo.linhas.valorDesconto', editorOptions: {format: TWO_DECIMAL_FORMATOR} satisfies NumberBoxOptions, allowSorting: false},
        {dataField: 'valorRecebido', dataType: 'double', caption: 'recibos.recibo.linhas.valorRecebido', editorOptions: {format: TWO_DECIMAL_FORMATOR} satisfies NumberBoxOptions, allowSorting: false},
        {dataField: 'buttons', type: 'buttons', headerCellTemplate: 'headerCellTemplateBtns', cellTemplate: 'cellTemplateBtns', showInColumnChooser: false}
      ],
      editing: {mode: 'cell', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: true, refreshMode: 'repaint'},
      export: {filename: 'global.menu.recibos'},
      height: '60vh',
      masterDetail: {enabled: true, template: 'masterDetailTemplate'},
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'}
    };

    this.modelReciboDistribuir = {
      valor: 0,
      tipoDistribuicao: EReciboTipoDistribuicao.Automatico
    };
    this.tipoDistribuicao = EReciboTipoDistribuicao;
    this._recibosParams = <IRecibosParams>this._transition.params();
    this.valorDistribuir = 0;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.edittable = !this.model.cab || this.model.cab?.extPocCabID === '';

    this._cgLocalStorageGroupService.getItem<string>(STORAGE_KEY, SCHEMA_STRING, EGroupName.GLOBAL).subscribe((value: string) => {
      this._savedmodel = fromJson(value);
    });
    if (this.edittable) {
      this.model = {
        ...reciboDefault(),
        ...this.model
      };
      if (this._recibosParams.nConta.length) {
        this.model.cab.nConta = this._recibosParams.nConta;
        this.model.cab.nome = this._recibosParams.nome;
      }
      this._previousDateValue = moment(this.model.cab.data);
      if (isObject(this._savedmodel) && this._savedmodel.cab.nConta !== '') {
        this.model.cab.nConta = this._savedmodel.cab.nConta;
        this.model.cab.nome = this._savedmodel.cab.nome;
        this.changedNConta({nConta: this.model.cab.nConta, nome: this.model.cab.nome});
      }
      if (this.model.cab.nConta) {
        this.changedNConta({nConta: this.model.cab.nConta, nome: this.model.cab.nome});
      }
    } else {
      this.toolTitle.caption = this.model.cab.nDocAsStr;
      this.modelClifo = {
        nConta: this.model.cab.nConta,
        nome: this.model.cab.nome
      };
      setTimeout(() => {
        this.toolbar.find('save').visible = false;
        this.toolbar.find('novo').visible = false;
      });
    }
  }

  public save(): Promise<IRecibo> {
    if (this.invalidHeaderDate) {
      return Promise.reject();
    }

    return this._dataGridInstance.saveEditData().then(() => {
      if (!this.validaDados()) {
        return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
      }
      const modalInstance = this._cgModalService.showVanilla(RecibosSaveModalComponent, {size: 'lg', backdrop: 'static', keyboard: false});
      const componentInstance: RecibosSaveModalComponent = modalInstance.componentInstance;
      componentInstance.recibo = this.model;
      componentInstance.saveFn = this.callback.save;
      componentInstance.abreviaturaMoeda = this.abreviaturaMoedaLinha;
      const promise: Promise<IRecibo> = modalInstance.result.then(({type, recibo}: IRecibosSaveModalResult) => {
        if (type === 'save' || type === 'pdf') {
          this._cgLocalStorageGroupService.removeItem(STORAGE_KEY, EGroupName.GLOBAL);
          this.extPocCabID = recibo.cab.extPocCabID;
          if (type === 'pdf') {
            this._recibosService.getPdf({
              extPocCabID: recibo.cab.extPocCabID,
              nConta: recibo.cab.nConta,
              nomeConta: recibo.cab.nome,
              email: recibo.cab.email
            });
          }
          this.callback.new();
          this.loadLinhas();
        }
        return recibo;
      });
      this._subjectOnSave.next(promise);
      return promise;
    });
  }

  public onContentReady({component}: IDevExpressDataGridEventOnContentReady): void {
    if (this._firstLoad) {
      this._dataGridInstance.columnOption('buttons', 'visible', this.edittable);
      component.refresh();
      this._firstLoad = false;
    }
    this._dataGridInstance.endCustomLoading();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
    this._dataGridInstance.columnOption('valorRecebido', 'allowEditing', this.edittable);
    this._dataGridInstance.columnOption('valorDesconto', 'allowEditing', this.edittable);
    this._dataGridInstance.columnOption('percDesconto', 'allowEditing', this.edittable);
    this._updateCambiosVisibility();
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IReciboLinha>): void {
    if (event.rowType === 'data' && !event.column.allowEditing) {
      if (event.row.isExpanded) {
        event.component.collapseAll(-1);
      } else {
        event.component.collapseAll(-1);
        if (!isObject(event.row)) {
          return;
        }
        if (isNumber(event.data.faccbid) && event.data.faccbid !== 0) {
          devExpressDataGridExpandDetailHandler(event, () => this._onDetail(event.data));
        }
      }
    }
  }

  public onRowExpand(event: IDevExpressDataGridEventOnRowExpanding<IReciboLinha>): void {
    if (!(<IReciboLinha>event.key).faccbid || !(<IReciboLinha>event.key)._thedoc) {
      event.cancel = true;
    }
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IReciboLinha>): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'montanteRetido') {
        event.component.columnOption('montanteRetido', 'visible', this.model.cab.temRetencao);
        event.component.columnOption('montanteRetido', 'showInColumnChooser', this.model.cab.temRetencao);
      }
      if (event.column.command === 'expand' && event.data.tipoDocumento === DOCUMENTO_CONTABILIDADE) {
        (<HTMLElement>event.cellElement.childNodes[0]).classList.remove('dx-datagrid-group-closed');
        event.cellElement.classList.remove('dx-datagrid-expand');
      }
      if (this.edittable) {
        if (event.column.dataField === 'cambioPagamento' || event.column.dataField === 'percDesconto' || event.column.dataField === 'valorDesconto' || event.column.dataField === 'valorRecebido') {
          if (
            (event.column.dataField === 'cambioPagamento' && (event?.row?.data?.valor < 0 || event?.row?.data?.cambioOrigem === 1)) ||
            ((event.column.dataField === 'percDesconto' || event.column.dataField === 'valorDesconto') && event?.row?.data?.valor < 0)
          ) {
            event.cellElement.classList.add('table-not-editable-cell');
          } else {
            event.cellElement.classList.add('datagrid-editable-cell');
          }
        }
      }
    }
  }

  public onEditorPreparing(event: IDevExpressDataGridEventOnEditorPreparing<IReciboLinha>): void {
    this._changedField = event.dataField;
    if (event.dataField === 'cambioPagamento' && (event?.row?.data?.valor < 0 || event?.row?.data?.cambioOrigem === 1)) {
      event.cancel = true;
    }

    if ((event.dataField === 'percDesconto' || event.dataField === 'valorDesconto') && event?.row?.data?.valor < 0) {
      event.cancel = true;
    }
  }

  public onSaved(event: IDevExpressDataGridEventOnSaved<IReciboLinha>): void {
    if (event.changes[0]) {
      const dataItem: IReciboLinha = <IReciboLinha>event.changes[0].data;
      const index = this.model.linhas.findIndex((value: IReciboLinha) => value.nlanc === dataItem.nlanc);
      if (index !== -1) {
        this.cambioPagamentoChanged(dataItem, index);
      }
      if (this._changedField === 'valorDesconto') {
        this.valorDescontoChanged(dataItem, false);
      }
      this.percentagemDescontoChanged(dataItem, false);
      this.valorRecebidoChanged(dataItem, true, false);
    }
  }

  public changedNConta(nContaOrClifo: string | Partial<IJsonClifo>): void {
    this.model.linhas = [];
    if (isEmpty(nContaOrClifo)) {
      this.model.cab.nConta = undefined;
      this.model.cab.nome = undefined;
      this.isME = false;
      this.modelClifo = {};
    } else if (isString(nContaOrClifo)) {
      this.model.cab.nConta = undefined;
      this.model.cab.nome = undefined;
      this.isME = false;
      this.modelClifo = {
        nConta: '',
        nome: ''
      };
    } else {
      this.model.cab.nConta = nContaOrClifo.nConta;
      this.model.cab.nome = nContaOrClifo.nome;
      this.isME = nContaOrClifo.codMoeda !== this.codMoedaEmpresa;
      this.modelClifo = {
        id: nContaOrClifo.nIdAltern ? `${nContaOrClifo.nConta}_${nContaOrClifo.nIdAltern}` : nContaOrClifo.nConta,
        nConta: nContaOrClifo.nConta,
        nome: nContaOrClifo.nome
      };
    }
    if (!isEmpty(this.model.cab.nConta)) {
      this.changed('nConta');
    }
  }

  public changedData(value: string): void {
    const dateValue: Moment = moment(value);
    this.model.cab.data = dateValue;

    if (!dateValue.isSame(this._previousDateValue, 'day')) {
      this.changed('data');

      this.invalidHeaderDate = false;
      const dateYearAfter: Moment = moment().add(1, 'year');
      if (moment(dateValue).isAfter(dateYearAfter)) {
        this.invalidHeaderDate = true;
      }
    }
    this._previousDateValue = dateValue;
  }

  public cambioPagamentoChanged(linha: IReciboLinha, idx: number): void {
    if (isUndefinedOrNull(linha.cambioPagamento)) {
      for (let i = 0; i < this.model.linhas.length; i++) {
        const item = this.model.linhas[i];
        if (item.codMoeda === linha.codMoeda && idx !== i && item.cambioPagamento !== item.cambioOrigem) {
          item.cambioPagamento = item.cambioOrigem;
        }
      }
      return;
    }

    for (let i = 0; i < this.model.linhas.length; i++) {
      const item = this.model.linhas[i];
      if (item.codMoeda === linha.codMoeda && idx !== i) {
        item.cambioPagamento = linha.cambioPagamento;
      }
    }
  }

  public percentagemDescontoChanged(linha: IReciboLinha, calcTotais: boolean = true): void {
    // atribui valorDesconto
    this._recibosService.calculaValorDesconto(linha, this.model.percDescSobreValorIVA);
    if (calcTotais) {
      this._calculaTotais();
    }
  }

  public valorDescontoChanged(linha: IReciboLinha, calcTotais: boolean = true): void {
    this._recibosService.calculaPercentagemDesconto(linha, this.model.percDescSobreValorIVA);
    if (calcTotais) {
      this._calculaTotais();
    }
  }

  public valorRecebidoChanged(linha: IReciboLinha, calcTotais: boolean = true, calcValDesconto: boolean = true): void {
    const index = this.model.linhas.findIndex((value: IReciboLinha) => value.nlanc === linha.nlanc);
    if (index !== -1) {
      if (!this._validaMoeda(index)) {
        return;
      }
    }

    // atribui valorDesconto
    this._recibosService.calculaValorRetencao(linha);
    if (calcValDesconto) {
      this._recibosService.calculaValorDesconto(linha, this.model.percDescSobreValorIVA);
    }

    if (calcTotais) {
      this._calculaTotais(linha);
    }
  }

  public changed(name: string): void {
    this._cgLocalStorageGroupService.setItem(STORAGE_KEY, toJson(this.model), SCHEMA_STRING, EGroupName.GLOBAL).subscribe();
    if (this.model.cab.nConta !== '' && name === 'nConta') {
      this.loadLinhas();
    }
  }

  public validaDados(): boolean {
    const v = this._recibosService.validaDados(this.model);
    if (this.modelReciboDistribuir.tipoDistribuicao === EReciboTipoDistribuicao.Manual && this.valorDistribuir) {
      v.push({fieldname: 'error', message: 'recibos.erros.faltaDistribuirValor'});
    }
    if (v.length > 0) {
      this.callback.resetErrors();
      this.callback.setFieldError(v);
    }
    return v.length === 0;
  }

  public loadContaResumo(): void {
    this.totalNaoVencido = 0;
    this.totalVencido = 0;

    this.model.linhas.forEach((linha: IReciboLinha) => {
      if (!this.isME) {
        this.isME = linha.codMoeda !== this.configurations.empresa.codMoeda;
      }

      if (moment(moment()).isBefore(linha.dataVencimento)) {
        this.totalNaoVencido += linha.valorPorReceber;
      } else {
        this.totalVencido += linha.valorPorReceber;
      }
    });
  }

  public loadLinhas(): Promise<void> {
    this.isloading = true;
    return this._recibosService
      .loadLinhas(this.model)
      .then(
        (recibo) => {
          this.model = recibo;
          this._previousDateValue = moment(this.model.cab.data);
          this.loadContaResumo();
          this._updateCambiosVisibility();
        },
        (error) => {
          const err = this._cgExceptionService.get(error);
          if (err) {
            this.callback.messages().error.push(err.message);
            if (isArray(err.fields) && err.fields.length > 0) {
              this.callback.setFieldError(err.fields[0]);
            }
          } else {
            this.callback.messages().error.push(this._translateService.instant('recibos.erros.errodesconhecido'));
          }
        }
      )
      .finally(() => {
        this.isloading = false;
      });
  }

  public limpaLinha(item: IReciboLinha): void {
    const index = this.model.linhas.findIndex((value: IReciboLinha) => value.nlanc === item.nlanc);
    if (index !== -1) {
      this.model.linhas[index].valorRecebido = 0;
      this.model.linhas[index].valorDesconto = 0;
      this.model.linhas[index].percDesconto = 0;
      if (this.abreviaturaMoedaLinha !== this.abreviaturaMoedaEmpresa) {
        this.abreviaturaMoedaLinha = this.abreviaturaMoedaEmpresa;
      }
      this._calculaTotais();
    }
  }

  public limpaValoresLinhas(): void {
    for (const linha of this.model.linhas) {
      this.limpaLinha(linha);
    }
    this.modelReciboDistribuir = {
      valor: 0,
      tipoDistribuicao: EReciboTipoDistribuicao.Automatico
    };
  }

  public receberTudoLinha(item: IReciboLinha): boolean {
    const index = this.model.linhas.findIndex((value: IReciboLinha) => value.nlanc === item.nlanc);
    if (index !== -1) {
      if (!this._validaMoeda(index)) {
        return false;
      }
      if (this.modelReciboDistribuir.tipoDistribuicao === EReciboTipoDistribuicao.Manual) {
        if (this.model.linhas[index].valorPorReceber === this.model.linhas[index].valorRecebido) {
          return false;
        }
        if (this.model.linhas[index].valorRecebido > 0) {
          this.valorDistribuir += this.model.linhas[index].valorRecebido - this.model.linhas[index].valorDesconto;
          this.model.linhas[index].valorRecebido = 0;
        }
        let valorRecebido: number = this.model.linhas[index].valorPorReceber;
        if (this.model.linhas[index].percDesconto > 0) {
          valorRecebido = round(this.valorDistribuir / ((100 - this.model.linhas[index].percDesconto) / 100), this.config.contabilidade.decimais.valor);
          if (this.model.linhas[index].valorPorReceber < valorRecebido) {
            valorRecebido = this.model.linhas[index].valorPorReceber;
          }
        } else if (this.model.linhas[index].valorPorReceber > this.valorDistribuir) {
          valorRecebido = this.valorDistribuir;
        }
        this.model.linhas[index].valorRecebido = valorRecebido;
      } else {
        this.model.linhas[index].valorRecebido = this.model.linhas[index].valorPorReceber;
      }
      this._recibosService.calculaValorDesconto(this.model.linhas[index], this.model.percDescSobreValorIVA);
      this._calculaTotais(this.model.linhas[index]);
    }
    return true;
  }

  public receberTudo(): void {
    for (const linha of this.model.linhas) {
      this.receberTudoLinha(linha);
    }
  }

  public aplicarValorReceber(valor: number): void {
    if (isUndefined(valor)) {
      return;
    }

    // Limpar linhas
    this.limpaValoresLinhas();

    let valNeg = 0;
    let valPos = 0;
    let isFinished = false;

    // calcula valores negativos e posivos
    for (const linha of this.model.linhas) {
      if (this.model.cab.total === 0) {
        this.abreviaturaMoedaLinha = linha.abreviaturaMoeda;
      } else if (this.abreviaturaMoedaLinha !== linha.abreviaturaMoeda) {
        continue;
      }
      if (linha.valorPorReceber < 0) {
        valNeg += Math.abs(linha.valorPorReceber);
      } else {
        valPos += linha.valorPorReceber;
      }
    }

    if (valor > valPos) {
      valor = valPos;
    }

    valPos -= valor;
    valor += valNeg;
    valNeg = 0;

    // consumir valores
    for (const linha of this.model.linhas) {
      if (this.model.cab.total === 0) {
        this.abreviaturaMoedaLinha = linha.abreviaturaMoeda;
      } else if (this.abreviaturaMoedaLinha !== linha.abreviaturaMoeda) {
        continue;
      }

      if (Math.abs(linha.valorPorReceber) > 0) {
        if (valor > 0) {
          if (valor >= linha.valorPorReceber) {
            // VALORES NEGATIVOS
            if (linha.valorPorReceber < 0) {
              if (valNeg + Math.abs(linha.valorPorReceber) > valPos && !isFinished) {
                valNeg += Math.abs(linha.valorPorReceber);
                linha.valorRecebido = linha.valorPorReceber + Math.abs(valNeg) - valPos;
                isFinished = true;
              } else if (!isFinished) {
                valNeg += Math.abs(linha.valorPorReceber);
                linha.valorRecebido = linha.valorPorReceber;
              }
            } else {
              const v = this._plI18nService.formatNumber(valor - linha.valorPorReceber, this.config.contabilidade.decimais.valor);
              valor = this._plCompsService.parseNumber(v);
              linha.valorRecebido = linha.valorPorReceber;
            }
            this.valorRecebidoChanged(linha);
            valor += linha.valorDesconto;
          } else {
            linha.valorRecebido = this._calcValorDesc(valor, linha.percDesconto);
            this.valorRecebidoChanged(linha);
            valor = 0;
          }
        } else if (linha.valorPorReceber < 0) {
          linha.valorRecebido = linha.valorPorReceber;
        }
      }
    }
    this._recibosService.calculaTotais(this.model);
  }

  public distribuiValor(): void {
    this.valorDistribuir = 0;
    this.limpaValoresLinhas();
    this._cgModalService.show<IReciboDistribuirValor>(RecibosDistribuivalorModalComponent, {size: 'md'}).then((reciboDistribuirValor: IReciboDistribuirValor) => {
      this.modelReciboDistribuir = reciboDistribuirValor;
      if (this.modelReciboDistribuir.tipoDistribuicao === EReciboTipoDistribuicao.Automatico) {
        this.aplicarValorReceber(this.modelReciboDistribuir.valor);
      } else {
        this.modelReciboDistribuir.valor = this.totalVencido + this.totalNaoVencido >= this.modelReciboDistribuir.valor ? this.modelReciboDistribuir.valor : this.totalVencido + this.totalNaoVencido;
        this.model.cab.totalReceber = this.modelReciboDistribuir.valor;
        this.valorDistribuir = this.model.cab.totalReceber;
      }
    });
  }

  public keyDownValorRecebido(value: string, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      const input: HTMLElement = document.querySelector('[name="valorRecebido"]');
      if (input) {
        event.stopImmediatePropagation();
        input.focus();
      }
    }
  }

  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);

  protected _onConfigurationsChanged(): void {
    if (!this._configService.configurations.empresa.verificacaoEfetuada && !this.hybridMode) {
      this._configService.checkConfiguracaoVerificada();
    }
    this.codMoedaEmpresa = this._configService.configurations.empresa.codMoeda;
    this.abreviaturaMoedaEmpresa = this._configService.configurations.empresa.abreviaturaMoeda;
    this.abreviaturaMoedaLinha = this._configService.configurations.empresa.abreviaturaMoeda;
  }

  private _onDetail(item: IReciboLinha): Promise<void> {
    if (!item._thedoc) {
      return this._docsComerciaisService.get({id: item.faccbid}).then((response: HttpResponse<IJsonDocComercial>) => {
        item._thedoc = response.body;
      });
    }
    return Promise.resolve();
  }

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc);
  }

  private _calcValorDesc(valor: number, desc: number): number {
    const d = this._plI18nService.formatNumber(desc / 100, this.config.contabilidade.decimais.percDesconto);
    const v = this._plI18nService.formatNumber(valor / (1 - this._plCompsService.parseNumber(d)), this.config.contabilidade.decimais.valor);
    return this._plCompsService.parseNumber(v);
  }

  private _calculaTotais(linha: IReciboLinha = undefined): void {
    this._recibosService.calculaTotais(this.model);

    if (this.modelReciboDistribuir.tipoDistribuicao === EReciboTipoDistribuicao.Manual) {
      this.model.cab.totalReceber = this.modelReciboDistribuir.valor + this.model.cab.totalDescontos;
      this.valorDistribuir = this.modelReciboDistribuir.valor - this.model.cab.total;
    }

    if (linha) {
      if (this.valorDistribuir < 0) {
        this._plAlertService.error('recibos.erros.valorUltrapassado');
        linha.valorRecebido = 0;
        this.valorRecebidoChanged(linha);
      }
    }
  }

  private _updateCambiosVisibility(): void {
    if (this._dataGridInstance) {
      this._dataGridInstance.columnOption('cambioOrigem', 'visible', this.isME);
      this._dataGridInstance.columnOption('cambioOrigem', 'showInColumnChooser', this.isME);
      this._dataGridInstance.columnOption('cambioPagamento', 'visible', this.isME);
      this._dataGridInstance.columnOption('cambioPagamento', 'showInColumnChooser', this.isME);
    }
  }

  private _validaMoeda(index: number): boolean {
    if (!this.model.cab.total) {
      this.abreviaturaMoedaLinha = this.model.linhas[index].abreviaturaMoeda;
    } else if (this.abreviaturaMoedaLinha !== this.model.linhas[index].abreviaturaMoeda) {
      this.model.linhas[index].valorRecebido = 0;
      this._plAlertService.error(this._translateService.instant('recibos.erros.moedasdiferentesnosrecibos'));
      return false;
    }
    return true;
  }
}
