import type dxDataGrid from 'devextreme/ui/data_grid';
import DxCheckbox from 'devextreme/ui/check_box';
import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {copy, EDelphiNumberTypes, IPlToolbarItem, IPlTooltipConfig, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ComplementosExcepcoesModalComponent} from '../../rhcessacontratotrabalho/modals/complementosexpcecoes/complementosExcepcoes.modal.component';
import {HttpResponse} from '@angular/common/http';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IExcluidosAbonosDescontos} from '../../rhcessacontratotrabalho/rhCessaContratoTrabalho.module.interface';
import {IJsonListaExcl, IJsonListaExclProcessamento} from '../../rhfolfer/jsonRHFolfer.module.interface';
import {IJsonRHSalToTrf} from '../jsonRHSalToTrf.module.interface';
import {ERhTransSalTab, IRHSalToTrf, IRHSalToTrfTotals} from '../rhSalToTrf.module.interface';
import {IRHSalToTrfHeader} from '../../rhfolfer/rhFolfer.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment from 'moment';
import {RHFolFerService} from '../../rhfolfer/rhFolfer.module.service';
import {RHSalToTrfDadosTransfModalComponent} from '../modals/dadostransf/rhSalToTrf.dadosTransf.modal.component';
import {RHSalToTrfService} from '../rhSalToTrf.module.service';
import {SELECTOR_DEV_EXPRESS_COMPONENT_SELECT_CHECKBOX} from '../../../../components/devexpress/widget/devexpress.selectors.interface';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {RHAgenciasModalComponent} from '../modals/config/rhAgencias/rhAgencias.modal.component';
import {RhSalToTrfImprimirModalComponent} from '../modals/imprimir/rhSalToTrf.imprimir.modal.component';

@Component({
  selector: 'module-rh-sal-to-trf',
  templateUrl: './rhSalToTrf.module.component.html'
})
export class RHSalToTrfModuleComponent extends ModuloComponent implements OnInit {
  public readonly tabs: typeof ERhTransSalTab;
  public readonly tooltipExcepcoesProcessamento: IPlTooltipConfig;

  public activeTabId: ERhTransSalTab;
  public dataGrid: IDevExpressDataGrid<IRHSalToTrf, number>;
  public dataGridDefinitionTotals: IDevExpressDataGrid<IRHSalToTrfTotals>;
  public rhSalToTrfHeader: IRHSalToTrfHeader;
  public rhSalToTrfList: Array<IRHSalToTrf>;
  public exclProcessamento: IJsonListaExclProcessamento;
  public isTabVencimento: boolean;
  public promise: Promise<void>;
  public promiseProcessamento: Promise<void>;
  public rhSalToTrfTotals: Array<IRHSalToTrfTotals>;
  public selectedRowKeys: Array<number>;

  private readonly _btnProcess: IPlToolbarItem;
  private readonly _btnPrint: IPlToolbarItem;
  private _cardPanel: CGCardPanelComponent;
  private _dataGridInstance: dxDataGrid<IRHSalToTrf, number>;
  private _isSearched: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhSalToTrfService: RHSalToTrfService,
    private readonly _rhFolFerService: RHFolFerService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.fnPesquisar = this.fnPesquisar.bind(this);
    this.tabs = ERhTransSalTab;
    this.activeTabId = ERhTransSalTab.RhTransBancariaVencimentos;
    this._isSearched = false;

    this._btnProcess = {
      id: 'processar',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-bolt fa-fw"></i>',
      class: 'btn-success',
      caption: 'global.btn.process',
      click: () => this._processar()
    };
    this._btnPrint = {
      id: 'print',
      order: this._btnProcess.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-print fa-fw"></i>',
      class: 'btn-light',
      caption: 'global.btn.print',
      click: () => this._imprimir()
    };

    this.tooltipExcepcoesProcessamento = {text: 'rhsaltotrf.messages.tooltipExcepcoesProcessamento', placement: 'bottom', container: 'body'};
    this.rhSalToTrfHeader = {
      codEmpDe: 1,
      codEmpAte: EDelphiNumberTypes.MaxSmallInt,
      dataProcesDe: moment().startOf('month'),
      dataProcesAte: moment().endOf('month')
    };
    this.exclProcessamento = {
      deData: this.rhSalToTrfHeader.dataProcesDe,
      ateData: this.rhSalToTrfHeader.dataProcesDe,
      listaPossiveis: [],
      listaExcluidos: []
    };
    this.rhSalToTrfList = [];
    this.rhSalToTrfTotals = [];
    this.selectedRowKeys = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._evaluateTabVencimentos();
    this.dataGrid = {
      keyExpr: 'codEmpregado',
      columns: [
        {dataField: 'valid', dataType: 'boolean', caption: 'rhsaltotrf.table.estado'},
        {dataField: 'codEmpregado', dataType: 'string', caption: 'rhsaltotrf.table.emp'},
        {dataField: 'nomeEmpregado', dataType: 'string', caption: 'rhsaltotrf.table.nome'},
        {dataField: 'valor', dataType: 'double', caption: 'rhsaltotrf.table.valor'},
        {dataField: 'iban', dataType: 'string', caption: 'rhsaltotrf.table.iban', visible: this.isTabVencimento, showInColumnChooser: this.isTabVencimento},
        {dataField: 'swift', dataType: 'string', caption: 'rhsaltotrf.table.swift', visible: this.isTabVencimento, showInColumnChooser: this.isTabVencimento},
        {dataField: 'estabelecimento', dataType: 'string', caption: 'rhsaltotrf.table.estabelecimento', visible: false, showInColumnChooser: true},
        {dataField: 'departamento', dataType: 'string', caption: 'rhsaltotrf.table.departamento', visible: false, showInColumnChooser: true},
        {dataField: 'ccusto', dataType: 'string', caption: 'rhsaltotrf.table.ccusto', visible: false, showInColumnChooser: true},
        {dataField: 'tipoProcess', dataType: 'string', caption: 'rhsaltotrf.table.tipoprocess', visible: false, showInColumnChooser: true},
        {dataField: 'dataProcessamento', dataType: 'string', caption: 'rhsaltotrf.table.dataprocess', visible: true, showInColumnChooser: true},
        {dataField: 'descricaoErro', dataType: 'date', caption: 'global.text.error'}
      ],
      filterRow: {visible: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      pager: {visible: false},
      paging: {enabled: false},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      searchPanel: {visible: true}
    };
    this._buildToolbar();

    this.dataGridDefinitionTotals = {
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      columns: [
        {dataField: 'caption', dataType: 'string', caption: ''},
        {dataField: 'totalLinhas', dataType: 'number', caption: 'rhsaltotrf.table.nRegistos'},
        {dataField: 'totalValor', dataType: 'double', caption: 'rhsaltotrf.table.valor'}
      ],
      filterRow: {visible: false},
      headerFilter: {visible: false},
      toolbar: {visible: false},
      remoteOperations: false,
      showColumnLines: false
    };

    this.rhSalToTrfTotals = [
      {caption: this._translateService.instant('rhsaltotrf.table.total'), totalLinhas: 0, totalValor: 0},
      {caption: this._translateService.instant('rhsaltotrf.table.selecionados'), totalLinhas: 0, totalValor: 0}
    ];
  }

  public onInitialized(event: IDevExpressDataGridEventOnInitialized<IRHSalToTrf, number>): void {
    this._dataGridInstance = event.component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IRHSalToTrf, number>): void {
    if (event.rowType !== 'data') {
      return;
    }
    if (event.column.command === 'select') {
      if (!event.data.valid) {
        const elementSelectCheckbox: HTMLElement = event.cellElement.querySelector(SELECTOR_DEV_EXPRESS_COMPONENT_SELECT_CHECKBOX);
        if (elementSelectCheckbox) {
          const dxCheckbox: DxCheckbox = <DxCheckbox>DxCheckbox.getInstance(elementSelectCheckbox);
          if (dxCheckbox) {
            dxCheckbox.option('disabled', true);
          }
        }
      }
    } else if (event.column.dataField === 'descricaoErro') {
      event.cellElement.classList.add('text-danger');
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IRHSalToTrf, number>): void {
    if (event.rowType === 'data' && !event.column?.type) {
      if (this.selectedRowKeys.includes(event.key)) {
        const index: number = this.selectedRowKeys.findIndex((key: number) => key === event.key);
        if (index !== -1) {
          this.selectedRowKeys.splice(index, 1);
        }
      } else if (event.data.valid) {
        this.selectedRowKeys.push(event.key);
      }
    }
    this._evaluateTotalSelecionados();
  }

  public onSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IRHSalToTrf, number>): void {
    const invalidKeys: Array<number> = [];
    for (let i = 0; i < event.selectedRowKeys.length; i++) {
      const key: number = event.selectedRowKeys[i];
      const data: IRHSalToTrf = event.selectedRowsData[i];
      if (!data.valid) {
        invalidKeys.push(key);
      }
    }
    if (invalidKeys.length) {
      for (const invalidkey of invalidKeys) {
        const index: number = this.selectedRowKeys.findIndex((key: number) => key === invalidkey);
        if (index !== -1) {
          this.selectedRowKeys.splice(index, 1);
        }
      }
    }

    this._evaluateTotalSelecionados();
  }

  public openExclusionsProcessamento(): Promise<void> {
    this.exclProcessamento.deData = this.rhSalToTrfHeader.dataProcesDe;
    this.exclProcessamento.ateData = this.rhSalToTrfHeader.dataProcesAte;
    this.promiseProcessamento = this._rhFolFerService.postExclusoesProcessamento(this.exclProcessamento).then((response: HttpResponse<IJsonListaExclProcessamento>) => {
      this.exclProcessamento.listaPossiveis = response.body.listaPossiveis;
      this.exclProcessamento.listaExcluidos = response.body.listaExcluidos;
      return this.openExclusions();
    });
    return this.promiseProcessamento.finally(() => {
      this.promiseProcessamento = undefined;
    });
  }

  public async openExclusions(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ComplementosExcepcoesModalComponent);
    const componentInstance: ComplementosExcepcoesModalComponent = modalInstance.componentInstance;
    componentInstance.excludedListPossiveis = copy(this.exclProcessamento.listaPossiveis);
    componentInstance.excludedListExcluidos = copy(this.exclProcessamento.listaExcluidos);
    const result: IExcluidosAbonosDescontos = await modalInstance.result;
    this.exclProcessamento.listaPossiveis = result.possiveis;
    this.exclProcessamento.listaExcluidos = result.excluidos;
  }

  public activeTabIdChange(): void {
    this._evaluateTabVencimentos();
    this._evaluateDatagridColumns();
    this.rhSalToTrfList = [];
    if (this._cardPanel.collapsed) {
      this._cardPanel.toggleCollapse();
    }
    this._isSearched = false;

    this.rhSalToTrfTotals[0].totalLinhas = 0;
    this.rhSalToTrfTotals[0].totalValor = 0;
    this.rhSalToTrfTotals[1].totalLinhas = 0;
    this.rhSalToTrfTotals[1].totalValor = 0;
  }

  public async fnPesquisar(): Promise<void> {
    await this._pesquisar();
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _pesquisar(): Promise<void> {
    const exclproc: string = this.exclProcessamento.listaExcluidos.map((value: IJsonListaExcl) => value.value).join(',');
    this._dataGridInstance.beginCustomLoading(undefined);
    this.promise = (this.isTabVencimento ? this._rhSalToTrfService.getVencimentos(this.rhSalToTrfHeader, exclproc) : this._rhSalToTrfService.getTicketsRef(this.rhSalToTrfHeader, exclproc))
      .then((response: HttpResponse<Array<IJsonRHSalToTrf>>) => {
        this.rhSalToTrfList = response.body.map<IRHSalToTrf>((item: IJsonRHSalToTrf) => {
          return {
            ...item,
            valid: item.idErro !== 1
          };
        });
        if (this.rhSalToTrfList?.length) {
          this._cardPanel.collapse();
        } else {
          this._cardPanel.focusFirstElement();
          this._plAlertService.info('global.text.searchNoData');
        }
      })
      .finally(() => {
        this.promise = undefined;
        this._dataGridInstance.endCustomLoading();
        this._isSearched = true;
        this._evaluateTotal();
      });

    return this.promise;
  }

  private async _processar(): Promise<void> {
    if (!this.selectedRowKeys.length) {
      this._plAlertService.warning('rhsaltotrf.messages.linesnotselected');
      return;
    }
    const rhSalToTrfSelList: Array<IRHSalToTrf> = this._dataGridInstance.getSelectedRowsData();
    for (const item of rhSalToTrfSelList) {
      item.selected = true;
    }
    const modalInstance = this._cgModalService.showVanilla(RHSalToTrfDadosTransfModalComponent);
    const componentInstance: RHSalToTrfDadosTransfModalComponent = modalInstance.componentInstance;
    componentInstance.rhSalToTrfSelList = rhSalToTrfSelList;
    componentInstance.dataTransf = rhSalToTrfSelList[0].dataLancamento;
    componentInstance.cartaoRef = this.activeTabId === ERhTransSalTab.RhTransBancariaTicketsRefeicao;
    await modalInstance.result;
  }

  private async _openConfigModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(RHAgenciasModalComponent);
    const componentInstance: RHAgenciasModalComponent = modalInstance.componentInstance;
    componentInstance.maintenanceMode = this.maintenanceMode;
    await modalInstance.result;
    await this._stateService.reload(this._state);
  }

  private async _imprimir(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(RhSalToTrfImprimirModalComponent);
    const componentInstance: RhSalToTrfImprimirModalComponent = modalInstance.componentInstance;
    componentInstance.selectedTab = this.activeTabId;
    componentInstance.filters = this.rhSalToTrfHeader;
    componentInstance.tabVencimento = this.isTabVencimento;
    componentInstance.searched = this._isSearched;
    await modalInstance.result;
  }

  private _buildToolbar(): void {
    this.toolbar.addButton(this._btnProcess).addButton(this._btnPrint);

    this.btnConfig.visible = true;
    this.btnConfig.order = this._btnPrint.order + 1;
    this.btnConfig.click = () => this._openConfigModal();
  }

  private _evaluateTabVencimentos(): void {
    this.isTabVencimento = this.activeTabId === ERhTransSalTab.RhTransBancariaVencimentos;
  }

  private _evaluateDatagridColumns(): void {
    this._dataGridInstance.columnOption('iban', 'visible', this.isTabVencimento);
    this._dataGridInstance.columnOption('iban', 'showInColumnChooser', this.isTabVencimento);
    this._dataGridInstance.columnOption('swift', 'visible', this.isTabVencimento);
    this._dataGridInstance.columnOption('swift', 'showInColumnChooser', this.isTabVencimento);
    this._dataGridInstance.repaint();
  }

  private _evaluateTotalSelecionados(): void {
    const rows: Array<IRHSalToTrf> = this._dataGridInstance.getSelectedRowsData();
    this.rhSalToTrfTotals[1].totalLinhas = rows.length;
    this.rhSalToTrfTotals[1].totalValor = 0;
    for (const row of rows) {
      this.rhSalToTrfTotals[1].totalValor += row.valor;
    }
  }

  private _evaluateTotal(): void {
    this.rhSalToTrfTotals[0].totalLinhas = this.rhSalToTrfList.length;
    this.rhSalToTrfTotals[0].totalValor = 0;
    for (const row of this.rhSalToTrfList) {
      this.rhSalToTrfTotals[0].totalValor += row.valor;
    }
  }
}
