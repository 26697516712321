import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPlFormDefinition, IPlFormTemplate} from 'pl-comps-angular';
import {IJsonEFaturaConfigDocsCabecalho} from '../../jsonEFaturaConfigDocs.module.interface';
import {ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS} from '../../../portalcontabilidade/manutencao/predefinidos/preDefinidosContab.entity.interface';
import {IEntityAutocompleteCustomAction} from '../../../../components/entity/entity.autocomplete.definition.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';

@Component({
  selector: 'config-docs-efatura-header',
  templateUrl: './configDocsEfatura.header.component.html'
})
export class ConfigDocsEFaturaHeaderComponent implements OnInit {
  @Input() public model: IJsonEFaturaConfigDocsCabecalho;
  @Output() public readonly evtLastFieldKeydown: EventEmitter<KeyboardEvent>;

  public readonly formDefinition: IPlFormDefinition;
  public readonly formTemplate: IPlFormTemplate;

  private readonly _customActionPreDefinido: IEntityAutocompleteCustomAction;
  private readonly _maintenancePreDefinidos: IEntityMaintenanceInstance;

  constructor(private readonly _entityMaintenanceService: EntityMaintenanceService) {
    this.evtLastFieldKeydown = new EventEmitter<KeyboardEvent>();
    this._customActionPreDefinido = {
      caption: 'configEfaturaDocPorLanc.text.maintenancePreDefinidos',
      action: async (selectedKey: number): Promise<void> => {
        await this._maintenancePreDefinidos.maintenanceEdit(selectedKey);
      }
    };
    this._maintenancePreDefinidos = this._entityMaintenanceService.build(ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS);
    this.formDefinition = {
      fields: [
        {
          name: 'fatura',
          type: 'predefinidoscontabilidade',
          caption: 'configEfaturaDocPorLanc.fields.fatura',
          properties: {
            entity: {
              keyTarget: 'fatura',
              outputTarget: 'faturaDesc',
              output: '{{preDefinidosID}} - {{descricao}}',
              fieldsMap: {preDefinidosID: 'fatura', descricao: 'faturaDesc'}
            },
            customActions: this._customActionPreDefinido
          }
        },
        {
          name: 'faturaRecibo',
          type: 'predefinidoscontabilidade',
          caption: 'configEfaturaDocPorLanc.fields.faturaRecibo',
          properties: {
            entity: {
              keyTarget: 'faturaRecibo',
              outputTarget: 'faturaReciboDesc',
              output: '{{preDefinidosID}} - {{descricao}}',
              fieldsMap: {preDefinidosID: 'faturaRecibo', descricao: 'faturaReciboDesc'}
            },
            customActions: this._customActionPreDefinido
          }
        },
        {
          name: 'notaDebito',
          type: 'predefinidoscontabilidade',
          caption: 'configEfaturaDocPorLanc.fields.notaDebito',
          properties: {
            entity: {
              keyTarget: 'notaDebito',
              outputTarget: 'notaDebitoDesc',
              output: '{{preDefinidosID}} - {{descricao}}',
              fieldsMap: {preDefinidosID: 'notaDebito', descricao: 'notaDebitoDesc'}
            },
            customActions: this._customActionPreDefinido
          }
        },
        {
          name: 'notaCredito',
          type: 'predefinidoscontabilidade',
          caption: 'configEfaturaDocPorLanc.fields.notaCredito',
          properties: {
            entity: {
              keyTarget: 'notaCredito',
              outputTarget: 'notaCreditoDesc',
              output: '{{preDefinidosID}} - {{descricao}}',
              fieldsMap: {preDefinidosID: 'notaCredito', descricao: 'notaCreditoDesc'}
            },
            customActions: this._customActionPreDefinido
          }
        },
        {
          name: 'reciboVerde',
          type: 'predefinidoscontabilidade',
          caption: 'configEfaturaDocPorLanc.fields.reciboVerde',
          properties: {
            entity: {
              keyTarget: 'reciboVerde',
              outputTarget: 'reciboVerdeDesc',
              output: '{{preDefinidosID}} - {{descricao}}',
              fieldsMap: {preDefinidosID: 'reciboVerde', descricao: 'reciboVerdeDesc'}
            },
            customActions: this._customActionPreDefinido
          }
        },
        {
          name: 'faturaSimplificada',
          type: 'predefinidoscontabilidade',
          caption: 'configEfaturaDocPorLanc.fields.faturaSimplificada',
          properties: {
            entity: {
              keyTarget: 'faturaSimplificada',
              outputTarget: 'faturaSimplificadaDesc',
              output: '{{preDefinidosID}} - {{descricao}}',
              fieldsMap: {preDefinidosID: 'faturaSimplificada', descricao: 'faturaSimplificadaDesc'}
            },
            customActions: this._customActionPreDefinido
          }
        },
        {
          name: 'docConferencia',
          type: 'predefinidoscontabilidade',
          caption: 'configEfaturaDocPorLanc.fields.docConferencia',
          properties: {
            entity: {
              keyTarget: 'docConferencia',
              outputTarget: 'docConferenciaDesc',
              output: '{{preDefinidosID}} - {{descricao}}',
              fieldsMap: {preDefinidosID: 'docConferencia', descricao: 'docConferenciaDesc'}
            },
            customActions: this._customActionPreDefinido
          }
        },
        {
          name: 'faturaEstrangeiro',
          type: 'predefinidoscontabilidade',
          caption: 'configEfaturaDocPorLanc.fields.faturaEstrangeiro',
          properties: {
            entity: {
              keyTarget: 'faturaEstrangeiro',
              outputTarget: 'faturaEstrangeiroDesc',
              output: '{{preDefinidosID}} - {{descricao}}',
              fieldsMap: {preDefinidosID: 'faturaEstrangeiro', descricao: 'faturaEstrangeiroDesc'}
            },
            customActions: this._customActionPreDefinido
          },
          events: {
            keydown: (value: string, event: KeyboardEvent) => {
              this._lastFieldKeydown(event);
            }
          }
        }
      ]
    };
    this.formTemplate = {
      items: [
        {type: 'group', fields: [{field: 'fatura'}, {field: 'faturaRecibo'}]},
        {type: 'group', fields: [{field: 'notaDebito'}, {field: 'notaCredito'}]},
        {type: 'group', fields: [{field: 'reciboVerde'}, {field: 'faturaSimplificada'}]},
        {type: 'group', fields: [{field: 'docConferencia'}, {field: 'faturaEstrangeiro'}]}
      ]
    };
  }

  public ngOnInit(): void {
    if (!this.model) {
      this.model = {
        docConferencia: undefined,
        docConferenciaDesc: undefined,
        fatura: undefined,
        faturaDesc: undefined,
        faturaEstrangeiro: undefined,
        faturaEstrangeiroDesc: undefined,
        faturaRecibo: undefined,
        faturaReciboDesc: undefined,
        faturaSimplificada: undefined,
        faturaSimplificadaDesc: undefined,
        notaCredito: undefined,
        notaCreditoDesc: undefined,
        notaDebito: undefined,
        notaDebitoDesc: undefined,
        reciboVerde: undefined,
        reciboVerdeDesc: undefined
      };
    }
  }

  private _lastFieldKeydown(event: KeyboardEvent): void {
    this.evtLastFieldKeydown.emit(event);
  }
}
