import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {PlAlertModule} from '../alert/alert.module';
import {PlTranslateModule} from '../translate/translate.module';

import {PlPdfComponent} from './pdf.component';
import {PlPdfHeaderDirective} from './pdf.header.directive';

export * from './pdf.component.interface';
export * from './pdf.component.options.service';
export * from './pdf.component';
export * from './pdf.errorhandler';
export * from './pdf.fetch.service';
export * from './pdf.header.directive';
export * from './pdf.utils';

const DECLARATIONS = [PlPdfComponent, PlPdfHeaderDirective];

@NgModule({
  imports: [CommonModule, PdfViewerModule, PlAlertModule, PlTranslateModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlPdfModule {}
