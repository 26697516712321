<div class="card documento-faturacao">
  <div class="card-header">
    <div class="card-title">
      <div class="fatura-title">
        <strong [translate]="'transferenciasarmazens.detail.cab.documentos'"></strong>: {{ doc.cab.numeroOrigem }} - {{ doc.cab.numeroDestino }}
        <span *ngIf="doc.cab.numeroSuporte" class="badge bg-secondary ms-3"><strong [translate]="'transferenciasarmazens.detail.cab.docSuporte'"></strong>: {{ doc.cab.numeroSuporte }}</span>
      </div>

      <div class="fatura-data" [translate]="'docscomerciais.doc.cab.dataDoc'">
        :<span class="badge bg-secondary ms-2">{{ doc.cab.dataDoc | cgcDate }}</span>
      </div>
    </div>
  </div>

  <div class="card-body card-documento">
    <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="tableDocsRecibos" [dataSource]="doc.linhas"></dx-data-grid>
  </div>
</div>
