import {Component, Input, OnInit} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {HttpResponse} from '@angular/common/http';
import {EDocTipoEntity, EFolderType, IJsonGDFolder} from '../../../gdoc/JsonGDoc.entity.interface';
import {IGDocsCallback, IGDocsOnAddingFile} from '../../../../components/gdocs/gdocs.component.interface';
import {EFolderDoc, ENTITY_NAME_GDOC, IGDocEntityService} from '../../../gdoc/gdoc.entity.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';

@Component({
  selector: 'artigos-gdoc',
  templateUrl: './artigos.gdoc.component.html'
})
export class ArtigosGdocComponent implements OnInit {
  @Input() public nArtigo: string;
  @Input() public modeComments: boolean;

  public readonly tipoDocEntity: EDocTipoEntity;
  public readonly callbackGDocs: IGDocsCallback;

  public folderID: string;
  public params: string;

  private readonly _service: IGDocEntityService;

  constructor(
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this.nArtigo = '';
    this.tipoDocEntity = EDocTipoEntity.Artigos;
    this.callbackGDocs = {};
    this._service = this._entityServiceBuilder.build<IJsonGDFolder, IGDocEntityService>(ENTITY_NAME_GDOC);
    this.modeComments = false;
  }

  public ngOnInit(): void {
    this.params = `NArtigo=${this.nArtigo}`;
    this._getGDocFolder(this.nArtigo, EFolderDoc.DefaultFolderArtigos);
  }

  public onGDocsViewerAddingFile(event: IGDocsOnAddingFile): void {
    event.promise = (async () => {
      await this._addDocFolder(this.nArtigo, EFolderDoc.DefaultFolderArtigos);
      await new Promise((resolve) => {
        setTimeout(resolve, 0);
      });
    })();
  }

  private _addDocFolder(name: string, parentfolderId: string): Promise<unknown> {
    const gdocFolder: Partial<IJsonGDFolder> = {
      parentId: parentfolderId,
      name: name,
      folderType: EFolderType.System,
      additionalParams: `CGUID=Artigos&CGUIDValue=${name}&Artigos=${name}&TipoEntityRef=${EDocTipoEntity.Artigos}`
    };
    return this._service
      .post({body: gdocFolder})
      .then((response: HttpResponse<IJsonGDFolder>) => {
        if (response) {
          this.folderID = response.body.folderId;
        }
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          this._plAlertService.error(exception.message);
        } else {
          this._plAlertService.error('docscomerciais.erros.naoCriouPastaDoc');
        }
      });
  }

  private _getGDocFolder(name: string, parentfolderId: string): Promise<unknown> {
    return this._service
      .getFolderId(parentfolderId, name)
      .then((response) => {
        if (response?.body) {
          this.folderID = response.body.folderId;
        }
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          this._plAlertService.error(exception.message);
        }
      });
  }
}
