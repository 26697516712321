import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy, IPlFormSubmitEvent, IPlMediaDevicesCameraCaptureProperties, IPlTooltipConfig, isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {ENTITY_NAME_PRH_ENT_ALTERS} from '../../../../../entities/prhentalters/pRHEntAlers.entity.interface';
import {ENTITY_NAME_PRH_SERVICO_EMPREGADOS} from '../../../../../entities/prhservicoempregados/pRHServicoEmpregados.entity.interface';
import {ENTITY_NAME_PRH_SERVICOS, IPRHServicosEntityService} from '../../../../../entities/prhservicos/pRHServicos.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonPRHEntAlter} from '../../../../../entities/prhentalters/jsonPRHEntAlter.entity.interface';
import {IJsonPRHServicoEmpregado} from '../../../../../entities/prhservicoempregados/jsonPRHServicoEmpregado.entity.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import moment from 'moment';
import {EDgempsFichaChangableFields, IDgempsFichaModel} from '../dgempsFicha.module.interface';
import {ApiUtilsService} from '../../../../../services/apiutils/apiutils.service';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../../../entities/dgemps/dgemps.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {EStatusCode} from '../../../../../../config/constants';
import {IJsonDGEMP} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {dataSourceRHSituacaoCivilTitular, ERHSituacaoCivilTitular, IRHDataSourceItem} from '../../../../portalrh/dgempsfull/dgempsFull.interface';

const MAX_WIDTH = 500;
const RESIZE_WIDTH = 150;
const RESIZE_HEIGHT = 150;

@Component({
  selector: 'dgemps-ficha',
  templateUrl: './dgempsFicha.module.component.html'
})
export class DgempsFichaComponent extends ModuloComponent implements OnInit {
  @Input() public model: IDgempsFichaModel;

  public readonly situacaoCivilTitularDataSource: Array<IRHDataSourceItem<ERHSituacaoCivilTitular>>;
  public readonly tooltipNumDependDef: IPlTooltipConfig;

  public papel: string;
  public servico: string;
  public pedidoEfectuado: boolean;
  public globalPromise: Promise<void>;
  public ibanValid: boolean;
  public promisePicture: Promise<unknown>;
  public colaboradorPicture: File;
  public propertiesCaptureImage: IPlMediaDevicesCameraCaptureProperties;

  private readonly _relServicoEmp: IEntityService<IJsonPRHServicoEmpregado>;
  private readonly _servicoEmp: IPRHServicosEntityService;
  private readonly _servicePRHEntAlters: IEntityService<IJsonPRHEntAlter>;
  private readonly _serviceDGEMPS: IDGEMPSEntityService;

  private _colaboradorIni: IDgempsFichaModel;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _apiUtilsService: ApiUtilsService
  ) {
    super(_injector);
    this.ibanValid = true;
    this._relServicoEmp = this._entityServiceBuilder.build(ENTITY_NAME_PRH_SERVICO_EMPREGADOS);
    this._servicoEmp = this._entityServiceBuilder.build(ENTITY_NAME_PRH_SERVICOS);
    this._servicePRHEntAlters = this._entityServiceBuilder.build(ENTITY_NAME_PRH_ENT_ALTERS);
    this._serviceDGEMPS = this._entityServiceBuilder.build<IJsonDGEMP, IDGEMPSEntityService>(ENTITY_NAME_DGEMPS);
    this.papel = 'Papel não definido';
    this.servico = 'Não definido';
    this.pedidoEfectuado = false;
    this.globalPromise = Promise.resolve();
    this.situacaoCivilTitularDataSource = dataSourceRHSituacaoCivilTitular(this._translateService).slice(1);
    this.tooltipNumDependDef = {text: 'dgempsfull.fields.numDependDefLabel', placement: ['bottom', 'left']};
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._colaboradorIni = copy(this.model);
    this.propertiesCaptureImage = {
      resizeWidth: RESIZE_WIDTH,
      resizeHeight: RESIZE_HEIGHT,
      resizeMethod: 'crop',
      defaultFileName: this._translateService.instant('dgempsfull.text.fileNamePicture', {codEmp: this.model.codEmp}),
      videoWidth: MAX_WIDTH,
      uploadProperties: {maxFileSize: 2},
      resizeOverlay: true
    };
    this._servicoEmp.get({id: this.model.codServicoPRH}).then((response) => {
      this.servico = response.body.nome;
      this._relServicoEmp.query({pesquisa: `codEmp=${this.model.codEmp}`}).then((papeis) => {
        let papelaux = '';
        for (const item of papeis.body.list) {
          if (item.codServico === this.model.codServicoPRH) {
            papelaux = papelaux === '' ? item.papel.nome : `${papelaux} / ${item.papel.nome}`;
          }
        }
        if (papelaux) {
          this.papel = papelaux;
        }
      });
    });

    // Load picture
    this.promisePicture = this._getDHEMPPicture(this.model.codEmp).then((value: File) => {
      this.colaboradorPicture = value;
    });
  }

  public changedPicture(value: File): void {
    this.colaboradorPicture = value;
    if (isDefinedNotNull(this.colaboradorPicture)) {
      this.promisePicture = this._serviceDGEMPS.setFoto(this.model.codEmp, this.colaboradorPicture);
    }
  }

  public ibanChanged(iban: string): void {
    this.model.iban = iban;
    if (isDefinedNotNull(iban)) {
      this.globalPromise = this._apiUtilsService.validateIban(iban).then((response) => {
        this.ibanValid = response.body.valid;
        this.model.swift = response.body.swift;
      });
    }
  }

  public readonly fnDeleteFoto = (): Promise<unknown> => this._deleteFoto();

  public alterarCampos({instance}: IPlFormSubmitEvent<void>): Promise<void> {
    if (!instance.valid) {
      return Promise.reject();
    }

    return new Promise<void>((resolve) => {
      const promises: Array<Promise<void>> = [];
      let auxPromise = Promise.resolve();
      if (this._colaboradorIni.iban !== this.model.iban) {
        auxPromise = new Promise((resolve1, reject) => {
          this._apiUtilsService.validateIban(this.model.iban).then((response) => {
            this.ibanValid = response.body.valid;
            if (response.body.valid) {
              promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.IBAN, this.model.iban, this._colaboradorIni.iban));
              resolve1();
            } else {
              this._plAlertService.error(this._translateService.instant('prhentalters.ibanInvalido', {iban: this.model.iban}));
              reject();
            }
          });
        });
      }

      auxPromise
        .then(() => {
          if (this._colaboradorIni.nome !== this.model.nome) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.NOME, this.model.nome, this._colaboradorIni.nome));
          }
          if (this._colaboradorIni.apelido !== this.model.apelido) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.APELIDO, this.model.apelido, this._colaboradorIni.apelido));
          }
          if (this._colaboradorIni.morada !== this.model.morada) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.MORADA, this.model.morada, this._colaboradorIni.morada));
          }
          if (this._colaboradorIni.rua !== this.model.rua) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.RUA, this.model.rua, this._colaboradorIni.rua));
          }
          if (this._colaboradorIni.codPostal !== this.model.codPostal) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.COD_POSTAL, this.model.codPostal, this._colaboradorIni.codPostal));
          }
          if (this._colaboradorIni.nomeLocalida !== this.model.nomeLocalida) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.NOME_LOCALIDA, this.model.nomeLocalida, this._colaboradorIni.nomeLocalida));
          }
          if (this._colaboradorIni.email !== this.model.email) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.EMAIL, this.model.email, this._colaboradorIni.email));
          }
          if (this._colaboradorIni.nTelefone !== this.model.nTelefone) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.NTELEFONE, this.model.nTelefone, this._colaboradorIni.nTelefone));
          }
          if (this._colaboradorIni.telemovelPart1 !== this.model.telemovelPart1) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.TELEMOVELPART1, this.model.telemovelPart1, this._colaboradorIni.telemovelPart1));
          }
          if (this._colaboradorIni.swift !== this.model.swift) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.SWIFT, this.model.swift, this._colaboradorIni.swift));
          }
          if (this._colaboradorIni.situacaoCivilTitular !== this.model.situacaoCivilTitular) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.SITUACAO_CIVIL_TITULAR, this.model.situacaoCivilTitular, this._colaboradorIni.situacaoCivilTitular));
          }
          if (this._colaboradorIni.numDepend !== this.model.numDepend) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.NUM_DEPEND, this.model.numDepend, this._colaboradorIni.numDepend));
          }
          if (this._colaboradorIni.numDependDef !== this.model.numDependDef) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.NUM_DEPEND_DEF, this.model.numDependDef, this._colaboradorIni.numDependDef));
          }
          if (promises.length) {
            Promise.allSettled(promises).then((results) => {
              const haveRejected = isDefinedNotNull(results.find((r) => r.status === 'rejected'));
              if (haveRejected) {
                this._plAlertService.warning('prhentalters.foiEnviadoPedidoDeAlteracaoDadosMasAlgunsComErro');
              } else {
                this._plAlertService.success('prhentalters.foiEnviadoPedidoDeAlteracaoDados');
              }
              resolve();
            });
          } else {
            this._plAlertService.info('prhentalters.naoExistemAlteracoesParaGuardar');
            resolve();
          }
        })
        .catch(resolve);
    });
  }

  private _postPRHEntAlter(campo: EDgempsFichaChangableFields, valor: string | number, valorAntigo: string | number): Promise<void> {
    const rootProp = [
      EDgempsFichaChangableFields.SWIFT,
      EDgempsFichaChangableFields.IBAN,
      EDgempsFichaChangableFields.NUM_DEPEND,
      EDgempsFichaChangableFields.NUM_DEPEND_DEF,
      EDgempsFichaChangableFields.SITUACAO_CIVIL_TITULAR
    ].includes(campo)
      ? 'dgempsfull'
      : 'dgemps';

    const rootFieldProp = campo === EDgempsFichaChangableFields.NUM_DEPEND_DEF ? 'numDependDef' : campo;

    const entidade = [
      EDgempsFichaChangableFields.SWIFT,
      EDgempsFichaChangableFields.IBAN,
      EDgempsFichaChangableFields.NUM_DEPEND,
      EDgempsFichaChangableFields.NUM_DEPEND_DEF,
      EDgempsFichaChangableFields.SITUACAO_CIVIL_TITULAR
    ].includes(campo)
      ? 'DHEMP'
      : 'DGEMP';

    return this._servicePRHEntAlters
      .post({
        body: {
          codPrhEntAlter: -1,
          nomeEmp: undefined,
          entidade: entidade,
          campo: campo,
          descricaoCampo: this._translateService.instant(`${rootProp}.fields.${rootFieldProp}`),
          tipo: 0,
          valor: valor,
          estado: 0,
          codEmp: this.model.codEmp,
          codEmpAlterou: this.model.codEmp,
          dataAlterou: moment(),
          codEmpAprovou: 0,
          dataAprovou: moment(),
          valorAntigo: valorAntigo
        }
      })
      .then(() => undefined)
      .catch((reason: unknown) => {
        this._logger.error(reason);
        this._plAlertService.error(this._translateService.instant('prhentalters.erroDoCampo', {campo: this._translateService.instant(`${rootProp}.fields.${campo}`)}));
      });
  }

  private _deleteFoto(): Promise<unknown> {
    this.promisePicture = this._serviceDGEMPS.deleteFoto(this.model.codEmp).then(() => {
      this.changedPicture(undefined);
    });
    return this.promisePicture;
  }

  private _getDHEMPPicture(codEmp: number): Promise<File> {
    return this._serviceDGEMPS.getFoto(codEmp, {reportExceptions: false}).then((response: HttpResponse<File>) => {
      if (response.status === EStatusCode.OK) {
        return response.body;
      }
      return undefined;
    });
  }
}
