import {merge, union, uniq} from 'lodash-es';
import dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {
  copy,
  EMonth,
  IPlDynamicVisualsSecondaryClickAction,
  IPlLifeCycleEvent,
  isArray,
  isDefined,
  isEmpty,
  isFunction,
  isNumber,
  isObject,
  PlAlertService,
  PlTranslateService
} from 'pl-comps-angular';
import moment, {Moment, MomentInput} from 'moment';
import {IATRecibosRendasFilter, IATRecibosRendasResultFindInSource, IRRImportDoc, IRRImportDocApiList, TABLE_LEGEND_DOCUMENTOS_JA_LANCADOS} from './atRecibosRendas.module.interface';
import {AtRecibosRendasConfigModalComponent} from './modal/config/atRecibosRendas.config.modal';
import {AtRecibosRendasLancarDocsEmSerieModalComponent} from './modal/lancarDocsEmSerie/atRecibosRendas.lancarDocsEmSerie.modal';
import {AtRecibosRendasNifConfigModalComponent} from './modal/nifConfig/atRecibosRendas.nifConfig.modal';
import {AtRecibosRendasService} from './atRecibosRendas.service';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {devExpressDataGridExpandDetailHandler} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDataGridUIService} from '../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {DocContabilidadeService} from '../docscontabilidade/components/doccontabilidade/docContabilidade.service';
import {DocsContabilidadeService} from '../docscontabilidade/service/docsContabilidade.service';
import {EATRecibosRendasJaLancado, IJsonRRDocContabilidade, IJsonRRErro, IJsonRRImportDocApiList, IJsonRRNIFConfig} from './jsonATRecibosRendas.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocsContabilidadeEntityService} from '../docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {EXCEPTION_AT_RR_CALL_LOGIN} from '../../../../common/exceptions';
import {IDevExpressDataGrid, IDevExpressDataGridColumn, TDevExpressDataGridSelection} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowCollapsed,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDocContabilidadeLoadPreDefinidoParams} from '../docscontabilidade/components/doccontabilidade/docContabilidade.interface';
import {IJsonDocContabilidade, IJsonDocContabilidadeLinha} from '../docscontabilidade/jsonDocContabilidade.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {SimpleLoginModalComponent} from '../../simplelogin/modals/simpleLogin.modal.component';
import {AtFaturasRecibosVerdesNovaContaCorrenteModalComponent} from '../atFaturasRecibosVerdes/modal/novaContaCorrente/atFaturasRecibosVerdes.novaContaCorrente.modal';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';
import {CGCardPanelComponent} from '../../../components/cg/cardpanel/cardpanel.component';
import {EDataGridLegendCellColors} from '../../../components/devexpress/datagrid/configuration/devexpress.datagrid.configuration';

const BTN_CONFIG_ID = 'config';
const SAVE_PROMPT_IDENTIFIER = 'at-rr-doc';
const TOOLBAR_GROUP_ID = 'at-faturas-recibos-verdes-toolbars';
const TAB_RECIBOS_ID = 'tabRecibos';
const TAB_RECIBOS_LANCADOS_ID = 'tabRecibosLancados';

@Component({
  selector: 'module-at-faturas-recibos-verdes',
  templateUrl: './atRecibosRendas.module.component.html'
})
export class AtRecibosRendasModuleComponent extends ModuloComponent implements OnInit {
  public readonly tiposJaLancado: typeof EATRecibosRendasJaLancado;
  public readonly tabRecibosId: string = TAB_RECIBOS_ID;
  public readonly tabRecibosLancadosId: string = TAB_RECIBOS_LANCADOS_ID;
  public tableSource: Array<IRRImportDoc>;
  public tableSourceLancIgnored: Array<IRRImportDoc>;
  public dataGridDefinition: IDevExpressDataGrid<IRRImportDoc, number>;
  public filters: IATRecibosRendasFilter;
  public promise: Promise<void>;
  public selectedTab: string;
  public lancarDocsEmSerie: boolean;
  public lancarDocsEmSerieCount: number;
  public documentosJaLancadosDataGridLegend: TTableLegend;

  private readonly _docsModel: Map<string, IJsonDocContabilidade>;
  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private _dataGridInstance: dxDataGrid<IRRImportDoc, number>;
  private _firstLoad: boolean;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _atRecibosRendasService: AtRecibosRendasService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _docContabilidadeService: DocContabilidadeService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService
  ) {
    super(_injector);
    this.pesquisar = this.pesquisar.bind(this);
    this.tiposJaLancado = EATRecibosRendasJaLancado;
    this._docsModel = new Map<string, IJsonDocContabilidade>();
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.selectedTab = TAB_RECIBOS_ID;
    this._applyDataGridDefinition();
    this.documentosJaLancadosDataGridLegend = TABLE_LEGEND_DOCUMENTOS_JA_LANCADOS;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: BTN_CONFIG_ID,
      order: 4,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      class: 'btn-light',
      caption: 'global.btn.config',
      click: () => this._configButtonClick()
    });
    this._firstLoad = true;
    this.lancarDocsEmSerieCount = 0;
    this.tableSource = [];
    this.tableSourceLancIgnored = [];

    const fromDate: Moment = moment().startOf('year');
    const toDate: Moment = fromDate.clone().month(EMonth.February).endOf('month');
    this.filters = {
      fromDate: fromDate,
      toDate: toDate
    };
    const momObj = moment(this._configService.configurations.empresa.periodo, 'YYYYMMD');
    if (isDefined(momObj) && momObj.isValid()) {
      this.filters.fromDate = momObj.clone().startOf('month');
      this._fillToDate();
    }
  }

  public async pesquisar(): Promise<void> {
    this._firstLoad = false;
    this.lancarDocsEmSerieCount = 0;
    this._dataGridInstance.collapseAll(-1);
    this.tableSource = [];
    this.tableSourceLancIgnored = [];
    this.lancarDocsEmSerieChange(false);
    await this._getTableSource();
  }

  public changeTab(nextId: string): void {
    this.selectedTab = nextId;
    this._applyDataGridDefinition();
  }

  public async ignoreRecibo(item: IRRImportDoc): Promise<void> {
    await this._atRecibosRendasService.ignorarRecibo(item.nifLocatario, item.reciboId);
    this._plAlertService.success(this._plTranslateService.translate('atRecibosRendas.messages.docIgnoradoSuccessMsg', {ndoc: item.numeroRecibo}));
    await this.pesquisar();
  }

  public async ignoreNif(item: IRRImportDoc): Promise<void> {
    await this._atRecibosRendasService.ignorarSempreNif(item.nifLocatario, item.nomeLocatario);
    this._plAlertService.success(
      this._plTranslateService.translate('atRecibosRendas.messages.nifIgnoradoSuccessMsg', {
        nif: item.nifLocatario,
        nome: item.nomeLocatario
      })
    );
    await this.pesquisar();
  }

  public async openNifConfigModal(item: IRRImportDoc): Promise<void> {
    const response: IJsonRRNIFConfig = await this._atRecibosRendasService.getNifConfig(item.nifLocatario);
    const listNumDocWithSameNIF: Array<IRRImportDoc> = this.tableSource.filter((itemSource: IRRImportDoc) => itemSource.nifLocatario === item.nifLocatario);

    const modalRef = this._cgModalService.showVanilla(AtRecibosRendasNifConfigModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
    const componentInstance: AtRecibosRendasNifConfigModalComponent = modalRef.componentInstance;
    componentInstance.nifLocatario = item.nifLocatario;
    componentInstance.nomeLocatario = item.nomeLocatario;
    componentInstance.model = {...response, nif: item.nifLocatario, nome: item.nomeLocatario};
    componentInstance.docsList = listNumDocWithSameNIF;

    await modalRef.result;
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      await this.pesquisar();
    } finally {
      if (this._dataGridInstance) {
        this._dataGridInstance.endCustomLoading();
      }
    }
  }

  public async gerarNovaCC(item: IRRImportDoc): Promise<void> {
    const modalRef = this._cgModalService.showVanilla(AtFaturasRecibosVerdesNovaContaCorrenteModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
    const componentInstance: AtFaturasRecibosVerdesNovaContaCorrenteModalComponent = modalRef.componentInstance;
    componentInstance.nif = item.nifLocatario;
    componentInstance.nome = item.nomeLocatario;
    await modalRef.result;
    this._dataGridInstance.beginCustomLoading(undefined);
    const promises: Array<Promise<void>> = [];
    for (const tableItem of this.tableSource) {
      if (item.nifLocatario === tableItem.nifLocatario) {
        promises.push(
          this._atRecibosRendasService.reprocessEFaturaDoc(tableItem).then((response: HttpResponse<IRRImportDoc>) => {
            const index = this.tableSource.findIndex((itemIndex: IRRImportDoc) => itemIndex.reciboId === item.reciboId);
            if (index !== -1) {
              this.tableSource[index] = merge(tableItem, response.body);
              this.tableSource[index].erro = response.body.erro;
              this.tableSource[index].listaErros = response.body.listaErros;
            }
          })
        );
      }
    }
    await Promise.all(promises);

    const index = this.tableSource.findIndex((itemIndex: IRRImportDoc) => itemIndex.reciboId === item.reciboId);
    if (index !== -1) {
      this.tableSource[index].docModel.predefinido = undefined;
      await this._applyDocContabilidade(this.tableSource[index]);
      this.tableSource[index].errorText = this.getErrorString(this.tableSource[index]);
      this.tableSource[index] = copy(this.tableSource[index]);
    }
    this._dataGridInstance.endCustomLoading();
  }

  public async reciboPDF(item: IRRImportDoc): Promise<void> {
    await this._cgModalService.showPdf(
      this._translateService.instant('atRecibosRendas.verReciboModalTitle', {recibo: `${item.numeroContrato}/${item.numeroRecibo}`}),
      this._atRecibosRendasService.getReciboPDFURL(item.numeroContrato, item.numeroRecibo)
    );
  }

  public async deixarIgnorarRecibo(item: IRRImportDoc): Promise<void> {
    await this._atRecibosRendasService.deixarIgnorarRecibo(item.nifLocatario, item.reciboId);
    this._plAlertService.success(this._plTranslateService.translate('atRecibosRendas.messages.docRemIgnoringSuccessMsg', {ndoc: item.numeroRecibo}));
    await this.pesquisar();
  }

  public async deixarIgnorarNif(item: IRRImportDoc): Promise<void> {
    await this._atRecibosRendasService.deixarIgnorarNif(item.nifLocatario);
    this._plAlertService.success(this._plTranslateService.translate('atRecibosRendas.messages.docRemIgnoringFornecedorSuccessMsg', {nif: item.nifLocatario}));
    await this.pesquisar();
  }

  public fromDateChanged(value: MomentInput): void {
    this.filters.fromDate = moment(value);
    this._fillToDate();
  }

  public getErrorString(item: IRRImportDoc): string {
    let errors: Array<string> = [];
    if (!isEmpty(item.erro)) {
      errors.push(item.erro);
    }
    if (isArray(item.listaErros)) {
      for (const error of item.listaErros) {
        errors.push(error.errorMessage);
      }
    }
    errors = uniq(errors);
    return errors.length > 0 ? errors.join('<br>') : '';
  }

  public itemDocModelChanged(value: IJsonDocContabilidade, item: IRRImportDoc): void {
    this._setItemDocModel(item, value);
  }

  public docContabilidadeInit(item: IRRImportDoc, {element}: IPlLifeCycleEvent): void {
    this._docContabilidadeService.listenForSaveEvent({
      identifier: `${SAVE_PROMPT_IDENTIFIER}-${item.reciboId}`,
      callbackGetDoc: () => item.docModel,
      callbackOnSave: () => this._saveTableDoc(item, item.docModel),
      contabilidadeDigital: false,
      simulation: false,
      whenNode: element
    });
  }

  public docContabilidadeDestroy(item: IRRImportDoc): void {
    this._docContabilidadeService.clearForSaveEventListener(`${SAVE_PROMPT_IDENTIFIER}-${item.reciboId}`);
    this._plToolbarService.unRegisterInstance(`${item.reciboId}-toolbar`);
  }

  public lancarDocsEmSerieChange(value: boolean): void {
    this.lancarDocsEmSerie = value;

    const selection: TDevExpressDataGridSelection = {
      mode: value ? 'multiple' : 'single',
      showCheckBoxesMode: value ? 'always' : 'none'
    };
    this._dataGridInstance.option('selection', selection);
  }

  public async doLancarDocsEmSerie(): Promise<void> {
    const docs: Array<IRRImportDoc> = this._dataGridInstance.getSelectedRowsData();
    const response: Array<IRRImportDoc> = await this._atRecibosRendasService.lancarDocsEmSerie(docs);
    let lancadosCount = 0;
    let naoLancadosCount = 0;

    for (const item of response) {
      const tblitem: IATRecibosRendasResultFindInSource = this._findInTableSource(item);
      if (tblitem.index === -1) {
        continue;
      }
      if (item.listaErros.length) {
        item.listaErros.forEach((error: IJsonRRErro) => {
          this.tableSource[tblitem.index].listaErros.push({
            errorType: error.errorType,
            errorMessage: error.errorMessage
          });
        });
        naoLancadosCount++;
      } else {
        lancadosCount++;
        this.tableSource.splice(tblitem.index, 1);
        this.lancarDocsEmSerieCount--;
      }
    }

    const modalRef = this._cgModalService.showVanilla(AtRecibosRendasLancarDocsEmSerieModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
    const componentInstance: AtRecibosRendasLancarDocsEmSerieModalComponent = modalRef.componentInstance;
    componentInstance.naoLancadosCount = naoLancadosCount;
    componentInstance.lancadosCount = lancadosCount;
    componentInstance.processing = false;
    await modalRef.result;
    await this.pesquisar();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IRRImportDoc, number>): void {
    this._dataGridInstance = component;
  }

  public async onRowCollapsed({key}: IDevExpressDataGridEventOnRowCollapsed<IRRImportDoc, number>): Promise<void> {
    const item: IRRImportDoc = await this._dataGridInstance.byKey(key);
    if (item.docModel?.linhas?.length > 1) {
      const lastLine: IJsonDocContabilidadeLinha = item.docModel.linhas[item.docModel.linhas.length - 1];
      if (!lastLine.nConta && !lastLine.valor) {
        item.docModel.linhas.pop();
      }
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IRRImportDoc, number>): void {
    if (event.column.dataField === 'vfactions') {
      return;
    }
    devExpressDataGridExpandDetailHandler(event, () => this._applyDocContabilidade(event.data), {collapseOthers: true}).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IRRImportDoc, number>): void {
    if (event.rowType === 'data' && (!isEmpty(event.data.erro) || event.data.listaErros?.length > 0)) {
      event.cellElement.classList.add(EDataGridLegendCellColors.RedLegendColor);
    } else if (event.cellElement.classList.contains(EDataGridLegendCellColors.RedLegendColor)) {
      event.cellElement.classList.remove(EDataGridLegendCellColors.RedLegendColor);
    }
  }

  public async onSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IRRImportDoc, number>): Promise<void> {
    if (!this.lancarDocsEmSerie) {
      return;
    }

    const withouPreDefinidoKeys: Array<number> = [];
    for (const item of event.selectedRowsData) {
      if (!item.preDefinidoId) {
        withouPreDefinidoKeys.push(item.reciboId);
      }
    }

    if (withouPreDefinidoKeys.length > 0) {
      await this._dataGridInstance.deselectRows(withouPreDefinidoKeys);

      this._plAlertService.error(
        this._plTranslateService.translate(withouPreDefinidoKeys.length === 1 ? 'atRecibosRendas.cantSelectRowDueInvalidPredefinido' : 'atRecibosRendas.cantSelectRowsDueInvalidPredefinido')
      );
    }

    this.lancarDocsEmSerieCount = event.selectedRowKeys.length;
  }

  public onContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IRRImportDoc, number>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();

      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = [
        {
          caption: this._translateService.instant('atRecibosRendas.btn.dropdown.ignorarDocumento'),
          click: () => this.ignoreRecibo(event.row.data)
        },
        {
          caption: this._translateService.instant('atRecibosRendas.btn.dropdown.ignorarSempreTodosDocumentos'),
          click: () => this.ignoreNif(event.row.data)
        },
        {
          caption: this._translateService.instant('atRecibosRendas.btn.dropdown.nifConfig'),
          click: () => this.openNifConfigModal(event.row.data)
        },
        {
          caption: this._translateService.instant('atRecibosRendas.btn.dropdown.gerarNovaCC'),
          click: () => this.gerarNovaCC(event.row.data),
          disabled: !event.row.data.podeGenNovaCC
        },
        {divider: true},
        {
          caption: this._translateService.instant('atRecibosRendas.btn.dropdown.pdf'),
          click: () => this.reciboPDF(event.row.data)
        }
      ];

      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public readonly fnOnLoadPreDefinido =
    (item: IRRImportDoc) =>
    (params: IDocContabilidadeLoadPreDefinidoParams): Promise<IJsonDocContabilidade> =>
      this._loadPreDefinido(item, params);

  public onLancadosCellPreparedEvent($event: IDevExpressDataGridEventOnCellPrepared<IRRImportDoc>): void {
    if ($event.rowType === 'data') {
      if ($event.row.data.tipoItemJaLancado === EATRecibosRendasJaLancado.Ignorado) {
        $event.cellElement.classList.add(EDataGridLegendCellColors.YellowLegendColor);
      } else if ($event.row.data.tipoItemJaLancado === EATRecibosRendasJaLancado.IgnoradoPorNif) {
        $event.cellElement.classList.add(EDataGridLegendCellColors.BlueLegendColor);
      }
    }
  }

  public readonly fnDocContabilidadeAfterInitDocument = (item: IRRImportDoc) => (): void => {
    if (!item.docLoadFocusField && isFunction(item.docCallback.addLine)) {
      item.docCallback.addLine();
    }
  };

  public readonly fnDoLancarDocsEmSerie = (): Promise<void> => this.doLancarDocsEmSerie();

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _setItemDocModel(item: IRRImportDoc, docModel: IJsonDocContabilidade): void {
    item.docModel = docModel;
    this._docsModel.set(item.reciboId.toString(), item.docModel);
  }

  private _fillToDate(): void {
    this.filters.toDate = moment(this.filters.fromDate).clone().endOf('month');
  }

  private async _loadPreDefinido(item: IRRImportDoc, {predefinido, docContabilidade}: IDocContabilidadeLoadPreDefinidoParams): Promise<IJsonDocContabilidade> {
    const doc: IRRImportDoc = {...item, preDefinidoId: predefinido?.preDefinidosID};
    let predefinidoChanged = predefinido && item.preDefinidoId !== predefinido.preDefinidosID;
    if (!predefinidoChanged) {
      if (predefinido && !isObject(docContabilidade.predefinido)) {
        docContabilidade.predefinido = predefinido;
      } else {
        predefinidoChanged = isObject(docContabilidade.predefinido) ? item.preDefinidoId !== docContabilidade.predefinido.preDefinidosID : isNumber(item.preDefinidoId);
      }
    }
    if (predefinidoChanged) {
      const response: HttpResponse<IRRImportDoc> = await this._atRecibosRendasService.reprocessEFaturaDoc(doc);
      merge(item, response.body || {});
      docContabilidade = await this._getDocContabilidadePredefinido(item);
    }
    return docContabilidade;
  }

  private async _getDocContabilidadePredefinido(item: IRRImportDoc): Promise<IJsonDocContabilidade> {
    item.podeGenNovaCC = true;
    const responsePredefinido: HttpResponse<IJsonRRDocContabilidade> = await this._atRecibosRendasService.getDocContabilidadePredefinido(item);
    item.listaErros = union(item.listaErros, responsePredefinido.body.errors);
    item.errorText = this.getErrorString(item);
    return responsePredefinido.body.doc;
  }

  private async _saveTableDoc(item: IRRImportDoc, docContabilidade: IDocContabilidade): Promise<void> {
    const itemIndex = this.tableSource.findIndex((tableSourceItem: IRRImportDoc) => item.reciboId === tableSourceItem.reciboId);
    this._dataGridInstance.beginCustomLoading(undefined);
    try {
      await this._saveDoc(docContabilidade, item);

      this.tableSource = this.tableSource.filter((tblItem: IRRImportDoc) => {
        return tblItem.reciboId !== item.reciboId;
      });

      await this.pesquisar();

      if (this.tableSource.length && itemIndex >= 0 && itemIndex < this.tableSource.length && this.tableSource.length > 1) {
        await this._applyDocContabilidade(this.tableSource[itemIndex]);
        await this._dataGridInstance.expandRow(this.tableSource[itemIndex].reciboId);
      }
    } finally {
      this._dataGridInstance.endCustomLoading();
    }
  }

  private async _applyDocContabilidade(item: IRRImportDoc): Promise<void> {
    if (item.docModel?.predefinido) {
      this._createDocumentToolbar(item);
      return;
    }
    const docContabilidade: IJsonDocContabilidade = await this._getDocContabilidadePredefinido(item);
    this._setItemDocModel(item, docContabilidade);
    item.preDefinidoContab = item.docModel.predefinido;
    await this._loadPreDefinido(item, {
      predefinido: item.preDefinidoContab,
      docContabilidade: item.docModel,
      docOCRCab: undefined
    });
    this._docContabilidadeService.calculaTotais(item.docModel);
    this._createDocumentToolbar(item);
    await this._docContabilidadeAfterInitDocument(item, item.docModel);
    if (!item.docModel.isUsingPreDefinido || item.docModel.linhas.length !== 1 || !item.docModel.linhas[0]?.nConta) {
      item.docLoadFocusField = 'linhas';
    }
  }

  private _saveDoc(docContabilidade: IDocContabilidade, sourceObj: IRRImportDoc): Promise<void> {
    this.promise = this._serviceDocsContabilidade
      .post({body: docContabilidade})
      .then(async (responseSave: HttpResponse<IJsonDocContabilidade>) => {
        const response: HttpResponse<IDocContabilidade> = await this._atRecibosRendasService.getDocContabilidade(responseSave.body.extPocCabID);
        sourceObj.docModel = response.body;
        this._plAlertService.success(this._plTranslateService.translate('docscontabilidade.saved', {id: response.body.nDocumento}));
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  private _createDocumentToolbar(item: IRRImportDoc): void {
    this._plToolbarService.registerInstance(`${item.reciboId}-toolbar`, {
      items: [
        {
          groupId: TOOLBAR_GROUP_ID,
          id: `lastDocToolbarSaveDoc-${item.reciboId}`,
          order: 1,
          type: 'button',
          class: 'btn-primary',
          iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>&nbsp;',
          caption: 'global.btn.save',
          click: () => this._saveTableDoc(item, item.docModel)
        }
      ]
    });
  }

  private async _docContabilidadeAfterInitDocument(item: IRRImportDoc, doc: IJsonDocContabilidade): Promise<void> {
    if (!doc.predefinido) {
      doc.documentoExterno = `${item.numeroContrato}/${item.numeroRecibo}`;
      doc.dataDoc = item.dataEmissao;
      doc.nContribuinte = item.nifLocatario;
    }
    if (!doc.predefinido || doc.predefinido.isGeneric) {
      await this._docsContabilidadeService.registaNif(doc.nContribuinte);
    }
  }

  private _findInTableSource(item: IRRImportDoc): IATRecibosRendasResultFindInSource {
    const result: IATRecibosRendasResultFindInSource = {index: -1, item: null};
    for (let i = 0; i < this.tableSource.length; i++) {
      if (this.tableSource[i].reciboId === item.reciboId) {
        result.item = this.tableSource[i];
        result.index = i;
        break;
      }
    }
    return result;
  }

  private async _getTableSource(): Promise<void> {
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      const response: IRRImportDocApiList = await this._getInternalTableSources();
      for (const item of response.list) {
        item.docCallback = {};
        item.taxaIVAStr = item.taxasIva.map((o) => o.taxa).join(' | ');
        item.reciboPeriodo = `${moment(item.dataInicio).format('DD/MM/YYYY')} - ${moment(item.dataFim).format('DD/MM/YYYY')}`;
        item.errorText = this.getErrorString(item);
      }
      for (const item of response.listLancadosIgnorados) {
        item.docCallback = {};
        item.taxaIVAStr = item.taxasIva.map((o) => o.taxa).join(' | ');
        item.reciboPeriodo = `${moment(item.dataInicio).format('DD/MM/YYYY')} - ${moment(item.dataFim).format('DD/MM/YYYY')}`;
        item.errorText = this.getErrorString(item);
      }
      if ((this.selectedTab === TAB_RECIBOS_ID && response.list.length) || (this.selectedTab === TAB_RECIBOS_LANCADOS_ID && response.listLancadosIgnorados.length)) {
        this._cardPanel.collapse();
      }
      this.tableSource = response.list;
      this.tableSourceLancIgnored = response.listLancadosIgnorados;
    } finally {
      if (this._dataGridInstance) {
        this._dataGridInstance.endCustomLoading();
      }
    }
  }

  private async _configButtonClick(): Promise<void> {
    await this._cgModalService.show(AtRecibosRendasConfigModalComponent, {size: 'md'});
    if (this.tableSource.length > 0) {
      await this.pesquisar();
    }
  }

  private _applyDataGridDefinition(): void {
    const columns: Array<IDevExpressDataGridColumn> = [
      {dataField: 'referencia', dataType: 'string', caption: 'atRecibosRendas.fields.referencia'},
      {dataField: 'nifLocatario', dataType: 'string', caption: 'atRecibosRendas.fields.nifLocatario'},
      {dataField: 'nomeLocatario', dataType: 'string', caption: 'atRecibosRendas.fields.nomeLocatario', width: 150},
      {dataField: 'numeroRecibo', dataType: 'string', caption: 'atRecibosRendas.fields.reciboId'},
      {dataField: 'reciboPeriodo', dataType: 'date', caption: 'atRecibosRendas.fields.reciboPeriodo'},
      {dataField: 'dataEmissao', dataType: 'date', caption: 'atRecibosRendas.fields.dataEmissao'},
      {dataField: 'importancia', dataType: 'double', caption: 'atRecibosRendas.fields.importanciaRecebida'},
      {dataField: 'valor', dataType: 'double', caption: 'atRecibosRendas.fields.valorBase'},
      {dataField: 'valorIva', dataType: 'double', caption: 'atRecibosRendas.fields.valorIVA'},
      {dataField: 'vRetencao', dataType: 'double', caption: 'atRecibosRendas.fields.valorIRS'},
      {dataField: 'taxaIVAStr', dataType: 'double', caption: 'atRecibosRendas.fields.taxaIVA'}
    ];

    if (this.selectedTab === TAB_RECIBOS_ID) {
      columns.push({dataField: 'preDefinidoId', dataType: 'number', caption: 'atRecibosRendas.fields.preDefinido'});
    }

    columns.push({
      dataField: 'vfactions',
      fixed: true,
      fixedPosition: 'right',
      caption: '',
      cellTemplate: 'cellTemplateActions',
      allowHiding: false,
      allowSearch: false,
      allowReordering: false,
      allowSorting: false,
      allowFiltering: false,
      allowResizing: false,
      allowHeaderFiltering: false,
      allowGrouping: false,
      allowFixing: false,
      allowEditing: false,
      allowExporting: false,
      showInColumnChooser: false
    });

    this.dataGridDefinition = {
      keyExpr: 'reciboId',
      columns: columns,
      dataSource: [],
      columnHidingEnabled: false,
      export: {
        filename: this.selectedTab === TAB_RECIBOS_ID ? 'atRecibosRendas.tabDocsEFaturaCaption' : 'atRecibosRendas.tabJaLancadosCaption'
      },
      paging: {pageSize: 25},
      remoteOperations: false
    };

    if (this.selectedTab === TAB_RECIBOS_ID) {
      this.dataGridDefinition.toolbar = {
        items: [
          {
            location: 'after',
            template: 'templateToolbarLancDocSerie',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      };

      this.dataGridDefinition.masterDetail = {
        enabled: true,
        template: 'cellTemplateMasterDetail'
      };
    }
  }

  private _getInternalTableSources(): Promise<IJsonRRImportDocApiList> {
    if (this._firstLoad) {
      return Promise.resolve({list: [], listLancadosIgnorados: []});
    }
    return this._atRecibosRendasService
      .getAtList(this.filters.fromDate, this.filters.toDate)
      .then((response: HttpResponse<IJsonRRImportDocApiList>) => {
        return response.body;
      })
      .catch((reason) => {
        const cgException = this._cgExceptionService.get(reason);
        if (cgException.class === EXCEPTION_AT_RR_CALL_LOGIN) {
          this._cgModalService.show(SimpleLoginModalComponent, {size: 'sm', backdrop: 'static'}).then(() => {
            this._plAlertService.success('simpleLogin.messages.successLogin');
            this.pesquisar();
          });
        } else {
          this._plAlertService.error(cgException.message);
        }
        return {list: [], listLancadosIgnorados: []};
      });
  }
}
