import {TPlTableItem} from 'pl-comps-angular';
import {IJsonReembolsoIvaDocumento, IJsonReembolsoIvaRelacao} from '../../jsonReembolsoIva.entity.interface';

export enum EReembolsosIvaTab {
  Clients = 'clients',
  Suppliers = 'suppliers'
}

export enum EReembolsosIvaPill {
  Documents = 'documents',
  Relations = 'relations'
}

export type TReembolsosIvaDocumentoSourceItem = IJsonReembolsoIvaDocumento & IReembolsosIvaSourceItem;

export type TReembolsosIvaRelacaoSourceItem = IJsonReembolsoIvaRelacao & IReembolsosIvaSourceItem;

export type TReembolsosIvaDocumentoSourceTableItem = TPlTableItem<TReembolsosIvaDocumentoSourceItem>;

export interface IReembolsosIvaSourceItem {
  _index?: number;
  _mudarAtoIsoladoDisabled?: boolean;
}

export interface IReembolsosIvaPanel {
  icon: string;
  title: string;
}

export interface IReembolsosIvaTab extends IReembolsosIvaPanel {
  name: EReembolsosIvaTab;
}

export interface IReembolsosIvaDocsWithError {
  clients: IReembolsosIvaDocWithError;
  suppliers: IReembolsosIvaDocWithError;
}

export interface IReembolsosIvaDocWithError {
  documents: boolean;
  relations: boolean;
}

export const REEMBOLSOS_IVA_DEFAULT_VALUE = 'all';
