<div class="transferenciasarmazens">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="transferenciasArmazens" [dataSource]="dataGridDefinition.dataSource">
    <div *dxTemplate="let item of 'headerCellTemplateBtn'">
      <div *ngIf="!model.cab?.faccbIdSuporte">
        <pl-tooltip [config]="{text: 'transferenciasarmazens.tooltip.addNewLine', placement: 'left'}">
          <dx-button *plTooltipContent class="dx-button-icon-fontawesome" (click)="addLine()" icon="fa fa-fw fa-plus"></dx-button>
        </pl-tooltip>
      </div>
    </div>
    <div *dxTemplate="let item of 'templateBtnToolbar'">
      <span *ngIf="model.cab?.faccbIdSuporte" class="badge bg-secondary"
        ><strong [translate]="'transferenciasarmazens.detail.table.fields.documentoSuporte'"></strong>: {{ model.cab?.numeroSuporte }}</span
      >
    </div>
    <div *dxTemplate="let item of 'cellTemplateBtn'">
      <dx-button class="dx-button-icon-fontawesome" (click)="editLine(item.data, item.rowIndex)" icon="fa fa-fw fa-pencil"></dx-button>
      &nbsp;
      <dx-button class="dx-button-icon-fontawesome" (click)="deleteLine(item.rowIndex)" icon="fa fa-fw fa-eraser"></dx-button>
    </div>
  </dx-data-grid>
</div>
