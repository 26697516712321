import {HttpResponse} from '@angular/common/http';
import {Component, Injector} from '@angular/core';
import {IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, IPlNavWizardEventStep, IPlNavWizardOptions, KEYCODES, TEditInputKeyboardEvent} from 'pl-comps-angular';
import {focusElement} from '../../../../../common/utils/element.utils';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {CONSTANT_COD_PAIS_NACIONAL, isTest} from '../../../../../config/constants';
import {Moment} from 'moment';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {ECompanyStatusInstance, STATE_NAME_COMPANY_STATUS} from '../../../../states/account/companystatus/companystatus.interface';
import {IJsonDescritivo} from '../../../../entities/descritivos/jsonDescritivo.entity.interface';
import {IJsonDiario} from '../../../../entities/diarios/jsonDiario.entity.interface';
import {IFimAnoExecute, IFimAnoUIData, IValidationError} from '../fimAno.module.interface';
import {FimAnoService} from '../fimAno.module.service';
import {ENTITY_NAME_DIARIOS} from '../../../../entities/diarios/diarios.entity.interface';
import {ENTITY_NAME_DESCRITIVOS} from '../../../../entities/descritivos/descritivos.entity.interface';

const STEP_INDEX_HOME = 0;
const STEP_INDEX_DATA = 1;
const STEP_INDEX_VALIDATIONS = 2;
const STEP_INDEX_EXECUTE = 3;
const EDIT_NAME_DIARIO = 'diario';
const DEFAULT_DIARIO = 999;
const DEFAULT_DESCRITIVO = 13;

@Component({
  selector: 'fim-ano',
  templateUrl: './fimAno.module.component.html'
})
export class FimAnoComponent extends ModuloComponent {
  public readonly editNameDiario: string;
  public readonly definitionNavWizard: IPlNavWizardDefinition;
  public dataGridDefinition: IDevExpressDataGrid;
  public propertiesNavWizard: IPlNavWizardOptions;
  public allowedSchedule: Array<Moment>;
  public uiData: IFimAnoUIData;
  public model: IFimAnoExecute;
  public executing: boolean;
  public validated: boolean;
  public usaAgendamento: boolean;
  public validationErrors: Array<IValidationError>;

  private readonly _diariosService: IEntityService<IJsonDiario>;
  private readonly _descritivosService: IEntityService<IJsonDescritivo>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _fimAnoService: FimAnoService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._diariosService = this._entityServiceBuilder.build(ENTITY_NAME_DIARIOS);
    this._descritivosService = this._entityServiceBuilder.build(ENTITY_NAME_DESCRITIVOS);
    this.editNameDiario = EDIT_NAME_DIARIO;
    this.definitionNavWizard = {
      items: []
    };
    this.propertiesNavWizard = {
      disableNavigation: false,
      disablePreviousStep: false,
      disableNextStep: false
    };
    this.allowedSchedule = [];
    this.uiData = undefined;
    this.model = {
      nDiario: 0,
      nDescritivo: 0,
      saldaContasClasse9: true,
      saldaContasClasse0: true,
      fazTratamentoME: false,
      agendadoPara: undefined,
      comJob: true
    };
    this.executing = false;
    this.validated = false;
    this.validationErrors = [];
    this.usaAgendamento = !isTest() && this.configurations.schedule.usaAgendamento;
    this._focusNext();

    this.dataGridDefinition = {
      columns: [
        {
          dataField: 'error',
          dataType: 'string',
          headerCellTemplate: 'headerCellTemplate',
          cellTemplate: 'cellTemplate',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowSearch: false,
          allowGrouping: false,
          allowSorting: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false
        }
      ],
      allowColumnReordering: false,
      filterRow: {visible: false},
      noDataText: '',
      searchPanel: {visible: false},
      toolbar: {visible: false}
    };
  }

  public stepChanged({currentStep}: IPlNavWizardEventStep): void {
    this._setNavWizardProperties({disableNextStep: false, disableNext: false});
    if (currentStep === this.definitionNavWizard.items[STEP_INDEX_HOME]) {
      this._focusNext();
    } else if (currentStep === this.definitionNavWizard.items[STEP_INDEX_DATA]) {
      setTimeout(() => {
        const inputDiario: HTMLInputElement = this._element.querySelector<HTMLInputElement>(`input[name="${this.editNameDiario}"]`);
        focusElement(inputDiario);
      });
    } else if (currentStep === this.definitionNavWizard.items[STEP_INDEX_VALIDATIONS]) {
      this._validate();
    } else if (currentStep === this.definitionNavWizard.items[STEP_INDEX_EXECUTE]) {
      this._focusNext();
    }
  }

  public changedFieldValue(): void {
    this.validated = false;
  }

  public changedDiario(value: IJsonDiario): void {
    this.model.nDiario = value.nDiario;
  }

  public changedDescritivo(value: IJsonDescritivo): void {
    this.model.nDescritivo = value.nDescrit;
  }

  public readonly fnBeforeStepChange: (event: IPlNavWizardEventBeforeChange) => Promise<boolean> = (event: IPlNavWizardEventBeforeChange) => this._beforeStepChange(event);

  public readonly fnValidatorStepValidations: () => boolean = () => this._validatorStepValidations();

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  public readonly fnKeydownAvancar: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keydownAvancar(event);
  };

  private _beforeStepChange({currentStep, previousStep, nextStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (
      (currentStep === this.definitionNavWizard.items[STEP_INDEX_HOME] && type === 'next') ||
      (previousStep === this.definitionNavWizard.items[STEP_INDEX_VALIDATIONS] && type === 'previous') ||
      (nextStep && nextStep === this.definitionNavWizard.items[STEP_INDEX_DATA])
    ) {
      return this._initStepData().then(() => true);
    }
    if (nextStep && nextStep === this.definitionNavWizard.items[STEP_INDEX_EXECUTE] && !this.validated) {
      return Promise.resolve(false);
    }
    if ((currentStep === this.definitionNavWizard.items[STEP_INDEX_VALIDATIONS] && type === 'next') || (nextStep && nextStep === this.definitionNavWizard.items[STEP_INDEX_EXECUTE])) {
      return Promise.resolve(this._initStepExecute());
    }
    return Promise.resolve(true);
  }

  private async _initStepData(): Promise<void> {
    this.model.nDiario = await this._diariosService
      .get({id: DEFAULT_DIARIO, reportExceptions: false})
      .then((response) => response.body.nDiario)
      .catch(() => 0);
    this.model.nDescritivo = await this._descritivosService
      .get({id: DEFAULT_DESCRITIVO, reportExceptions: false})
      .then((response) => response.body.nDescrit)
      .catch(() => 0);
    if (this.uiData) {
      return;
    }
    if (this.usaAgendamento) {
      const allowedSchedule: HttpResponse<Array<Moment>> = await this._fimAnoService.horario();
      this.allowedSchedule = allowedSchedule.body;
      if (this.allowedSchedule.length) {
        this.model.agendadoPara = this.allowedSchedule[0];
      }
    }
    this.uiData = await this._fimAnoService.fetchUIData();
    this.model.fazTratamentoME = this.uiData.companyCodPais !== CONSTANT_COD_PAIS_NACIONAL;
  }

  private _initStepValidations(): void {
    this._setNavWizardProperties({disableNextStep: !this.validated || this.executing, disableNext: !this.validated || this.executing});
  }

  private _initStepExecute(): boolean {
    return !this.executing && this.validated;
  }

  private _validate(): void {
    this.validated = false;
    this.executing = true;
    this._fimAnoService
      .valida()
      .then((response: HttpResponse<Array<string>>) => {
        this.validated = true;
        for (const error of response.body) {
          this.validationErrors.push({error: error});
        }
        this._focusNext();
      })
      .catch()
      .finally(() => {
        this.executing = false;
        this._initStepValidations();
      });
  }

  private _validatorStepValidations(): boolean {
    return this.validated && this.validationErrors.length === 0;
  }

  private _setNavWizardProperties(properties: Partial<IPlNavWizardOptions>): void {
    this.propertiesNavWizard = {...this.propertiesNavWizard, ...properties};
  }

  private async _finalize(): Promise<void> {
    await this._fimAnoService.executar(this.model);
    await this._stateService.go(STATE_NAME_COMPANY_STATUS, {statusInstance: ECompanyStatusInstance.FimAno});
  }

  private _keydownAvancar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this._focusNext();
    }
  }

  private _focusNext(): void {
    setTimeout(() => {
      const button: HTMLButtonElement = this._element.querySelector<HTMLButtonElement>('pl-nav-wizard-step button.action-next-step, pl-nav-wizard-step button.action-finalize');
      focusElement(button);
    });
  }
}
