import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isDefined, isEmpty, PlAlertService} from 'pl-comps-angular';
import {IJsonNumber} from '../../../../../common/interfaces/json';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonArmazem} from '../../../armazens/jsonArmazem.entity.interface';
import {IJsonArtigo} from '../../../artigos/jsonArtigo.entity.interface';
import {IJsonConfigTransfArmazem, IJsonTransfArmazem} from '../../jsonTransferenciasArmazens.entity.interface';
import {IStockChanges, ITransfArmazem} from '../../transferenciasArmazens.entity.interface';
import {TransferenciasArmazensService} from '../../transferenciasArmazens.entity.service';
import {IJsonLote} from '../../../lotes/jsonLote.entity.interface';
import {isObject} from 'lodash-es';

@Component({
  selector: 'modal-transferencias-armazens-newline',
  templateUrl: './transferenciasArmazens.newLine.modal.component.html'
})
export class TransferenciasArmazensNewLineModalComponent extends CGModalComponent<IJsonTransfArmazem> implements OnInit {
  @Input() public listatransfarmazem: Array<IJsonTransfArmazem>;
  @Input() public linhaTransferencia: IJsonTransfArmazem;
  @Input() public configTransfArmazem: IJsonConfigTransfArmazem;
  @Input() public nArtigoTemLote: boolean;
  @Input() public stockItem: IStockChanges;
  public transfArmazem: ITransfArmazem;
  public availableStock: number;
  public isEditing: boolean;
  public temLote: boolean;
  public isDocSuporte: boolean;
  public filterLotes: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _transferenciasArmazensService: TransferenciasArmazensService,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.transfArmazem = {
      origemNArtigo: '',
      origemNLoteEspecifico: '',
      origemNArmazem: undefined,
      origemQtd: undefined,
      destinoNArtigo: '',
      destinoNLoteEspecifico: '',
      destinoNArmazem: undefined,
      destinoQtd: undefined,
      nomeOrigemArtigo: '',
      nomeOrigemArmazem: '',
      nomeDestinoArtigo: '',
      nomeDestinoArmazem: '',
      facliIdOrigem: 0
    };
    this.availableStock = 0;
    this.isEditing = false;
    this.isDocSuporte = false;
    this.filterLotes = '';
    this.temLote = false;
  }

  public ngOnInit(): void {
    if (this.configTransfArmazem?.nArmazemOrigem) {
      this.transfArmazem.origemNArmazem = this.configTransfArmazem.nArmazemOrigem;
      this.transfArmazem.nomeOrigemArmazem = this.configTransfArmazem.nomeArmazemOrigem;
    }

    if (this.configTransfArmazem?.nArmazemDestino) {
      this.transfArmazem.destinoNArmazem = this.configTransfArmazem.nArmazemDestino;
      this.transfArmazem.nomeDestinoArmazem = this.configTransfArmazem.nomeArmazemDestino;
    }

    if (this.linhaTransferencia) {
      this.transfArmazem = {...this.linhaTransferencia};
      this.transfArmazem.nomeOrigemArtigo = this.linhaTransferencia.nomeArtigoOrigem;
      this.transfArmazem.nomeDestinoArtigo = this.linhaTransferencia.nomeArtigoDestino;
      this.transfArmazem.nomeOrigemArmazem = this.linhaTransferencia.nomeArmazemOrigem;
      this.transfArmazem.nomeDestinoArmazem = this.linhaTransferencia.nomeArmazemDestino;
      this.isDocSuporte = this.linhaTransferencia.isDocSuporte;
      this.isEditing = true;
      if (this.linhaTransferencia.temLote === undefined) {
        this.temLote = this.transfArmazem.nArtigoTemLote || this.transfArmazem.origemNLoteEspecifico?.length > 0;
      } else {
        this.temLote = this.linhaTransferencia.temLote;
        this.isEditing = isDefined(this.linhaTransferencia.origemNLoteEspecifico);
      }
      this._evaluateStock();
    }
  }

  public close(): void {
    if (!this.transfArmazem.origemNArtigo) {
      this._plAlertService.error('transferenciasarmazens.messages.origemartigonulo');
    } else if (!this.transfArmazem.destinoNArtigo) {
      this._plAlertService.error('transferenciasarmazens.messages.destinoartigonulo');
    } else if (!this.transfArmazem.origemNArmazem) {
      this._plAlertService.error('transferenciasarmazens.messages.origemarmazemnulo');
    } else if (!this.transfArmazem.destinoNArmazem) {
      this._plAlertService.error('transferenciasarmazens.messages.destinoarmazemnulo');
    } else if (this.transfArmazem.origemQtd <= 0) {
      this._plAlertService.error('transferenciasarmazens.messages.qtdMaiorZero');
    } else if (this.transfArmazem.origemQtd > this.availableStock) {
      this._plAlertService.error('transferenciasarmazens.messages.qtdsup');
    } else if (this.temLote && !this.transfArmazem.origemNLoteEspecifico) {
      this._plAlertService.error('transferenciasarmazens.messages.loteNulo');
    } else if (this.transfArmazem.origemNArmazem === this.transfArmazem.destinoNArmazem) {
      this._plAlertService.error('transferenciasarmazens.messages.nArmazensDiferentes');
    } else {
      const listStock: Array<number> = this.listatransfarmazem
        .filter((item: IJsonTransfArmazem) => item.origemNArtigo === this.transfArmazem.origemNArtigo && item.origemNArmazem === this.transfArmazem.origemNArmazem)
        .map((item: IJsonTransfArmazem) => item.origemQtd);

      let totalStock = this.transfArmazem.origemQtd;
      for (const value of listStock) {
        totalStock += value;
      }

      if (this.isEditing) {
        totalStock -= this.linhaTransferencia.destinoQtd;
      }

      if (totalStock > this.availableStock) {
        const message: string = this._translateService.instant('transferenciasarmazens.messages.qtdtotalsup', {artigo: this.transfArmazem.origemNArtigo});
        this._plAlertService.error(message);
      } else {
        this.transfArmazem.destinoQtd = this.transfArmazem.origemQtd;
        this.transfArmazem.nArtigoTemLote = this.temLote;
        super.close(this.transfArmazem);
      }
    }
  }

  public origemNArtigoChanged(value: IJsonArtigo): void {
    this.transfArmazem.origemNArtigo = this.transfArmazem.destinoNArtigo = value.nArtigo;
    this.transfArmazem.nomeOrigemArtigo = this.transfArmazem.nomeDestinoArtigo = value.nome;
    this.transfArmazem.origemNLoteEspecifico = this.transfArmazem.destinoNLoteEspecifico = '';
    this.temLote = value.temLote;
    this.filterLotes = `nartigo=${this.transfArmazem.origemNArtigo}`;
    this._evaluateStock();
  }

  public origemNArmazemChanged(value: IJsonArmazem): void {
    this.transfArmazem.origemNArmazem = 0;
    if (isObject(value)) {
      this.transfArmazem.origemNArmazem = value.nArmazem;
      this.transfArmazem.nomeOrigemArmazem = value.nome;
    }
    this._evaluateStock();
  }

  public origemNLoteEspecificoChanged(value: IJsonLote): void {
    this.transfArmazem.origemNLoteEspecifico = '';
    if (isObject(value)) {
      this.transfArmazem.origemNLoteEspecifico = this.transfArmazem.destinoNLoteEspecifico = value.nLoteEspecifico;
      this._evaluateStock();
    }
  }

  public destinoNArmazemChanged(value: IJsonArmazem): void {
    this.transfArmazem.destinoNArmazem = 0;
    if (isObject(value)) {
      this.transfArmazem.destinoNArmazem = value.nArmazem;
      this.transfArmazem.nomeDestinoArmazem = value.nome;
    }
  }

  private _evaluateStock(): void | Promise<void> {
    this.availableStock = 0;
    if (!isEmpty(this.transfArmazem.origemNArtigo) && this.transfArmazem.origemNArmazem > 0) {
      this._transferenciasArmazensService
        .getQtdStockArtigoArmazem(this.transfArmazem.origemNArtigo, this.transfArmazem.origemNArmazem, this.transfArmazem.origemNLoteEspecifico)
        .then((response: HttpResponse<IJsonNumber>) => {
          this.availableStock = response.body.value;
          if (this.isEditing && this.stockItem?.faccbIdOrigem > 0) {
            this._transferenciaCorrigeStock();
          }
        });
    }
  }

  private _transferenciaCorrigeStock(): void {
    let qtd = 0;

    if (this.stockItem.loteEspecifico === this.transfArmazem.origemNLoteEspecifico) {
      const qtdStock = this.transfArmazem.facliIdOrigem > 0 ? this.transfArmazem.origemQtd : this.stockItem.qtd;

      qtd = this.stockItem.origemNArmazem === this.transfArmazem.origemNArmazem ? qtdStock : this.stockItem.destinoNArmazem === this.transfArmazem.origemNArmazem ? -qtdStock : 0;
    }
    this.availableStock += qtd;
  }
}
