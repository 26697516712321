import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, isArray, isNumber, isObject, isString, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {CGExceptionService} from '../exceptions/exceptions.service';
import {EDocTipoEntity, IJsonGDFile, IJsonGDFolder, IJsonGDFolderContent} from '../../entities/gdoc/JsonGDoc.entity.interface';
import {ENTITY_NAME_GDOC, IGDocEntityService} from '../../entities/gdoc/gdoc.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IFileItem, IFileItemDefinition} from '../fileviewer/fileviewer.interface';
import {ITree, ITreeItem} from '../treeviewer/treeviewer.interface';
import {IGDocsCallback, IGDocsOnAddingFile} from './gdocs.component.interface';
import {TranslateService} from '@ngx-translate/core';
import {catchReasonToMessage} from '../../../common/utils/errors.utils';

@Component({
  selector: 'gdocs-viewer',
  templateUrl: './gdocs.component.html'
})
export class GDocsComponent implements OnInit, OnChanges {
  @Input() public folderId: string;
  @Input() public showTreeViewer: boolean;
  @Input() public showList: boolean;
  @Input() public showBox: boolean;
  @Input() public maxNFiles: number;
  @Input() public treeCollapsed: boolean;
  @Input() public params: string;
  @Input() public tipoDocEntity: EDocTipoEntity;
  @Input() public errorFolderMessage: string;
  @Input() public callback: IGDocsCallback;
  @Input() public readOnly: boolean;
  @Input() public showExtension: boolean;
  @Output() public readonly uploadFileEvent: EventEmitter<void>;
  @Output() public readonly deleteFileEvent: EventEmitter<void>;
  @Output() public readonly evtAddingFile: EventEmitter<IGDocsOnAddingFile>;

  public gdocFolder: IJsonGDFolderContent;
  public gdocFolderFile: IJsonGDFolderContent;
  public treeItem: ITree;
  public isLoading: boolean;
  public fileItem: Array<IFileItemDefinition>;
  public pdfUrl: string;

  private readonly _service: IGDocEntityService;
  private _selectedFolderId: string;

  constructor(
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _translateService: TranslateService
  ) {
    this.uploadFileEvent = new EventEmitter<void>();
    this.deleteFileEvent = new EventEmitter<void>();
    this.evtAddingFile = new EventEmitter<IGDocsOnAddingFile>();
    this.gdocFolder = {
      files: [],
      folder: undefined,
      subfolders: [],
      comments: []
    };
    this.gdocFolderFile = {
      files: [],
      folder: undefined,
      subfolders: [],
      comments: []
    };
    this.treeItem = {nodes: []};
    this.fileItem = [];
    this._service = this._entityServiceBuilder.build<IJsonGDFolder, IGDocEntityService>(ENTITY_NAME_GDOC);
  }

  public ngOnInit(): void {
    if (!isString(this.params)) {
      this.params = '';
    }
    if (!isNumber(this.tipoDocEntity)) {
      this.tipoDocEntity = EDocTipoEntity.None;
    }
  }

  public ngOnChanges({folderId, callback}: SimpleChanges): void {
    if (folderId?.currentValue) {
      this.folderId = folderId.currentValue;
      this._loadFirstFolder();
    }

    if (isUndefinedOrNull(this.showBox)) {
      this.showBox = true;
    }

    if (isUndefinedOrNull(this.showList)) {
      this.showList = true;
    }

    if (isUndefinedOrNull(this.showTreeViewer)) {
      this.showTreeViewer = true;
    }

    if (callback) {
      const value: IGDocsCallback = callback.currentValue;
      if (isObject(value)) {
        value.refresh = () => this._refresh();
        value.refreshSelectedFolder = (selectedFolderId) => this._refreshSelectedFolder(selectedFolderId);
      }
    }
  }

  public loadFolderFiles(doc: ITreeItem): void {
    this._selectedFolderId = String(doc.nodeId);
    this._loadFiles(String(doc.nodeId));
  }

  public generateUrl(file: IFileItem): void {
    this._service.getFolderUrl(String(file.docId), String(file.folderId)).subscribe((folderUrl: string) => {
      this.pdfUrl = folderUrl;
    });
  }

  public deleteFile(file: IFileItem): Promise<void> {
    if (isUndefinedOrNull(this._selectedFolderId) || !this._selectedFolderId) {
      this._selectedFolderId = this.folderId;
    }
    this._service.deleteFile(String(file.folderId), String(file.docId)).then(() => {
      this.deleteFileEvent.emit();
      return this._refresh();
    });
    return undefined;
  }

  public async addFile(file: File): Promise<void> {
    if (this.maxNFiles && this.fileItem.length + 1 > this.maxNFiles) {
      this._plAlertService.warning('gdoc.messages.maxFicheirosAtingido');
      return;
    }

    const evtAddedFile: IGDocsOnAddingFile = {
      file: file,
      cancel: false,
      promise: undefined
    };

    this.evtAddingFile.emit(evtAddedFile);

    if (evtAddedFile.promise) {
      await evtAddedFile.promise;
    }

    if (evtAddedFile.cancel) {
      return;
    }

    if (isUndefinedOrNull(this._selectedFolderId) || !this._selectedFolderId || !this.showTreeViewer) {
      if (isUndefinedOrNull(this.folderId) || this.folderId === '') {
        await this._checkFolder();
      }
      this._selectedFolderId = this.folderId;
    }

    await this._service.uploadFile(file, this._selectedFolderId, file.name, this.params, this.tipoDocEntity);

    this.uploadFileEvent.emit();

    await this._refresh();
  }

  private _loadFirstFolder(): Promise<void> {
    this.gdocFolder = {
      files: [],
      folder: undefined,
      subfolders: [],
      comments: []
    };
    return this._getFolder(this.folderId).then((folderContent: IJsonGDFolderContent) => {
      this.gdocFolder = folderContent;
      if (this.gdocFolder?.subfolders.length) {
        this._setTreeViewer(this.gdocFolder);
      }
      this._setFileViwer(isArray(this.gdocFolder?.files) ? this.gdocFolder?.files : []);
    });
  }

  private _loadFiles(folderId: string): Promise<void> {
    this.gdocFolderFile = {
      files: [],
      folder: undefined,
      subfolders: [],
      comments: []
    };
    return this._getFolder(folderId).then((folderContent: IJsonGDFolderContent) => {
      this.gdocFolderFile = folderContent;
      if (this.gdocFolderFile?.subfolders.length) {
        this._setTreeSubViewer(this.gdocFolderFile);
      }
      this._setFileViwer(this.gdocFolderFile.files);
    });
  }

  private _setFileViwer(docFile: Array<IJsonGDFile>): void {
    this.fileItem = [];
    for (const docFileElement of docFile) {
      this.fileItem.push({
        classificador: '',
        dateCreate: docFileElement.dataCriacaoReg,
        dateModified: docFileElement.dataModificacao,
        docExt: docFileElement.nDocExterno,
        docId: docFileElement.docID,
        folderId: docFileElement.folderID,
        hourCreate: docFileElement.horaCriacaoReg,
        hourModified: docFileElement.horaModificacao,
        mimeType: docFileElement.mimeType,
        path: docFileElement.path,
        size: docFileElement.tamanho,
        text: this.showExtension ? docFileElement.nomeExtensao : docFileElement.nome,
        textExt: docFileElement.nomeExtensao,
        user: docFileElement.nomeUtilizActuReg
      });
    }
    this.fileItem = this.fileItem.slice();
  }

  private _setTreeViewer(docFolder: IJsonGDFolderContent): void {
    const item: ITreeItem = {
      nodeId: docFolder.folder.folderId,
      nodeText: docFolder.folder.name,
      childNodes: [],
      collapsed: this.treeCollapsed
    };
    this.treeItem.nodes.push(item);
    if (this.gdocFolder.subfolders.length) {
      for (const subfolder of this.gdocFolder.subfolders) {
        item.childNodes.push({
          nodeText: subfolder.name,
          nodeId: subfolder.folderId,
          childNodes: []
        });
      }
    }
    this.treeItem = copy(this.treeItem);
  }

  private _findIndexFolder(tree: Array<ITreeItem>, docFolder: IJsonGDFolderContent): ITreeItem {
    let item: ITreeItem;
    if (tree) {
      for (const node of tree) {
        if (node.nodeId === docFolder.folder.parentId) {
          item = node.childNodes.find((value) => value.nodeId === docFolder.folder.folderId);
          break;
        }
        if (node.childNodes.length) {
          item = this._findIndexFolder(node.childNodes, docFolder);
        }
      }
    }
    return item;
  }

  private _setTreeSubViewer(docFolder: IJsonGDFolderContent): void {
    const item: ITreeItem = this._findIndexFolder(this.treeItem.nodes, docFolder);
    if (item && !item.childNodes.length) {
      if (docFolder.subfolders.length) {
        for (const subfolder of docFolder.subfolders) {
          item.childNodes.push({
            nodeText: subfolder.name,
            nodeId: subfolder.folderId,
            childNodes: []
          });
        }
      }
    }
  }

  private async _getFolder(folderId: string): Promise<IJsonGDFolderContent> {
    if (!folderId) {
      throw new Error('FolderId is required');
    }
    try {
      const response: HttpResponse<IJsonGDFolderContent> = await this._service.get<IJsonGDFolderContent>({id: folderId, reportExceptions: false});
      return response.body;
    } catch (reason: unknown) {
      const errorMessage = catchReasonToMessage({reason: reason, exceptionService: this._cgExceptionService, translateService: this._translateService, defaultMessage: this.errorFolderMessage});
      this._plAlertService.error(errorMessage);
      throw new Error(errorMessage);
    }
  }

  private _refresh(): Promise<void> {
    if (!this._selectedFolderId) {
      return Promise.resolve();
    }
    this.isLoading = true;
    return this._loadFiles(this._selectedFolderId).finally(() => {
      this.isLoading = false;
    });
  }

  private _refreshSelectedFolder(folderId: string): Promise<void> {
    if (isUndefinedOrNull(folderId) || !folderId) {
      return Promise.resolve();
    }
    if (isUndefinedOrNull(this._selectedFolderId) || !this._selectedFolderId) {
      this._selectedFolderId = folderId;
    }
    return this._refresh();
  }

  private async _checkFolder(): Promise<void> {
    const response: HttpResponse<IJsonGDFolder> = await this._service.checkFolder(this.tipoDocEntity, this.params);
    this.folderId = response.body.folderId;
    this._selectedFolderId = response.body.folderId;
    this._loadFirstFolder();
  }
}
