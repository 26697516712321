import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Component, Injector, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {merge} from 'lodash-es';
import {
  EScreenSize,
  getScreenHeight,
  getScreenWidth,
  IPlToolbarInstance,
  IPlToolbarItem,
  IPlToolbarItemTemplateContext,
  IPlToolbarMenuItem,
  isBoolean,
  isDefinedNotNull,
  isEmpty,
  isUndefined,
  nodeForEach,
  PlAlertService,
  PlI18nPlNumberService,
  PlI18nService,
  timeout
} from 'pl-comps-angular';
import {Subscription} from 'rxjs';
import {focusElement} from '../../../../../common/utils/element.utils';
import {minDateCG} from '../../../../../common/utils/utils';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {
  EConciliacaoBancariaBancosTreeViewerNodeType,
  IConciliacaoBancariaBancosTreeViewerCallback,
  IConciliacaoBancariaBancosTreeViewerItemData
} from '../../../../components/conciliacaobancaria/bancostreeviewer/conciliacaobancaria.bancos.treeviewer.component.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {IGDocsCallback} from '../../../../components/gdocs/gdocs.component.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {ITreeItem} from '../../../../components/treeviewer/treeviewer.interface';
import {EGroupName, EMPTY_GUID} from '../../../../../config/constants';
import moment, {Moment} from 'moment';
import {EConfigOptionsInstanceName, IConciliacaoBancariaConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {EDebitoCredito} from '../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {EDocTipoEntity} from '../../../../entities/gdoc/JsonGDoc.entity.interface';
import {IJsonPeriodo} from '../../../../entities/periodos/jsonPeriodo.entity.interface';
import {ENTITY_NAME_PERIODOS, IPeriodosEntityService} from '../../../../entities/periodos/periodos.entity.interface';
import {ENTITY_NAME_POCS} from '../../../../entities/pocs/pocs.entity.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../docscontabilidade/docsContabilidade.interface';
import {
  EConciliacaoBancariaAddLinhaExtratoBancoModalMode,
  EConciliacaoBancariaEstadoConciliacaoCab,
  EConciliacaoBancariaExtPocEstado,
  EConciliacaoBancariaExtratoBancoTipo,
  EConciliacaoBancariaListType,
  EConciliacaoBancariaMode,
  EConciliacaoBancariaRecordState,
  EConciliacaoBancariaSelecaoExtrato,
  IConciliacaoBancaria,
  IConciliacaoBancariaBancoCab,
  IConciliacaoBancariaExtPocItem,
  IConciliacaoBancariaLegend,
  IConciliacaoBancariaLinItem,
  IConciliacaoBancariaNovaLinhaExtratoBancoModal,
  IConciliacaoBancariaSort,
  IConciliacaoBancariaTreeKey,
  IRestCmdConciliacaoBancariaAssociarExtrato,
  IRestCmdConciliacaoBancariaGetConciliacao,
  IRestCmdConciliacaoBancariaObterSaldos,
  TABLE_LEGEND_CONCILIACAO_BANCARIA,
  TConciliacaoBancariaColCaption,
  TConciliacaoBancariaSortableFields
} from '../conciliacaoBancaria.module.interface';
import {ConciliacaoBancariaService} from '../conciliacaoBancaria.module.service';
import {ConciliacaoBancariaAddDocsNaoConciliadosModalComponent} from '../modals/adddocsnaoconciliados/conciliacaoBancaria.addDocsNaoConciliados.modal.component';
import {ConciliacaoBancariaAddLinhaExtratoBancoModalComponent} from '../modals/addlinhaextratobanco/conciliacaoBancaria.addLinhaExtratoBanco.modal.component';
import {ConciliacaoBancariaAddLinhaExtratoCGModalComponent} from '../modals/addlinhaextratocg/conciliacaoBancaria.addLinhaExtratoCG.modal.component';
import {ConciliacaoBancariaImportExtratoBancarioModalComponent} from '../modals/importextratobancario/conciliacaoBancaria.importExtratoBancario.modal.component';
import {ConciliacaoBancariaNovaConciliacaoModalComponent} from '../modals/novaconciliacao/conciliacaoBancaria.novaConciliacao.modal.component';
import {ConciliacaoBancariaPdfModalComponent} from '../modals/pdf/conciliacaoBancaria.pdf.modal.component';
import {ConciliacaoBancariaSaldoDetalhesModalComponent} from '../modals/saldodetalhes/conciliacaoBancaria.saldo.detalhes.modal.component';
import {ConciliacaoBancariaViewDocModalComponent} from '../modals/viewdoc/conciliacaoBancaria.viewDoc.modal.component';
import {ConciliacaoBancariaContaPendentesBancoModalComponent} from '../modals/contaPendentesBanco/conciliacaoBancaria.contaPendentesBanco.modal.component';
import {TTableLegend} from '../../../../components/tablelegend/tablelegend.component.interface';

const TOOLBAR_INSTANCE_ID_EXTRATO_CG = 'extratoCG-toolbar';
const TOOLBAR_INSTANCE_ID_EXTRATO_BANCO = 'extratoBanco-toolbar';
const LINHA_NAO_CONCILIADA = 0;
const LINHA_CONCILIADA = 1;
const LINHA_CONCILIADA_PERIODO_POSTERIOR = 2;
const LINHA_PRIMEIRA_CONCILIACAO_NAO_CONCILIADA = 3;
const LINHA_PRIMEIRA_CONCILIACAO_CONCILIADA = 4;
const VSCROLL_SIZE = 51;
const VSCROLL_ITEM_SIZE = 28;
const SELECTED_ROW_CSS_CLASS = 'selected-row';
const APP_BLOCKED_BY_OTHER_EXCEPTION_NAME = 'EAPIJobConciliacaoBancariaControllerBlockedByUser';
const MAIN_TAB_ID = 'conciliacaoMainTab';
const ATTACHMENTS_TAB_ID = 'conciliacaoAttachmentsTab';

@Component({
  selector: 'conciliacao-bancaria',
  templateUrl: './conciliacaoBancaria.module.component.html'
})
export class ConciliacaoBancariaModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly extPocEstado: typeof EConciliacaoBancariaExtPocEstado;
  public readonly recordState: typeof EConciliacaoBancariaRecordState;
  public readonly extratoBancoTipo: typeof EConciliacaoBancariaExtratoBancoTipo;
  public readonly estadoConciliacaoCab: typeof EConciliacaoBancariaEstadoConciliacaoCab;
  public readonly listType: typeof EConciliacaoBancariaListType;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly virtualScrollItemSize: number;
  public readonly treeCallback: IConciliacaoBancariaBancosTreeViewerCallback;
  public readonly conciliacaoBancariaMainTabId: string = MAIN_TAB_ID;
  public readonly conciliacaoBancariaAttachmentsTabId: string = ATTACHMENTS_TAB_ID;
  public readonly gdocTipoDocEntity: EDocTipoEntity = EDocTipoEntity.ConciliacaoBancaria;
  public readonly gdocCallback: IGDocsCallback;
  public readonly selecaoExtratoEnum: typeof EConciliacaoBancariaSelecaoExtrato;

  public toolbarInstanceIdExtratoCG: string;
  public toolbarInstanceIdExtratoBanco: string;
  public treeCollapsed: boolean;
  public mostrarLinhasConciliadasExtratoCG: boolean;
  public mostrarLinhasConciliadasExtratoBanco: boolean;
  public conciliacao: IConciliacaoBancaria;
  public extratoCGHaveScroll: boolean;
  public extratoBancoHaveScroll: boolean;
  public selectedNode: ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>;
  public selectedBancoCabItem: IConciliacaoBancariaBancosTreeViewerItemData;
  public expPocListValueFilter: string;
  public colDescricaoVisible: boolean;
  public colNDocExtVisible: boolean;
  public colTipoVisible: boolean;
  public colSaldoVisible: boolean;
  public colStampUpdateVisible: boolean;
  public colDataLancVisible: boolean;
  public colDataVisible: boolean;
  public colValorSemDCVisible: boolean;
  public colBancoValorSemDCVisible: boolean;
  public colDCVisible: boolean;
  public colBancoDCVisible: boolean;
  public extratoBancoSelectAll: boolean;
  public extratoCGSelectAll: boolean;
  public valorTotalSelecionadoCG: number;
  public valorTotalSelecionadoBanco: number;
  public isMediumOrHigherScreen: boolean;
  public legends: Array<IConciliacaoBancariaLegend>;
  public conciliacaoTitle: string;
  public sortExtratoCG: IConciliacaoBancariaSort;
  public sortExtratoBanco: IConciliacaoBancariaSort;
  public vScrollHeight: number;
  public lockPromise: Promise<void>;
  public blockedByUser: boolean;
  public blockedByUserMessage: string;
  public activeTab: string;
  public gdocParams: string;
  public gdocFolderId: string;
  public countSelecionadosCG: number;
  public countSelecionadosBanco: number;
  public conciliacaoBancariaLegend: TTableLegend;

  private readonly _btnToggleTree: IPlToolbarItem;
  private readonly _btnMainConciliacaoDropdownToolbarItem: IPlToolbarItem;
  private readonly _btnMainPrintConciliacao: IPlToolbarItem;
  private readonly _btnMainNovaConciliacaoMenuItem: IPlToolbarMenuItem;
  private readonly _btnMainNovaConciliacaoFechoAnoMenuItem: IPlToolbarMenuItem;
  private readonly _btnMenuEditarConciliacaoMenuItem: IPlToolbarMenuItem;
  private readonly _btnMenuRefreshConciliacaoMenuItem: IPlToolbarMenuItem;
  private readonly _btnMainEliminaConciliacaoMenuItem: IPlToolbarMenuItem;
  private readonly _btnMainEliminarTodasConciliacoesBancoMenuItem: IPlToolbarMenuItem;
  private readonly _btnMainMudarEstadoConcilidacaoMenuItem: IPlToolbarMenuItem;
  private readonly _btnMainPrintConciliacaoMenuItem: IPlToolbarMenuItem;
  private readonly _btnMainConfigContaPendentesBancoMenuItem: IPlToolbarMenuItem;
  private readonly _btnMainShortcutsToolbarItem: IPlToolbarItem;
  private readonly _btnMainPocNContaMenuItem: IPlToolbarMenuItem;
  private readonly _btnMainPesqDocsMenuItem: IPlToolbarMenuItem;
  private readonly _btnExtratoCGMostrarLinhasConciliadasToolbarItem: IPlToolbarItem;
  private readonly _btnExtratoCGAdd: IPlToolbarItem;
  private readonly _btnExtratoCGImportaDocsPendentesConcilAntiga: IPlToolbarItem;
  private readonly _btnExtratoCGExtratoContaPendentes: IPlToolbarItem;
  private readonly _btnExtratoCGAssociarTodoExtratoCGAoExtradoDoBancoToolbarItem: IPlToolbarItem;
  private readonly _btnExtratoCGRemoverLinhasMenuItem: IPlToolbarMenuItem;
  private readonly _btnExtratoCGAssociarSelecionadosMenuItem: IPlToolbarMenuItem;
  private readonly _btnExtratoCGDesassociarSelecionadosMenuItem: IPlToolbarMenuItem;
  private readonly _btnExtratoCGLinkSelecionaodsMenuItem: IPlToolbarMenuItem;
  private readonly _btnExtratoCGAddLinhaPrimeiraConcilExtratoCG: IPlToolbarMenuItem;
  private readonly _btnExtratoCGAddDocsPrimeiraConcilExtratoCG: IPlToolbarMenuItem;
  private readonly _btnExtratoBancoMostrarLinhasConciliadasToolbarItem: IPlToolbarItem;
  private readonly _btnExtratoBancoRemoverTodasLinhasExtratoToolbarItem: IPlToolbarItem;
  private readonly _btnExtratoBancoDropdownSelecionados: IPlToolbarItem;
  private readonly _btnExtratoBancoAdd: IPlToolbarItem;
  private readonly _btnExtratoBancoImportaDocsPendentesBancoConcilAntiga: IPlToolbarItem;
  private readonly _btnExtratoBancoImportarExtratoBancario: IPlToolbarItem;
  private readonly _btnExtratoBancoConciliarAutomaticamente: IPlToolbarItem;
  private readonly _btnExtratoBancoAddLancPrimeiraConciliacaoMenu: IPlToolbarMenuItem;
  private readonly _btnExtratoBancoAddLancamentoBancoMenu: IPlToolbarMenuItem;
  private readonly _btnExtratoBancoAddLinhaExtradoBancarioMenu: IPlToolbarMenuItem;
  private readonly _btnExtratoBancoRemoverExtratoBancoSelecionadosMenuItem: IPlToolbarMenuItem;
  private readonly _btnExtratoBancoAddDocsDoExtratoBancarioAContabilidadeMenuItem: IPlToolbarMenuItem;
  private readonly _btnExtratoBancoDesassociarSelecionadosMenuItem: IPlToolbarMenuItem;
  private readonly _decimais: number;
  private readonly _entityMaintenanceInstance: IEntityMaintenanceInstance;
  private readonly _pesqDocsEntityMaintenanceInstance: IEntityMaintenanceInstance;
  private readonly _periodosService: IPeriodosEntityService;
  private readonly _subscriptionConciliacaoBancariaOptions: Subscription;

  private _cacheExtPocList: Array<IConciliacaoBancariaExtPocItem>;
  private _cacheConcilBancoList: Array<IConciliacaoBancariaLinItem>;
  private _fromFocus: boolean;
  private _internalExtPocSelectedItem: IConciliacaoBancariaExtPocItem;
  private _cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  private _cdkVirtualScrollViewportExtratoBanco: CdkVirtualScrollViewport;
  private _lastConciliadoValue: boolean;
  private _clickTimeout: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _conciliacaoBancariaService: ConciliacaoBancariaService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plI18nService: PlI18nService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plI18nPlNumberService: PlI18nPlNumberService
  ) {
    super(_injector);
    this.conciliacaoBancariaLegend = TABLE_LEGEND_CONCILIACAO_BANCARIA;
    this.vScrollHeight = VSCROLL_SIZE;
    this.virtualScrollItemSize = VSCROLL_ITEM_SIZE;
    this.selecaoExtratoEnum = EConciliacaoBancariaSelecaoExtrato;
    this.legends = [
      {text: 'Linha não Conciliada', cssClass: 'linha-nao-conciliada'},
      {text: 'Linha Conciliada', cssClass: 'linha-conciliada'},
      {text: 'Linha Conciliada Periodo Posterior', cssClass: 'linha-conciliada-periodo-posterior'},
      {text: 'Linha de Primeira Conciliação Não Conciliada', cssClass: 'linha-primeira-conciliacao-nao-conciliada'},
      {text: 'Linha de Primeira Conciliação Conciliada', cssClass: 'linha-primeira-conciliacao-conciliada'}
    ];
    this.sortExtratoCG = {column: 'valor', sort: 'asc'};
    this.sortExtratoBanco = {column: 'valor', sort: 'asc'};
    this.isMediumOrHigherScreen = getScreenWidth() >= EScreenSize.Large;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.CONCILIACAO_BANCARIA;
    this.configOptionsGroupName = EGroupName.CONTABILIDADE;
    this.treeCallback = {};
    this.extPocEstado = EConciliacaoBancariaExtPocEstado;
    this.recordState = EConciliacaoBancariaRecordState;
    this.extratoBancoTipo = EConciliacaoBancariaExtratoBancoTipo;
    this.estadoConciliacaoCab = EConciliacaoBancariaEstadoConciliacaoCab;
    this.listType = EConciliacaoBancariaListType;
    this.treeCollapsed = false;
    this.extratoCGHaveScroll = false;
    this.extratoBancoHaveScroll = false;
    this.expPocListValueFilter = '';
    this.valorTotalSelecionadoCG = 0;
    this.valorTotalSelecionadoBanco = 0;
    this.mostrarLinhasConciliadasExtratoCG = false;
    this.mostrarLinhasConciliadasExtratoBanco = false;
    this.conciliacaoTitle = '';
    this._decimais = this._configService.configurations.contabilidade.decimais.valor;
    this._entityMaintenanceInstance = this._entityMaintenanceService.build(ENTITY_NAME_POCS);
    this._pesqDocsEntityMaintenanceInstance = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._periodosService = this._entityServiceBuilder.build<IJsonPeriodo, IPeriodosEntityService>(ENTITY_NAME_PERIODOS);
    this._btnMainConciliacaoDropdownToolbarItem = {
      id: 'mainToolbar-conciliacao-dropdown',
      type: 'dropdown',
      order: 2,
      groupId: 'mainToolbar-conciliacao',
      caption: 'conciliacaoBancaria.btns.toolbarConciliacaoBtn',
      class: 'btn-primary',
      menu: []
    };
    this._btnToggleTree = {
      id: 'mainToolbar-conciliacao-toggleTree',
      type: 'button',
      class: 'btn-light',
      order: 1,
      caption: 'conciliacaoBancaria.hideTreeText',
      click: () => {
        this._toggleTree();
      }
    };
    this._btnMainPrintConciliacao = {
      id: 'mainToolbar-conciliacao-print-btn',
      type: 'button',
      visible: false,
      order: 3,
      class: 'btn-light',
      iconLeft: '<i class="fa fa-fw fa-print"></i>&nbsp;',
      caption: 'conciliacaoBancaria.print',
      click: () => this._printConciliacao()
    };
    this._btnMainNovaConciliacaoMenuItem = {
      id: 'mainToolbar-conciliacao-new',
      iconLeft: '<i class="fa fa-fw fa-plus-circle"></i>&nbsp;',
      caption: 'conciliacaoBancaria.novaConciliacao',
      click: () => {
        this._callNewEditConciliacaoModal(EConciliacaoBancariaMode.NEW, false);
      }
    };
    this._btnMainNovaConciliacaoFechoAnoMenuItem = {
      id: 'mainToolbar-conciliacao-new',
      iconLeft: '<i class="fa fa-fw fa-plus-circle"></i>&nbsp;',
      caption: 'conciliacaoBancaria.novaConciliacaoFechoAno',
      click: () => {
        this._callNewEditConciliacaoModal(EConciliacaoBancariaMode.NEW, true);
      }
    };
    this._btnMenuEditarConciliacaoMenuItem = {
      id: 'mainToolbar-conciliacao-edit',
      iconLeft: '<i class="fa fa-fw fa-pencil"></i>&nbsp;',
      caption: 'conciliacaoBancaria.editarConciliacao',
      disabled: true,
      click: () => {
        this._callNewEditConciliacaoModal(EConciliacaoBancariaMode.EDIT, this.conciliacao?.eConcialicaoFimDeAno);
      }
    };
    this._btnMenuRefreshConciliacaoMenuItem = {
      id: 'mainToolbar-conciliacao-refresh',
      iconLeft: '<i class="fa fa-fw fa-refresh"></i>&nbsp;',
      caption: 'conciliacaoBancaria.refreshConciliacao',
      disabled: true,
      click: () => this._reloadConciliacao()
    };
    this._btnMainPrintConciliacaoMenuItem = {
      id: 'mainToolbar-conciliacao-print',
      type: 'button',
      disabled: true,
      iconLeft: '<i class="fa fa-fw fa-print"></i>&nbsp;',
      caption: 'conciliacaoBancaria.printConciliacao',
      click: () => this._printConciliacao()
    };
    this._btnMainConfigContaPendentesBancoMenuItem = {
      id: 'mainToolbar-conciliacao-config-conta-pendentes-banco',
      type: 'button',
      disabled: true,
      iconLeft: '<i class="fa fa-fw fa-cog"></i>&nbsp;',
      caption: 'conciliacaoBancaria.configContaPendentesBanco',
      click: () => this._configContaPendentesBanco()
    };
    this._btnMainEliminaConciliacaoMenuItem = {
      id: 'mainToolbar-conciliacao-delete',
      iconLeft: '<i class="fa fa-fw fa-trash"></i>&nbsp;',
      caption: 'conciliacaoBancaria.eliminaConciliacao',
      disabled: true,
      click: () => this._eliminaConciliacaoBancaria()
    };
    this._btnMainEliminarTodasConciliacoesBancoMenuItem = {
      id: 'mainToolbar-conciliacao-delete-all',
      iconLeft: '<i class="fa fa-fw fa-trash"></i>&nbsp;',
      caption: 'conciliacaoBancaria.eliminarTodasConciliacoesBanco',
      disabled: true,
      click: () => this._eliminarTodasConciliacoesBanco()
    };
    this._btnMainMudarEstadoConcilidacaoMenuItem = {
      id: 'mainToolbar-conciliacao-change-state',
      iconLeft: '<i class="fa fa-fw fa-check"></i>&nbsp;',
      caption: 'conciliacaoBancaria.terminarConcilidacao',
      visible: false,
      disabled: true,
      click: () => this._terminarConcilidacao()
    };
    this._btnExtratoCGMostrarLinhasConciliadasToolbarItem = {
      id: 'extratoCGToolbar-mostrarLinhasConciliadas',
      type: 'custom',
      templateRef: undefined
    };
    this._btnExtratoBancoMostrarLinhasConciliadasToolbarItem = {
      id: 'extratoBancoToolbar-mostrarLinhasConciliadas',
      type: 'custom',
      templateRef: undefined
    };
    this._btnExtratoBancoRemoverTodasLinhasExtratoToolbarItem = {
      id: 'extratoBancoToolbar-removerTodasLinhasExtrato',
      type: 'button',
      class: 'btn-danger',
      align: 'right',
      iconLeft: '<i class="fa fa-trash"></i>',
      tooltip: {
        text: 'conciliacaoBancaria.removerTodasLinhasExtrato'
      },
      click: () => this.removeTodasLinhasExtratoBanco()
    };
    this._btnMainShortcutsToolbarItem = {
      id: 'mainToolbar-shortcuts',
      type: 'dropdown-split',
      caption: 'conciliacaoBancaria.btns.toolbarShortcuts',
      class: 'btn-light',
      order: 4,
      menu: []
    };
    this._btnMainPesqDocsMenuItem = {
      id: 'mainToolbar-pesq-docs',
      iconLeft: '<i class="fa fa-fw fa-search"></i>&nbsp;',
      caption: 'conciliacaoBancaria.btns.toolbarPesqDocs',
      click: () => this._viewPesqDocs()
    };
    this._btnMainPocNContaMenuItem = {
      id: 'mainToolbar-poc-nconta',
      iconLeft: '<i class="fa fa-fw fa-address-card-o"></i>&nbsp;',
      caption: 'conciliacaoBancaria.btns.toolbarBancoPoc',
      disabled: true,
      click: () => this._viewPocBanco()
    };
    this._btnExtratoBancoRemoverExtratoBancoSelecionadosMenuItem = {
      caption: 'Remover',
      iconLeft: '<i class="fa fa-trash"></i>&nbsp;',
      disabled: true,
      click: () => this._removerExtratoBancoSelecionados()
    };
    this._btnExtratoBancoAddDocsDoExtratoBancarioAContabilidadeMenuItem = {
      caption: 'conciliacaoBancaria.adicionarDocsDoExtratoBancarioAContabilidade',
      iconLeft: '<i class="fa fa-plus"></i>&nbsp;',
      disabled: true,
      click: () => this._addDocsDoExtratoBancarioAContabilidadeClick()
    };
    this._btnExtratoBancoDesassociarSelecionadosMenuItem = {
      caption: 'Desassociar',
      iconLeft: '<i class="fa fa-unlink"></i>&nbsp;',
      disabled: true,
      click: () => this._desassociarSelecionadosExtratoBancario()
    };
    this._btnExtratoBancoDropdownSelecionados = {
      id: 'extratoBanco-dropdown-selecionados',
      type: 'dropdown',
      groupId: 'extratoBancoToolbarSelecionados',
      caption: 'conciliacaoBancaria.btns.selecionados',
      class: 'btn-light',
      iconLeft: '<i class="fa fa-fw fa-check-square-o"></i>',
      menu: [this._btnExtratoBancoRemoverExtratoBancoSelecionadosMenuItem, this._btnExtratoBancoAddDocsDoExtratoBancarioAContabilidadeMenuItem, this._btnExtratoBancoDesassociarSelecionadosMenuItem]
    };
    this._btnExtratoBancoAddLancPrimeiraConciliacaoMenu = {
      caption: 'conciliacaoBancaria.addLancPrimeiraConciliacaoExtradoBancario', // actAddonlyBancolineExecute
      iconLeft: '<i class="fa fa-plus"></i>&nbsp;',
      disabled: true,
      click: () => this.showConciliacaoBancariaAddLinhaExtratoBancoModal(EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyBanco, EConciliacaoBancariaExtratoBancoTipo.PrimeiraConciliacao)
    };
    this._btnExtratoBancoAddLancamentoBancoMenu = {
      caption: 'conciliacaoBancaria.adicionarLancamentoBanco', // actInsertBancoExecute
      iconLeft: '<i class="fa fa-plus"></i>&nbsp;',
      disabled: true,
      click: () => this.showConciliacaoBancariaAddLinhaExtratoBancoModal(EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyBanco, EConciliacaoBancariaExtratoBancoTipo.LinhaBancoAdd)
    };
    this._btnExtratoBancoAddLinhaExtradoBancarioMenu = {
      caption: 'conciliacaoBancaria.addLinhaExtradoBancario', // actAddBancLineExecute
      iconLeft: '<i class="fa fa-plus"></i>&nbsp;',
      disabled: true,
      click: () => this.showConciliacaoBancariaAddLinhaExtratoBancoModal(EConciliacaoBancariaAddLinhaExtratoBancoModalMode.Both, EConciliacaoBancariaExtratoBancoTipo.Undefined)
    };
    this._btnExtratoBancoAdd = {
      id: 'extratoBanco-dropdown-add',
      type: 'dropdown',
      class: 'btn-light',
      iconLeft: '<i class="fa fa-fw fa-plus"></i>',
      menu: [this._btnExtratoBancoAddLancPrimeiraConciliacaoMenu, this._btnExtratoBancoAddLancamentoBancoMenu, this._btnExtratoBancoAddLinhaExtradoBancarioMenu]
    };
    this._btnExtratoBancoImportaDocsPendentesBancoConcilAntiga = {
      id: 'extratoBanco-importaDocsPendentesBancoConcilAntiga',
      type: 'button',
      class: 'btn-light',
      visible: false,
      iconLeft: '<i class="fa fa-download"></i>',
      tooltip: {
        text: 'conciliacaoBancaria.importaDocsPendentesBancoConcilAntiga'
      },
      click: () => this._importaDocsPendentesBancoConcilAntiga()
    };
    this._btnExtratoBancoImportarExtratoBancario = {
      id: 'extratoBanco-importarExtratoBancario',
      type: 'button',
      class: 'btn-light',
      iconLeft: '<i class="fa fa-download"></i>',
      tooltip: {
        text: 'conciliacaoBancaria.importarExtratoBancario'
      },
      click: () => this._importartExtratoBancario()
    };
    this._btnExtratoBancoConciliarAutomaticamente = {
      id: 'extratoBanco-conciliarAutomaticamente',
      type: 'button',
      class: 'btn-light',
      iconLeft: '<i class="fa fa-magic"></i>',
      tooltip: {
        text: 'conciliacaoBancaria.conciliarAutomaticamente'
      },
      click: () => this._conciliarAutomaticamente()
    };
    this._btnExtratoCGAddLinhaPrimeiraConcilExtratoCG = {
      caption: 'conciliacaoBancaria.adicionarLinhaPrimeiraConcilExtratoCG',
      disabled: true,
      iconLeft: '<i class="fa fa-plus"></i>&nbsp;',
      click: () => this._addLinhaPrimeiraConcilExtratoCG()
    };
    this._btnExtratoCGAddDocsPrimeiraConcilExtratoCG = {
      caption: 'conciliacaoBancaria.adicionarDocsPrimeiraConcilExtratoCG',
      iconLeft: '<i class="fa fa-plus"></i>&nbsp;',
      click: () => this._addDocsPrimeiraConcilExtratoCG()
    };
    this._btnExtratoCGAdd = {
      id: 'extratoCG-dropdown-add',
      type: 'dropdown',
      class: 'btn-light',
      iconLeft: '<i class="fa fa-fw fa-plus"></i>',
      menu: [this._btnExtratoCGAddLinhaPrimeiraConcilExtratoCG, this._btnExtratoCGAddDocsPrimeiraConcilExtratoCG]
    };
    this._btnExtratoCGImportaDocsPendentesConcilAntiga = {
      id: 'extratoCGToolbar-importaDocsPendentesConcilAntiga',
      type: 'button',
      class: 'btn-light',
      disabled: true,
      visible: false,
      iconLeft: '<i class="fa fa-download"></i>',
      tooltip: {
        text: 'conciliacaoBancaria.importaDocsPendentesConcilAntiga'
      },
      click: () => this._importaDocsPendentesConcilAntiga()
    };
    this._btnExtratoCGExtratoContaPendentes = {
      id: 'extratoCGToolbar-extratoContaPendentes',
      type: 'button',
      class: 'btn-light',
      iconLeft: '<i class="fa fa-book"></i>',
      tooltip: {
        text: 'conciliacaoBancaria.extratoContaPendentes'
      },
      click: () => this._viewPesqDocs()
    };
    this._btnExtratoCGAssociarTodoExtratoCGAoExtradoDoBancoToolbarItem = {
      id: 'extratoCGToolbar-associarTodoExtratoCGAoExtradoDoBanco',
      type: 'button',
      class: 'btn-success',
      align: 'right',
      iconLeft: '<i class="fa fa-angle-double-right"></i>',
      tooltip: {
        text: 'conciliacaoBancaria.associarTodoExtratoCGAoExtradoDoBanco'
      },
      click: () => this.associarTodoExtratoCGAoExtradoDoBanco()
    };
    this._btnExtratoCGRemoverLinhasMenuItem = {
      caption: 'global.btn.remove',
      iconLeft: '<i class="fa fa-trash"></i>&nbsp;',
      disabled: true,
      tooltip: {
        text: 'conciliacaoBancaria.removerLinhasSelecionadasTooltip'
      },
      click: () => this._removeExtratoCGSelecionados()
    };
    this._btnExtratoCGAssociarSelecionadosMenuItem = {
      caption: 'Associar',
      iconLeft: '<i class="fa fa-link"></i>&nbsp;',
      disabled: true,
      click: () => this._associarSelecionados()
    };
    this._btnExtratoCGDesassociarSelecionadosMenuItem = {
      caption: 'Desassociar',
      iconLeft: '<i class="fa fa-unlink"></i>&nbsp;',
      disabled: true,
      click: () => this._desassociarSelecionadosExtratoCG()
    };
    this._btnExtratoCGLinkSelecionaodsMenuItem = {
      caption: 'Associar com linhas selecionados no extrado do banco',
      iconLeft: '<i class="fa fa-link"></i>&nbsp;',
      disabled: true,
      tooltip: {
        container: 'body',
        text: 'conciliacaoBancaria.associarLinhasDosExtratosEmAmbosLadosTooltip'
      },
      click: () => this._connectLines()
    };
    this._cacheExtPocList = [];
    this._cacheConcilBancoList = [];
    this._fromFocus = false;
    this._internalExtPocSelectedItem = undefined;
    this.countSelecionadosCG = 0;
    this.countSelecionadosBanco = 0;
    this._subscriptionConciliacaoBancariaOptions = this._conciliacaoBancariaService.getOptions().subscribe((configOptions: TConfigOptions<boolean, IConciliacaoBancariaConfigOptions>) => {
      this.colDescricaoVisible = configOptions.get('showColumnDescricao').value;
      this.colNDocExtVisible = configOptions.get('showColumnNDocExt').value;
      this.colTipoVisible = configOptions.get('showColumnTipo').value;
      this.colSaldoVisible = configOptions.get('showColumnSaldo').value;
      this.colStampUpdateVisible = configOptions.get('showColumnStampUpdate').value;
      this.colDataLancVisible = configOptions.get('showColumnDataLanc').value;
      this.colDataVisible = configOptions.get('showColumnData').value;
      this.colValorSemDCVisible = configOptions.get('showColumnValorSemDC').value;
      this.colBancoValorSemDCVisible = configOptions.get('showColumnBancoValorSemDC').value;
      this.colDCVisible = configOptions.get('showColumnDC').value;
      this.colBancoDCVisible = configOptions.get('showColumnBancoDC').value;
    });
    this.blockedByUser = false;
    this.activeTab = MAIN_TAB_ID;
    this.gdocCallback = {};
    this.gdocParams = '';
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.vScrollHeight = this._getVScrollHeight();
    this._btnMainConciliacaoDropdownToolbarItem.menu.push(this._btnMainNovaConciliacaoMenuItem);
    this._btnMainConciliacaoDropdownToolbarItem.menu.push(this._btnMenuEditarConciliacaoMenuItem);
    this._btnMainConciliacaoDropdownToolbarItem.menu.push(this._btnMenuRefreshConciliacaoMenuItem);
    this._btnMainConciliacaoDropdownToolbarItem.menu.push(this._btnMainPrintConciliacaoMenuItem);
    this._btnMainConciliacaoDropdownToolbarItem.menu.push(this._btnMainMudarEstadoConcilidacaoMenuItem);
    this._btnMainConciliacaoDropdownToolbarItem.menu.push(this._btnMainConfigContaPendentesBancoMenuItem);
    this._btnMainConciliacaoDropdownToolbarItem.menu.push({type: 'divider'});
    this._btnMainConciliacaoDropdownToolbarItem.menu.push(this._btnMainEliminaConciliacaoMenuItem);
    this._btnMainConciliacaoDropdownToolbarItem.menu.push(this._btnMainEliminarTodasConciliacoesBancoMenuItem);
    this._btnMainConciliacaoDropdownToolbarItem.menu.push(this._btnMainNovaConciliacaoFechoAnoMenuItem);

    this._btnMainShortcutsToolbarItem.menu.push(this._btnMainPocNContaMenuItem);
    this._btnMainShortcutsToolbarItem.menu.push(this._btnMainPesqDocsMenuItem);

    this.toolbar.addButton(this._btnToggleTree);
    this.toolbar.addButton(this._btnMainConciliacaoDropdownToolbarItem);
    this.toolbar.addButton(this._btnMainPrintConciliacao);
    this.toolbar.addButton(this._btnMainShortcutsToolbarItem);

    const toolbarInstanceExtratoCG: IPlToolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceIdExtratoCG);
    toolbarInstanceExtratoCG.setItems([
      {
        id: 'extratoCGToolbar-selecionados',
        type: 'dropdown',
        groupId: 'extratoCGToolbarSelecionados',
        caption: 'conciliacaoBancaria.btns.selecionados',
        class: 'btn-light',
        iconLeft: '<i class="fa fa-fw fa-check-square-o"></i>',
        menu: [this._btnExtratoCGRemoverLinhasMenuItem, this._btnExtratoCGAssociarSelecionadosMenuItem, this._btnExtratoCGDesassociarSelecionadosMenuItem, this._btnExtratoCGLinkSelecionaodsMenuItem]
      },
      this._btnExtratoCGAdd,
      this._btnExtratoCGImportaDocsPendentesConcilAntiga,
      this._btnExtratoCGExtratoContaPendentes,
      this._btnExtratoCGMostrarLinhasConciliadasToolbarItem,
      this._btnExtratoCGAssociarTodoExtratoCGAoExtradoDoBancoToolbarItem
    ]);
    const toolbarInstanceExtratoBanco: IPlToolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceIdExtratoBanco);
    toolbarInstanceExtratoBanco.setItems([
      this._btnExtratoBancoDropdownSelecionados,
      this._btnExtratoBancoAdd,
      this._btnExtratoBancoImportaDocsPendentesBancoConcilAntiga,
      this._btnExtratoBancoImportarExtratoBancario,
      this._btnExtratoBancoConciliarAutomaticamente,
      this._btnExtratoBancoMostrarLinhasConciliadasToolbarItem,
      this._btnExtratoBancoRemoverTodasLinhasExtratoToolbarItem
    ]);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.selectedBancoCabItem) {
      this._conciliacaoBancariaService.cancelJob(this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo);
    }
    this._subscriptionConciliacaoBancariaOptions.unsubscribe();
    this._plToolbarService.unRegisterInstance(this.toolbarInstanceIdExtratoBanco);
    this._plToolbarService.unRegisterInstance(this.toolbarInstanceIdExtratoCG);
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    if (this.toolbarInstanceIdExtratoCG) {
      this._plToolbarService.unRegisterInstance(this.toolbarInstanceIdExtratoCG);
    }
    this.toolbarInstanceIdExtratoCG = `${this.instanceName}-${TOOLBAR_INSTANCE_ID_EXTRATO_CG}`;
    if (this.toolbarInstanceIdExtratoBanco) {
      this._plToolbarService.unRegisterInstance(this.toolbarInstanceIdExtratoBanco);
    }
    this.toolbarInstanceIdExtratoBanco = `${this.instanceName}-${TOOLBAR_INSTANCE_ID_EXTRATO_BANCO}`;
  }

  public changedSelectedNode(item: ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>): void {
    this.selectedNode = item;
    this.selectedBancoCabItem = undefined;
    this._btnMainPocNContaMenuItem.disabled = !this.selectedNode;
    if (!this.selectedNode) {
      return;
    }
    if (this.selectedNode.nodeValue.nodeType === EConciliacaoBancariaBancosTreeViewerNodeType.Item) {
      this.selectedBancoCabItem = this.selectedNode.nodeValue;
      this.conciliacaoTitle = `${this.selectedBancoCabItem.nConta} - ${this.selectedBancoCabItem.nomeNConta} : ${this.selectedBancoCabItem.nomeConciliacao}`;
      this._btnMainEliminaConciliacaoMenuItem.disabled = false;
      this._btnMainMudarEstadoConcilidacaoMenuItem.disabled = false;
      this._btnMainPrintConciliacaoMenuItem.disabled = false;
      this._btnMainConfigContaPendentesBancoMenuItem.disabled = false;
      this._btnMainPrintConciliacao.visible = true;
      this._btnMainEliminarTodasConciliacoesBancoMenuItem.disabled = false;
      this._toggleTree();
      this._reloadConciliacao()
        .then(() => timeout())
        .then(() => {
          if (this.activeTab === ATTACHMENTS_TAB_ID) {
            this.initGDoc();
          } else {
            this._focusFilterInput();
          }
        });
    } else {
      this._btnMainEliminaConciliacaoMenuItem.disabled = true;
      this._btnMainMudarEstadoConcilidacaoMenuItem.disabled = true;
      this._btnMenuEditarConciliacaoMenuItem.disabled = true;
      this._btnMenuRefreshConciliacaoMenuItem.disabled = true;
      this._btnMainPrintConciliacaoMenuItem.disabled = true;
      this._btnMainConfigContaPendentesBancoMenuItem.disabled = true;
      this._btnMainPrintConciliacao.visible = false;
      this._btnMainEliminarTodasConciliacoesBancoMenuItem.disabled = true;
    }
  }

  public associarTodoExtratoCGAoExtradoDoBanco(): Promise<void> {
    this.lockPromise = this._conciliacaoBancariaService
      .associarTodoExtratoCGAoExtradoDoBanco(this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then(() => {
        return this._refreshConciliacao();
      })
      .catch((reason: HttpErrorResponse) => {
        this._handleRequestError(reason);
      })
      .finally(() => {
        this.lockPromise = undefined;
      });
    return this.lockPromise;
  }

  public removeTodasLinhasExtratoBanco(): Promise<void> {
    this.lockPromise = this._cgModalService
      .showOkCancel('conciliacaoBancaria.removeTodasLinhasExtratoBancoTitle', 'conciliacaoBancaria.removeTodasLinhasExtratoBancoMsg', {size: 'md'})
      .then(() => {
        return this._conciliacaoBancariaService
          .removeTodasLinhasExtratoBanco(this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
          .then(() => this._refreshConciliacao())
          .catch((reason: HttpErrorResponse) => {
            this._handleRequestError(reason);
          });
      })
      .finally(() => {
        this.lockPromise = undefined;
      });
    return this.lockPromise;
  }

  public associarExtrato(item: IConciliacaoBancariaExtPocItem): Promise<void> {
    item._state = EConciliacaoBancariaRecordState.PENDING;
    if (this._fromFocus) {
      this._fromFocus = false;
      this._focusFilterInput();
    }
    return this._conciliacaoBancariaService
      .associarExtrato(item.nLanc, this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then((response: IRestCmdConciliacaoBancariaAssociarExtrato) => {
        if (response.concilLinItem) {
          response.concilLinItem._selected = false;
          this._cacheConcilBancoList.unshift(response.concilLinItem);
          this._updateConcilBancoList(true);
          this._updateBancoSaldoFinalCalc();
          const index: number = this._cacheExtPocList.findIndex((extPocItem: IConciliacaoBancariaExtPocItem) => extPocItem.nLanc === item.nLanc);
          if (index > -1) {
            this._cacheExtPocList[index]._selected = false;
            this._cacheExtPocList[index].estadoConciliacao = EConciliacaoBancariaExtPocEstado.Conciliado;
            this._cacheExtPocList[index].estadoConciliacaoBool = true;
            this._updateExtPocList();
          }
        }
        if (item._selected) {
          this.valorTotalSelecionadoCG -= item.valorSemDCInv;
        }
      })
      .catch((reason: HttpErrorResponse) => {
        this._handleRequestError(reason);
      })
      .finally(() => {
        item._state = EConciliacaoBancariaRecordState.IDLE;
        this._refreshConciliacao();
      });
  }

  public showConciliacaoBancariaAddLinhaExtratoBancoModal(
    mode: EConciliacaoBancariaAddLinhaExtratoBancoModalMode = EConciliacaoBancariaAddLinhaExtratoBancoModalMode.Both,
    tipo: EConciliacaoBancariaExtratoBancoTipo = EConciliacaoBancariaExtratoBancoTipo.None
  ): Promise<IConciliacaoBancariaLinItem | IConciliacaoBancariaNovaLinhaExtratoBancoModal> {
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaAddLinhaExtratoBancoModalComponent, {size: 'lg'});
    const componentInstance: ConciliacaoBancariaAddLinhaExtratoBancoModalComponent = modalInstance.componentInstance;
    if (mode === EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyBanco) {
      if (tipo === EConciliacaoBancariaExtratoBancoTipo.PrimeiraConciliacao) {
        componentInstance.modalTitle = 'conciliacaoBancaria.addLancPrimeiraConciliacaoExtradoBancario';
      } else if (tipo === EConciliacaoBancariaExtratoBancoTipo.LinhaBancoAdd) {
        componentInstance.modalTitle = 'conciliacaoBancaria.adicionarLinhaExtratoBancario';
      }
    } else if (mode === EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyContabilidade) {
      componentInstance.modalTitle = 'conciliacaoBancaria.adicionarDocsDoExtratoBancarioAContabilidade';
      componentInstance.model = {
        concilBancoLinID: '',
        contaBanco: this.selectedBancoCabItem.nConta,
        data: this.conciliacao.extratoBanco.dataFim,
        dc: EDebitoCredito.Credito,
        descricao: '',
        valor: 0,
        valorSemDC: 0,
        descritivo: this.conciliacao.descritivo,
        diario: this.conciliacao.diario,
        nContaLanc: `${this.conciliacao.radicalContaContrapartida}${this.selectedBancoCabItem.nConta}`,
        radical: `${this.conciliacao.radicalContaContrapartida}${this.selectedBancoCabItem.nConta}`
      };
    } else {
      componentInstance.modalTitle = 'conciliacaoBancaria.addLinhaExtradoBancario';
    }

    componentInstance.modalMode = mode;
    componentInstance.nContaBanco = this.selectedBancoCabItem.nConta;
    componentInstance.periodo = this.selectedBancoCabItem.periodo;
    componentInstance.conciliacaoBancaria = this.conciliacao;
    componentInstance.tipo = tipo;

    return modalInstance.result.then((result: IConciliacaoBancariaLinItem | IConciliacaoBancariaNovaLinhaExtratoBancoModal) => {
      if (mode === EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyBanco) {
        const linha: IConciliacaoBancariaLinItem = <IConciliacaoBancariaLinItem>result;
        linha._selected = false;
        this._cacheConcilBancoList.push(linha);
        this._updateConcilBancoList();
        this._updateBancoSaldoFinalCalc();
        this.treeCallback.reload();
      } else if (mode === EConciliacaoBancariaAddLinhaExtratoBancoModalMode.Both) {
        this._refreshConciliacao();
        this.treeCallback.reload();
      } else {
        const model: IConciliacaoBancariaNovaLinhaExtratoBancoModal = <IConciliacaoBancariaNovaLinhaExtratoBancoModal>result;
        this.conciliacao.diario = model.diario;
        this.conciliacao.descritivo = model.descritivo;
      }
      return result;
    });
  }

  public onNotaChanged(item: IConciliacaoBancariaLinItem): Promise<void> {
    return this._conciliacaoBancariaService.atualizaNotasLinhaExtratoBanco(item).then(() => undefined);
  }

  public removeLinhaExtratoBanco(item: IConciliacaoBancariaLinItem): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.warning', 'conciliacaoBancaria.messages.deleteSelectedLine').then(() => {
      return this._conciliacaoBancariaService
        .removeLinhaExtratoBanco([item.concilBancoLinID], this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
        .then(() => this._refreshConciliacao())
        .catch((reason: HttpErrorResponse) => {
          this._handleRequestError(reason);
        });
    });
  }

  public onMostrarLinhasConciliadasExtratoCG(value: boolean): void {
    this.mostrarLinhasConciliadasExtratoCG = value;
    this._updateExtPocList();
  }

  public onMostrarLinhasConciliadasExtratoBanco(value: boolean): void {
    this.mostrarLinhasConciliadasExtratoBanco = value;
    this._updateConcilBancoList();
  }

  public onExpPocListFilterChange(filterText: string): void {
    this.expPocListValueFilter = filterText;
    this._findAndSelect(this.expPocListValueFilter);
  }

  public extratoBancoToggleSelectAll(value: boolean): void {
    this.extratoBancoSelectAll = value;
    this.valorTotalSelecionadoBanco = 0;
    this.conciliacao.extratoBanco.concilBancoList.forEach((item) => {
      item._selected = value;
      if (value) {
        this.valorTotalSelecionadoBanco += item.valorSemDC;
      }
    });
    this.countSelecionadosBanco = value ? this.conciliacao.extratoBanco.concilBancoList.length : 0;
    this._updateExtratoBancoToolbarActions();
  }

  public extratoCGToggleSelectAll(value: boolean): void {
    this.extratoCGSelectAll = value;
    this.valorTotalSelecionadoCG = 0;
    this.conciliacao.extratoCG.extPocList.forEach((item) => {
      item._selected = value;
      if (value) {
        this.valorTotalSelecionadoCG += item.valorSemDCInv;
      }
    });
    this.countSelecionadosCG = value ? this.conciliacao.extratoCG.extPocList.length : 0;
    this._updateExtratoCGToolbarActions();
  }

  public onExtratoBancoItemSelect(extrato: IConciliacaoBancariaLinItem, selecaoExtratoEnum: EConciliacaoBancariaSelecaoExtrato = this.selecaoExtratoEnum.None): void {
    extrato._selected = true;
    this.extratoBancoSelectAll = false;
    this.valorTotalSelecionadoBanco = this._round(this.valorTotalSelecionadoBanco + extrato.valorSemDC);
    this.countSelecionadosBanco += 1;
    this._updateExtratoBancoToolbarActions();
    this._updateExtratoCGToolbarActions();
    if (selecaoExtratoEnum === this.selecaoExtratoEnum.Banco) {
      const extPoc = this._getSelectedExtPoc(extrato);
      if (extPoc) {
        this._selectExtPoc(extPoc, selecaoExtratoEnum);
      }
    }
  }

  public onExtratoBancoItemDeselect(extrato: IConciliacaoBancariaLinItem, selecaoExtratoEnum: EConciliacaoBancariaSelecaoExtrato = this.selecaoExtratoEnum.None): void {
    extrato._selected = false;
    this.extratoBancoSelectAll = false;
    this.valorTotalSelecionadoBanco = this._round(this.valorTotalSelecionadoBanco - extrato.valorSemDC);
    this.countSelecionadosBanco -= 1;
    this._updateExtratoBancoToolbarActions();
    this._updateExtratoCGToolbarActions();

    if (selecaoExtratoEnum === this.selecaoExtratoEnum.Banco) {
      const extPoc = this._getSelectedExtPoc(extrato);
      if (extPoc) {
        this._deselectExtPoc(extPoc, selecaoExtratoEnum);
        this._cacheExtPocList.forEach((item) => {
          if (item.valorSemDCInv === extrato.valorSemDC) {
            item._selected = false;
          }
        });
      }
    }
  }

  public onExtPocItemSelect(extrato: IConciliacaoBancariaExtPocItem, selecaoExtratoEnum: EConciliacaoBancariaSelecaoExtrato = this.selecaoExtratoEnum.None): void {
    extrato._selected = true;
    this.extratoCGSelectAll = false;
    this.valorTotalSelecionadoCG = this._round(this.valorTotalSelecionadoCG + extrato.valorSemDCInv);
    this.countSelecionadosCG += 1;
    this._updateExtratoCGToolbarActions();
    if (selecaoExtratoEnum === this.selecaoExtratoEnum.Contabilidade) {
      const extratoBanco = this._getSelectedExtratoBanco(extrato);
      if (extratoBanco) {
        this._selectExtratoBanco(extratoBanco, selecaoExtratoEnum);
      }
    }
  }

  public onExtPocItemDeselect(extrato: IConciliacaoBancariaExtPocItem, selecaoExtratoEnum: EConciliacaoBancariaSelecaoExtrato = this.selecaoExtratoEnum.None): void {
    extrato._selected = false;
    this.extratoCGSelectAll = false;
    this.valorTotalSelecionadoCG = this._round(this.valorTotalSelecionadoCG - extrato.valorSemDCInv);
    this.countSelecionadosCG -= 1;
    this._updateExtratoCGToolbarActions();

    if (selecaoExtratoEnum === this.selecaoExtratoEnum.Contabilidade) {
      const extratoBanco = this._getSelectedExtratoBanco(extrato);
      if (extratoBanco) {
        this._deselectExtratoBanco(extratoBanco, selecaoExtratoEnum);
        this._cacheConcilBancoList.forEach((item) => {
          if (item.valorSemDC === extrato.valorSemDCInv) {
            item._selected = false;
          }
        });
      }
    }
  }

  public getSaldoString(value: number, invert: boolean = false): string {
    const dcString: 'DEB' | 'CRE' = value >= 0 ? (invert ? 'DEB' : 'CRE') : invert ? 'CRE' : 'DEB';
    return `${this._plI18nService.formatNumber(Math.abs(value), this._decimais)} <small>${dcString}</small>`;
  }

  public extratoCGViewLanc(extrato: IConciliacaoBancariaExtPocItem): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaViewDocModalComponent, {size: 'xl'});
    const componentInstance: ConciliacaoBancariaViewDocModalComponent = modalInstance.componentInstance;
    componentInstance.expPocCabId = extrato.extPocCabID;
    return modalInstance.result.then((isChanged: boolean) => {
      if (isChanged) {
        this._reloadConciliacao();
      }
    });
  }

  public getExtPocRowClass(item: IConciliacaoBancariaExtPocItem): string {
    const cssClass: Array<string> = [];
    if (item._state === this.recordState.PENDING) {
      cssClass.push('table-locked');
    }

    if (item.linhaPrimeiraConciliacao || item.linhaAdicionada) {
      if (!item.estadoConciliacaoBool) {
        cssClass.push(this.legends[LINHA_PRIMEIRA_CONCILIACAO_NAO_CONCILIADA].cssClass);
      } else {
        cssClass.push(this.legends[LINHA_PRIMEIRA_CONCILIACAO_CONCILIADA].cssClass);
      }
    } else if (!item.eDesteExtrato) {
      cssClass.push(this.legends[LINHA_CONCILIADA_PERIODO_POSTERIOR].cssClass);
    } else if (item.estadoConciliacaoBool) {
      cssClass.push(this.legends[LINHA_CONCILIADA].cssClass);
    } else if (item._selected) {
      cssClass.push(SELECTED_ROW_CSS_CLASS);
    } else {
      cssClass.push(this.legends[LINHA_NAO_CONCILIADA].cssClass);
    }

    return cssClass.join(' ');
  }

  public getExtratoBancoRowClass(item: IConciliacaoBancariaLinItem): string {
    const cssClass: Array<string> = [];
    if (!item.eDesteExtrato) {
      cssClass.push(this.legends[LINHA_CONCILIADA_PERIODO_POSTERIOR].cssClass);
    } else if (item.tipo === EConciliacaoBancariaExtratoBancoTipo.PrimeiraConciliacao) {
      if (item.ligadoALancamento) {
        cssClass.push(this.legends[LINHA_PRIMEIRA_CONCILIACAO_CONCILIADA].cssClass);
      } else {
        cssClass.push(this.legends[LINHA_PRIMEIRA_CONCILIACAO_NAO_CONCILIADA].cssClass);
      }
    } else if (item.ligadoALancamento) {
      cssClass.push(this.legends[LINHA_CONCILIADA].cssClass);
    } else if (item._selected) {
      cssClass.push(SELECTED_ROW_CSS_CLASS);
    } else {
      cssClass.push(this.legends[LINHA_NAO_CONCILIADA].cssClass);
    }
    return cssClass.join(' ');
  }

  public getExtratoBancoRowId(extrato: IConciliacaoBancariaLinItem): string {
    return extrato.concilBancoLinID;
  }

  public getExtPocRowId(extrato: IConciliacaoBancariaExtPocItem): string {
    return extrato.nLanc;
  }

  public isDisabledExtratoCGEditLanc(item: IConciliacaoBancariaExtPocItem): boolean {
    return !item || !this.conciliacao?.podeEditarConciliacao || !item.linhaPrimeiraConciliacao || item.estadoConciliacaoBool;
  }

  public isDisabledAssociarExtrato(item: IConciliacaoBancariaExtPocItem): boolean {
    return !item || !this.conciliacao?.podeEditarConciliacao || item.estadoConciliacaoBool;
  }

  public isDisabledExtratoCGRemoveLinha(item: IConciliacaoBancariaExtPocItem): boolean {
    return !item || !this.conciliacao?.podeEditarConciliacao || !item.linhaPrimeiraConciliacao;
  }

  public isDisabledExtratoCGDesassociarLinha(item: IConciliacaoBancariaExtPocItem): boolean {
    return !item || !this.conciliacao?.podeEditarConciliacao || !item.estadoConciliacaoBool;
  }

  public isDisabledExtratoCGViewLanc(item: IConciliacaoBancariaExtPocItem): boolean {
    return !item?.extPocCabID || item.extPocCabID === EMPTY_GUID;
  }

  public extratoCGEditLanc(item: IConciliacaoBancariaExtPocItem): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaAddLinhaExtratoCGModalComponent, {size: 'md'});
    const componentInstance: ConciliacaoBancariaAddLinhaExtratoCGModalComponent = modalInstance.componentInstance;
    componentInstance.nConta = this.selectedBancoCabItem.nConta;
    componentInstance.periodo = this.selectedBancoCabItem.periodo;
    componentInstance.date = moment(this.conciliacao.extratoBanco.dataInicio).subtract(1, 'days');
    componentInstance.editModel = item;
    return modalInstance.result.then((newData: IConciliacaoBancariaExtPocItem) => {
      newData = {
        ...newData,
        _selected: false,
        valorDisplay: this._plI18nService.formatNumber(newData.valorSemDCInv, this._decimais),
        valorSemDCDisplay: this._plI18nService.formatNumber(newData.valor, this._decimais)
      };
      const index = this._cacheExtPocList.findIndex((obj) => obj.nLanc === item.nLanc);
      this._cacheExtPocList[index] = newData;
      this._updateExtPocList();
    });
  }

  public extratoCGRemoveLinha(item: IConciliacaoBancariaExtPocItem): Promise<void> {
    item._state = EConciliacaoBancariaRecordState.PENDING;
    return this._conciliacaoBancariaService
      .removeLinhaExtratoCG([item.nLanc], this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then((response) => {
        let index = this._cacheExtPocList.findIndex((extPocItem) => extPocItem.nLanc === item.nLanc);
        if (index > -1) {
          this._cacheExtPocList.splice(index, 1);
          this._updateExtPocList();
        }

        response.concilBancoLinIDs.forEach((concilBancoLinID) => {
          index = this._cacheConcilBancoList.findIndex((concilBancoItem) => concilBancoItem.concilBancoLinID === concilBancoLinID);
          if (index > -1) {
            this._cacheConcilBancoList.splice(index, 1);
            this._updateConcilBancoList();
          }
        });
      })
      .catch((reason: HttpErrorResponse) => {
        this._handleRequestError(reason);
      })
      .finally(() => {
        this._reloadConciliacao();
        item._state = EConciliacaoBancariaRecordState.IDLE;
      });
  }

  public extratoCGDesassociarLinha(item: IConciliacaoBancariaExtPocItem): Promise<void> {
    if (this._fromFocus) {
      this._focusFilterInput();
      this._fromFocus = false;
    }
    item._state = EConciliacaoBancariaRecordState.PENDING;
    return this._conciliacaoBancariaService
      .desassociarExtratoCG([item.nLanc], this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then(() => this._refreshConciliacao(false))
      .catch((reason: HttpErrorResponse) => {
        this._handleRequestError(reason);
      })
      .finally(() => {
        item._state = EConciliacaoBancariaRecordState.IDLE;
      });
  }

  public isDisabledExtratoBancoEditLanc(item: IConciliacaoBancariaLinItem): boolean {
    return (
      !item ||
      !this.conciliacao?.podeEditarConciliacao ||
      item.ligadoALancamento ||
      (item.tipo !== EConciliacaoBancariaExtratoBancoTipo.PrimeiraConciliacao && item.tipo !== EConciliacaoBancariaExtratoBancoTipo.LinhaBancoAdd)
    );
  }

  public isDisabledExtratoBancoRemoveLinha(item: IConciliacaoBancariaLinItem): boolean {
    return !item || !this.conciliacao?.podeEditarConciliacao;
  }

  public isDisabledExtratoBancoDesassociarLinha(item: IConciliacaoBancariaLinItem): boolean {
    return !item || !this.conciliacao?.podeEditarConciliacao || !item.ligadoALancamento;
  }

  public isDisabledExtratoBancoViewLanc(item: IConciliacaoBancariaLinItem): boolean {
    return !item || isEmpty(item.nLanc);
  }

  public extratoBancoViewLanc(item: IConciliacaoBancariaLinItem): void {
    const found: IConciliacaoBancariaExtPocItem = this._cacheExtPocList.find((extPocItem: IConciliacaoBancariaExtPocItem) => extPocItem.nLanc === item.nLanc);
    if (found) {
      const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaViewDocModalComponent, {size: 'xl'});
      const componentInstance: ConciliacaoBancariaViewDocModalComponent = modalInstance.componentInstance;
      componentInstance.expPocCabId = found.extPocCabID;
      modalInstance.result.then((isChanged: boolean) => {
        if (isChanged) {
          this._reloadConciliacao();
        }
      });
    } else {
      this._plAlertService.error(this._translateService.instant('conciliacaoBancaria.extPocItemNotFound'));
    }
  }

  public extratoBancoDesassociarLinha(item: IConciliacaoBancariaLinItem): Promise<void> {
    item._state = EConciliacaoBancariaRecordState.PENDING;
    return this._conciliacaoBancariaService
      .desassociarExtratoBanco([item.concilBancoLinID], this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then(() => this._refreshConciliacao(false))
      .catch((reason: HttpErrorResponse) => {
        this._handleRequestError(reason);
      })
      .finally(() => {
        item._state = EConciliacaoBancariaRecordState.IDLE;
      });
  }

  public extratoBancoEditLanc(item: IConciliacaoBancariaLinItem): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaAddLinhaExtratoBancoModalComponent, {size: 'lg'});
    const componentInstance: ConciliacaoBancariaAddLinhaExtratoBancoModalComponent = modalInstance.componentInstance;
    componentInstance.modalTitle = 'conciliacaoBancaria.editLinhaExtradoBancarioModalTitle';
    componentInstance.modalMode = EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyBanco;
    componentInstance.nContaBanco = this.selectedBancoCabItem.nConta;
    componentInstance.periodo = this.selectedBancoCabItem.periodo;
    componentInstance.conciliacaoBancaria = this.conciliacao;
    componentInstance.model = {
      concilBancoLinID: item.concilBancoLinID,
      contaBanco: this.selectedBancoCabItem.nConta,
      data: this.conciliacao.extratoBanco.dataFim,
      dc: item.dc,
      descricao: item.descricao,
      valor: item.valor,
      valorSemDC: item.valorSemDC,
      descritivo: -1,
      diario: -1,
      nContaLanc: '',
      radical: ''
    };
    return modalInstance.result.then((model: IConciliacaoBancariaNovaLinhaExtratoBancoModal) => {
      const index = this._cacheConcilBancoList.findIndex((obj) => obj.concilBancoLinID === item.concilBancoLinID);
      if (index !== -1) {
        merge(this._cacheConcilBancoList[index], {
          valor: model.valor,
          dc: model.dc,
          data: model.data,
          descricao: model.descricao,
          valorSemDC: model.valorSemDC
        });
        this._updateBancoSaldoFinalCalc();
      }
    });
  }

  public isDisabledAddLinhaExtratoBancarioAContabilidade(item: IConciliacaoBancariaLinItem): boolean {
    return !item || !this.conciliacao?.podeEditarConciliacao || item.ligadoALancamento;
  }

  public addLinhaExtratoBancarioAContabilidade(item: IConciliacaoBancariaLinItem): Promise<void> {
    return this._addDocsDoExtratoBancarioAContabilidade([item.concilBancoLinID]);
  }

  public verDetalhesSaldo(): void {
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaSaldoDetalhesModalComponent, {size: 'md'});
    const componentInstance: ConciliacaoBancariaSaldoDetalhesModalComponent = modalInstance.componentInstance;
    componentInstance.nConta = this.selectedBancoCabItem.nConta;
    componentInstance.periodo = this.selectedBancoCabItem.periodo;
    componentInstance.decimais = this._decimais;
    componentInstance.conciliado = this.conciliacao.extratoBanco.estado === EConciliacaoBancariaEstadoConciliacaoCab.Conciliado;
  }

  public onExtratoRowClick(event: MouseEvent, extrato: IConciliacaoBancariaExtPocItem): void {
    const target: HTMLElement = <HTMLElement>event.target;
    const td: HTMLElement = target.classList.contains('conciliacaoBancaria-table-cell') ? target : target.closest('.conciliacaoBancaria-table-cell');
    const timeoutTime = 250;
    if (this._clickTimeout) {
      // Second click detected
      clearTimeout(this._clickTimeout);
      this._clickTimeout = null;
      if (td && !td.classList.contains('col-action') && !extrato._selected) {
        this.onExtPocItemSelect(extrato, this.selecaoExtratoEnum.Contabilidade);
      }
      this.onExtratoRowDoubleClick(extrato);
    } else {
      // Start a timer for detecting a double-click
      this._clickTimeout = setTimeout(() => {
        if (td && !td.classList.contains('col-action')) {
          if (!extrato._selected) {
            this.conciliacao.extratoCG.extPocList.map((item) => {
              if (item._selected) {
                this._deselectExtPoc(item, this.selecaoExtratoEnum.Contabilidade);
                return true;
              }
              return false;
            });
            this.onExtPocItemSelect(extrato, this.selecaoExtratoEnum.Contabilidade);
          } else {
            this.onExtPocItemDeselect(extrato, this.selecaoExtratoEnum.Contabilidade);
          }
        }
        this._clickTimeout = null;
      }, timeoutTime);
    }
  }

  public onExtratoRowDoubleClick(extrato: IConciliacaoBancariaExtPocItem): Promise<void> {
    if (!extrato) {
      return Promise.resolve();
    }
    if (extrato._selected && this.countSelecionadosCG > 0 && this.countSelecionadosBanco > 0) {
      return this._connectLines();
    }
    return this.associarExtrato(extrato);
  }

  public onExtratoBancoRowClick(event: MouseEvent, extrato: IConciliacaoBancariaLinItem): void {
    const target: HTMLElement = <HTMLElement>event.target;
    const td: HTMLElement = target.classList.contains('conciliacaoBancaria-table-cell') ? target : target.closest('.conciliacaoBancaria-table-cell');

    if (td && !td.classList.contains('col-action')) {
      if (!extrato._selected) {
        this.conciliacao.extratoBanco.concilBancoList.map((item) => {
          if (item._selected) {
            this._deselectExtratoBanco(item, this.selecaoExtratoEnum.Banco);
            return true;
          }
          return false;
        });
        this.onExtratoBancoItemSelect(extrato, this.selecaoExtratoEnum.Banco);
      } else {
        this.onExtratoBancoItemDeselect(extrato, this.selecaoExtratoEnum.Banco);
      }
    }
  }

  public onExtratoBancoRowDoubleClick(extrato: IConciliacaoBancariaLinItem): Promise<void> {
    if (!extrato?.ligadoALancamento) {
      return Promise.resolve();
    }
    return this.extratoBancoDesassociarLinha(extrato);
  }

  public doSortList(listType: EConciliacaoBancariaListType, columnName: TConciliacaoBancariaSortableFields): void {
    if (listType === EConciliacaoBancariaListType.ExtratoCG) {
      if (this.sortExtratoCG.column === columnName) {
        this.sortExtratoCG.sort = this.sortExtratoCG.sort === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortExtratoCG.column = columnName;
        this.sortExtratoCG.sort = 'asc';
      }
      this._updateExtPocList();
    } else if (listType === EConciliacaoBancariaListType.ExtratoBanco) {
      if (this.sortExtratoBanco.column === columnName) {
        this.sortExtratoBanco.sort = this.sortExtratoBanco.sort === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortExtratoBanco.column = columnName;
        this.sortExtratoBanco.sort = 'asc';
      }

      this._updateConcilBancoList();
    }
  }

  public conciliado(): boolean {
    const conciliado = isDefinedNotNull(this.conciliacao) && this.conciliacao.extratoBanco && this.conciliacao.extratoBanco.estado === EConciliacaoBancariaEstadoConciliacaoCab.Conciliado;
    if (this._lastConciliadoValue !== conciliado) {
      this._lastConciliadoValue = conciliado;
      this.treeCallback.reload();
    }
    return conciliado;
  }

  public getAssociarExtratoTooltipText(item: IConciliacaoBancariaExtPocItem): string {
    return item._selected && this.countSelecionadosCG > 0 && this.countSelecionadosBanco > 0
      ? this._translateService.instant('conciliacaoBancaria.associarLinhasDosExtratosEmAmbosLadosTooltip')
      : this._translateService.instant('conciliacaoBancaria.associarExtratoTooltip');
  }

  public associarExtratoFast(item: IConciliacaoBancariaExtPocItem): Promise<void> {
    item._selected = true;
    const extratoBanco = this._getSelectedExtratoBanco(item);
    if (extratoBanco) {
      this._selectExtratoBanco(extratoBanco, this.selecaoExtratoEnum.Banco);
    }
    //Desseleciona todos os extratos selecionados que não são o extrato que se pretende fazer a associação
    const extPocSelected: Array<IConciliacaoBancariaExtPocItem> = this.conciliacao.extratoCG.extPocList.filter((extPocItem: IConciliacaoBancariaExtPocItem) => extPocItem._selected === true);
    if (extPocSelected?.length > 0) {
      for (const extpoc of extPocSelected) {
        if (extpoc.nLanc !== item.nLanc) {
          this._deselectExtPoc(extpoc, this.selecaoExtratoEnum.Contabilidade);
          const extBanco = this._getSelectedExtratoBanco(extpoc);
          this._deselectExtratoBanco(extBanco, this.selecaoExtratoEnum.Contabilidade);
        }
      }
    }
    if (this.countSelecionadosBanco > 0) {
      return this._connectLines();
    }
    return this.associarExtrato(item);
  }

  public changedTab(tabId: string): void {
    this.activeTab = tabId;
    if (this.activeTab === ATTACHMENTS_TAB_ID) {
      this.initGDoc();
    }
  }

  public initGDoc(): void {
    if (this.selectedBancoCabItem) {
      this.gdocParams = `NConta=${this.selectedBancoCabItem.nConta}&Periodo=${this.selectedBancoCabItem.periodo}`;
      this._conciliacaoBancariaService.getGDocFolderId(this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo).then((response) => {
        this.gdocFolderId = response.body.folderId;
      });
    }
  }

  public getColCaption(col: TConciliacaoBancariaColCaption): string {
    let caption: string = col === 'valor' ? 'conciliacaoBancaria.fields.valor' : 'conciliacaoBancaria.fields.valorSemDC';
    if (this.conciliacao?.usaMoedaEstrangeira && this.conciliacao?.abrevMoedaEstrangeira.length) {
      caption = `${<string>this._translateService.instant(caption)} (${this.conciliacao?.abrevMoedaEstrangeira})`;
    }
    return caption;
  }

  @ViewChild('cdkVirtualScrollViewport')
  public set cdkVirtualScrollViewport(value: CdkVirtualScrollViewport) {
    this._cdkVirtualScrollViewport = value;
  }

  @ViewChild('cdkVirtualScrollViewportExtratoBanco')
  public set cdkVirtualScrollViewportExtratoBanco(value: CdkVirtualScrollViewport) {
    this._cdkVirtualScrollViewportExtratoBanco = value;
  }

  @ViewChild('templateMostrarLinhasConciliadasExtratoCG')
  public set templateMostrarLinhasConciliadasExtratoCG(value: TemplateRef<IPlToolbarItemTemplateContext>) {
    this._btnExtratoCGMostrarLinhasConciliadasToolbarItem.templateRef = value;
  }

  @ViewChild('templateMostrarLinhasConciliadasExtratoBanco')
  public set templateMostrarLinhasConciliadasExtratoBanco(value: TemplateRef<IPlToolbarItemTemplateContext>) {
    this._btnExtratoBancoMostrarLinhasConciliadasToolbarItem.templateRef = value;
  }

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._conciliacaoBancariaService.cancelJob(this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo);
  }

  private _callNewEditConciliacaoModal(mode: EConciliacaoBancariaMode, eConciliacaoFechoAno: boolean): void {
    let data: IConciliacaoBancariaBancoCab = this.selectedNode
      ? {...this.selectedBancoCabItem}
      : {
          nConta: '',
          periodo: '',
          nomeNConta: '',
          concilBancoCabID: '',
          nomeConciliacao: '',
          saldoFinal: 0,
          saldoInicial: 0,
          saldoFinalDC: EDebitoCredito.Credito,
          saldoInicialDC: EDebitoCredito.Credito,
          estado: EConciliacaoBancariaEstadoConciliacaoCab.EmAberto
        };
    if (mode === EConciliacaoBancariaMode.NEW) {
      data = {...data, saldoInicial: 0, saldoFinal: 0, saldoInicialDC: EDebitoCredito.Credito, saldoFinalDC: EDebitoCredito.Credito};
      if (!isEmpty(data.periodo) && !eConciliacaoFechoAno) {
        this._periodosService.obterProximoPeriodoContab(data.periodo).then((response: HttpResponse<IJsonPeriodo>) => {
          data.periodo = response.body.periodo;
          this._showNewEditConciliacaoModal(mode, data, eConciliacaoFechoAno);
        });
      } else {
        this._showNewEditConciliacaoModal(mode, data, eConciliacaoFechoAno);
      }
    } else {
      this._showNewEditConciliacaoModal(mode, data, eConciliacaoFechoAno);
    }
  }

  private _showNewEditConciliacaoModal(mode: EConciliacaoBancariaMode, model: IConciliacaoBancariaBancoCab, eConciliacaoFechoAno: boolean): void {
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaNovaConciliacaoModalComponent, {size: 'lg'});
    const componentInstance: ConciliacaoBancariaNovaConciliacaoModalComponent = modalInstance.componentInstance;
    componentInstance.mode = mode;
    componentInstance.abrevMoedaEstrangeira = mode === EConciliacaoBancariaMode.EDIT ? this.conciliacao?.abrevMoedaEstrangeira || '' : '';
    componentInstance.model = model;
    componentInstance.primeiraConciliacao = this.conciliacao?.ePrimeiraConciliacao || true;
    if (mode === EConciliacaoBancariaMode.EDIT) {
      componentInstance.nConta = this.selectedBancoCabItem.nConta;
      componentInstance.periodo = this.selectedBancoCabItem.periodo;
    }
    componentInstance.eConciliacaoFechoAno = eConciliacaoFechoAno;
    modalInstance.result.then((data: Partial<IConciliacaoBancariaBancoCab>) => {
      this.treeCollapsed = false;
      this._reloadTree({nConta: data.nConta, periodo: data.periodo});
    });
  }

  private _reloadTree(selectNodeKey?: IConciliacaoBancariaTreeKey): void {
    this.changedSelectedNode(undefined);
    this.treeCallback
      .reload()
      .then(() => {
        if (selectNodeKey) {
          const nodeId: string = this.treeCallback.generateNodeId(selectNodeKey.nConta, selectNodeKey.periodo);
          timeout().then(() => {
            this.treeCallback.selectNode(nodeId);
          });
        }
      })
      .catch((reason: HttpErrorResponse) => {
        this._handleRequestError(reason);
      });
  }

  private _handleRequestError(reason: HttpErrorResponse): void {
    if (reason instanceof HttpErrorResponse) {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      this._plAlertService.error(exception.message);
    } else {
      this._logger.error(reason);
    }
  }

  private _reloadConciliacao(): Promise<void> {
    this.lockPromise = this._conciliacaoBancariaService
      .loadConciliacao(this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then(() => {
        this.blockedByUser = false;
        return this._refreshConciliacao().then(() => {
          this._updateToolbarButtons();
        });
      })
      .catch((reason: HttpErrorResponse) => {
        const cgException = this._cgExceptionService.get(reason);
        if (cgException.class === APP_BLOCKED_BY_OTHER_EXCEPTION_NAME) {
          this.blockedByUser = true;
          this.blockedByUserMessage = cgException.message;
        } else {
          this._handleRequestError(reason);
        }
      })
      .finally(() => {
        this.lockPromise = undefined;
      });
    return this.lockPromise;
  }

  private _refreshConciliacao(resetSelTotais: boolean = true): Promise<void> {
    return this._conciliacaoBancariaService
      .getConciliacao(this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then((response: IRestCmdConciliacaoBancariaGetConciliacao) => {
        this.conciliacao = response.conciliacao;
        this.extratoBancoSelectAll = false;
        this.extratoCGSelectAll = false;
        this.countSelecionadosCG = 0;
        this.countSelecionadosBanco = 0;

        if (resetSelTotais) {
          this.valorTotalSelecionadoCG = 0;
          this.valorTotalSelecionadoBanco = 0;
        }
        this._cacheExtPocList = this.conciliacao.extratoCG.extPocList.slice();
        this._updateExtPocList();

        this._cacheConcilBancoList = this.conciliacao.extratoBanco.concilBancoList.slice();
        this._updateConcilBancoList(true);

        const maxItems: number = this._getMaxItemsViewPort();
        this.extratoCGHaveScroll = this.conciliacao.extratoCG.extPocList.length > maxItems;
        this.extratoBancoHaveScroll = this.conciliacao.extratoBanco.concilBancoList.length > maxItems;
        this._updateBancoSaldoFinalCalc();

        this._updateExtratoCGToolbarActions();
        this._updateExtratoBancoToolbarActions();
        this._updateToolbarButtons();
      })
      .catch((reason: HttpErrorResponse) => {
        this._handleRequestError(reason);
      });
  }

  private _filterExtPocList(mostrarLinhasConciliadas: boolean): Array<IConciliacaoBancariaExtPocItem> {
    const source: Array<IConciliacaoBancariaExtPocItem> = mostrarLinhasConciliadas
      ? this._cacheExtPocList.slice()
      : this._cacheExtPocList.filter((item: IConciliacaoBancariaExtPocItem) => !item.estadoConciliacaoBool);
    for (const item of source) {
      if (!isBoolean(item._selected)) {
        item._selected = false;
      }
      item.valorDisplay = this._plI18nService.formatNumber(item.valorSemDCInv, this._decimais);
      item.valorSemDCDisplay = this._plI18nService.formatNumber(item.valor, this._decimais);
    }
    return source;
  }

  private _filterConcilBancoList(mostrarLinhasConciliadas: boolean): Array<IConciliacaoBancariaLinItem> {
    return mostrarLinhasConciliadas
      ? this._cacheConcilBancoList.slice()
      : this._cacheConcilBancoList.filter((item: IConciliacaoBancariaLinItem) => !item.ligadoALancamento || item.tipo === EConciliacaoBancariaExtratoBancoTipo.PrimeiraConciliacao);
  }

  private _scrollToItem(index: number): void {
    this._cdkVirtualScrollViewport.scrollToIndex(index);
    setTimeout(() => {
      const element = document.getElementById(this._cacheExtPocList[index].nLanc);
      if (isDefinedNotNull(element)) {
        element.scrollIntoView();
      }
    }, 50);
  }

  private _scrollToItemExtratoBanco(index: number): void {
    this._cdkVirtualScrollViewportExtratoBanco.scrollToIndex(index);
    setTimeout(() => {
      const element = document.getElementById(this.conciliacao.extratoBanco.concilBancoList[index].concilBancoLinID);
      if (isDefinedNotNull(element)) {
        element.scrollIntoView();
      }
    }, 50);
  }

  private _findAndSelect(filter: string): void {
    const elements: NodeListOf<HTMLElement> = this._element.querySelectorAll<HTMLElement>('.highlight');
    if (elements.length) {
      nodeForEach(elements, (element: HTMLElement) => {
        element.classList.remove('highlight');
      });
    }
    this._internalExtPocSelectedItem = undefined;
    for (const item of this.conciliacao.extratoCG.extPocList) {
      const valorCompare = this._plI18nPlNumberService.formatPlNumber(item.valorSemDCInv, {thousandsSeparator: ''});
      item.valorDisplay = this._plI18nService.formatNumber(item.valorSemDCInv, this._decimais);
      if (isDefinedNotNull(filter) && filter.length) {
        const regex = new RegExp(`^[-]?${filter.replace('.', ',')}`);
        const match = regex.exec(valorCompare);
        if (match) {
          const displayValue = this._plI18nService.formatNumber(item.valorSemDCInv, this._decimais);
          item.valorDisplay = this._getHighlightedText(displayValue, filter);
          this._internalExtPocSelectedItem = item;
          this._highlightExtPocTableRow(item.nLanc);
        }
      }
    }
    if (isDefinedNotNull(this._internalExtPocSelectedItem)) {
      const index = this._cacheExtPocList.findIndex((obj) => obj.nLanc === this._internalExtPocSelectedItem.nLanc);
      if (index !== -1) {
        this._scrollToItem(index);
      }
    }
  }

  private _getHighlightedText(str: string, query: string): string {
    const result = [];
    let p = 0;
    let tagOpenned = false;
    query = query.replace('.', ',');
    for (const charStr of str) {
      if (p < query.length && charStr === query[p]) {
        if (!tagOpenned) {
          result.push('<span class="highlight">');
          tagOpenned = true;
        }
        p++;
      }
      result.push(charStr);
      if (tagOpenned && p >= query.length) {
        result.push('</span>');
        tagOpenned = false;
      }
    }
    return result.join('');
  }

  private _highlightExtPocTableRow(nLanc: string): void {
    const row: HTMLElement = this._element.querySelector(`.conciliacaoBancaria-table[data-id="${nLanc}"]`);
    if (row) {
      row.classList.add('highlight');
    }
  }

  private _focusFilterInput(): void {
    const input: HTMLInputElement = this._element.querySelector('input[name="expPocListFilterInput"]');
    if (input) {
      focusElement(input);
    }
  }

  private _updateExtPocList(): void {
    const isAsc: boolean = this.sortExtratoCG.sort === 'asc';
    this._cacheExtPocList.sort((a: IConciliacaoBancariaExtPocItem, b: IConciliacaoBancariaExtPocItem) => {
      if (this.sortExtratoCG.column === 'valor') {
        return isAsc ? a.valorSemDCInv - b.valorSemDCInv : b.valorSemDCInv - a.valorSemDCInv;
      } else if (this.sortExtratoCG.column === 'dataDocExt') {
        const dateA = moment(a.dataDocExt).unix();
        const dateB = moment(b.dataDocExt).unix();
        return isAsc ? dateA - dateB : dateB - dateA;
      } else if (this.sortExtratoCG.column === 'nDoc' || this.sortExtratoCG.column === 'descricao') {
        return isAsc ? a[this.sortExtratoCG.column].localeCompare(b[this.sortExtratoCG.column]) : -1 * a[this.sortExtratoCG.column].localeCompare(b[this.sortExtratoCG.column]);
      }
      return 0;
    });
    this.conciliacao.extratoCG.extPocList = this._filterExtPocList(this.mostrarLinhasConciliadasExtratoCG);
  }

  private _updateConcilBancoList(resetSelection: boolean = false): void {
    if (resetSelection) {
      for (const item of this._cacheConcilBancoList) {
        item._selected = false;
      }
    }

    const isAsc: boolean = this.sortExtratoBanco.sort === 'asc';
    this._cacheConcilBancoList.sort((a: IConciliacaoBancariaLinItem, b: IConciliacaoBancariaLinItem) => {
      if (this.sortExtratoBanco.column === 'valor') {
        return isAsc ? a.valorSemDC - b.valorSemDC : b.valorSemDC - a.valorSemDC;
      } else if (this.sortExtratoBanco.column === 'data') {
        const dateA = moment(a.data).unix();
        const dateB = moment(b.data).unix();
        return isAsc ? dateA - dateB : dateB - dateA;
      } else if (this.sortExtratoBanco.column === 'descricao') {
        return isAsc ? a.descricao.localeCompare(b.descricao) : -1 * a.descricao.localeCompare(b.descricao);
      }
      return 0;
    });
    this.conciliacao.extratoBanco.concilBancoList = this._filterConcilBancoList(this.mostrarLinhasConciliadasExtratoBanco);
  }

  private _eliminaConciliacaoBancaria(): Promise<void> {
    if (!this.selectedNode) {
      return Promise.resolve();
    }
    const msg: string = this._translateService.instant('conciliacaoBancaria.eliminaConciliacaoModalMsg', {nome: this.selectedBancoCabItem.nomeConciliacao});
    return this._cgModalService.showOkCancel('conciliacaoBancaria.eliminaConciliacao', msg, {size: 'md'}).then(() => {
      return this._conciliacaoBancariaService
        .eliminaConciliacaoBancaria(this.selectedBancoCabItem.concilBancoCabID, this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
        .then(() => {
          this._reloadTree();
          this._resetMainToolbarButtons();
        })
        .catch((reason: HttpErrorResponse) => {
          this._handleRequestError(reason);
        });
    });
  }

  private _eliminarTodasConciliacoesBanco(): Promise<void> {
    if (!this.selectedNode) {
      return Promise.resolve();
    }
    const isFolder: boolean = this.selectedBancoCabItem.nodeType === EConciliacaoBancariaBancosTreeViewerNodeType.Folder;
    const nContaBanco: string = isFolder ? this.selectedBancoCabItem.nConta : this.treeCallback.getParentNode(this.selectedNode)?.nodeValue.nConta;
    const periodo: string = isFolder ? this.selectedNode.childNodes[0].nodeValue.periodo : this.selectedBancoCabItem.periodo;
    const msg: string = this._translateService.instant('conciliacaoBancaria.eliminaTodasConciliacoesModalMsg', {
      conta: `${this.selectedBancoCabItem.nConta} - ${this.selectedBancoCabItem.nomeNConta}`
    });
    return this._cgModalService.showOkCancel('conciliacaoBancaria.eliminaConciliacao', msg, {size: 'md'}).then(() => {
      return this._conciliacaoBancariaService
        .eliminaTodasConciliacaoBancariaDaConta(nContaBanco, nContaBanco, periodo)
        .then(() => {
          this._reloadTree();
          this._resetMainToolbarButtons();
        })
        .catch((reason: HttpErrorResponse) => {
          this._handleRequestError(reason);
        });
    });
  }

  private _terminarConcilidacao(): Promise<void> {
    if (!this.selectedNode) {
      return Promise.resolve();
    }
    return this._cgModalService.showOkCancel('conciliacaoBancaria.terminarConcilidacao', 'conciliacaoBancaria.terminarConcilidacaoModalMsg', {size: 'md'}).then(() => {
      return this._conciliacaoBancariaService
        .mudarEstadoConcilidacao(EConciliacaoBancariaEstadoConciliacaoCab.Conciliado, this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
        .then(() => {
          this._internalExtPocSelectedItem = undefined;
          this.expPocListValueFilter = '';
          this._reloadTree({nConta: this.selectedBancoCabItem.nConta, periodo: this.selectedBancoCabItem.periodo});
        })
        .catch((reason: HttpErrorResponse) => {
          this._handleRequestError(reason);
        });
    });
  }

  private _updateBancoSaldoFinalCalc(): void {
    this._conciliacaoBancariaService
      .obterSaldos(this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then((response: IRestCmdConciliacaoBancariaObterSaldos) => {
        this.conciliacao.extratoCG.saldoInicial = response.saldoInicialCG;
        this.conciliacao.extratoCG.saldoFinal = response.saldoFinalCG;
        this.conciliacao.extratoBanco.saldoInicial = response.saldoInicialBanco;
        this.conciliacao.extratoBanco.saldoFinal = response.saldoFinalBanco;
        this.conciliacao.extratoBanco.saldoInicialDCStr = response.saldoInicialBancoDCStr;
        this.conciliacao.extratoBanco.saldoFinalDCStr = response.saldoFinalBancoDCStr;
        this.conciliacao.extratoBanco.saldoFinalCalculado = response.saldoFinalBancoCalculado;
        this.conciliacao.extratoBanco.diferencaConciliacao = response.diferencaConciliacao;
        this.conciliacao.extratoBanco.estado = response.extratoBancoEstado;
      })
      .catch((reason: HttpErrorResponse) => {
        this._handleRequestError(reason);
      });
  }

  private _associarSelecionados(): Promise<void> {
    const selected: Array<string> = this.conciliacao.extratoCG.extPocList
      .filter((item: IConciliacaoBancariaExtPocItem) => {
        if (item._selected) {
          item._state = EConciliacaoBancariaRecordState.PENDING;
          return true;
        }
        return false;
      })
      .map<string>((item: IConciliacaoBancariaExtPocItem) => item.nLanc);
    if (!selected.length) {
      return Promise.resolve();
    }
    if (selected.length === this.conciliacao.extratoCG.extPocList.length) {
      return this.associarTodoExtratoCGAoExtradoDoBanco();
    }
    if (selected.length === 1) {
      return this._associarExtratoMultiple(selected, false);
    }
    return this._cgModalService
      .showOkCancel('global.text.confirmation', 'conciliacaoBancaria.sMsgOQueFazercomVariaslinhas', {
        size: 'md',
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => this._associarExtratoMultiple(selected, true))
      .catch(() => this._associarExtratoMultiple(selected, false));
  }

  private _associarExtratoMultiple(selArrays: Array<string>, oneLine: boolean): Promise<void> {
    return this._conciliacaoBancariaService
      .associarExtratoMultiple(selArrays, oneLine, this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then(() => this._refreshConciliacao())
      .catch((reason: HttpErrorResponse) => {
        this._handleRequestError(reason);
      });
  }

  private _removerExtratoBancoSelecionados(): Promise<void> {
    const concilBancoLinIDs: Array<string> = this.conciliacao.extratoBanco.concilBancoList
      .filter((item: IConciliacaoBancariaLinItem) => item._selected)
      .map<string>((item: IConciliacaoBancariaLinItem) => item.concilBancoLinID);
    if (!concilBancoLinIDs.length) {
      return Promise.resolve();
    }
    if (concilBancoLinIDs.length === this.conciliacao.extratoBanco.concilBancoList.length) {
      return this.removeTodasLinhasExtratoBanco();
    }
    return this._conciliacaoBancariaService
      .removeLinhaExtratoBanco(concilBancoLinIDs, this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then(() => this._refreshConciliacao())
      .catch((reason: HttpErrorResponse) => {
        this._handleRequestError(reason);
      });
  }

  private _toggleTree(): void {
    this.treeCollapsed = !this.treeCollapsed;
    this._btnToggleTree.caption = this.treeCollapsed ? 'conciliacaoBancaria.showTreeText' : 'conciliacaoBancaria.hideTreeText';
  }

  private _resetMainToolbarButtons(): void {
    this._btnMainMudarEstadoConcilidacaoMenuItem.disabled = true;
    this._btnMenuEditarConciliacaoMenuItem.disabled = true;
    this._btnMenuRefreshConciliacaoMenuItem.disabled = true;
    this._btnMainMudarEstadoConcilidacaoMenuItem.disabled = true;
    this._btnMainEliminaConciliacaoMenuItem.disabled = true;
    this._btnMainPocNContaMenuItem.disabled = isUndefined(this.selectedNode);
    this._btnMainEliminarTodasConciliacoesBancoMenuItem.disabled = isUndefined(this.selectedNode);
  }

  private _viewPocBanco(): Promise<void> {
    return this._entityMaintenanceInstance.maintenanceEdit(this.selectedBancoCabItem.nConta).then(() => undefined);
  }

  private _printConciliacao(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaPdfModalComponent, {size: 'xl'});
    const componentInstance: ConciliacaoBancariaPdfModalComponent = modalInstance.componentInstance;
    componentInstance.nConta = this.selectedBancoCabItem.nConta;
    componentInstance.nomeNConta = this.selectedBancoCabItem.nomeNConta;
    componentInstance.periodo = this.selectedBancoCabItem.periodo;
    return modalInstance.result;
  }

  private _configContaPendentesBanco(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaContaPendentesBancoModalComponent, {size: 'md'});
    const componentInstance: ConciliacaoBancariaContaPendentesBancoModalComponent = modalInstance.componentInstance;
    componentInstance.nConta = this.selectedBancoCabItem.nConta;
    componentInstance.periodo = this.selectedBancoCabItem.periodo;
    componentInstance.conciliacao = this.conciliacao;
    return modalInstance.result.then(() => this._refreshConciliacao());
  }

  private _addLinhaPrimeiraConcilExtratoCG(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaAddLinhaExtratoCGModalComponent, {size: 'md'});
    const componentInstance: ConciliacaoBancariaAddLinhaExtratoCGModalComponent = modalInstance.componentInstance;
    componentInstance.nConta = this.selectedBancoCabItem.nConta;
    componentInstance.periodo = this.selectedBancoCabItem.periodo;
    componentInstance.date = moment(this.conciliacao.extratoBanco.dataInicio).subtract(1, 'days');
    return modalInstance.result.then((extPocItem: IConciliacaoBancariaExtPocItem) => {
      extPocItem._selected = false;
      this._cacheExtPocList.push(extPocItem);
      this._updateExtPocList();
      this._updateBancoSaldoFinalCalc();
    });
  }

  private _addDocsPrimeiraConcilExtratoCG(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaAddDocsNaoConciliadosModalComponent);
    const componentInstance: ConciliacaoBancariaAddDocsNaoConciliadosModalComponent = modalInstance.componentInstance;
    componentInstance.nConta = this.selectedBancoCabItem.nConta;
    componentInstance.periodo = this.selectedBancoCabItem.periodo;
    return modalInstance.result.then(() => this._refreshConciliacao());
  }

  private _round(value: number): number {
    return Math.round(value * 100) / 100;
  }

  private _updateExtratoBancoToolbarActions(): void {
    this._btnExtratoBancoRemoverExtratoBancoSelecionadosMenuItem.disabled = this.countSelecionadosBanco === 0;
    this._btnExtratoBancoAddDocsDoExtratoBancarioAContabilidadeMenuItem.disabled = this.countSelecionadosBanco === 0;
    this._btnExtratoBancoDesassociarSelecionadosMenuItem.disabled = !this._canUnlinkLinhasExtratoBanco();
  }

  private _updateExtratoCGToolbarActions(): void {
    this._btnExtratoCGRemoverLinhasMenuItem.disabled = !this._canRemoveLinhasExtratoCG();
    this._btnExtratoCGRemoverLinhasMenuItem.tooltip.disabled = !this._btnExtratoCGRemoverLinhasMenuItem.disabled;
    this._btnExtratoCGAssociarSelecionadosMenuItem.disabled = this.countSelecionadosCG === 0;
    this._btnExtratoCGLinkSelecionaodsMenuItem.disabled = this.countSelecionadosCG === 0 || this.countSelecionadosBanco === 0;
    this._btnExtratoCGDesassociarSelecionadosMenuItem.disabled = !this._canUnlinkLinhasExtratoCG();
  }

  private _connectLines(): Promise<void> {
    const nLancs: Array<string> = this._cacheExtPocList.filter((item: IConciliacaoBancariaExtPocItem) => item._selected).map<string>((item: IConciliacaoBancariaExtPocItem) => item.nLanc);
    const concilBancoLinIds: Array<string> = this._cacheConcilBancoList
      .filter((item: IConciliacaoBancariaLinItem) => item._selected)
      .map<string>((item: IConciliacaoBancariaLinItem) => item.concilBancoLinID);
    if (!nLancs.length || !concilBancoLinIds.length) {
      this._plAlertService.error(this._translateService.instant('conciliacaoBancaria.temQueSelecionarLinhas'));
      return Promise.resolve();
    }
    return this._conciliacaoBancariaService
      .connectLines(nLancs, concilBancoLinIds, this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then(() => this._refreshConciliacao())
      .catch((reason: HttpErrorResponse) => {
        this._refreshConciliacao();
        this._handleRequestError(reason);
      });
  }

  private _importaDocsPendentesBancoConcilAntiga(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'conciliacaoBancaria.sAntesDeProsseguirDeveVerificarSe', {size: 'md'}).then(() => {
      return this._conciliacaoBancariaService
        .importaDocsPendentesBancoConcilAntiga(this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
        .then(() => this._refreshConciliacao())
        .catch((reason: HttpErrorResponse) => {
          this._handleRequestError(reason);
        });
    });
  }

  private _importaDocsPendentesConcilAntiga(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'conciliacaoBancaria.sAntesDeProsseguirDeveVerificarSe', {size: 'md'}).then(() => {
      return this._conciliacaoBancariaService
        .importaDocsPendentesContabConcilAntiga(this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
        .then(() => this._refreshConciliacao())
        .catch((reason: HttpErrorResponse) => {
          this._handleRequestError(reason);
        });
    });
  }

  private _addDocsDoExtratoBancarioAContabilidadeClick(): Promise<void> {
    const concilBancoLinIds: Array<string> = this._cacheConcilBancoList.filter((item: IConciliacaoBancariaLinItem) => item._selected).map((item: IConciliacaoBancariaLinItem) => item.concilBancoLinID);
    return this._addDocsDoExtratoBancarioAContabilidade(concilBancoLinIds);
  }

  private _addDocsDoExtratoBancarioAContabilidade(concilBancoLinIds: Array<string>): Promise<void> {
    if (!concilBancoLinIds.length) {
      this._plAlertService.error(this._translateService.instant('conciliacaoBancaria.temQueSelecionarLinhas'));
      return Promise.resolve();
    }
    return this.showConciliacaoBancariaAddLinhaExtratoBancoModal(EConciliacaoBancariaAddLinhaExtratoBancoModalMode.OnlyContabilidade, EConciliacaoBancariaExtratoBancoTipo.Undefined)
      .then((modalModel: IConciliacaoBancariaNovaLinhaExtratoBancoModal) => {
        this._conciliacaoBancariaService
          .lancaLinhasNaContabilidadeEAssocia(concilBancoLinIds, this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo, modalModel.nContaLanc)
          .then(() => {
            return this._reloadConciliacao();
          })
          .catch((reason: HttpErrorResponse) => {
            this._handleRequestError(reason);
          });
      })
      .catch((catchResult: IConciliacaoBancariaNovaLinhaExtratoBancoModal | HttpErrorResponse) => {
        if (catchResult instanceof HttpErrorResponse) {
          this._handleRequestError(catchResult);
        } else if (catchResult) {
          this.conciliacao.diario = catchResult.diario;
          this.conciliacao.descritivo = catchResult.descritivo;
        }
      });
  }

  private _conciliarAutomaticamente(): Promise<void> {
    return this._conciliacaoBancariaService
      .conciliarAutomaticamente(this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then(() => this._refreshConciliacao())
      .catch((reason: HttpErrorResponse) => {
        this._handleRequestError(reason);
      });
  }

  private _importartExtratoBancario(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaImportExtratoBancarioModalComponent, {size: 'xl'});
    const componentInstance: ConciliacaoBancariaImportExtratoBancarioModalComponent = modalInstance.componentInstance;
    componentInstance.nConta = this.selectedBancoCabItem.nConta;
    componentInstance.periodo = this.selectedBancoCabItem.periodo;
    componentInstance.decimais = this._decimais;
    componentInstance.nomeConta = this.selectedBancoCabItem.nomeNConta;
    componentInstance.concilBancoCabID = this.selectedBancoCabItem.concilBancoCabID;
    return modalInstance.result.then(() => this._refreshConciliacao());
  }

  private _removeExtratoCGSelecionados(): Promise<void> {
    const nLancs: Array<string> = this._cacheExtPocList.filter((item: IConciliacaoBancariaExtPocItem) => item._selected).map<string>((item: IConciliacaoBancariaExtPocItem) => item.nLanc);
    return this._conciliacaoBancariaService
      .removeLinhaExtratoCG(nLancs, this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then(() => this._refreshConciliacao())
      .catch((reason) => {
        this._handleRequestError(reason);
      });
  }

  private _canRemoveLinhasExtratoCG(): boolean {
    if (this.countSelecionadosCG === 0) {
      return false;
    }
    const selected = this._cacheExtPocList.filter((item: IConciliacaoBancariaExtPocItem) => item._selected);
    for (const item of selected) {
      if (!item.linhaPrimeiraConciliacao) {
        return false;
      }
    }
    return true;
  }

  private _desassociarSelecionadosExtratoCG(): Promise<void> {
    const nLancs: Array<string> = this.conciliacao.extratoCG.extPocList
      .filter((item: IConciliacaoBancariaExtPocItem) => item._selected)
      .map<string>((item: IConciliacaoBancariaExtPocItem) => item.nLanc);
    return this._conciliacaoBancariaService
      .desassociarExtratoCG(nLancs, this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then(() => this._refreshConciliacao())
      .catch((reason) => {
        this._handleRequestError(reason);
      });
  }

  private _desassociarSelecionadosExtratoBancario(): Promise<void> {
    const concilBancoLinIDs: Array<string> = this.conciliacao.extratoBanco.concilBancoList
      .filter((item: IConciliacaoBancariaLinItem) => item._selected)
      .map((item: IConciliacaoBancariaLinItem) => item.concilBancoLinID);
    return this._conciliacaoBancariaService
      .desassociarExtratoBanco(concilBancoLinIDs, this.selectedBancoCabItem.nConta, this.selectedBancoCabItem.periodo)
      .then(() => this._refreshConciliacao())
      .catch((reason) => {
        this._handleRequestError(reason);
      });
  }

  private _canUnlinkLinhasExtratoCG(): boolean {
    if (this.countSelecionadosCG === 0) {
      return false;
    }
    const selected = this._cacheExtPocList.filter((item: IConciliacaoBancariaExtPocItem) => item._selected);
    for (const item of selected) {
      if (this.isDisabledExtratoCGDesassociarLinha(item)) {
        return false;
      }
    }
    return true;
  }

  private _canUnlinkLinhasExtratoBanco(): boolean {
    if (this.countSelecionadosBanco === 0) {
      return false;
    }
    const selected = this._cacheConcilBancoList.filter((item: IConciliacaoBancariaLinItem) => item._selected);
    for (const item of selected) {
      if (this.isDisabledExtratoBancoDesassociarLinha(item)) {
        return false;
      }
    }
    return true;
  }

  private _getVScrollHeight(): number {
    const defaultHeight = VSCROLL_SIZE;
    if (getScreenWidth() < EScreenSize.Large) {
      return defaultHeight;
    }
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const v = this._roundVSH(getScreenHeight() - 380);
    return v > defaultHeight ? defaultHeight : v;
  }

  private _roundVSH(num: number): number {
    return num + (this.virtualScrollItemSize - (num % this.virtualScrollItemSize));
  }

  private _getMaxItemsViewPort(): number {
    return Math.floor(this.vScrollHeight / this.virtualScrollItemSize);
  }

  private async _viewPesqDocs(): Promise<void> {
    await this._pesqDocsEntityMaintenanceInstance.maintenanceList({
      initialFilter: `nConta=${this.conciliacao.radicalContaContrapartida}${this.conciliacao.extratoBanco.nConta}`
    });
  }

  private _updateToolbarButtons(): void {
    const podeEditar: boolean = this.conciliacao?.podeEditarConciliacao;
    const menuEditarAtivo: boolean = podeEditar || this.conciliacao?.eConcialicaoFimDeAno;
    this._btnMenuEditarConciliacaoMenuItem.disabled = !menuEditarAtivo;
    this._btnMenuRefreshConciliacaoMenuItem.disabled = !podeEditar;
    this._btnMainMudarEstadoConcilidacaoMenuItem.disabled = !podeEditar;
    this._btnExtratoBancoAddLancPrimeiraConciliacaoMenu.disabled = !podeEditar || !this.conciliacao.podeAdicionarDocAnteriores;
    this._btnExtratoBancoAddLancamentoBancoMenu.disabled = !podeEditar;
    this._btnExtratoBancoAddLinhaExtradoBancarioMenu.disabled = !podeEditar;
    this._btnExtratoCGAddLinhaPrimeiraConcilExtratoCG.disabled = !this.conciliacao.ePrimeiraConciliacao || !podeEditar;
    this._btnExtratoCGAddDocsPrimeiraConcilExtratoCG.disabled = !this.conciliacao.ePrimeiraConciliacao || !podeEditar;
    this._btnExtratoCGImportaDocsPendentesConcilAntiga.disabled = !this.conciliacao.ePrimeiraConciliacao || !podeEditar;
    this._btnExtratoCGExtratoContaPendentes.disabled = !this.conciliacao.temSaldoContaContraPartida || !podeEditar;
    this._btnExtratoBancoImportaDocsPendentesBancoConcilAntiga.disabled = !this.conciliacao.ePrimeiraConciliacao || !podeEditar;
    this._btnExtratoBancoImportarExtratoBancario.disabled = !podeEditar;
    this._btnExtratoBancoConciliarAutomaticamente.disabled = !this.conciliacao.podeJustificarLinhas || !podeEditar;
  }

  private _getSelectedExtratoBanco(extrato: IConciliacaoBancariaExtPocItem): IConciliacaoBancariaLinItem {
    const possibleLines: Array<IConciliacaoBancariaLinItem> = [];
    const possibleLinesByDesc: Array<IConciliacaoBancariaLinItem> = [];
    let selectExtratoBancoLine: IConciliacaoBancariaLinItem;
    let lastDate: Moment = minDateCG();
    if (this.countSelecionadosCG > 1) {
      return undefined;
    }
    this.conciliacao.extratoBanco.concilBancoList.forEach((item) => {
      if (item.valorSemDC === extrato.valorSemDCInv && item.ligadoALancamento === extrato.estadoConciliacaoBool) {
        possibleLines.push(item);
      }
    });

    if (possibleLines.length > 0) {
      const descArr = extrato.descricao.split(' ').filter(Boolean);
      possibleLines.forEach((possibleLine) => {
        for (const descArrItem of descArr) {
          if (descArrItem.length > 2) {
            if (possibleLine.descricao.includes(descArrItem)) {
              possibleLinesByDesc.push(possibleLine);
              break;
            }
          }
        }
      });

      if (possibleLinesByDesc.length > 0) {
        if (possibleLinesByDesc.length > 1) {
          for (const possibleLineByDesc of possibleLinesByDesc) {
            if (possibleLineByDesc.data > lastDate && possibleLineByDesc.data <= extrato.dataLacam) {
              lastDate = moment(extrato.dataLacam);
              selectExtratoBancoLine = possibleLineByDesc;
            }
          }
        } else {
          selectExtratoBancoLine = possibleLinesByDesc[0];
        }
      }

      if (!selectExtratoBancoLine) {
        for (const possibleLine of possibleLines) {
          if (!selectExtratoBancoLine) {
            selectExtratoBancoLine = possibleLine;
          }
          if (possibleLine.data > lastDate && possibleLine.data <= extrato.dataLacam) {
            lastDate = moment(extrato.dataLacam);
            selectExtratoBancoLine = possibleLine;
          }
        }
      }
    }
    return selectExtratoBancoLine;
  }

  private _selectExtratoBanco(extrato: IConciliacaoBancariaLinItem, selecaoExtratoEnum: EConciliacaoBancariaSelecaoExtrato): void {
    const index = this.conciliacao.extratoBanco.concilBancoList.findIndex(
      (obj) => (!obj.ligadoALancamento || (obj.ligadoALancamento && this.mostrarLinhasConciliadasExtratoBanco)) && obj.concilBancoLinID === extrato.concilBancoLinID
    );
    if (index !== -1) {
      this._scrollToItemExtratoBanco(index);
      this.onExtratoBancoItemSelect(extrato, selecaoExtratoEnum);
    }
  }

  private _deselectExtratoBanco(extrato: IConciliacaoBancariaLinItem, selecaoExtratoEnum: EConciliacaoBancariaSelecaoExtrato): void {
    const index = this.conciliacao.extratoBanco.concilBancoList.findIndex(
      (obj) => (!obj.ligadoALancamento || (obj.ligadoALancamento && this.mostrarLinhasConciliadasExtratoBanco)) && obj.concilBancoLinID === extrato.concilBancoLinID
    );
    if (index !== -1) {
      this._scrollToItemExtratoBanco(index);
      this.onExtratoBancoItemDeselect(extrato, selecaoExtratoEnum);
    }
  }

  private _getSelectedExtPoc(extrato: IConciliacaoBancariaLinItem): IConciliacaoBancariaExtPocItem {
    const possibleLines: Array<IConciliacaoBancariaExtPocItem> = [];
    const possibleLinesByDesc: Array<IConciliacaoBancariaExtPocItem> = [];
    let selectExtPocLine: IConciliacaoBancariaExtPocItem;
    let lastDate: Moment = minDateCG();

    if (this.countSelecionadosBanco > 1) {
      return undefined;
    }

    this.conciliacao.extratoCG.extPocList.forEach((item) => {
      if (item.valorSemDCInv === extrato.valorSemDC && item.estadoConciliacaoBool === extrato.ligadoALancamento) {
        possibleLines.push(item);
      }
    });

    if (possibleLines.length > 0) {
      const descArr = extrato.descricao.split(' ').filter(Boolean);
      possibleLines.forEach((possibleLine) => {
        for (const descArrItem of descArr) {
          if (descArrItem.length > 2) {
            if (possibleLine.descricao.includes(descArrItem)) {
              possibleLinesByDesc.push(possibleLine);
              break;
            }
          }
        }
      });

      if (possibleLinesByDesc.length > 0) {
        if (possibleLinesByDesc.length > 1) {
          for (const possibleLineByDesc of possibleLinesByDesc) {
            if (possibleLineByDesc.dataLacam > lastDate && possibleLineByDesc.dataLacam <= extrato.data) {
              lastDate = moment(extrato.data);
              selectExtPocLine = possibleLineByDesc;
            }
          }
        } else {
          selectExtPocLine = possibleLinesByDesc[0];
        }
      }

      if (!selectExtPocLine) {
        for (const possibleLine of possibleLines) {
          if (!selectExtPocLine) {
            selectExtPocLine = possibleLine;
          }
          if (possibleLine.dataLacam > lastDate && possibleLine.dataLacam <= extrato.data) {
            lastDate = moment(extrato.data);
            selectExtPocLine = possibleLine;
          }
        }
      }
    }
    return selectExtPocLine;
  }

  private _selectExtPoc(extrato: IConciliacaoBancariaExtPocItem, selecaoExtratoEnum: EConciliacaoBancariaSelecaoExtrato): void {
    const index = this.conciliacao.extratoCG.extPocList.findIndex((obj) => obj.extPocCabID === extrato.extPocCabID);
    this._scrollToItem(index);
    this.onExtPocItemSelect(extrato, selecaoExtratoEnum);
  }

  private _deselectExtPoc(extrato: IConciliacaoBancariaExtPocItem, selecaoExtratoEnum: EConciliacaoBancariaSelecaoExtrato): void {
    const index = this.conciliacao.extratoCG.extPocList.findIndex((obj) => obj.extPocCabID === extrato.extPocCabID);
    this._scrollToItem(index);
    this.onExtPocItemDeselect(extrato, selecaoExtratoEnum);
  }
}
