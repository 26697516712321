import {Component, Injector, Input, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IPlToolbarItem, isObject, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ModuloEntityDetailComponent} from '../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonConfigTransfArmazem, IJsonDocTransfArmazem} from '../jsonTransferenciasArmazens.entity.interface';
import {TransferenciasArmazensNewLineModalComponent} from '../modals/newline/transferenciasArmazens.newLine.modal.component';
import {TransferenciasArmazensProcessarModalComponent} from '../modals/processartrfarm/transferenciasArmazens.processar.modal.component';
import {IStockChanges, ITransfArmazem, ITransfArmazemParams} from '../transferenciasArmazens.entity.interface';
import {EEntityStateDetailType} from '../../../../common/utils/entity.state.utils';
import moment from 'moment';

@Component({
  selector: 'module-transferencias-armazens',
  templateUrl: './transferenciasArmazens.entity.component.html'
})
export class TransferenciasArmazensModuleComponent extends ModuloEntityDetailComponent<IJsonDocTransfArmazem> implements OnInit {
  @Input() public configTransfArmazem: IJsonConfigTransfArmazem;
  @Input() public doc: IJsonDocTransfArmazem;

  public dataGridDefinition: IDevExpressDataGrid;

  private readonly _btnProcess: IPlToolbarItem;
  private _dicStockArtigos: {[key: string]: IStockChanges};

  constructor(
    protected readonly _injector: Injector,
    protected readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);

    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'origemNArtigo', dataType: 'string', caption: 'transferenciasarmazens.fields.origemNArtigo'},
        {dataField: 'origemNLoteEspecifico', dataType: 'string', caption: 'transferenciasarmazens.fields.origemNLoteEspecifico'},
        {dataField: 'origemNArmazem', dataType: 'number', caption: 'transferenciasarmazens.fields.origemNArmazem'},
        {dataField: 'destinoNArmazem', dataType: 'number', caption: 'transferenciasarmazens.fields.destinoNArmazem'},
        {dataField: 'origemQtd', dataType: 'double', caption: 'transferenciasarmazens.fields.origemQtd'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn', headerCellTemplate: 'headerCellTemplateBtn', showInColumnChooser: false}
      ],
      dataSource: [],
      export: {filename: 'global.menu.transferenciasarmazens'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateBtnToolbar',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this._btnProcess = {
      id: 'processar',
      order: 100,
      type: 'button',
      iconLeft: '<i class="fa fa-gears fa-fw"></i>',
      class: 'btn-success',
      caption: 'global.btn.process',
      disabled: true,
      click: () => this._processarModal()
    };
    this._dicStockArtigos = {};
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnProcess);
    this.btnSave.visible = false;
    if (this.type === EEntityStateDetailType.NEW) {
      this.model = {
        cab: {
          faccbIdOrigem: 0,
          nDocfaOrigem: 0,
          nDocOrigem: 0,
          nNumerOrigem: 0,
          nDocfaDestino: 0,
          nDocDestino: 0,
          nNumerDestino: 0,
          dataDoc: undefined
        },
        linhas: []
      };

      if (this._transition.params()) {
        const params = <ITransfArmazemParams>this._transition.params();
        if (isObject(params.doc)) {
          this.model = params.doc;
        } else {
          this.addLine();
        }
      } else {
        this.addLine();
      }
    }
    this._evaluateListaTransfArmazem();
  }

  public addLine(): void {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(TransferenciasArmazensNewLineModalComponent, {size: 'md'});
    const componentInstance: TransferenciasArmazensNewLineModalComponent = modalInstance.componentInstance;
    componentInstance.listatransfarmazem = this.model.linhas.slice();
    componentInstance.configTransfArmazem = this.configTransfArmazem;
    modalInstance.result.then((item: ITransfArmazem) => {
      item = {
        ...item,
        facliIdOrigem: 0,
        nomeArtigoOrigem: item.nomeOrigemArtigo,
        nomeArtigoDestino: item.nomeDestinoArtigo,
        nomeArmazemOrigem: item.nomeOrigemArmazem,
        nomeArmazemDestino: item.nomeDestinoArmazem
      };
      this.model.linhas.push(item);
      this._evaluateListaTransfArmazem();
    });
  }

  public deleteLine(index: number): void {
    this.model.linhas.splice(index, 1);
    this._evaluateListaTransfArmazem();
  }

  public editLine(item: ITransfArmazem, index: number): void {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(TransferenciasArmazensNewLineModalComponent, {size: 'md'});
    const componentInstance: TransferenciasArmazensNewLineModalComponent = modalInstance.componentInstance;
    componentInstance.listatransfarmazem = this.model.linhas.slice();
    componentInstance.linhaTransferencia = item;
    componentInstance.configTransfArmazem = this.configTransfArmazem;

    const key = `${item.origemNArtigo}_${item.origemNArmazem}_${item.origemNLoteEspecifico}`;
    if (key in this._dicStockArtigos) {
      componentInstance.stockItem = this._dicStockArtigos[key];
    } else {
      componentInstance.stockItem = {
        origemNArmazem: item.origemNArmazem,
        destinoNArmazem: item.destinoNArmazem,
        loteEspecifico: item.origemNLoteEspecifico,
        faccbIdOrigem: this.model.cab.faccbIdOrigem,
        qtd: item.origemQtd
      };
    }
    modalInstance.result.then((editedItem: ITransfArmazem) => {
      if (this.model.cab.faccbIdOrigem > 0 && item.facliIdOrigem > 0) {
        if (key in this._dicStockArtigos) {
          componentInstance.stockItem.qtd += item.origemQtd;
        } else {
          this._dicStockArtigos[key] = {
            origemNArmazem: item.origemNArmazem,
            destinoNArmazem: item.destinoNArmazem,
            loteEspecifico: item.origemNLoteEspecifico,
            faccbIdOrigem: this.model.cab.faccbIdOrigem,
            qtd: item.origemQtd
          };
        }
      }

      this.model.linhas[index] = {
        ...editedItem,
        facliIdOrigem: editedItem.isDocSuporte ? editedItem.facliIdOrigem : 0,
        nomeArtigoOrigem: editedItem.nomeOrigemArtigo,
        nomeArtigoDestino: editedItem.nomeDestinoArtigo,
        nomeArmazemOrigem: editedItem.nomeOrigemArmazem,
        nomeArmazemDestino: editedItem.nomeDestinoArmazem
      };
    });
  }

  private async _processarModal(): Promise<void> {
    if (this.model.cab.faccbIdSuporte > 0) {
      for (const [index, item] of this.model.linhas.entries()) {
        if (item.temLote && !item.origemNLoteEspecifico) {
          this._plAlertService.error('transferenciasarmazens.messages.loteNulo');
          this.editLine(item, index);
          return;
        }
      }
    }

    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(TransferenciasArmazensProcessarModalComponent, {size: 'sm'});
    const componentInstance: TransferenciasArmazensProcessarModalComponent = modalInstance.componentInstance;
    if (!this.model.cab.dataDoc) {
      this.model.cab.dataDoc = moment();
    }
    componentInstance.doc = this.model;
    await modalInstance.result.then(() => {
      this.model.linhas = [];
      this.model.cab.faccbIdSuporte = 0;
      this._plAlertService.success('transferenciasarmazens.messages.transfarmsuccess');
      this._evaluateListaTransfArmazem();
    });
  }

  private _evaluateListaTransfArmazem(): void {
    this.dataGridDefinition.dataSource = this.model.linhas;
    this._btnProcess.disabled = !this.model.linhas.length && this.type === EEntityStateDetailType.NEW;
  }
}
