import {Component, Injector} from '@angular/core';
import {isUndefinedOrNull} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {AtRecibosRendasService} from '../../atRecibosRendas.service';
import {IEntityAutocompleteCustomAction} from '../../../../../components/entity/entity.autocomplete.definition.interface';
import {ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS} from '../../../manutencao/predefinidos/preDefinidosContab.entity.interface';
import {IJsonRRConfig} from '../../jsonATRecibosRendas.interface';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IEntityMaintenanceInstance} from '../../../../../components/entity/maintenance/entity/entity.maintenance.interface';

@Component({
  selector: 'at-rr-config-modal',
  templateUrl: './atRecibosRendas.config.modal.html'
})
export class AtRecibosRendasConfigModalComponent extends CGModalComponent<void> {
  public readonly promise: Promise<void>;
  public model: IJsonRRConfig;

  public readonly customActionPreDefinido: IEntityAutocompleteCustomAction;
  private _maintenancePreDefinidos: IEntityMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _atFaturasRecibosVerdesService: AtRecibosRendasService,
    private readonly _entityMaintenanceService: EntityMaintenanceService
  ) {
    super(_injector);
    this.model = {preDefinidoId: undefined, preDefinidoStr: undefined};
    this.promise = this._atFaturasRecibosVerdesService.getConfig().then((response: IJsonRRConfig) => {
      this.model = response;
    });

    this.customActionPreDefinido = {
      caption: 'docscontabilidade.text.maintenancePreDefinidos',
      action: async (selectedKey: number): Promise<void> => {
        if (!this._maintenancePreDefinidos) {
          this._maintenancePreDefinidos = this._entityMaintenanceService.build(ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS);
        }
        await this._maintenancePreDefinidos.maintenanceEdit(selectedKey);
      }
    };
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._atFaturasRecibosVerdesService
      .saveConfig(this.model.preDefinidoId)
      .then(() => {
        this.enableClose();
        super.close();
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }

  public onPreDefinidoChanged(value: number): void {
    this.model.preDefinidoId = isUndefinedOrNull(value) ? 0 : value;
  }
}
