<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'gdocscomentarios.titulo'"></h4>
  </div>

  <div class="modal-body">
    <pl-group [properties]="{readonly: isEditing}">
      <label [translate]="'gdocscomentarios.table.titulo'"></label>
      <edit>
        <pl-edit-text attrName="descricao" [(model)]="comment.descricao"></pl-edit-text>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'gdocscomentarios.table.texto'"></label>
      <edit>
        <pl-edit-textarea attrName="descricao" [(model)]="comment.dados"></pl-edit-textarea>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <pl-button klass="btn-primary action-submit" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
    <pl-button klass="btn-light btn-sm" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
