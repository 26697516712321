import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, isArray, isNumber, isObject, isString, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {CGExceptionService} from '../exceptions/exceptions.service';
import {EDocTipoEntity, IJsonGDComment, IJsonGDFolder, IJsonGDFolderContent} from '../../entities/gdoc/JsonGDoc.entity.interface';
import {ENTITY_NAME_GDOC, IGDocEntityService} from '../../entities/gdoc/gdoc.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {ICommentItem, IGDocsComentariosCallback} from './gdocscomentarios.component.interface';
import {TranslateService} from '@ngx-translate/core';
import {catchReasonToMessage} from '../../../common/utils/errors.utils';
import {IDevExpressDataGrid} from '../devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellClick} from '../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {GDocsComentariosEditModalComponent} from './modals/gdocscomentarios.edit.modalcomponent';
import {CGModalService} from '../cg/modal/cgmodal.service';

@Component({
  selector: 'gdoccomentarios-viewer',
  templateUrl: './gdocscomentarios.component.html'
})
export class GDocsComentariosComponent implements OnInit, OnChanges {
  @Input() public folderId: string;
  @Input() public params: string;
  @Input() public tipoDocEntity: EDocTipoEntity;
  @Input() public errorFolderMessage: string;
  @Input() public callback: IGDocsComentariosCallback;
  @Input() public readOnly: boolean;

  public gdocFolder: IJsonGDFolderContent;
  public gdocFolderFile: IJsonGDFolderContent;
  public isLoading: boolean;
  public comments: Array<ICommentItem>;
  public selectedComment: ICommentItem;

  public readonly dataGridDefinition: IDevExpressDataGrid;

  private readonly _serviceGDoc: IGDocEntityService;
  private _selectedFolderId: string;

  constructor(
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService
  ) {
    this.gdocFolder = {
      files: [],
      folder: undefined,
      subfolders: [],
      comments: []
    };
    this.gdocFolderFile = {
      files: [],
      folder: undefined,
      subfolders: [],
      comments: []
    };
    this.comments = [];
    this._serviceGDoc = this._entityServiceBuilder.build<IJsonGDFolder, IGDocEntityService>(ENTITY_NAME_GDOC);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'descricao', dataType: 'string', caption: 'gdocscomentarios.table.titulo', width: 350},
        {dataField: 'dados', dataType: 'string', caption: 'gdocscomentarios.table.texto'},
        {dataField: 'data', dataType: 'date', caption: 'gdocscomentarios.table.data', width: 150},
        {dataField: 'utilizador', dataType: 'string', caption: 'gdocscomentarios.table.utilizador', width: 250}
      ],
      dataSource: [],
      remoteOperations: false,
      height: '58vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      scrolling: {rowRenderingMode: 'virtual', columnRenderingMode: 'virtual'},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbar',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
  }

  public async ngOnInit(): Promise<void> {
    if (!isString(this.params)) {
      this.params = '';
    }
    if (!isNumber(this.tipoDocEntity)) {
      this.tipoDocEntity = EDocTipoEntity.None;
    }
    await this._loadFirstFolder();
  }

  public async ngOnChanges({folderId, callback}: SimpleChanges): Promise<void> {
    if (!folderId?.isFirstChange()) {
      this.folderId = folderId.currentValue;
      await this._loadFirstFolder();
    }

    if (callback) {
      const value: IGDocsComentariosCallback = callback.currentValue;
      if (isObject(value)) {
        value.refresh = () => this._refresh();
        value.refreshSelectedFolder = (selectedFolderId) => this._refreshSelectedFolder(selectedFolderId);
      }
    }
  }

  public selectComment(comment: ICommentItem): void {
    this.selectedComment = comment;
  }

  public async addComment(): Promise<void> {
    if (isUndefinedOrNull(this._selectedFolderId) || !this._selectedFolderId) {
      if (isUndefinedOrNull(this.folderId) || this.folderId === '') {
        await this._checkFolder();
      }
      this._selectedFolderId = this.folderId;
    }

    await this._openComment(undefined);

    return this._refresh();
  }

  public async editComment(): Promise<void> {
    if (isUndefinedOrNull(this._selectedFolderId) || !this._selectedFolderId) {
      this._selectedFolderId = this.folderId;
    }

    if (!this.selectedComment) {
      return this._refresh();
    }

    await this._openComment(this.selectedComment);

    return this._refresh();
  }

  public async removeComment(): Promise<void> {
    if (isUndefinedOrNull(this._selectedFolderId) || !this._selectedFolderId) {
      this._selectedFolderId = this.folderId;
    }

    if (!this.selectedComment) {
      return;
    }

    await this._promptDeleteModal();

    await this._serviceGDoc.deleteComment(String(this.selectedComment.docID), String(this.selectedComment.comentarioID));
    await this._refresh();
  }

  public onCellClick({rowType, data}: IDevExpressDataGridEventOnCellClick<ICommentItem>): void {
    if (rowType === 'data') {
      this.selectComment(data);
    }
  }

  private _loadFirstFolder(): Promise<void> {
    this.gdocFolder = {
      files: [],
      folder: undefined,
      subfolders: [],
      comments: []
    };
    return this._getFolder(this.folderId).then((folderContent: IJsonGDFolderContent) => {
      this.gdocFolder = folderContent;
      this._setCommentsViewer(isArray(this.gdocFolder?.comments) ? this.gdocFolder?.comments : []);
    });
  }

  private _promptDeleteModal(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'gdocscomentarios.confirmacaoDelete', {
      type: 'delete',
      size: 'md',
      icon: 'warning',
      btnOkIcon: 'fa fa-trash fa-fw',
      btnOkText: 'global.btn.delete'
    });
  }

  private _loadComments(folderId: string): Promise<void> {
    this.gdocFolderFile = {
      files: [],
      folder: undefined,
      subfolders: [],
      comments: []
    };
    return this._getFolder(folderId).then((folderContent: IJsonGDFolderContent) => {
      this.gdocFolderFile = folderContent;
      this._setCommentsViewer(this.gdocFolderFile.comments);
    });
  }

  private _setCommentsViewer(comments: Array<IJsonGDComment>): void {
    this.comments = [];
    for (const commentsElement of comments) {
      this.comments.push({
        comentarioID: commentsElement.comentarioID,
        comentarioParentID: commentsElement.comentarioParentID,
        docID: commentsElement.docID,
        descricao: commentsElement.descricao,
        dados: commentsElement.dados,
        data: commentsElement.data,
        utilizador: commentsElement.utilizador
      });
    }
    this.comments = this.comments.slice();
  }

  private async _getFolder(folderId: string): Promise<IJsonGDFolderContent> {
    if (!folderId) {
      const errorMessage = this._translateService.instant('gdocscomentarios.erros.pastaNaoValida');
      throw new Error(errorMessage);
    }
    try {
      const response: HttpResponse<IJsonGDFolderContent> = await this._serviceGDoc.get<IJsonGDFolderContent>({id: folderId, reportExceptions: false});
      return response.body;
    } catch (reason: unknown) {
      const errorMessage = catchReasonToMessage({reason: reason, exceptionService: this._cgExceptionService, translateService: this._translateService, defaultMessage: this.errorFolderMessage});
      this._plAlertService.error(errorMessage);
      throw new Error(errorMessage);
    }
  }

  private async _refresh(): Promise<void> {
    if (!this._selectedFolderId) {
      return;
    }

    this.isLoading = true;
    await this._loadComments(this._selectedFolderId).finally(() => {
      this.isLoading = false;
    });
  }

  private async _refreshSelectedFolder(folderId: string): Promise<void> {
    if (isUndefinedOrNull(folderId) || !folderId) {
      return;
    }
    if (isUndefinedOrNull(this._selectedFolderId) || !this._selectedFolderId) {
      this._selectedFolderId = folderId;
    }
    await this._refresh();
  }

  private async _openComment(comment: ICommentItem): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(GDocsComentariosEditModalComponent);
    const componentInstance: GDocsComentariosEditModalComponent = modalInstance.componentInstance;

    componentInstance.folderId = this._selectedFolderId;
    componentInstance.comment = copy(comment);

    this.selectedComment = await modalInstance.result;
  }

  private async _checkFolder(): Promise<void> {
    const response: HttpResponse<IJsonGDFolder> = await this._serviceGDoc.checkFolder(this.tipoDocEntity, this.params);
    this.folderId = response.body.folderId;
  }
}
