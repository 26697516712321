import {Observable, of, Subscriber} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {blobToDataURI, dataURIToBlob, newFile} from '../files/files';
import {ExifRestore} from '../exif/exif.restore';
import {IMAGE_RESIZER_DEFAULT_RESIZE_QUALITY, IPlImageResizerProperties, IPlResizedImage, IPlResizedImageInfo, ONE_KIBIBYTE, TPlImageResizerMethod} from './image.resizer.interface';
import {isEmpty, isNumber} from '../utilities/utilities';

const DIVIDE_BY_HALF = 2;
const REGEX_IMAGE = /image.*/;
const DEFAULT_RESIZE_TARGET_SIZE_STEP = 0.1;

export class PlImageResizer {
  public static resizeImage(blob: Blob, properties: Partial<IPlImageResizerProperties>): Observable<IPlResizedImage> {
    if (!PlImageResizer.canResize(blob, properties)) {
      return undefined;
    }
    if (isEmpty(properties.resizeMimeType)) {
      properties.resizeMimeType = blob.type;
      if (isEmpty(properties.resizeMimeType)) {
        properties.resizeMimeType = 'image/png';
      }
    }
    if (!isNumber(properties.resizeQuality)) {
      properties.resizeQuality = IMAGE_RESIZER_DEFAULT_RESIZE_QUALITY;
    }
    if (isEmpty(properties.resizeMethod)) {
      properties.resizeMethod = 'contain';
    }
    return PlImageResizer._createImage(blob, properties).pipe(
      mergeMap<IPlResizedImage, Observable<IPlResizedImage>>((resizedImage: IPlResizedImage) => {
        return PlImageResizer._ensureTargetSize(resizedImage, properties);
      })
    );
  }

  public static resizeImageToUrl(blob: Blob, properties: Partial<IPlImageResizerProperties>): Observable<string> {
    if (!PlImageResizer.canResize(blob, properties)) {
      return blobToDataURI(blob);
    }
    return PlImageResizer.resizeImage(blob, properties).pipe(map((image: IPlResizedImage) => image.dataURL));
  }

  public static resizeImageToBlob(blob: Blob, properties: Partial<IPlImageResizerProperties>): Observable<Blob> {
    if (!PlImageResizer.canResize(blob, properties)) {
      return of(blob);
    }
    return PlImageResizer.resizeImage(blob, properties).pipe(map((image: IPlResizedImage) => dataURIToBlob(image.dataURL)));
  }

  public static resizeImageToFile(file: File, properties: Partial<IPlImageResizerProperties>): Observable<File> {
    if (!PlImageResizer.canResize(file, properties)) {
      return of(file);
    }

    function blobToFile(blob: Blob): File {
      return newFile(blob, file.name, {type: properties.resizeMimeType, lastModified: Date.now()});
    }

    return PlImageResizer.resizeImageToBlob(file, properties).pipe(map<Blob, File>(blobToFile));
  }

  public static canResize(blob: Blob, properties: Partial<IPlImageResizerProperties>): boolean {
    return (Boolean(properties.resizeWidth) || Boolean(properties.resizeHeight) || Boolean(properties.resizeTargetSize)) && Boolean(REGEX_IMAGE.exec(blob.type));
  }

  private static _createImage(blob: Blob, properties: Partial<IPlImageResizerProperties>): Observable<IPlResizedImage> {
    function subscribe(subscriber: Subscriber<IPlResizedImage>): void {
      const result: IPlResizedImage = {canvas: undefined, dataURL: undefined, width: undefined, height: undefined, resizeInfo: undefined};
      const fileReader = new FileReader();
      fileReader.onload = () => {
        // Don't bother creating an image for SVG since it's a vector
        if (blob.type === 'image/svg+xml') {
          subscriber.next(result);
          subscriber.complete();
        } else {
          result.dataURL = <string>fileReader.result;
          PlImageResizer._createImageFromDataUrl(result, properties).subscribe({
            next: (value: IPlResizedImage) => {
              subscriber.next(value);
              subscriber.complete();
            },
            error: (error: unknown) => {
              subscriber.error(error);
            }
          });
        }
      };
      fileReader.onerror = (error: unknown) => {
        subscriber.error(error);
      };
      fileReader.onabort = () => {
        subscriber.next(result);
        subscriber.complete();
      };
      fileReader.readAsDataURL(blob);
    }

    return new Observable<IPlResizedImage>(subscribe);
  }

  private static _createImageFromDataUrl(
    image: IPlResizedImage,
    {resizeWidth, resizeHeight, resizeMethod, resizeMimeType, resizeQuality}: Partial<IPlImageResizerProperties>
  ): Observable<IPlResizedImage> {
    function subscribe(subscriber: Subscriber<IPlResizedImage>): void {
      const imageElement: HTMLImageElement = window.document.createElement<'img'>('img');
      imageElement.onload = () => {
        image.width = imageElement.width;
        image.height = imageElement.height;
        const resizeInfo: IPlResizedImageInfo = calculateResizeInfo(image.width, image.height, resizeWidth, resizeHeight, resizeMethod);
        image.resizeInfo = resizeInfo;
        const canvasElement: HTMLCanvasElement = window.document.createElement<'canvas'>('canvas');
        canvasElement.width = resizeInfo.targetWidth;
        canvasElement.height = resizeInfo.targetHeight;
        const sourceX: number = resizeInfo.srcX ? resizeInfo.srcX : 0;
        const sourceY: number = resizeInfo.srcY ? resizeInfo.srcY : 0;
        const sourceWidth: number = resizeInfo.srcWidth;
        const sourceHeight: number = resizeInfo.srcHeight;
        const targetX = 0;
        const targetY = 0;
        const targetWidth: number = resizeInfo.targetWidth;
        const targetHeight: number = resizeInfo.targetHeight;
        PlImageResizer._drawImage(imageElement, canvasElement, sourceX, sourceY, sourceWidth, sourceHeight, targetX, targetY, targetWidth, targetHeight);
        const originalDataURL = image.dataURL;
        image.dataURL = canvasElement.toDataURL(resizeMimeType, resizeQuality);
        image.canvas = canvasElement;
        image.width = canvasElement.width;
        image.height = canvasElement.height;
        if (resizeMimeType === 'image/jpeg' || resizeMimeType === 'image/jpg') {
          // Add the original EXIF information
          image.dataURL = ExifRestore.restore(originalDataURL, image.dataURL);
        }
        subscriber.next(image);
        subscriber.complete();
      };
      imageElement.onerror = (error: unknown) => {
        subscriber.error(error);
      };
      imageElement.src = image.dataURL;
    }

    return new Observable<IPlResizedImage>(subscribe);
  }

  private static _drawImage(
    imageElement: HTMLImageElement,
    canvasElement: HTMLCanvasElement,
    sourceX: number,
    sourceY: number,
    sourceWidth: number,
    sourceHeight: number,
    targetX: number,
    targetY: number,
    targetWidth: number,
    targetHeight: number
  ): void {
    const verticalSquashRatio: number = detectVerticalSquash(imageElement);
    targetHeight /= verticalSquashRatio;
    const context = canvasElement.getContext('2d');
    context.drawImage(imageElement, sourceX, sourceY, sourceWidth, sourceHeight, targetX, targetY, targetWidth, targetHeight);
  }

  private static _ensureTargetSize(image: IPlResizedImage, properties: Partial<IPlImageResizerProperties>): Observable<IPlResizedImage> {
    if (!isNumber(properties.resizeTargetSize) || properties.resizeTargetSize <= 0) {
      return of(image);
    }

    const resizeTargetSizeMb: number = properties.resizeTargetSize * ONE_KIBIBYTE * ONE_KIBIBYTE;
    const resizeProperties: Partial<IPlImageResizerProperties> = {...properties};

    const ensureTargetSizeOperator = mergeMap<Blob, Observable<IPlResizedImage>>((resizedBlob: Blob) => {
      if (resizedBlob.size <= resizeTargetSizeMb) {
        return of(image);
      }

      if (image.height > image.width) {
        if (!isNumber(resizeProperties.resizeTargetSizeStep) || resizeProperties.resizeTargetSizeStep <= 0) {
          resizeProperties.resizeTargetSizeStep = Math.max(1, Math.floor(image.height * DEFAULT_RESIZE_TARGET_SIZE_STEP));
        }
        resizeProperties.resizeWidth = undefined;
        resizeProperties.resizeHeight = Math.max(0, Math.floor(image.height - properties.resizeTargetSizeStep));
        if (!resizeProperties.resizeHeight) {
          return of(image);
        }
      } else {
        if (!isNumber(resizeProperties.resizeTargetSizeStep) || resizeProperties.resizeTargetSizeStep <= 0) {
          resizeProperties.resizeTargetSizeStep = Math.max(1, Math.floor(image.width * DEFAULT_RESIZE_TARGET_SIZE_STEP));
        }
        resizeProperties.resizeHeight = undefined;
        resizeProperties.resizeWidth = Math.max(0, Math.floor(image.width - properties.resizeTargetSizeStep));
        if (!resizeProperties.resizeWidth) {
          return of(image);
        }
      }

      return PlImageResizer._createImage(resizedBlob, resizeProperties)
        .pipe(
          mergeMap<IPlResizedImage, Observable<Blob>>((resizedImage: IPlResizedImage) => {
            image = resizedImage;
            return of(dataURIToBlob(image.dataURL));
          })
        )
        .pipe(ensureTargetSizeOperator);
    });

    return of(dataURIToBlob(image.dataURL)).pipe(ensureTargetSizeOperator);
  }
}

export function calculateResizeInfo(imageWidth: number, imageHeight: number, resizeWidth: number, resizeHeight: number, resizeMethod: TPlImageResizerMethod): IPlResizedImageInfo {
  const info: IPlResizedImageInfo = {
    srcX: 0,
    srcY: 0,
    srcWidth: imageWidth,
    srcHeight: imageHeight,
    targetWidth: undefined,
    targetHeight: undefined
  };
  const srcRatio: number = imageWidth / imageHeight;

  // Automatically calculate dimensions if not specified
  if (!resizeWidth && !resizeHeight) {
    resizeWidth = info.srcWidth;
    resizeHeight = info.srcHeight;
  } else if (!resizeWidth) {
    resizeWidth = resizeHeight * srcRatio;
  } else if (!resizeHeight) {
    resizeHeight = resizeWidth / srcRatio;
  }

  // Make sure images aren't up-scaled
  resizeWidth = Math.min(resizeWidth, info.srcWidth);
  resizeHeight = Math.min(resizeHeight, info.srcHeight);

  const targetRatio: number = resizeWidth / resizeHeight;

  if (info.srcWidth > resizeWidth || info.srcHeight > resizeHeight) {
    // Image is bigger and needs rescaling
    if (resizeMethod === 'crop') {
      if (srcRatio > targetRatio) {
        info.srcHeight = imageHeight;
        info.srcWidth = info.srcHeight * targetRatio;
      } else {
        info.srcWidth = imageWidth;
        info.srcHeight = info.srcWidth / targetRatio;
      }
    } else if (resizeMethod === 'contain') {
      // Method 'contain'
      if (srcRatio > targetRatio) {
        resizeHeight = resizeWidth / srcRatio;
      } else {
        resizeWidth = resizeHeight * srcRatio;
      }
    } else {
      throw new Error(`Unknown resizeMethod '${String(resizeMethod) || '(unknown)'}'`);
    }
  }

  info.srcX = (imageWidth - info.srcWidth) / DIVIDE_BY_HALF;
  info.srcY = (imageHeight - info.srcHeight) / DIVIDE_BY_HALF;

  info.targetWidth = resizeWidth;
  info.targetHeight = resizeHeight;

  return info;
}

export function detectVerticalSquash(imageElement: HTMLImageElement): number {
  // eslint-disable no-bitwise no-magic-numbers
  const imageHeight = imageElement.naturalHeight;
  const canvas: HTMLCanvasElement = document.createElement<'canvas'>('canvas');
  canvas.width = 1;
  canvas.height = imageHeight;
  const context: CanvasRenderingContext2D = canvas.getContext('2d');
  context.drawImage(imageElement, 0, 0);
  const {data} = context.getImageData(1, 0, 1, imageHeight);
  // Search image edge pixel position in case it is squashed vertically.
  let sy = 0;
  let ey = imageHeight;
  let py = imageHeight;
  while (py > sy) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const alpha = data[(py - 1) * 4 + 3];
    if (alpha === 0) {
      ey = py;
    } else {
      sy = py;
    }
    // eslint-disable-next-line no-bitwise
    py = (ey + sy) >> 1;
  }
  const ratio = py / imageHeight;
  return ratio === 0 ? 1 : ratio;
  // eslint-enable no-bitwise no-magic-numbers
}
