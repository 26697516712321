import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

const DECLARATIONS = [];

@NgModule({
  imports: [CommonModule],
  exports: DECLARATIONS
})
export class CGCBarcodeModule {}
