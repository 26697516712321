<div class="item-group-dropdown" tabindex="-1" ngbDropdown [placement]="placement" [container]="dropdownContainer" (openChange)="openChanged($event)">
  <div class="item-group-dropdown-toggle" ngbDropdownToggle data-capture-click>
    <i class="hollow-form-icon pl-icon-more-vertical" aria-hidden="true"></i>
  </div>

  <div class="dropdown-menu" ngbDropdownMenu>
    <ng-container *ngTemplateOutlet="templateContent?.templateRef"></ng-container>

    <ng-container *ngFor="let customEntityAction of customActions">
      <button *ngIf="customEntityAction.visible !== false" type="button" class="dropdown-item" [click]="executeAction(customEntityAction)" plPromise>
        <ng-container *ngIf="customEntityAction.icon"><i [cgcIcon]="customEntityAction.icon"></i>&nbsp;</ng-container>
        <span [translate]="customEntityAction.caption"></span>
      </button>
    </ng-container>
  </div>
</div>
