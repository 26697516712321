import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {Component, Injector, OnInit} from '@angular/core';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {EDGEMPSEstado} from '../../dgemps/dgemps.module.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IColaboradoresAltEntidades} from '../alteracoesEntidades.module.interface';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonPRHEntAlter} from '../../../../entities/prhentalters/jsonPRHEntAlter.entity.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment from 'moment';
import {IPRHEntAlter} from '../../../../entities/prhentalters/pRHEntAlers.entity.interface';
import {dataSourceRHSituacaoCivilTitular} from '../../../portalrh/dgempsfull/dgempsFull.interface';
import {EDgempsFichaChangableFields} from '../../dgemps/ficha/dgempsFicha.module.interface';

@Component({
  selector: 'module-colaboradores-alteracoes-entidades',
  templateUrl: './alteracoesEntidades.module.component.html'
})
export class AlteracoesEntidadesModuleComponent extends ModuloComponent implements OnInit {
  public readonly definition: IDevExpressDataGrid;
  public readonly definitionMasterDetail: IDevExpressDataGrid;

  private readonly _prhentalters: IEntityService<IJsonPRHEntAlter>;
  private _codEmp: number;
  private _colaboradores: Array<IColaboradoresAltEntidades>;
  private _dataGridInstance: dxDataGrid<IColaboradoresAltEntidades, IColaboradoresAltEntidades>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.definition = {
      columns: [
        {dataField: 'codEmp', dataType: 'string', caption: 'dgemps.fields.codEmp'},
        {dataField: 'nome', dataType: 'string', caption: 'dgemps.fields.nome'}
      ],
      dataSource: new CustomStore({
        load: () => this._getList()
      }),
      searchPanel: {visible: true},
      grouping: {contextMenuEnabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      columnChooser: {enabled: false},
      hoverStateEnabled: true,
      masterDetail: {
        enabled: true,
        template: 'detail',
        autoExpandAll: false
      }
    };

    this.definitionMasterDetail = {
      columns: [
        {dataField: 'descricaoCampo', dataType: 'string', caption: 'dgemps.fields.masterDetail.campo'},
        {dataField: 'descricaoValorAntigo', dataType: 'string', caption: 'dgemps.fields.masterDetail.antigo'},
        {dataField: 'descricaoValor', dataType: 'string', caption: 'dgemps.fields.masterDetail.novo'},
        {
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false,
          width: 150
        }
      ],
      searchPanel: {visible: false},
      filterRow: {visible: false},
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      grouping: {contextMenuEnabled: false},
      columnFixing: {enabled: false},
      groupPanel: {
        allowColumnDragging: false,
        visible: false
      }
    };
    this._prhentalters = this._entityServiceBuilder.build<IJsonPRHEntAlter>('prhentalters');
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._codEmp = this.session.erp.codEmp;
    this._fillColaboradores();
  }

  public onDataGridInitializedDadosAAlterar(event: IDevExpressDataGridEventOnInitialized<IColaboradoresAltEntidades, IColaboradoresAltEntidades>): void {
    this._dataGridInstance = event.component;
  }

  public onDataGridCellClickDadosAAlterar(event: IDevExpressDataGridEventOnCellClick<IColaboradoresAltEntidades>): void {
    devExpressDataGridExpandDetailHandler(event);
  }

  public aprovar(colaborador: IColaboradoresAltEntidades, item: IJsonPRHEntAlter): Promise<void> {
    return this._aprovarRejeitar(colaborador, item, EDGEMPSEstado.Aprovado);
  }

  public rejeitar(colaborador: IColaboradoresAltEntidades, item: IJsonPRHEntAlter): Promise<void> {
    return this._aprovarRejeitar(colaborador, item, EDGEMPSEstado.Rejeitado);
  }

  private _fillColaboradores(): void {
    this._prhentalters.query({pesquisa: 'estado=0'}).then((data) => {
      this._colaboradores = [];
      for (const item of data.body.list) {
        const itemNew: IPRHEntAlter = {
          ...item,
          descricaoValor:
            item.campo === EDgempsFichaChangableFields.SITUACAO_CIVIL_TITULAR
              ? dataSourceRHSituacaoCivilTitular(this._translateService).find((value) => value.value.toString() === item.valor)?.label || item.valor
              : item.valor,
          descricaoValorAntigo:
            item.campo === EDgempsFichaChangableFields.SITUACAO_CIVIL_TITULAR
              ? dataSourceRHSituacaoCivilTitular(this._translateService).find((value) => value.value.toString() === item.valorAntigo)?.label || item.valorAntigo
              : item.valorAntigo
        };

        let existe = false;
        for (const colaborador of this._colaboradores) {
          if (colaborador.codEmp === itemNew.codEmp) {
            existe = true;
            colaborador.lista.push(itemNew);
          }
        }
        if (!existe) {
          const myLista = [];
          myLista.push(itemNew);
          this._colaboradores.push({codEmp: itemNew.codEmp, nome: itemNew.nomeEmp, lista: myLista, detailOpen: false});
        }
      }
      this._getList();
      this._dataGridInstance.refresh();
    });
  }

  private _getList(): Array<IColaboradoresAltEntidades> {
    if (!this._colaboradores) {
      return [];
    }

    this._colaboradores.forEach((item) => {
      item.detailOpen = false;
    });
    return this._colaboradores;
  }

  private _aprovarRejeitar(colaborador: IColaboradoresAltEntidades, item: IJsonPRHEntAlter, estado: EDGEMPSEstado): Promise<void> {
    item.estado = estado;
    item.codEmpAprovou = this._codEmp;
    item.dataAprovou = moment();
    return this._prhentalters.put({url: `${item.codPrhEntAlter}/aprovacaoalteracao`, body: item}).then(() => {
      for (const itemColaborador of this._colaboradores) {
        if (itemColaborador.codEmp === colaborador.codEmp) {
          for (let m = 0; m < colaborador.lista.length; m++) {
            if (item.codPrhEntAlter === colaborador.lista[m].codPrhEntAlter) {
              colaborador.lista.splice(m, 1);
              break;
            }
          }
          break;
        }
      }
    });
  }
}
