import {Component, Injector, OnInit} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonPeriodoReembolsoIva, IJsonPeriodosReembolsoIva, IJsonPeriodosRelacaoReembolsoIva, IJsonReembolsoIva} from '../../jsonReembolsoIva.entity.interface';
import {IReembolsosIvaEntityService} from '../../reembolsosIva.entity.interface';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'reembolsosiva-new',
  templateUrl: './reembolsosIva.entity.new.component.html'
})
export class ReembolsosivaNewComponent extends ModuloEntityDetailComponent<IJsonReembolsoIva, IReembolsosIvaEntityService> implements OnInit {
  public readonly periodoRowTemplate: string = '{{periodo}} - {{anoCivil}} - {{nome}}';
  public readonly periodoOutput: string = 'periodo';

  public ngForm: FormGroupDirective;
  public periodosDeclaracao: Array<IJsonPeriodoReembolsoIva>;
  public periodosRelacao: Array<string>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public activePeriodosRelacao: {0: boolean; 1: boolean; 2: boolean};
  public errorClosed: boolean;
  public promise: Promise<void>;

  private _periodosEncerrados: Array<string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.errorClosed = false;
    this.periodosDeclaracao = [];
    this.periodosRelacao = [];
    this._periodosEncerrados = [];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.activePeriodosRelacao = {0: false, 1: false, 2: false};

    this.service.getPeriodosDeclaracao().then((response: HttpResponse<IJsonPeriodosReembolsoIva>) => {
      this.periodosDeclaracao = response.body.periodos;
      this.periodosDeclaracao.forEach((item: IJsonPeriodoReembolsoIva) => {
        if (item.encerrado) {
          this._periodosEncerrados.push(item.periodo);
        }
      });
    });

    this.btnSave.caption = 'global.btn.process';
    this.btnSave.class = 'btn-success';
    this.btnSave.iconLeft = '<i class="fa fa-bolt fa-fw"></i>';
    this.btnSave.disabled = true;
  }

  public save(): Promise<IJsonReembolsoIva> {
    this.model.periodosRelacao = this.periodosRelacao.filter((periodo: string, index: number) => this.activePeriodosRelacao[index]);
    return super.save().then((response: IJsonReembolsoIva) => {
      if (!response.temErros) {
        this._plAlertService.success(this._translateService.instant('reembolsosiva.saved', {id: response.periodoDeclaracao}));
      } else {
        this._plAlertService.warning(this._translateService.instant('reembolsosiva.error', {id: response.periodoDeclaracao}));
      }
      this._stateService.go(this.states.detail.name, {id: response.periodoDeclaracao, model: response});
      return response;
    });
  }

  public changedPeriodosDeclaracao(value: IJsonPeriodoReembolsoIva): void {
    const periodo: string = value.periodo;
    this.btnSave.disabled = isEmpty(periodo);
    this.model.periodoDeclaracao = periodo;
    this.errorClosed = this._periodosEncerrados.includes(periodo);
    this.promise = this.service.getPeriodosRelacao(this.model.periodoDeclaracao).then((response: HttpResponse<IJsonPeriodosRelacaoReembolsoIva>) => {
      this.periodosRelacao = response.body.periodosRelacao;
    });
  }
}
