import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonDocTransfArmazem} from '../../jsonTransferenciasArmazens.entity.interface';
import {TransferenciasArmazensService} from '../../transferenciasArmazens.entity.service';

@Component({
  selector: 'modal-transferencias-armazens-processar',
  templateUrl: './transferenciasArmazens.processar.modal.component.html'
})
export class TransferenciasArmazensProcessarModalComponent extends CGModalComponent<void> {
  @Input() public doc: IJsonDocTransfArmazem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _transferenciasArmazensService: TransferenciasArmazensService
  ) {
    super(_injector);
  }

  public close(): Promise<void> {
    return this._transferenciasArmazensService.processarTransfArmazens(this.doc.cab.dataDoc, this.doc.linhas, this.doc.cab.faccbIdSuporte, this.doc.cab.faccbIdOrigem).then(() => {
      return super.close();
    });
  }
}
