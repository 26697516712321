import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {TDate} from '../../../common/dates';
import {IJsonConfigTransfArmazem, IJsonDocTransfArmazem, IJsonDocTransfArmazemCab, IJsonTransfArmazem} from './jsonTransferenciasArmazens.entity.interface';
import {IJsonNumber} from '../../../common/interfaces/json';
import {IApiQueryRequestConfig, TServiceQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class TransferenciasArmazensService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/transferenciasarmazens`;
  }

  public getConfiguracoesTransfArmazens(): TServiceResponse<IJsonConfigTransfArmazem> {
    return this._apiService.get<IJsonConfigTransfArmazem>({url: `${this._path}/config`});
  }

  public getQtdStockArtigoArmazem(nartigo: string, narmazem: number, nloteEspecifico?: string): TServiceResponse<IJsonNumber> {
    return this._apiService.get<IJsonNumber>({url: `${this._path}/stock`, params: {nartigo: nartigo, narmazem: narmazem, nloteespecifico: nloteEspecifico}});
  }

  public processarTransfArmazens(data: TDate, listatransfarmazem: Array<IJsonTransfArmazem>, faccbSuporte: number, faccbOrigem: number): TServiceResponse<void> {
    return this._apiService.post<void, Array<IJsonTransfArmazem>>({
      url: this._path,
      body: listatransfarmazem,
      params: {data: data, faccbidsuporte: faccbSuporte, faccbidorigem: faccbOrigem ? faccbOrigem : undefined}
    });
  }

  public postConfiguracoesTransfArmazens(configTransfArmazem: IJsonConfigTransfArmazem): TServiceResponse<void> {
    return this._apiService.post<void, IJsonConfigTransfArmazem>({
      url: `${this._path}/config`,
      body: configTransfArmazem
    });
  }

  public query(config?: IApiQueryRequestConfig): TServiceQueryResponse<IJsonDocTransfArmazemCab> {
    return this._apiService.query(config);
  }

  public getDocDetail(ndocfa: number, nnumer: number, ndocumento: number): TServiceResponse<IJsonDocTransfArmazem> {
    return this._apiService.get<IJsonDocTransfArmazem>({
      url: `${this._path}/doc`,
      params: {
        ndocfa: ndocfa,
        nnumer: nnumer,
        ndocumento: ndocumento
      }
    });
  }
}
