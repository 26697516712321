import {TDate} from '../../../common/dates';

export enum EDocTipoEntity {
  None = 0,
  CliFo = 1,
  CliPo = 2,
  Processo = 3,
  Contrato = 4,
  ContratoTrab = 5,
  CRM = 6,
  ApolicesSC = 7,
  DGEMP = 8,
  WebContab = 9,
  Requisicao = 10,
  Obras = 11,
  Empresa = 12,
  MBRefPagam = 13,
  GestaoComercial = 14,
  Logistica = 15,
  Cotacoes = 16,
  QuadroPessoal = 17,
  SegurancaSocial = 18,
  TransfBancarias = 19,
  CCusto = 20,
  EmailOutlook = 21,
  EvEmp = 22,
  Lotes = 23,
  EstrArt = 24,
  ExtCtNotas = 25,
  OpLin = 26,
  PnLin = 27,
  WFInstance = 28,
  ObrasAutos = 29,
  Compras = 30,
  GarantiasBancarias = 31,
  Artigos = 32,
  Contabilidade = 33,
  ManutPlanoExecucao = 34,
  ObrasAdicionais = 35,
  SeguradoraFolhaFerias = 36,
  Imagens = 37,
  PortalReg = 38,
  EFatura = 39,
  DeclaracaoMensalREmuneracoesAT = 40,
  COPE = 41,
  COTFO = 42,
  RelatorioUnico = 43,
  SubDe = 44,
  RHDHEMPCompetencia = 45,
  Modelo3 = 46,
  DigitalFaccb = 47,
  ARCLI = 48,
  TRServicos = 49,
  PCAccao = 50,
  DebitosDiretosAutorizacao = 51,
  DebitosDiretosSEPA = 52,
  LinksUteis = 53,
  TributAutonoTaxas = 54,
  DPIVA = 55,
  EdiImpEncomendas = 56,
  ByGUID = 57,
  IMBEM = 58,
  IMBEMMapas = 59,
  PRHComInt = 60,
  PRHDocsPlata = 61,
  DocsPartilhadosCliente = 62,
  EmpresaLogotipos = 63,
  DeclaracaoPeriodicaIVA = 64,
  SOAPActionsServer = 65,
  DeclaracaoRecapitulativaIVA = 66,
  IRCPagamentosAntecipados = 67,
  Modelo22 = 68,
  IES = 69,
  Modelo10 = 70,
  RetencoesIRSIRCIS = 71,
  RV = 72,
  Rendas = 73,
  CaixaGeralAposentacoes = 74,
  DossierFiscal = 75,
  GTAtividadeProc = 76,
  GTTarefaProc = 77,
  FotoEmpregado = 78,
  Outros = 79,
  ArCla = 80,
  PRHTarefaCab = 81,
  GestaoReservas = 82,
  TesCheque = 83,
  FaturacaoPublica = 84,
  TesLetra = 85,
  AccaoFormacao = 86,
  ERPessoa = 87,
  ConciliacaoBancaria = 88
}

export enum EFolderType {
  Normal,
  Extended,
  System,
  SystemExtended
}

export interface IJsonGDFile {
  docID: string;
  nome: string;
  nDocExterno: string;
  tamanho: string;
  extensao: string;
  deleted: boolean;
  dataModificacao: TDate;
  horaModificacao: TDate;
  dataCriacaoReg: TDate;
  horaCriacaoReg: TDate;
  nomeUtilizActuReg: string;
  path: string;
  folderID: string;
  visualizado: boolean;
  nomeExtensao: string;
  mimeType: string;
}

export interface IJsonGDFolder {
  folderId: string;
  parentId: string;
  name: string;
  folderPath: string;
  haveSubFolders: boolean;
  tag: number;
  folderType?: EFolderType;
  additionalParams?: string;
}

export interface IJsonGDComment {
  comentarioID: string;
  comentarioParentID: string;
  docID: string;
  descricao: string;
  dados: string;
  data: TDate;
  utilizador: string;
}

export interface IJsonGDFolderContent {
  folder: IJsonGDFolder;
  subfolders: Array<IJsonGDFolder>;
  files: Array<IJsonGDFile>;
  comments: Array<IJsonGDComment>;
}

export interface IJsonGDHistory {
  versao: number;
  utilizador: string;
  comentario: string;
  alteracao: string;
  data: TDate;
  hora: TDate;
}
