import {HttpResponse} from '@angular/common/http';
import {StateService, Transition} from '@uirouter/core';
import {ENTITY_NAME_REEMBOLSOS_IVA, IReembolsosIvaDetailParams, IReembolsosIvaEntity, IReembolsosIvaEntityService} from './reembolsosIva.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {EReembolsosIvaPill, EReembolsosIvaTab, REEMBOLSOS_IVA_DEFAULT_VALUE} from './components/detail/reembolsosIva.entity.detail.component.interface';
import {IApiRequestConfig, TServiceResponse} from '../../services/api/api.service.interface';
import {IJsonPeriodosReembolsoIva, IJsonPeriodosRelacaoReembolsoIva, IJsonReembolsoIva, IJsonReembolsoIvaDocumento} from './jsonReembolsoIva.entity.interface';
import {IPortalStates} from '../../services/portals/portals.service.interface';
import {ReembolsosIvaDetailComponent} from './components/detail/reembolsosIva.entity.detail.component';
import {ReembolsosivaNewComponent} from './components/new/reembolsosIva.entity.new.component';
import {ROLE} from '../../services/role.const';
import {TEntityServiceRequestDataWithBody} from '../../services/entity/entity.service.interface';

export const ENTITY_REEMBOLSOS_IVA: IReembolsosIvaEntity = {
  name: ENTITY_NAME_REEMBOLSOS_IVA,
  url: 'reembolsosiva/declaracoes',
  pageTitle: 'global.menu.reembolsosiva',
  roles: [ROLE.CONTABILIDADE],
  searchPlaceholder: 'reembolsosiva.pesquisa',
  metadata: {
    keyName: 'periodoDeclaracao',
    fields: [
      {name: 'periodoDeclaracao', type: 'string', width: '300px', caption: 'reembolsosiva.fields.periodoDeclaracao'},
      {name: 'periodoRelacao', type: 'string', caption: 'reembolsosiva.fields.periodoRelacao'}
    ],
    order: 'periodoDeclaracao desc',
    searchFields: 'periodoDeclaracao'
  },
  actions: {
    new: true,
    detail: true,
    edit: false,
    delete: true,
    search: true,
    filter: false
  },
  new: {
    state: {
      component: ReembolsosivaNewComponent,
      data: {
        pageTitle: 'reembolsosiva.title_new'
      }
    }
  },
  detail: {
    state: {
      urlSuffix: '/:periodoRelacao',
      component: ReembolsosIvaDetailComponent,
      resolve: [
        {
          provide: 'model',
          deps: [StateService, Transition, EntityServiceBuilder, 'states'],
          useFactory: (stateService: StateService, transition: Transition, entityServiceBuilder: EntityServiceBuilder, states: IPortalStates): Promise<IJsonReembolsoIva> => {
            const params: IReembolsosIvaDetailParams = <IReembolsosIvaDetailParams>transition.params();
            if (!params.model && params.id) {
              const serviceReembolsosIVA = entityServiceBuilder.build<IJsonReembolsoIva, IReembolsosIvaEntityService>(ENTITY_NAME_REEMBOLSOS_IVA);
              return serviceReembolsosIVA
                .get({id: params.id})
                .then((response: HttpResponse<IJsonReembolsoIva>) => response.body)
                .catch(() => {
                  stateService.go(states.list.name);
                  return undefined;
                });
            }
            return Promise.resolve(undefined);
          }
        }
      ],
      params: {
        periodoRelacao: {
          type: 'string',
          dynamic: true,
          squash: true,
          value: REEMBOLSOS_IVA_DEFAULT_VALUE
        },
        model: {
          type: 'json',
          value: null
        },
        documentIndex: {
          type: 'int',
          value: null
        },
        typeTab: {
          type: 'string',
          dynamic: true,
          value: EReembolsosIvaTab.Clients
        },
        typePill: {
          type: 'string',
          dynamic: true,
          value: EReembolsosIvaPill.Documents
        }
      },
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        pageTitle: 'reembolsosiva.title_detail'
      }
    }
  },
  service: function () {
    const fnDelete = this.delete.bind(this);

    this.getPeriodosDeclaracao = (config?: IApiRequestConfig): TServiceResponse<IJsonPeriodosReembolsoIva> => {
      return this.apiService.get({url: `${this.apiService.path.restapi}/${this.entity.name}/periodos`, ...config});
    };

    this.getPeriodosRelacao = (periodoDeclaracao: string, config?: IApiRequestConfig): TServiceResponse<IJsonPeriodosRelacaoReembolsoIva> => {
      return this.get({url: `${periodoDeclaracao}/periodosrelacao`, ...config});
    };

    this.mudarParaAtoIsolado = (documento: IJsonReembolsoIvaDocumento, config?: IApiRequestConfig): TServiceResponse<IJsonReembolsoIva> => {
      return this.post({
        url: 'mudarparaatoisolado',
        body: documento,
        ...config
      });
    };

    this.delete = <TResponse = void, TRequest = TResponse>(config?: TEntityServiceRequestDataWithBody<TRequest>): TServiceResponse<TResponse> => {
      config = {url: `periodo/${config?.id}`, ...config};
      return fnDelete(config);
    };

    this.deleteDocument = (id: number, config?: IApiRequestConfig) => {
      config = {url: `documento/${id}`, ...config};
      return fnDelete(config);
    };
  }
};
