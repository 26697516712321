import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {merge} from 'lodash-es';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import moment from 'moment';
import {ConfigService} from '../../../../services/config/config.service';
import {IJsonDocTransfArmazem} from '../../jsonTransferenciasArmazens.entity.interface';

@Component({
  selector: 'tranferencias-armazens-detail',
  templateUrl: './transferenciasArmazens.entity.detail.component.html'
})
export class TransferenciasArmazensDetailComponent implements OnChanges {
  @Input() public doc: IJsonDocTransfArmazem;
  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonDocTransfArmazem>;
  private readonly _decimalsQtd: number;

  private readonly _defaultTransferencia: IJsonDocTransfArmazem;

  constructor(private readonly _configService: ConfigService) {
    this._decimalsQtd = this._configService.configurations.gestaoComercial.decimais.quantidades;
    this._defaultTransferencia = {
      cab: {
        faccbIdOrigem: 0,
        nDocfaOrigem: 0,
        nDocOrigem: 0,
        nNumerOrigem: 0,
        nDocfaDestino: 0,
        nDocDestino: 0,
        nNumerDestino: 0,
        dataDoc: moment()
      },
      linhas: []
    };
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'origemNArtigo', dataType: 'string', caption: 'transferenciasarmazens.detail.table.fields.numeroartigo'},
        {dataField: 'nomeArtigoOrigem', dataType: 'string', caption: 'transferenciasarmazens.detail.table.fields.artigo'},
        {dataField: 'origemNLoteEspecifico', dataType: 'string', caption: 'transferenciasarmazens.detail.table.fields.nLoteEspecifico'},
        {dataField: 'nomeArmazemOrigem', dataType: 'string', caption: 'transferenciasarmazens.detail.table.fields.armazemorigem'},
        {dataField: 'nomeArmazemDestino', dataType: 'string', caption: 'transferenciasarmazens.detail.table.fields.armazemdestino'},
        {dataField: 'destinoQtd', dataType: 'double', caption: 'transferenciasarmazens.detail.table.fields.qtd', format: {decimalsLimit: this._decimalsQtd}}
      ],
      allowColumnReordering: false,
      filterRow: {visible: false},
      headerFilter: {visible: false},
      height: '50vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      rowAlternationEnabled: true,
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'},
      toolbar: {visible: false}
    };
  }

  public ngOnChanges({doc}: SimpleChanges): void {
    if (doc) {
      this._changedDoc(doc.currentValue);
    }
  }

  private _changedDoc(value: IJsonDocTransfArmazem = this.doc): void {
    this.doc = merge<object, IJsonDocTransfArmazem, IJsonDocTransfArmazem>({}, this._defaultTransferencia, value);
  }
}
