import {IJsonDocTransfArmazem, IJsonDocTransfArmazemCab, IJsonTransfArmazem} from './jsonTransferenciasArmazens.entity.interface';
import {TTableLegend} from '../../components/tablelegend/tablelegend.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {IEntityService, TEntityServiceRequestData} from '../../services/entity/entity.service.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IEntityDefinition, IEntityDetailStateParams} from '../../components/entity/entity.definition.interface';

export type TTransfArmazemRequestData = TEntityServiceRequestData & ITransfArmazemRequest;

export interface ITransfArmazemEntityService extends IEntityService<IJsonDocTransfArmazem> {
  get<TResponse = IJsonDocTransfArmazem>(config: TTransfArmazemRequestData): TServiceResponse<TResponse>;
}

export interface ITransfArmazemEntity extends IEntityDefinition<IJsonDocTransfArmazem, ITransfArmazemEntityService> {}

export interface ITransfArmazemRequest {
  id: string;
  ndocfa: number;
  nnumer: number;
  ndocumento: number;
}

export interface ITransfArmazemDetailStateParams extends IEntityDetailStateParams {
  id: string;
  ndocfa?: number;
  nnumer?: number;
  ndocumento?: number;
}

export interface ITransfArmazem extends IJsonTransfArmazem {
  _index?: number;
  nomeOrigemArtigo?: string;
  nomeOrigemArmazem?: string;
  nomeDestinoArtigo?: string;
  nomeDestinoArmazem?: string;
  nArtigoTemLote?: boolean;
}

export interface IDocTransfArmazemCabWithData extends IJsonDocTransfArmazemCab {
  _doc?: IJsonDocTransfArmazem;
}

export interface ITransfArmazemParams {
  doc?: IJsonDocTransfArmazem;
}

export interface IStockChanges {
  origemNArmazem: number;
  destinoNArmazem: number;
  loteEspecifico: string;
  faccbIdOrigem: number;
  qtd: number;
}

export const ENTITY_NAME_TRANSFERENCIAS_ARMAZENS = 'transferenciasarmazens';

export enum ETransfArmazensTableLegendColors {
  TransferenciaAnulada = 'transf-armazens-anulada'
}

export const TABLE_LEGEND_TRANSF_ARMAZENS: TTableLegend = deepFreeze([{caption: 'transferenciasarmazens.list.legend.anulada', badgeCSSClass: ETransfArmazensTableLegendColors.TransferenciaAnulada}]);
