import {EDebitoCredito} from '../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {Moment} from 'moment';
import {TDate} from '../../../../common/dates';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';
import {deepFreeze} from 'pl-comps-angular';

export enum EConciliacaoBancariaMode {
  NEW,
  EDIT
}

export enum EConciliacaoBancariaSelecaoExtrato {
  None,
  Banco,
  Contabilidade
}

export enum EConciliacaoBancariaExtPocEstado {
  NaoSelecionado,
  NaoConciliado,
  Conciliado
}

export enum EConciliacaoBancariaEstadoConciliacaoCab {
  EmAberto,
  Conciliado
}

export enum EConciliacaoBancariaAddLinhaExtratoBancoModalMode {
  OnlyContabilidade,
  OnlyBanco,
  Both
}

export enum EConciliacaoBancariaStatus {
  Inactive,
  Started,
  Ended,
  Timeout,
  Error
}

export enum EConciliacaoBancariaExtratoBancoTipo {
  Undefined = -1,
  None,
  Importada,
  PrimeiraConciliacao,
  PrimeiraConciliacaoContab,
  LinhaBancoAdd
}

export enum EConciliacaoBancariaCommandType {
  CancelJob = 'StopJob',
  LoadConciliacao = 'LoadConciliacao',
  NovaConciliacao = 'NovaConciliacao',
  AssociarTodoExtratoCGAoExtradoDoBanco = 'AssociarTodoExtratoCGAoExtradoDoBanco',
  AssociarExtrato = 'AssociarExtrato',
  InserirLinhaExtratoBanco = 'InserirLinhaExtratoBanco',
  RemoveLinhaExtratoBanco = 'RemoveLinhaExtratoBanco',
  DesassociarExtratoBanco = 'DesassociarExtratoBanco',
  DesassociarExtratoCG = 'DesassociarExtratoCG',
  EliminaConciliacaoBancaria = 'EliminaConciliacaoBancaria',
  MudarEstadoConcilidacao = 'MudarEstadoConcilidacao',
  Editar = 'Editar',
  AssociarExtratoMultiple = 'AssociarExtratoMultiple',
  EliminaConciliacaoBancariaMultiple = 'EliminaConciliacaoBancariaMultiple',
  CarregaDocumentosAnteriores = 'carregaDocumentosAnteriores',
  IncluirDocsDaListaNoExtratoCG = 'IncluirDocsDaListaNoExtratoCG',
  GetConciliacao = 'GetConciliacao',
  RemoveTodasLinhasExtratoBanco = 'RemoveTodasLinhasExtratoBanco',
  ObterSaldos = 'ObterSaldos',
  EliminaTodasConciliacaoBancariaDaConta = 'EliminaTodasConciliacaoBancariaDaConta',
  AddEditLinhaExtratoCG = 'AddEditLinhaExtratoCG',
  RemoveLinhaExtratoCG = 'RemoveLinhaExtratoCG',
  EditLinhaExtratoBanco = 'EditLinhaExtratoBanco',
  LancaLinhasNaContabilidadeEAssocia = 'LancaLinhasNaContabilidadeEAssocia',
  ConnectLines = 'ConnectLines',
  ImportaDocsPendentesBancoConcilAntiga = 'ImportaDocsPendentesBancoConcilAntiga',
  ImportaDocsPendentesContabConcilAntiga = 'ImportaDocsPendentesContabConcilAntiga',
  JustificaExtratoImportado = 'JustificaExtratoImportado',
  LoadImportExtractoBancario = 'LoadImportExtractoBancario',
  GuardaExtractoBancario = 'GuardaExtractoBancario',
  UpdateRegDiario = 'UpdateRegDiario',
  UpdateRegDescritivo = 'UpdateRegDescritivo',
  LoadTemplateImportExtractoBancario = 'LoadTemplateImportExtractoBancario',
  SaveTemplateImportExtractoBancario = 'SaveTemplateImportExtractoBancario',
  RemoveTemplateImportExtractoBancario = 'RemoveTemplateImportExtractoBancario',
  SetTemplateImportExtractoBancario = 'SetTemplateImportExtractoBancario',
  SetContaPendentesBanco = 'SetContaPendentesBanco'
}

export enum EConciliacaoBancariaRecordState {
  IDLE,
  PENDING
}

export enum EConciliacaoBancariaListType {
  ExtratoCG,
  ExtratoBanco
}

export type TConciliacaoBancariaSortableFields = 'dataDocExt' | 'valor' | 'data' | 'descricao' | 'nDoc';

export type TConciliacaoBancariaCommandRawResult<T extends IRestCmdConciliacaoBancaria = IRestCmdConciliacaoBancaria> = Promise<T>;

export type TJsonConciliacaoBancariaCommandResponse<T> = T & {
  commandName: string;
  jobState: EConciliacaoBancariaStatus;
};

export type TConciliacaoBancariaColCaption = 'valor' | 'valorSemDC';

export interface IConciliacaoBancariaLinItem {
  dc: EDebitoCredito;
  valor: number;
  data: TDate;
  descricao: string;
  tipo: EConciliacaoBancariaExtratoBancoTipo;
  concilBancoLinID: string;
  ligadoALancamento: boolean;
  dcStr: string;
  tipoStr: string;
  valorSemDC: number;
  saldo: number;
  nSeq: number;
  stampUpdate: TDate;
  infoMsg: string;
  comErro: boolean;
  jaImportado: boolean;
  eDesteExtrato: boolean;
  nLanc: string;
  nota: string;
  _state?: EConciliacaoBancariaRecordState;
  _selected?: boolean;
}

export interface IConciliacaoBancariaExtPocItem {
  nLanc: string;
  dcStr: string;
  data: Moment;
  descricao: string;
  dc: EDebitoCredito;
  valor: number;
  valorDisplay: string;
  valorSemDCDisplay: string;
  estadoConciliacao: EConciliacaoBancariaExtPocEstado;
  nDoc: string;
  extPocCabID: string;
  nDocExterno: string;
  dataLacam: TDate;
  dataDocExt: TDate;
  valorSemDCInv: number;
  identificado: boolean;
  estadoConciliacaoBool: boolean;
  conciliadoNoPeriodo: string;
  periodo: string;
  linhaPrimeiraConciliacao: boolean;
  linhaAdicionada: boolean;
  eDesteExtrato: boolean;
  _state?: EConciliacaoBancariaRecordState;
  _selected?: boolean;
}

export interface IConciliacaoBancariaExtratoBanco {
  dataInicio: Moment;
  dataFim: Moment;
  periodo: string;
  estado: EConciliacaoBancariaEstadoConciliacaoCab;
  nConta: string;
  saldoInicial: number;
  saldoFinal: number;
  concilBancoCabId: string;
  concilBancoList: Array<IConciliacaoBancariaLinItem>;
  saldoInicialDC: number;
  saldoFinalDC: number;
  saldoFinalCalculado: number;
  nomeConta: string;
  saldoInicialDCStr: string;
  saldoFinalDCStr: string;
  diferencaConciliacao: number;
}

export interface IConciliacaoBancariaExtratoCG {
  extPocList: Array<IConciliacaoBancariaExtPocItem>;
  saldoInicial: number;
  saldoFinal: number;
}

export interface IConciliacaoBancariaStatus {
  requestDate: Moment;
  userStarted: string;
  userStartedId: number;
  state: EConciliacaoBancariaStatus;
  description: string;
  position: number;
  max: number;
  nConta: string;
  periodo: string;
}

export interface IConciliacaoBancariaBancoCab {
  concilBancoCabID: string;
  nConta: string;
  periodo: string;
  saldoInicial: number;
  saldoFinal: number;
  saldoInicialDC: EDebitoCredito;
  saldoFinalDC: EDebitoCredito;
  estado: EConciliacaoBancariaEstadoConciliacaoCab;
  nomeConciliacao: string;
  nomeNConta: string;
}

export interface IRestCmdConciliacaoBancaria {
  jobState?: EConciliacaoBancariaStatus;
  nConta: string;
  periodo: string;
}

export interface IConciliacaoBancaria {
  podeAdicionarDocAnteriores: boolean;
  podeEditarConciliacao: boolean;
  eConcialicaoFimDeAno: boolean;
  radicalContaContrapartida: string;
  extratoCG: IConciliacaoBancariaExtratoCG;
  extratoBanco: IConciliacaoBancariaExtratoBanco;
  saldoInicialImportado: number;
  saldoFinalImportado: number;
  saldoFinalImportadoValido: boolean;
  saldoInicialImportadoDC: number;
  saldoFinalImportadoDC: number;
  saldoContaContraPartida: number;
  saldoContaContraPartidaDC: number;
  temSaldoContaContraPartida: boolean;
  ePrimeiraConciliacao: boolean;
  podeSerEliminada: boolean;
  podeJustificarLinhas: boolean;
  nContaContrapartida: string;
  diario: number;
  descritivo: number;
  usaMoedaEstrangeira: boolean;
  codMoedaEstrangeira: number;
  abrevMoedaEstrangeira: string;
}

export interface IRestCmdConciliacaoBancariaGetConciliacao extends IRestCmdConciliacaoBancaria {
  conciliacao?: IConciliacaoBancaria;
}

export interface IRestCmdConciliacaoBancariaNovaConciliacao extends IRestCmdConciliacaoBancaria, Partial<IConciliacaoBancariaBancoCab> {
  nConta: string;
  periodo: string;
  conciliacaoFechoAno: boolean;
}

export interface IRestCmdConciliacaoBancariaAssociarExtrato extends IRestCmdConciliacaoBancaria {
  nLanc: string;
  concilLinItem?: IConciliacaoBancariaLinItem;
}

export interface IRestCmdConciliacaoBancariaAssociarExtratoMultiple extends IRestCmdConciliacaoBancaria {
  nLancs: Array<string>;
  oneLine: boolean;
}

export interface IRestCmdConciliacaoBancariaIncluirDocsDaListaNoExtratoCG extends IRestCmdConciliacaoBancaria {
  nLancs: Array<string>;
}

export interface IRestCmdConciliacaoBancariaNovaLinhaExtratoBanco extends IRestCmdConciliacaoBancaria, IConciliacaoBancariaNovaLinhaExtratoBanco {
  concilLinItem?: IConciliacaoBancariaLinItem;
  tipo?: EConciliacaoBancariaExtratoBancoTipo;
}

export interface IRestCmdConciliacaoBancariaRemoveLinhaExtratoBanco extends IRestCmdConciliacaoBancaria {
  concilBancoLinIDs: Array<string>;
}

export interface IRestCmdConciliacaoBancariaNovaLinhaExtratoCG extends IRestCmdConciliacaoBancaria, IConciliacaoBancariaAddEditLinhaExtratoCGModal {
  extPocItem?: IConciliacaoBancariaExtPocItem;
}

export interface IRestCmdConciliacaoBancariaRemoveLinhaExtratoCG extends IRestCmdConciliacaoBancaria {
  nLancs: Array<string>;
  concilBancoLinIDs?: Array<string>;
}

export interface IRestCmdConciliacaoBancariaEditarLinhaExtratoBanco extends IRestCmdConciliacaoBancaria, IConciliacaoBancariaEditLinhaExtratoBanco {}

export interface IRestCmdConciliacaoBancariaLancaLinhasNaContabilidadeEAssocia extends IRestCmdConciliacaoBancaria {
  concilLinIds: Array<string>;
  nContaLanc: string;
}

export interface IRestCmdConciliacaoBancariaConnectLines extends IRestCmdConciliacaoBancaria {
  nLancs: Array<string>;
  concilBancoLinIDs: Array<string>;
}

export interface IRestCmdConciliacaoBancariaLoadImportExtractoBancario extends IRestCmdConciliacaoBancaria {
  list?: Array<string>;
  concilBancoCabID: string;
}

export interface IRestCmdConciliacaoBancariaUpdateRegDiario extends IRestCmdConciliacaoBancaria {
  diario: number;
}

export interface IRestCmdConciliacaoBancariaUpdateRegDescritivo extends IRestCmdConciliacaoBancaria {
  descritivo: number;
}

export interface IRestCmdConciliacaoBancariaObterSaldos extends IRestCmdConciliacaoBancaria {
  saldoInicialCG?: number;
  saldoFinalCG?: number;
  saldoInicialBanco?: number;
  saldoFinalBanco?: number;
  saldoFinalBancoCalculado?: number;
  saldoInicialBancoDCStr?: string;
  saldoFinalBancoDCStr?: string;
  diferencaConciliacao?: number;
  extratoBancoEstado?: EConciliacaoBancariaEstadoConciliacaoCab;
}

export interface IRestCmdConciliacaoBancariaEliminaTodasConciliacaoBancariaDaConta extends IRestCmdConciliacaoBancaria {
  nContaBanco: string;
}

export interface IRestCmdConciliacaoBancariaDesassociarExtratoBanco extends IRestCmdConciliacaoBancaria {
  concilBancoLinIDs: Array<string>;
}

export interface IRestCmdConciliacaoBancariaDesassociarExtratoCG extends IRestCmdConciliacaoBancaria {
  nLancs: Array<string>;
}

export interface IRestCmdConciliacaoBancariaEliminaConciliacaoBancaria extends IRestCmdConciliacaoBancaria {
  concilBancoCabId: string;
}

export interface IRestCmdConciliacaoBancariaEliminaConciliacaoBancariaMultiple extends IRestCmdConciliacaoBancaria {
  concilBancoCabIds: Array<string>;
}

export interface IRestCmdConciliacaoBancariaMudarEstadoConcilidacao extends IRestCmdConciliacaoBancaria {
  estado: EConciliacaoBancariaEstadoConciliacaoCab;
}

export interface IRestCmdConciliacaoBancariaCarregaDocumentosAnteriores extends IRestCmdConciliacaoBancaria {
  list?: Array<IConciliacaoBancariaExtPocItem>;
}

export interface IConciliacaoBancariaSaldoInicialBanco {
  existe: boolean;
  saldoInicial: number;
  saldoInicialDC: EDebitoCredito;
}

export interface IRestCmdConciliacaoBancariaEditar extends IRestCmdConciliacaoBancaria, Partial<IConciliacaoBancariaBancoCab> {
  nConta: string;
  periodo: string;
}

export interface IConciliacaoBancariaEditLinhaExtratoBanco {
  concilBancoLinID?: string;
  dc: EDebitoCredito;
  valor: number;
  descricao: string;
  data: TDate;
  valorSemDC?: number;
}

export interface IConciliacaoBancariaNovaLinhaExtratoBanco extends IConciliacaoBancariaEditLinhaExtratoBanco {
  diario: number;
  descritivo: number;
  nContaLanc: string;
}

export interface IConciliacaoBancariaNovaLinhaExtratoBancoModal extends IConciliacaoBancariaNovaLinhaExtratoBanco {
  contaBanco: string;
  radical: string;
}

export interface IRestCmdConciliacaoBancariaLoadTemplateImportExtractoBancario extends IRestCmdConciliacaoBancaria {
  templateName: string;
  list?: Array<string>;
  template?: IConciliacaoBancariaImportTemplateData;
  loadList: boolean;
}

export interface IRestCmdConciliacaoBancariaSaveTemplateImportExtractoBancario extends IRestCmdConciliacaoBancaria {
  templateName: string;
  template: IConciliacaoBancariaImportTemplateData;
}

export interface IRestCmdConciliacaoBancariaRemoveTemplateImportExtractoBancario extends IRestCmdConciliacaoBancaria {
  templateName: string;
}

export interface IRestCmdConciliacaoBancariaSetTemplateImportExtractoBancario extends IRestCmdConciliacaoBancaria {
  bancoTemplate: string;
}

export interface IRestCmdConciliacaoBancariaSetContaPendentesBanco extends IRestCmdConciliacaoBancaria {
  radicalConta: string;
}

export interface IConciliacaoBancariaTreeKey {
  nConta: string;
  periodo: string;
}

export interface IConciliacaoBancariaPrint {
  deNConta: string;
  nomeDeNConta: string;
  ateNConta: string;
  nomeAteNConta: string;
  dePeriodo: string;
  atePeriodo: string;
}

export interface IConciliacaoBancariaLegend {
  text: string;
  cssClass: string;
}

export const TABLE_LEGEND_CONCILIACAO_BANCARIA: TTableLegend = deepFreeze([
  {
    caption: 'conciliacaoBancaria.legend.linhaNConciliada',
    badgeCSSClass: 'linha-nao-conciliada-legend'
  },
  {
    caption: 'conciliacaoBancaria.legend.linhaConciliada',
    badgeCSSClass: 'linha-conciliada'
  },
  {
    caption: 'conciliacaoBancaria.legend.linhaConciliadaPosterior',
    badgeCSSClass: 'linha-conciliada-periodo-posterior'
  },
  {
    caption: 'conciliacaoBancaria.legend.linhaPrimeiraConciliacaoNConc',
    badgeCSSClass: 'linha-primeira-conciliacao-nao-conciliada'
  },
  {
    caption: 'conciliacaoBancaria.legend.linhaPrimeiraConciliacaoConc',
    badgeCSSClass: 'linha-primeira-conciliacao-conciliada'
  }
]);

export interface IConciliacaoBancariaAddEditLinhaExtratoCGModal {
  nLanc?: string;
  descricao: string;
  data: TDate;
  valor: number;
  dc: EDebitoCredito;
}

export interface IConciliacaoBancariaImportExtratoBancarioModel {
  list: Array<string>;
  bancoTemplate: string;
  concilBancoCabID: string;
  saldoInicial: number;
  saldoFinal: number;
  saldoInicialImportado: number;
  saldoFinalImportado: number;
}

export interface IConciliacaoBancariaImportExtratoBancarioUploadResponse {
  list: Array<IConciliacaoBancariaLinItem>;
  saldoInicialImportado: number;
  saldoFinalImportado: number;
  saldoFinalImportadoValido: boolean;
}

export interface IConciliacaoBancariaSort {
  column: TConciliacaoBancariaSortableFields;
  sort: 'asc' | 'desc';
}

// export interface IConciliacaoBancariaConfigImportModel {
//   templateName: string;
//   linhaInicial: number;
//   colDataMovimento: string;
//   colDataValor: string;
//   colDescricao: string;
//   colSaldo: string;
//   valorMovimento: number;
//   colOp1ValorMovimento: string;
//   valNegative: number;
//   valPositive: number;
//   colOp2Debito: string;
//   colOp2Credito: string;
//   op2InverteSinal: boolean;
//   colOp3ValorMovimento: string;
//   colOp3TipoMovimento: string;
//   op3CaraterDebito: string;
//   op3CaraterCredito: string;
// }

export interface IConciliacaoBancariaImportTemplateData {
  tipoMovimento: number;
  colunaValor: string;
  colunaValorDebito: string;
  colunaDescricao: string;
  caraterCredito: string;
  colunaSaldo: string;
  linhaInicial: number;
  colunaTipoMovimento: string;
  colunaDataMovimento: string;
  caraterDebito: string;
  inverteSinal: boolean;
  colunaValorCredito: string;
  colunaDataValor: string;
  valorNegativoLancaCredito: boolean;
  valorPositivoLancaCredito: boolean;
  valorNegativoLancaCreditoIndex?: number;
  valorPositivoLancaCreditoIndex?: number;
}

export interface IConciliacaoBancariaConfigImportValorMovimItem {
  value: number;
  label: string;
}

export interface IConciliacaoBancariaNContaAbrevMoedaEstrangeira {
  abrevMoedaEstrangeira: string;
}

export const MODULE_NAME_CONCILIACAO_BANCARIA = 'conciliacaoBancaria';
