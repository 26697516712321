import {findLast} from 'lodash-es';
import {firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {fromJson} from '@uirouter/core';
import {TranslateService} from '@ngx-translate/core';
import {
  ECGCDateType,
  GLOBAL_EVENT_DOCUMENT_CLICK,
  isBoolean,
  isEmpty,
  isFunction,
  isNumber,
  isObject,
  isString,
  KEYCODES,
  Logger,
  PlGlobalEventsService,
  PlI18nService,
  toJson
} from 'pl-comps-angular';
import {CGLocalStorageGroupService} from '../../../../../services/storage/localstoragegroup.service';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ConfigOptionsService} from '../../../../../services/config/options/config.options.service';
import {ConfigService} from '../../../../../services/config/config.service';
import {CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES} from '../../../manutencao/predefinidos/preDefinidosContab.entity.interface';
import {ContabilidadeDigitalService} from '../../../../../services/contabilidadedigital/contabilidadedigital.service';
import {DocsContabilidadeEditValorModalComponent} from './modals/editvalormodal/docsContabilidade.editValor.modal.component';
import {DocsContabilidadeSavePromptModalComponent} from './modals/savepromptmodal/docsContabilidade.savePrompt.modal.component';
import {ECampoCalculadoME, IJsonDocContabilidade} from '../../jsonDocContabilidade.interface';
import {EClassificacaoControlo, EClassificacaoControloRetencao} from '../../../../../../common/enums/contabilidade.enums';
import {EConfigOptionsInstanceName, IDocContabilidadeConfigOptions, TConfigOptions} from '../../../../../services/config/options/config.options.service.interface';
import {EDebitoCredito} from '../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {EGroupName, EMPTY_GUID} from '../../../../../../config/constants';
import {EPocsClasse} from '../../../../../entities/pocs/pocs.entity.interface';
import {
  EPreDefinidoContabTipoConta,
  IJsonPreDefinidoContab,
  IJsonPreDefinidoContabItemDefault,
  IJsonPreDefinidoContabItemVisibilidadeProperties,
  IJsonPreDefinidoContabLinha,
  TPredefinidoField,
  TPredefinidoVisibilidadeField
} from '../../../manutencao/predefinidos/jsonPreDefinidosContab.entity.interface';
import {generateUUID, numberEquals, round} from '../../../../../../common/utils/utils';
import {ICGConfigContabilidade, ICGConfigurations} from '../../../../../services/config/config.service.interface';
import {IConfigOptionsInstance} from '../../../../../services/config/options/config.options.instance.interface';
import {IDocContabilidade, IDocContabilidadeLinha} from '../../docsContabilidade.interface';
import {
  IDocContabilidadePropertyNameOriginalData,
  IDocContabilidadePropertyNameOriginalValidateData,
  IDocContabilidadeServiceSavePromptDefinition,
  IDocContabilidadeServiceSavePromptInstance
} from './docContabilidade.interface';
import {IJsonContabDigitalConfigs} from '../../../../../services/contabilidadedigital/jsonContabDigital.interface';
import moment, {Moment} from 'moment';
import {SCHEMA_STRING} from '../../../../../../common/schemas';
import {IJsonPOC} from '../../../../../entities/pocs/jsonPOC.entity.interface';

const NAME_BACKUP = 'doccontabilidadebackup';

@Injectable({
  providedIn: 'root'
})
export class DocContabilidadeService implements OnDestroy {
  private readonly _configOptionsDocContabilidade: IConfigOptionsInstance<boolean, IDocContabilidadeConfigOptions>;
  private readonly _savePromptInstances: Map<string, IDocContabilidadeServiceSavePromptInstance>;
  private readonly _savePromptInstanceIds: Array<string>;
  private readonly _clearPromises: Array<Promise<void>>;
  private _savePromptInstanceNextId: number;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _configOptionsService: ConfigOptionsService,
    private readonly _logger: Logger,
    private readonly _cgLocalStorageGroupService: CGLocalStorageGroupService,
    private readonly _configService: ConfigService,
    private readonly _cgModalService: CGModalService,
    private readonly _plGlobalEventsService: PlGlobalEventsService,
    private readonly _plI18nService: PlI18nService,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    this._configOptionsDocContabilidade = this._configOptionsService.getOptionsContabilidade().get(EConfigOptionsInstanceName.DOC_CONTABILIDADE);
    this._savePromptInstances = new Map<string, IDocContabilidadeServiceSavePromptInstance>();
    this._savePromptInstanceIds = [];
    this._clearPromises = [];
    this._savePromptInstanceNextId = -1;
  }

  public ngOnDestroy(): void {
    this._savePromptInstances.forEach((instance: IDocContabilidadeServiceSavePromptInstance) => {
      this._removeSavePromptListener(instance, false);
    });
  }

  public emptyDoc(): IDocContabilidade {
    const date: Moment = moment();
    return {
      cambioRef: undefined,
      campoErroInformativo: undefined,
      codDescritivo: undefined,
      codMoeda: undefined,
      dataLancamento: date,
      dataDoc: date,
      dataRefIvaCaixa: date,
      dataVencimento: date,
      descricao: undefined,
      docExternoFixed: undefined,
      documentoExterno: undefined,
      estadIvaCaixa: undefined,
      extPocCabID: undefined,
      linhas: [],
      nContribuinte: undefined,
      nDecimaisCambio: undefined,
      nDecimaisValor: undefined,
      nDecimaisValorME: undefined,
      nDiario: undefined,
      nDocInterno: undefined,
      nDocSeq: undefined,
      nDocumento: undefined,
      nomeDescritivo: undefined,
      nomeDiario: undefined,
      nomeMoeda: undefined,
      nomePeriodo: undefined,
      origem: undefined,
      periodo: undefined,
      predefinido: undefined,
      refExterna: undefined,
      temDocDigital: undefined,
      totalDebitoGeral: 0,
      totalCreditoGeral: 0,
      revision: undefined,
      soDeLeitura: false,
      nUtilizDocFacOk: undefined,
      nomeUtilizDocFacOk: undefined,
      stampDocFacOk: undefined,
      isUsingPreDefinido: false,
      anulado: false,
      docFimAno: false,
      isDocDiferimento: false,
      isDocPrincipalDiferimento: false,
      nDocExterno: undefined,
      totalCredidoBaseTributavel: 0,
      totalCredidoIva: 0,
      totalCreditoMoedaCorrente: 0,
      totalCreditoMoedaEstrangeira: 0,
      totalDebitoBaseTributavel: 0,
      totalDebitoIva: 0,
      totalDebitoMoedaCorrente: 0,
      totalDebitoMoedaEstrangeira: 0,
      dataIniDiferimento: undefined,
      dataFimDiferimento: undefined,
      refDiferimento: '',
      tipoDataRefDiferimento: 0,
      _invalidDataLancamento: false,
      _invalidNif: false,
      _hasLinhasImputadas: false
    };
  }

  public emptyDocWithLines(numberOfLines: number = 1): IDocContabilidade {
    const docContabilidade: IDocContabilidade = this.emptyDoc();
    for (let i = 0; i < numberOfLines; i++) {
      docContabilidade.linhas.push(this.emptyLine(docContabilidade));
    }
    return docContabilidade;
  }

  public emptyLine(docContabilidade: IDocContabilidade): IDocContabilidadeLinha {
    return {
      nLanc: generateUUID(true),
      nLancImput: EMPTY_GUID,
      extPocCabID: docContabilidade.extPocCabID,
      cambio: docContabilidade.cambioRef,
      campoMeCalculado: ECampoCalculadoME.ValorME,
      cDecAnual: undefined,
      cDecPer: undefined,
      classificControlo: EClassificacaoControlo.NA,
      classificControloRetencao: EClassificacaoControloRetencao.RetidoDisponivel,
      codControloIva: undefined,
      codIva: undefined,
      codMoeda: docContabilidade.codMoeda,
      codMovAberto: true,
      codRetencao: undefined,
      dataLancamento: docContabilidade.dataLancamento,
      dataDoc: docContabilidade.dataDoc,
      dataVencimento: docContabilidade.dataVencimento,
      dc: EDebitoCredito.Debito,
      descricao: docContabilidade.descricao,
      extPocLigaIDOrigemRetNaoDispo: undefined,
      extPocLigaToSave: undefined,
      extPocRetToSave: undefined,
      isRegularizacaoCampo40Art78: false,
      listaLancsRetencaoAssociados: [],
      listaRegularizacaoCampo40: [],
      nConta: undefined,
      nContaCredito: undefined,
      nContaDebito: undefined,
      nContrib: docContabilidade.nContribuinte,
      nDescr: docContabilidade.codDescritivo,
      nDiario: docContabilidade.nDiario,
      nDocExterno: docContabilidade.documentoExterno,
      nDocInterno: docContabilidade.nDocInterno,
      nGrupo: undefined,
      nGrupoRetencao: undefined,
      nLancDestinoImputacaoCCusto: undefined,
      nLancDestinoIva: undefined,
      nLancDestinoRetencao: undefined,
      nLancOrigemImputacaoCCusto: undefined,
      nLancOrigemIva: undefined,
      nLancOrigemRetencao: undefined,
      nomeMoeda: docContabilidade.nomeMoeda,
      nSeq: docContabilidade.linhas.length,
      nUtilizador: undefined,
      periodo: docContabilidade.periodo,
      preDefinidoContabLinhaIndice: docContabilidade.isUsingPreDefinido && docContabilidade.predefinido ? docContabilidade.predefinido.linhas.length - 1 : undefined,
      taxaIvaEmVigor: undefined,
      taxaRetencaoUsada: undefined,
      temImputacoes: false,
      temMovAberto: false,
      tipoRetServNIF: undefined,
      valor: undefined,
      valorDesconto: undefined,
      valorDestinoIva: undefined,
      valorDestinoRetencao: undefined,
      valorME: undefined,
      valorOrigemIva: undefined,
      valorOrigemRetencao: undefined,
      valorPago: undefined,
      valorPagoME: undefined,
      valorRetencao: undefined,
      valorTaxa: undefined,
      valorTaxaME: undefined,
      naoPerguntaCC: false,
      saldoContaCalculado: false,
      saldoConta: undefined,
      changedDataDoc: false,
      poc: {
        bloqueada: false,
        cc: false,
        codControloIVACredito: undefined,
        codControloIVACreditoNome: undefined,
        codControloIVADebito: undefined,
        codControloIVADebitoNome: undefined,
        daq08NCampo: undefined,
        daq08NCampoNome: undefined,
        daq09NCampo: undefined,
        daq09NCampoNome: undefined,
        dpq06NCampo: undefined,
        dpq06NCampoNome: undefined,
        nConta: undefined,
        nif: undefined,
        nifNome: undefined,
        nome: undefined,
        registaRetencao: false,
        temCCusto: false,
        temIVA: false,
        temRetencao: false,
        tipo: undefined,
        codRet: undefined,
        nomeRet: undefined
      },
      entNIF: {
        nContribuinte: undefined,
        nifExtendido: undefined,
        codRet: undefined,
        tipoNIF: undefined,
        tipoRetServNIF: undefined,
        txRet: {
          codRet: undefined,
          nContaRetencao: undefined,
          nomeRet: undefined,
          nomeContaRetencao: undefined,
          nomeTipRendiment: undefined,
          sinal: undefined,
          taxaRet: undefined,
          tipoCodRet: undefined,
          tipRendiment: undefined,
          rubrica: undefined,
          zona: undefined,
          tipoDeclaracao: undefined,
          codRegTribCod: undefined
        }
      },
      _index: docContabilidade.linhas ? docContabilidade.linhas.length : 0,
      _invalidNContaDebito: false,
      _invalidNContaCredito: false,
      _invalidNif: false,
      _invalidDataDoc: false,
      _deletable: true,
      _isImputacao: false,
      _predefinidoFake: false,
      baseTributavel: 0,
      nLancDescontoRefCC: undefined
    };
  }

  public normalizeDoc(docContabilidade: IDocContabilidade): void {
    delete docContabilidade.nDocExterno;
    delete docContabilidade.totalCredidoBaseTributavel;
    delete docContabilidade.totalCredidoIva;
    delete docContabilidade.totalCreditoMoedaCorrente;
    delete docContabilidade.totalCreditoMoedaEstrangeira;
    delete docContabilidade.totalDebitoBaseTributavel;
    delete docContabilidade.totalDebitoIva;
    delete docContabilidade.totalDebitoMoedaCorrente;
    delete docContabilidade.totalDebitoMoedaEstrangeira;
    delete docContabilidade._invalidDataLancamento;
    delete docContabilidade._invalidNif;
    delete docContabilidade.campoErroInformativo;
    for (let i = docContabilidade.linhas.length - 1; i >= 0; i--) {
      const linha: IDocContabilidadeLinha = docContabilidade.linhas[i];
      if (this.isLineEmpty(linha)) {
        docContabilidade.linhas.splice(i, 1);
        continue;
      }
      delete linha._index;
      delete linha._invalidNContaDebito;
      delete linha._invalidNContaCredito;
      delete linha._invalidNif;
      delete linha._invalidDataDoc;
      delete linha._valorOriginal;
      delete linha._valorTaxaOriginal;
      delete linha._deletable;
      delete linha._predefinidoFake;
    }
  }

  public calculaTotais(docContabilidade: Partial<IDocContabilidade>, calculaGeral: boolean = true): void {
    if (!isNumber(docContabilidade.totalCreditoGeral)) {
      docContabilidade.totalDebitoGeral = 0;
    }
    if (!isNumber(docContabilidade.totalDebitoGeral)) {
      docContabilidade.totalDebitoGeral = 0;
    }
    docContabilidade.totalCredidoBaseTributavel = 0;
    docContabilidade.totalCredidoIva = 0;
    docContabilidade.totalCreditoMoedaCorrente = 0;
    docContabilidade.totalCreditoMoedaEstrangeira = 0;
    docContabilidade.totalDebitoBaseTributavel = 0;
    docContabilidade.totalDebitoIva = 0;
    docContabilidade.totalDebitoMoedaCorrente = 0;
    docContabilidade.totalDebitoMoedaEstrangeira = 0;
    const isME: boolean = isNumber(docContabilidade.codMoeda) && docContabilidade.codMoeda !== this._configurations().empresa.codMoeda;
    if (calculaGeral || isME) {
      docContabilidade.totalCreditoGeral = 0;
      docContabilidade.totalDebitoGeral = 0;
    }
    for (const linha of docContabilidade.linhas) {
      if (!linha.nContaDebito && !linha.nContaCredito) {
        continue;
      }
      if (!isNumber(linha.valor)) {
        linha.valor = 0;
      }
      if (!isNumber(linha.valorME)) {
        linha.valorME = 0;
      }
      if (linha.dc === EDebitoCredito.Credito) {
        switch (linha.classificControlo) {
          case EClassificacaoControlo.BaseTributavel:
          case EClassificacaoControlo.ValorTributavelNaoDedutivelProrrata:
          case EClassificacaoControlo.ValorTributavelNaoDedutivel:
            docContabilidade.totalCredidoBaseTributavel += linha.valor;
            break;
          case EClassificacaoControlo.IvaDedutivel:
          case EClassificacaoControlo.IvaNaoDedutivel:
          case EClassificacaoControlo.IvaNaoDedutivelProrrata:
            docContabilidade.totalCredidoIva += linha.valor;
            docContabilidade.totalCreditoMoedaCorrente += linha.valor;
            break;
          default:
            break;
        }
      } else {
        switch (linha.classificControlo) {
          case EClassificacaoControlo.BaseTributavel:
          case EClassificacaoControlo.ValorTributavelNaoDedutivelProrrata:
          case EClassificacaoControlo.ValorTributavelNaoDedutivel:
            docContabilidade.totalDebitoBaseTributavel += linha.valor;
            break;
          case EClassificacaoControlo.IvaDedutivel:
          case EClassificacaoControlo.IvaNaoDedutivel:
          case EClassificacaoControlo.IvaNaoDedutivelProrrata:
            docContabilidade.totalDebitoIva += linha.valor;
            docContabilidade.totalDebitoMoedaCorrente += linha.valor;
            break;
          default:
            break;
        }
      }

      if (!calculaGeral && !isME) {
        continue;
      }

      const tipoConta: EPocsClasse = <EPocsClasse>(isString(linha.nConta) ? linha.nConta.charAt(0) : '');
      if (tipoConta !== EPocsClasse.ORCAMENTAL && tipoConta !== EPocsClasse.ANALITICA) {
        if (linha.dc === EDebitoCredito.Credito) {
          docContabilidade.totalCreditoGeral = round(docContabilidade.totalCreditoGeral + linha.valor);
          docContabilidade.totalCreditoMoedaEstrangeira += linha.valorME;
        } else {
          docContabilidade.totalDebitoGeral = round(docContabilidade.totalDebitoGeral + linha.valor);
          docContabilidade.totalDebitoMoedaEstrangeira += linha.valorME;
        }
      }
    }
  }

  public saldaDocumento(docContabilidadeLinha: IDocContabilidadeLinha, docContabilidade: IDocContabilidade, calculaTotais: boolean): void {
    this.calculaTotais(docContabilidade);
    const valorCredito: number = docContabilidade.totalCreditoGeral;
    const valorDebito: number = docContabilidade.totalDebitoGeral;
    if (valorCredito > valorDebito) {
      docContabilidadeLinha.dc = EDebitoCredito.Debito;
      docContabilidadeLinha.nContaDebito = docContabilidadeLinha.nConta;
      docContabilidadeLinha.nContaCredito = '';
      docContabilidadeLinha.valor = round(valorCredito - valorDebito);
      if (calculaTotais) {
        this.calculaTotais(docContabilidade);
      }
    } else if (valorDebito > valorCredito) {
      docContabilidadeLinha.dc = EDebitoCredito.Credito;
      docContabilidadeLinha.nContaDebito = '';
      docContabilidadeLinha.nContaCredito = docContabilidadeLinha.nConta;
      docContabilidadeLinha.valor = round(valorDebito - valorCredito);
      if (calculaTotais) {
        this.calculaTotais(docContabilidade);
      }
    }
  }

  public isDocumentoSaldado(docContabilidade: IDocContabilidade, calculaTotais: boolean = false): boolean {
    if (calculaTotais) {
      this.calculaTotais(docContabilidade);
    }
    return numberEquals(docContabilidade.totalDebitoGeral, docContabilidade.totalCreditoGeral);
  }

  public isDocEmpty(docContabilidade: IDocContabilidade): boolean {
    return !docContabilidade.linhas.length || (docContabilidade.linhas.length === 1 && this.isLineEmpty(docContabilidade.linhas[0]));
  }

  public isLineEmpty(docContabilidadeLinha: IDocContabilidadeLinha): boolean {
    return !docContabilidadeLinha.nContaDebito && !docContabilidadeLinha.nContaCredito && !docContabilidadeLinha.valor;
  }

  public isLineDisabled(linha: IDocContabilidadeLinha): boolean {
    return (
      linha.classificControlo > EClassificacaoControlo.BaseTributavel ||
      this.isLineImputacao(linha) ||
      !isEmpty(linha.nLancOrigemImputacaoCCusto) ||
      !isEmpty(linha.nLancOrigemRetencao) ||
      isObject(linha.extPocRetToSave)
    );
  }

  public isLineImputacao(linha: IDocContabilidadeLinha): boolean {
    return linha.temImputacoes || (linha.nLancImput !== EMPTY_GUID && linha.nLanc !== linha.nLancImput);
  }

  public isLineContaCalculatedOnline(poc: IJsonPOC, docContabilidade: IDocContabilidade): boolean {
    return poc.cc || this.isLineValueCalculatedOnline(poc, docContabilidade);
  }

  public isLineValueCalculatedOnline(poc: IJsonPOC, docContabilidade: IDocContabilidade): boolean {
    return poc.temIVA || poc.temCCusto || (docContabilidade.isUsingPreDefinido && !docContabilidade.predefinido.isGeneric) || this.isMoedaPresent(docContabilidade);
  }

  public isLastEnabledLine(docContabilidade: IDocContabilidade, linha: IDocContabilidadeLinha): boolean {
    const lastLine: IDocContabilidadeLinha = findLast(docContabilidade.linhas, (docContabilidadeLinha: IDocContabilidadeLinha) => !this.isLineDisabled(docContabilidadeLinha));
    return linha === lastLine;
  }

  public isMoedaPresent(docContabilidade: IDocContabilidade, codMoeda: number = this._configurations().empresa.codMoeda): boolean {
    return isNumber(docContabilidade.codMoeda) && docContabilidade.codMoeda !== codMoeda;
  }

  public doMovimentosAberto(docContabilidade: IDocContabilidade, linha: IDocContabilidadeLinha, force: boolean = false): boolean {
    return (
      linha.temMovAberto &&
      (!linha.naoPerguntaCC || force) &&
      (!isNumber(linha.preDefinidoContabLinhaIndice) ||
        !docContabilidade.predefinido ||
        docContabilidade.predefinido.isGeneric ||
        docContabilidade.predefinido.linhas[linha.preDefinidoContabLinhaIndice].conta.tipoConta !== EPreDefinidoContabTipoConta.ContaCorrente ||
        docContabilidade.predefinido.linhas[linha.preDefinidoContabLinhaIndice].conta.contaCorrenteProperties.temImputacao)
    );
  }

  public getConfigurations(): ICGConfigContabilidade {
    return {diferencaIva: 1, moeda: '€', ...this._configurations().contabilidade};
  }

  public getBackup(): Promise<IDocContabilidade> {
    return firstValueFrom(this._cgLocalStorageGroupService.getItem<string>(NAME_BACKUP, SCHEMA_STRING, EGroupName.CONTABILIDADE)).then((value: string) => {
      if (value) {
        return fromJson(value);
      }
      return undefined;
    });
  }

  public backupDocContabilidade(value: IDocContabilidade): Promise<void> {
    return firstValueFrom(this._cgLocalStorageGroupService.setItem(NAME_BACKUP, toJson(value), SCHEMA_STRING, EGroupName.CONTABILIDADE));
  }

  public clearBackup(): Promise<void> {
    return firstValueFrom(this._cgLocalStorageGroupService.removeItem(NAME_BACKUP, EGroupName.CONTABILIDADE));
  }

  /**
   * @description Do not forget to un-listen this event whenever such behaviour is no longer expected,
   *    preferably on a component's OnDestroy cycle
   */
  public listenForSaveEvent(definition: IDocContabilidadeServiceSavePromptDefinition): Promise<void> {
    return Promise.all(this._clearPromises).then(() => {
      this._plGlobalEventsService.on(GLOBAL_EVENT_DOCUMENT_CLICK, this._generateSavePrompt(definition));
    });
  }

  public clearForSaveEventListener(identifier: string): Promise<void> {
    const clearPromise = new Promise<void>((resolve) => {
      const instance: IDocContabilidadeServiceSavePromptInstance = this._savePromptInstances.get(identifier);
      if (!instance) {
        resolve();
        return;
      }
      Promise.resolve<void>(instance.promise)
        .catch((reason: unknown) => {
          this._logger.error(reason);
        })
        .finally(() => {
          this._removeSavePromptListener(instance);
          this._clearPromises.pop();
          resolve();
        });
    });
    this._clearPromises.push(clearPromise);
    return clearPromise;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  public editValorModal<T extends object, S extends string & keyof T>(model: T, property: S, title: string, podeUltrapassarTolerancia?: boolean): Promise<T> {
    const modalInstance = this._cgModalService.showVanilla(DocsContabilidadeEditValorModalComponent, {size: 'sm'});
    const componentInstance: DocsContabilidadeEditValorModalComponent<T, S> = modalInstance.componentInstance;
    componentInstance.property = property;
    componentInstance.type = title;
    componentInstance.model = model;
    componentInstance.podeUltrapassarTolerancia = podeUltrapassarTolerancia;
    return <Promise<T>>modalInstance.result;
  }

  public evaluateVisible(docContabilidade: IDocContabilidade, field: TPredefinidoField, index?: number): boolean {
    return this.evaluateVisibilidade(docContabilidade, 'visible', field, index);
  }

  public evaluateReadOnly(docContabilidade: IDocContabilidade, field: TPredefinidoField, index?: number): boolean {
    return this.evaluateVisibilidade(docContabilidade, 'readonly', field, index);
  }

  public evaluateTabStop(docContabilidade: IDocContabilidade, field: TPredefinidoField, index?: number): boolean {
    return this.evaluateVisibilidade(docContabilidade, 'tabStop', field, index);
  }

  public evaluateVisibilidade(docContabilidade: IDocContabilidade, visibilidadeProperty: TPredefinidoVisibilidadeField, field: TPredefinidoField, index?: number): boolean {
    if (visibilidadeProperty === 'tabOrder') {
      return true;
    }
    const defaultValue: boolean = CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES[visibilidadeProperty];
    const predefinido: IJsonPreDefinidoContab = docContabilidade.predefinido;
    if (!predefinido) {
      return defaultValue;
    }
    let property: IJsonPreDefinidoContabItemDefault;
    if (isNumber(index)) {
      if (!predefinido.linhas) {
        return defaultValue;
      }
      const linha: IJsonPreDefinidoContabLinha = predefinido.linhas[index];
      if (linha) {
        property = predefinido.linhas[index][field];
      }
    } else {
      if (!predefinido.cabecalho) {
        return defaultValue;
      }
      property = predefinido.cabecalho[field];
    }
    if (property) {
      const visibilidadeProperties: IJsonPreDefinidoContabItemVisibilidadeProperties = property.visibilidadeProperties;
      if (visibilidadeProperties) {
        return visibilidadeProperties[visibilidadeProperty];
      }
    }
    return defaultValue;
  }

  public evaluateAllowEditValorTaxa(linha: IDocContabilidadeLinha): boolean {
    return linha.classificControlo === EClassificacaoControlo.BaseTributavel && linha.valorTaxa !== 0;
  }

  public getLockButtonClass(linha: IDocContabilidadeLinha, type: ECampoCalculadoME): string {
    return this.isLocked(linha, type) ? 'btn-warning' : 'btn-light';
  }

  public getLockClass(linha: IDocContabilidadeLinha, type: ECampoCalculadoME): string {
    return this.isLocked(linha, type) ? 'fa-lock' : 'fa-unlock-alt';
  }

  public doLock(linha: IDocContabilidadeLinha, type: ECampoCalculadoME): void {
    linha.campoMeCalculado = type;
  }

  public isLocked(linha: IDocContabilidadeLinha, type: ECampoCalculadoME): boolean {
    return type === linha.campoMeCalculado;
  }

  public nextSavePromptInstanceId(): number {
    this._savePromptInstanceNextId++;
    return this._savePromptInstanceNextId;
  }

  public generateHintMarcadoComoConsistente(docContabilidade: IJsonDocContabilidade): string {
    if (docContabilidade.nUtilizDocFacOk) {
      const formattedDate: string = this._plI18nService.formatDate(docContabilidade.stampDocFacOk, ECGCDateType.DATE);
      return this._translateService.instant('docscontabilidade.text.marcadoComoConsistente', {
        nome: docContabilidade.nomeUtilizDocFacOk,
        data: formattedDate
      });
    }
    return '';
  }

  public initPropertyNameOriginal<T extends object, S extends string & keyof T>(model: T, propertyName: S): IDocContabilidadePropertyNameOriginalData<T[S]> {
    const valorExistente: T[S] = model[propertyName];
    const propertyNameOriginal = `_${propertyName}Original`;
    if (!Object.prototype.hasOwnProperty.call(model, propertyNameOriginal)) {
      model[propertyNameOriginal] = valorExistente;
    }
    const valorOriginal: T[S] = model[propertyNameOriginal];
    return {
      propertyNameOriginal: propertyNameOriginal,
      valorExistente: valorExistente,
      valorOriginal: valorOriginal,
      validate: (value: number, diferencaIva: number): IDocContabilidadePropertyNameOriginalValidateData => {
        const result: IDocContabilidadePropertyNameOriginalValidateData = {
          minValue: -1,
          maxValue: -1,
          valid: true
        };
        if (!isNumber(valorOriginal)) {
          return result;
        }
        result.minValue = valorOriginal - diferencaIva;
        result.maxValue = valorOriginal + diferencaIva;
        result.valid = value >= result.minValue && value <= result.maxValue;
        return result;
      }
    };
  }

  public getLinha(docContabilidade: IJsonDocContabilidade, nLanc: string): IDocContabilidadeLinha {
    return docContabilidade.linhas.find((linha: IDocContabilidadeLinha) => linha.nLanc === nLanc);
  }

  public get configOptionsDocContabilidade(): IConfigOptionsInstance<boolean, IDocContabilidadeConfigOptions> {
    return this._configOptionsDocContabilidade;
  }

  private _configurations(): ICGConfigurations {
    return this._configService.configurations;
  }

  private _generateSavePrompt(definition: IDocContabilidadeServiceSavePromptDefinition): (event: KeyboardEvent) => void {
    const {identifier, whenNode}: IDocContabilidadeServiceSavePromptDefinition = definition;
    let instance: IDocContabilidadeServiceSavePromptInstance = this._savePromptInstances.get(identifier);
    if (instance) {
      this._removeSavePromptListener(instance);
    }
    instance = {
      identifier: identifier,
      open: false,
      promise: undefined,
      whenNode: whenNode,
      callback: (event: KeyboardEvent) => {
        this._savePrompt(definition, event);
      }
    };
    this._savePromptInstances.set(identifier, instance);
    this._savePromptInstanceIds.unshift(identifier);
    return instance.callback;
  }

  private _savePrompt(definition: IDocContabilidadeServiceSavePromptDefinition, event: KeyboardEvent): void {
    this._contabilidadeDigitalService.configs
      .getConfigs()
      .pipe(take(1))
      .subscribe((configs: IJsonContabDigitalConfigs) => {
        const {identifier, callbackGetDoc, callbackOnSave, contabilidadeDigital, simulation}: IDocContabilidadeServiceSavePromptDefinition = definition;
        const instance: IDocContabilidadeServiceSavePromptInstance = this._savePromptInstances.get(identifier);
        const whenNode: Node = instance.whenNode;
        const eventTarget: Node = <Node>event.target;
        const isMostRecentInstance: boolean = this._savePromptInstanceIds.length > 0 && instance.identifier === this._savePromptInstanceIds[0];
        const isContabilidadeDigital = isFunction(contabilidadeDigital) ? contabilidadeDigital() === true : contabilidadeDigital === true;
        const recolhaAnexaAuto = isBoolean(configs.recolhaAnexaAuto) ? configs.recolhaAnexaAuto : true;
        const attachDigitalDocs: boolean = isContabilidadeDigital && (event.key === KEYCODES.MULTIPLY || (event.key === KEYCODES.ADD && recolhaAnexaAuto));
        if (
          instance.open ||
          (event.key !== KEYCODES.ADD && event.key !== KEYCODES.EQUAL_SIGN && !attachDigitalDocs) ||
          !isMostRecentInstance ||
          (whenNode && whenNode !== eventTarget && !whenNode.contains(eventTarget))
        ) {
          return;
        }
        const docContabilidade: IDocContabilidade = callbackGetDoc();
        if (!docContabilidade) {
          return;
        }
        const saldado = this.isDocumentoSaldado(docContabilidade);
        this.configOptionsDocContabilidade
          .options()
          .pipe(take(1))
          .subscribe((configOptions: TConfigOptions<boolean, IDocContabilidadeConfigOptions>) => {
            const skipPromptSave: boolean = configOptions.get('skipPromptSave').value;
            if (saldado && skipPromptSave) {
              callbackOnSave(attachDigitalDocs);
            } else {
              instance.open = true;
              const modalInstance = this._cgModalService.showVanilla(DocsContabilidadeSavePromptModalComponent, {size: 'lg'});
              const componentInstance: DocsContabilidadeSavePromptModalComponent = modalInstance.componentInstance;
              componentInstance.docContabilidade = docContabilidade;
              componentInstance.simulation = simulation;
              componentInstance.saldado = saldado;
              componentInstance.attachDigitalDocs = attachDigitalDocs;
              instance.promise = Promise.resolve(modalInstance.result)
                .then(() => {
                  instance.open = false;
                  callbackOnSave(attachDigitalDocs);
                })
                .catch(() => {
                  instance.open = false;
                });
            }
          });
      });
  }

  private _removeSavePromptListener(instance: IDocContabilidadeServiceSavePromptInstance, pop: boolean = true): void {
    this._plGlobalEventsService.off(GLOBAL_EVENT_DOCUMENT_CLICK, instance.callback);
    this._savePromptInstances.delete(instance.identifier);
    if (pop) {
      const idIndex: number = this._savePromptInstanceIds.findIndex((instanceId) => instanceId === instance.identifier);
      if (idIndex !== -1) {
        this._savePromptInstanceIds.splice(idIndex, 1);
      }
    }
  }
}
